export const npcTrade = {
  tradeList: [
    {
      itemName: "Ancient Shield",
      itemPrice: "900",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Black Shield",
      itemPrice: "800",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Bonebreaker",
      itemPrice: "10,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Dark Armor",
      itemPrice: "400",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Dark Helmet",
      itemPrice: "250",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Hammer",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Dreaded Cleaver",
      itemPrice: "15,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Earth Knight Axe",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Energy Knight Axe",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Fiery Knight Axe",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Giant Sword",
      itemPrice: "17,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Haunted Blade",
      itemPrice: "8,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Icy Knight Axe",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Knight Armor",
      itemPrice: "5,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Knight Axe",
      itemPrice: "2,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Knight Legs",
      itemPrice: "5,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Mystic Turban",
      itemPrice: "150",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Onyx Flail",
      itemPrice: "22,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Ornamented Axe",
      itemPrice: "20,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Poison Dagger",
      itemPrice: "50",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Scimitar",
      itemPrice: "150",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Serpent Sword",
      itemPrice: "900",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Skull Staff",
      itemPrice: "6,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Strange Helmet",
      itemPrice: "500",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Titan Axe",
      itemPrice: "4,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Tower Shield",
      itemPrice: "8,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Vampire Shield",
      itemPrice: "15,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Warrior Helmet",
      itemPrice: "5,000",
      npcName: "Alesar",
      tradeType: "buy",
    },
    {
      itemName: "Ancient Shield",
      itemPrice: "5,000",
      npcName: "Alesar",
      tradeType: "sell",
    },
    {
      itemName: "Dark Armor",
      itemPrice: "1,500",
      npcName: "Alesar",
      tradeType: "sell",
    },
    {
      itemName: "Dark Helmet",
      itemPrice: "1,000",
      npcName: "Alesar",
      tradeType: "sell",
    },
    {
      itemName: "Ice Rapier",
      itemPrice: "5,000",
      npcName: "Alesar",
      tradeType: "sell",
    },
    {
      itemName: "Serpent Sword",
      itemPrice: "6,000",
      npcName: "Alesar",
      tradeType: "sell",
    },
    {
      itemName: "Bow",
      itemPrice: "100",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Bowl of Terror Sweat",
      itemPrice: "500",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Broken Visor",
      itemPrice: "1,900",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Crossbow",
      itemPrice: "120",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Dead Weight",
      itemPrice: "450",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Large)",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Medium)",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Small)",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Frazzle Skin",
      itemPrice: "400",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Frazzle Tongue",
      itemPrice: "700",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Goosebump Leather",
      itemPrice: "650",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Hemp Rope",
      itemPrice: "350",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Pool of Chitinous Glue",
      itemPrice: "480",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Sight of Surrender's Eye",
      itemPrice: "3,000",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Silencer Claws",
      itemPrice: "390",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Silencer Resonating Chamber",
      itemPrice: "600",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Spear",
      itemPrice: "3",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Trapped Bad Dream Monster",
      itemPrice: "900",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Vial",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "buy",
    },
    {
      itemName: "Animate Dead Rune",
      itemPrice: "375",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Arrow",
      itemPrice: "3",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Blue Quiver",
      itemPrice: "400",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Bolt",
      itemPrice: "4",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Bow",
      itemPrice: "400",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Crossbow",
      itemPrice: "500",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Crystalline Arrow",
      itemPrice: "20",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Diamond Arrow",
      itemPrice: "100",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Disintegrate Rune",
      itemPrice: "26",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Drill Bolt",
      itemPrice: "12",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Earth Arrow",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Energy Bomb Rune",
      itemPrice: "203",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Envenomed Arrow",
      itemPrice: "12",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Fireball Rune",
      itemPrice: "30",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Flaming Arrow",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Flash Arrow",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Great Health Potion",
      itemPrice: "225",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Great Mana Potion",
      itemPrice: "144",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Great Spirit Potion",
      itemPrice: "228",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Health Potion",
      itemPrice: "50",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Holy Missile Rune",
      itemPrice: "16",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Icicle Rune",
      itemPrice: "30",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Magic Wall Rune",
      itemPrice: "116",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Mana Potion",
      itemPrice: "56",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Onyx Arrow",
      itemPrice: "7",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Paralyse Rune",
      itemPrice: "700",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Piercing Bolt",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Poison Bomb Rune",
      itemPrice: "85",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Power Bolt",
      itemPrice: "7",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Prismatic Bolt",
      itemPrice: "20",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Quiver",
      itemPrice: "400",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Red Quiver",
      itemPrice: "400",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Royal Spear",
      itemPrice: "15",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Shiver Arrow",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Sniper Arrow",
      itemPrice: "5",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Soulfire Rune",
      itemPrice: "46",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Spear",
      itemPrice: "9",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Spectral Bolt",
      itemPrice: "70",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Stone Shower Rune",
      itemPrice: "37",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Strong Health Potion",
      itemPrice: "115",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Strong Mana Potion",
      itemPrice: "93",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Supreme Health Potion",
      itemPrice: "625",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Tarsal Arrow",
      itemPrice: "6",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Throwing Star",
      itemPrice: "42",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Thunderstorm Rune",
      itemPrice: "47",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Health Potion",
      itemPrice: "379",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Mana Potion",
      itemPrice: "438",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Spirit Potion",
      itemPrice: "438",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Vortex Bolt",
      itemPrice: "6",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Wild Growth Rune",
      itemPrice: "160",
      npcName: "Asnarus",
      tradeType: "sell",
    },
    {
      itemName: "Almanac of Magic",
      itemPrice: "600",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Animal Fetish",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Baby Rotworm",
      itemPrice: "600",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Bag with Naga Eggs",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Bale of White Cloth",
      itemPrice: "6,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Beer Bottle",
      itemPrice: "600",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Bill",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Blood Crystal",
      itemPrice: "50,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Bloodkiss Flower",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Book with Old Legends",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Bundle of Rags",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Butterfly Conservation Kit (Empty)",
      itemPrice: "40,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Carrying Device",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Cask of Brown Ale (Item)",
      itemPrice: "1,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Celestial Chart",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Cigar",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Conch Shell",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Cookbook",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Copied Research Notes",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Crate (Wine)",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Crimson Nightshade Blossoms",
      itemPrice: "7,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Damaged Logbook",
      itemPrice: "40,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Dark Essence",
      itemPrice: "17,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Dark Moon Mirror",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Dark Sun Catcher",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Deep Crystal",
      itemPrice: "13,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Dragha's Spellbook",
      itemPrice: "16,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Elemental Crystal",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Empty Starlight Vial",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Exploding Cookie",
      itemPrice: "100",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Exquisite Silk",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Exquisite Wood",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Faded Last Will",
      itemPrice: "600",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Fae Talisman",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Family Brooch",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Family Signet Ring",
      itemPrice: "15,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Fan Club Membership Card",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Filled Carrying Device",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Fishnapped Goldfish",
      itemPrice: "7,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Flask of Crown Polisher",
      itemPrice: "700",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Flask of Extra Greasy Oil",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Flask of Poison",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Flexible Dragon Scale",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Formula for a Memory Potion",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Funeral Urn",
      itemPrice: "6,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Fur of a Wolf Whelp",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Gemmed Lamp (Fa'hradin's)",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Ghost Charm",
      itemPrice: "20,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Ghost's Tear",
      itemPrice: "50,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Ghostsilver Lantern (Unlit)",
      itemPrice: "20,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Giant Ape's Hair",
      itemPrice: "24,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Gold Nuggets",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Golden Symbol of Suon",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Golem Blueprint",
      itemPrice: "13,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Golem Head",
      itemPrice: "25,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Hastily Scribbled Note",
      itemPrice: "3,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Headache Pill",
      itemPrice: "350",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Heliodor's Scrolls",
      itemPrice: "50,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Icicle Chisel",
      itemPrice: "12,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Incantation Fragment",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Ivory Lyre",
      itemPrice: "50,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Julius' Map",
      itemPrice: "25,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Letter to Markwin",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Letterbag",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Lump of Clay",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Machine Crate",
      itemPrice: "8,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Magic Crystal",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Mago Mechanic Core",
      itemPrice: "13,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Map to the Unknown",
      itemPrice: "650",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Memory Crystal",
      itemPrice: "500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Memory Stone",
      itemPrice: "3,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Monk's Diary",
      itemPrice: "3,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Morik's Helmet",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Mystic Root",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Nautical Map",
      itemPrice: "5,250",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Nightshade Distillate",
      itemPrice: "15,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Old Iron",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Old Map",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Old Power Core",
      itemPrice: "13,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Part of an Old Map (Bottom Left)",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Part of an Old Map (Upper Left)",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Part of an Old Map (Upper Right)",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Piece of Parchment (Gold)",
      itemPrice: "1,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Piece of Parchment (Shadow Bite)",
      itemPrice: "1,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Piece of Parchment (Silver)",
      itemPrice: "1,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Plans for a Strange Device",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Poison Salt Crystal",
      itemPrice: "20,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Present (Postman)",
      itemPrice: "16,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Rare Crystal",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Research Notes",
      itemPrice: "3,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Sacred Earth",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Sceptre of Sun and Sea",
      itemPrice: "50,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Secret Letter",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Shadow Orb",
      itemPrice: "12,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Sheet of Tracing Paper (Blank)",
      itemPrice: "500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Sheet of Tracing Paper (Full)",
      itemPrice: "500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Silver Nuggets",
      itemPrice: "7,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Snake Destroyer",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Soul Contract",
      itemPrice: "666",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Special Flask (Fools Guild)",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Spectral Cloth",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Spectral Dress",
      itemPrice: "15,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Spyreport",
      itemPrice: "3,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Stabilizer",
      itemPrice: "12,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Stone Tablet with Ley Lines",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Strange Powder",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Striker's Favourite Pillow",
      itemPrice: "16,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Strong Sinew",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Suspicious Documents",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Suspicious Signet Ring",
      itemPrice: "15,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Tattered Swan Feather",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Tear of Daraman",
      itemPrice: "16,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Technomancer Beard",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Alchemists' Formulas",
      itemPrice: "8,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Dust of Arthei",
      itemPrice: "40,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Dust of Boreth",
      itemPrice: "20,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Dust of Lersatio",
      itemPrice: "25,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Dust of Marziel",
      itemPrice: "30,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Ring of the Count",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "The Witches' Grimoire",
      itemPrice: "25,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Toy Mouse",
      itemPrice: "16,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Universal Tool",
      itemPrice: "550",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Unworked Sacred Wood",
      itemPrice: "1,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Waldo's Post Horn",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Whisper Moss",
      itemPrice: "18,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Whoopee Cushion",
      itemPrice: "2,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Wolf Tooth Chain (Ceiron)",
      itemPrice: "10,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Worm Queen Tooth",
      itemPrice: "12,500",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Wrinkled Parchment",
      itemPrice: "4,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "Xodet's First Wand",
      itemPrice: "5,000",
      npcName: "Black Bert",
      tradeType: "sell",
    },
    {
      itemName: "White Deer Antlers",
      itemPrice: "400",
      npcName: "Cruleo",
      tradeType: "buy",
    },
    {
      itemName: "White Deer Skin",
      itemPrice: "245",
      npcName: "Cruleo",
      tradeType: "buy",
    },
    {
      itemName: "Axe",
      itemPrice: "7",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Battle Axe",
      itemPrice: "80",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Battle Hammer",
      itemPrice: "120",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Battle Shield",
      itemPrice: "95",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Bone Club",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Bone Shoulderplate",
      itemPrice: "150",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Bone Sword",
      itemPrice: "20",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Brass Armor",
      itemPrice: "150",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Brass Helmet",
      itemPrice: "30",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Brass Legs",
      itemPrice: "49",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Brass Shield",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Broken Draken Mail",
      itemPrice: "340",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Broken Halberd",
      itemPrice: "100",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Broken Slicer",
      itemPrice: "120",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Carlin Sword",
      itemPrice: "118",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Chain Armor",
      itemPrice: "70",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Chain Helmet",
      itemPrice: "17",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Chain Legs",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Club",
      itemPrice: "1",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Coat",
      itemPrice: "1",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Copper Shield",
      itemPrice: "50",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Crowbar",
      itemPrice: "50",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Cursed Shoulder Spikes",
      itemPrice: "320",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Dagger",
      itemPrice: "2",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Double Axe",
      itemPrice: "260",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Doublet",
      itemPrice: "3",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Drachaku",
      itemPrice: "10,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Draken Boots",
      itemPrice: "40,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Draken Wristbands",
      itemPrice: "430",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Drakinata",
      itemPrice: "10,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Dwarven Shield",
      itemPrice: "100",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Elite Draken Mail",
      itemPrice: "50,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Fire Sword",
      itemPrice: "1,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Guardian Boots",
      itemPrice: "35,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Halberd",
      itemPrice: "400",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Hand Axe",
      itemPrice: "4",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Hatchet",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "High Guard Shoulderplates",
      itemPrice: "130",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Iron Helmet",
      itemPrice: "150",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Jacket",
      itemPrice: "1",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Katana",
      itemPrice: "35",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Leather Armor",
      itemPrice: "12",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Leather Boots",
      itemPrice: "2",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Leather Helmet",
      itemPrice: "4",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Leather Legs",
      itemPrice: "9",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Legion Helmet",
      itemPrice: "22",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Longsword",
      itemPrice: "51",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Mace",
      itemPrice: "30",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Morning Star",
      itemPrice: "100",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Orcish Axe",
      itemPrice: "350",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Plate Armor",
      itemPrice: "400",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Plate Legs",
      itemPrice: "115",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Plate Shield",
      itemPrice: "45",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Rapier",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Red Lantern",
      itemPrice: "250",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Sabre",
      itemPrice: "12",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Sai",
      itemPrice: "16,500",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Scale Armor",
      itemPrice: "75",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Short Sword",
      itemPrice: "10",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Sickle",
      itemPrice: "3",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Small Axe",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Soldier Helmet",
      itemPrice: "16",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Spike Sword",
      itemPrice: "240",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Spiked Iron Ball",
      itemPrice: "100",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Steel Helmet",
      itemPrice: "293",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Steel Shield",
      itemPrice: "80",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Studded Armor",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Studded Club",
      itemPrice: "10",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Studded Helmet",
      itemPrice: "20",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Studded Legs",
      itemPrice: "15",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Studded Shield",
      itemPrice: "16",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Swampling Club",
      itemPrice: "40",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Sword",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Throwing Knife",
      itemPrice: "2",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Trashed Draken Boots",
      itemPrice: "40,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Twiceslicer",
      itemPrice: "28,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Twin Hooks",
      itemPrice: "500",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Two Handed Sword",
      itemPrice: "450",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Viking Helmet",
      itemPrice: "66",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Viking Shield",
      itemPrice: "85",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Wailing Widow's Necklace",
      itemPrice: "3,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "War Hammer",
      itemPrice: "470",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Warmaster's Wristguards",
      itemPrice: "200",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Wooden Shield",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Armor",
      itemPrice: "14,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Halberd",
      itemPrice: "500",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Helmet",
      itemPrice: "45,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Legs",
      itemPrice: "14,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Shoes",
      itemPrice: "5,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaoan Sword",
      itemPrice: "30,000",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Zaogun Shoulderplates",
      itemPrice: "150",
      npcName: "Esrik",
      tradeType: "buy",
    },
    {
      itemName: "Axe",
      itemPrice: "20",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Battle Axe",
      itemPrice: "235",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Battle Hammer",
      itemPrice: "350",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Bone Sword",
      itemPrice: "75",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Brass Armor",
      itemPrice: "450",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Brass Helmet",
      itemPrice: "120",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Brass Legs",
      itemPrice: "195",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Brass Shield",
      itemPrice: "65",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Carlin Sword",
      itemPrice: "473",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Chain Armor",
      itemPrice: "200",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Chain Helmet",
      itemPrice: "52",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Chain Legs",
      itemPrice: "80",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Club",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Coat",
      itemPrice: "8",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Crowbar",
      itemPrice: "260",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Dagger",
      itemPrice: "5",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Doublet",
      itemPrice: "16",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Durable Exercise Axe",
      itemPrice: "945,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Durable Exercise Bow",
      itemPrice: "945,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Durable Exercise Club",
      itemPrice: "945,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Durable Exercise Shield",
      itemPrice: "945,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Durable Exercise Sword",
      itemPrice: "945,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Dwarven Shield",
      itemPrice: "500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Exercise Axe",
      itemPrice: "262,500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Exercise Bow",
      itemPrice: "262,500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Exercise Club",
      itemPrice: "262,500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Exercise Shield",
      itemPrice: "262,500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Exercise Sword",
      itemPrice: "262,500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Hand Axe",
      itemPrice: "8",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Iron Helmet",
      itemPrice: "390",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Jacket",
      itemPrice: "12",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lasting Exercise Axe",
      itemPrice: "7,560,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lasting Exercise Bow",
      itemPrice: "7,560,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lasting Exercise Club",
      itemPrice: "7,560,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lasting Exercise Shield",
      itemPrice: "7,560,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lasting Exercise Sword",
      itemPrice: "7,560,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Leather Armor",
      itemPrice: "35",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Leather Boots",
      itemPrice: "10",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Leather Helmet",
      itemPrice: "12",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Leather Legs",
      itemPrice: "10",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Lizard Weapon Rack",
      itemPrice: "500",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Longsword",
      itemPrice: "160",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Mace",
      itemPrice: "90",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Morning Star",
      itemPrice: "430",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Plate Armor",
      itemPrice: "1,200",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Plate Shield",
      itemPrice: "125",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Rapier",
      itemPrice: "15",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Sabre",
      itemPrice: "35",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Scale Armor",
      itemPrice: "260",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Short Sword",
      itemPrice: "26",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Sickle",
      itemPrice: "7",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Soldier Helmet",
      itemPrice: "110",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Spike Sword",
      itemPrice: "8,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Steel Helmet",
      itemPrice: "580",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Steel Shield",
      itemPrice: "240",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Studded Armor",
      itemPrice: "90",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Studded Helmet",
      itemPrice: "63",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Studded Legs",
      itemPrice: "50",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Studded Shield",
      itemPrice: "50",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Sword",
      itemPrice: "85",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Throwing Knife",
      itemPrice: "25",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Twin Hooks",
      itemPrice: "1,100",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Two Handed Sword",
      itemPrice: "950",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Viking Helmet",
      itemPrice: "265",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Viking Shield",
      itemPrice: "260",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "War Hammer",
      itemPrice: "10,000",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Wooden Shield",
      itemPrice: "15",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Zaoan Halberd",
      itemPrice: "1,200",
      npcName: "Esrik",
      tradeType: "sell",
    },
    {
      itemName: "Collar of Blue Plasma",
      itemPrice: "6,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Collar of Green Plasma",
      itemPrice: "6,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Collar of Red Plasma",
      itemPrice: "6,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Condensed Energy",
      itemPrice: "260",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Bone",
      itemPrice: "250",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Crystallized Anger",
      itemPrice: "400",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Curious Matter",
      itemPrice: "430",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Dangerous Proto Matter",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Energy Ball",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Energy Vein",
      itemPrice: "270",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Frozen Lightning",
      itemPrice: "270",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Glistening Bone",
      itemPrice: "250",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Green Bandage",
      itemPrice: "180",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Instable Proto Matter",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Little Bowl of Myrrh",
      itemPrice: "500",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Odd Organ",
      itemPrice: "410",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Plasma Pearls",
      itemPrice: "250",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Plasmatic Lightning",
      itemPrice: "270",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Ring of Blue Plasma",
      itemPrice: "8,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Ring of Green Plasma",
      itemPrice: "8,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Ring of Red Plasma",
      itemPrice: "8,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Single Human Eye",
      itemPrice: "1,000",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Small Energy Ball",
      itemPrice: "250",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Solid Rage",
      itemPrice: "310",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Spark Sphere",
      itemPrice: "350",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Claw",
      itemPrice: "290",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Legs",
      itemPrice: "310",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Stings",
      itemPrice: "280",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Tail",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Strange Proto Matter",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Volatile Proto Matter",
      itemPrice: "300",
      npcName: "Fadil",
      tradeType: "buy",
    },
    {
      itemName: "Black Pearl",
      itemPrice: "280",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "Onyx Chip",
      itemPrice: "500",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "Opal",
      itemPrice: "500",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "Small Ruby",
      itemPrice: "250",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "Small Topaz",
      itemPrice: "200",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "White Pearl",
      itemPrice: "160",
      npcName: "Larek",
      tradeType: "buy",
    },
    {
      itemName: "Cookie",
      itemPrice: "7",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Flour",
      itemPrice: "30",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Hoe",
      itemPrice: "15",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Juice Squeezer",
      itemPrice: "100",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Kitchen Knife",
      itemPrice: "20",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Rope",
      itemPrice: "50",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Shovel",
      itemPrice: "50",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Vial",
      itemPrice: "20",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Vial",
      itemPrice: "50",
      npcName: "Larek",
      tradeType: "sell",
    },
    {
      itemName: "Axe Ring",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Bronze Amulet",
      itemPrice: "50",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Club Ring",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Elven Amulet",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Garlic Necklace",
      itemPrice: "50",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Life Crystal",
      itemPrice: "50",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Magic Light Wand",
      itemPrice: "35",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Mind Stone",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Orb",
      itemPrice: "750",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Power Ring",
      itemPrice: "50",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Stealth Ring",
      itemPrice: "200",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Stone Skin Amulet",
      itemPrice: "500",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Sword Ring",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Cosmic Energy",
      itemPrice: "2,000",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Decay",
      itemPrice: "1,000",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Defiance",
      itemPrice: "6,500",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Draconia",
      itemPrice: "1,500",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Dragonbreath",
      itemPrice: "200",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Everblazing",
      itemPrice: "6,000",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Inferno",
      itemPrice: "3,000",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Starstorm",
      itemPrice: "3,600",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Voodoo",
      itemPrice: "4,400",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Wand of Vortex",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "buy",
    },
    {
      itemName: "Axe Ring",
      itemPrice: "500",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Bronze Amulet",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Club Ring",
      itemPrice: "500",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Elven Amulet",
      itemPrice: "500",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Enchanted Chicken Wing",
      itemPrice: "Boots of Haste",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Flask of Warrior's Sweat",
      itemPrice: "4 Warrior Helmets",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Garlic Necklace",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Magic Light Wand",
      itemPrice: "120",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Magic Sulphur",
      itemPrice: "3 Fire Swords",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Power Ring",
      itemPrice: "100",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Spirit Container",
      itemPrice: "2 Royal Helmets.",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Stealth Ring",
      itemPrice: "5,000",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Stone Skin Amulet",
      itemPrice: "5,000",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Sword Ring",
      itemPrice: "500",
      npcName: "Haroun",
      tradeType: "sell",
    },
    {
      itemName: "Angelic Axe",
      itemPrice: "5,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Blue Robe",
      itemPrice: "10,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Bonelord Shield",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Boots of Haste",
      itemPrice: "30,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Broadsword",
      itemPrice: "500",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Butcher's Axe",
      itemPrice: "18,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Crown Armor",
      itemPrice: "12,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Crown Helmet",
      itemPrice: "2,500",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Crown Legs",
      itemPrice: "12,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Crown Shield",
      itemPrice: "8,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Crusader Helmet",
      itemPrice: "6,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Lance",
      itemPrice: "9,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Shield",
      itemPrice: "4,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Earth Spike Sword",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Earth War Hammer",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Energy Spike Sword",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Energy War Hammer",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Fiery Spike Sword",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Fiery War Hammer",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Fire Axe",
      itemPrice: "8,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Fire Sword",
      itemPrice: "4,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Glorious Axe",
      itemPrice: "3,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Guardian Shield",
      itemPrice: "2,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Ice Rapier",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Icy Spike Sword",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Icy War Hammer",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Noble Armor",
      itemPrice: "900",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Obsidian Lance",
      itemPrice: "500",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Phoenix Shield",
      itemPrice: "16,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Queen's Sceptre",
      itemPrice: "20,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Royal Helmet",
      itemPrice: "30,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Shadow Sceptre",
      itemPrice: "10,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Spike Sword",
      itemPrice: "1,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Thaian Sword",
      itemPrice: "16,000",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "War Hammer",
      itemPrice: "1,200",
      npcName: "Nah'Bob",
      tradeType: "buy",
    },
    {
      itemName: "Bonelord Shield",
      itemPrice: "7,000",
      npcName: "Nah'Bob",
      tradeType: "sell",
    },
    {
      itemName: "Noble Armor",
      itemPrice: "8,000",
      npcName: "Nah'Bob",
      tradeType: "sell",
    },
    {
      itemName: "Obsidian Lance",
      itemPrice: "3,000",
      npcName: "Nah'Bob",
      tradeType: "sell",
    },
    {
      itemName: "Spike Sword",
      itemPrice: "8,000",
      npcName: "Nah'Bob",
      tradeType: "sell",
    },
    {
      itemName: "War Hammer",
      itemPrice: "10,000",
      npcName: "Nah'Bob",
      tradeType: "sell",
    },
    {
      itemName: "Fishing Rod",
      itemPrice: "30",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Leather Harness",
      itemPrice: "750",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Life Preserver",
      itemPrice: "300",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Ratana",
      itemPrice: "500",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Rope",
      itemPrice: "8",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Shovel",
      itemPrice: "2",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Spike Shield",
      itemPrice: "250",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Spiky Club",
      itemPrice: "300",
      npcName: "Rafzan",
      tradeType: "buy",
    },
    {
      itemName: "Advertisement Sign",
      itemPrice: "75",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Backpack",
      itemPrice: "10",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Bag",
      itemPrice: "4",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Bottle with Rat Urine",
      itemPrice: "150",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Fishing Rod",
      itemPrice: "150",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Guardcatcher",
      itemPrice: "200",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Perfume Gatherer (First)",
      itemPrice: "400",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Rope",
      itemPrice: "50",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Scroll",
      itemPrice: "5",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Scythe",
      itemPrice: "12",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Shovel",
      itemPrice: "10",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Torch",
      itemPrice: "2",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Trunkhammer",
      itemPrice: "150",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Worm",
      itemPrice: "1",
      npcName: "Rafzan",
      tradeType: "sell",
    },
    {
      itemName: "Abyss Hammer",
      itemPrice: "20,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Albino Plate",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Amber Staff",
      itemPrice: "8,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Ancient Amulet",
      itemPrice: "200",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Assassin Dagger",
      itemPrice: "20,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Bandana",
      itemPrice: "150",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Beastslayer Axe",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Beetle Necklace",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Berserker",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Blacksteel Sword",
      itemPrice: "6,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Blessed Sceptre",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Bone Shield",
      itemPrice: "80",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Bonelord Helmet",
      itemPrice: "7,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Brutetamer's Staff",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Buckle",
      itemPrice: "7,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Castle Shield",
      itemPrice: "5,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Chain Bolter",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Chaos Mace",
      itemPrice: "9,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Cobra Crown",
      itemPrice: "50,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Coconut Shoes",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Composite Hornbow",
      itemPrice: "25,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Cranial Basher",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crocodile Boots",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Crossbow",
      itemPrice: "35,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Mace",
      itemPrice: "12,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Necklace",
      itemPrice: "400",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Ring",
      itemPrice: "250",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Sword",
      itemPrice: "600",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Crystalline Armor",
      itemPrice: "16,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Daramian Mace",
      itemPrice: "110",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Daramian Waraxe",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dark Shield",
      itemPrice: "400",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Death Ring",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Demon Shield",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Demonbone Amulet",
      itemPrice: "32,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Demonrage Sword",
      itemPrice: "36,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Devil Helmet",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Diamond Sceptre",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Divine Plate",
      itemPrice: "55,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Djinn Blade",
      itemPrice: "15,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Doll",
      itemPrice: "200",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Scale Mail",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Slayer",
      itemPrice: "15,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dragonbone Staff",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dreaded Cleaver",
      itemPrice: "10,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Dwarven Armor",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Elvish Bow",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Emerald Bangle",
      itemPrice: "800",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Epee",
      itemPrice: "8,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Flower Dress",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Flower Wreath",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Fur Boots",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Furry Club",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Glacier Amulet",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Glacier Kilt",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Glacier Mask",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Glacier Robe",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Glacier Shoes",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Gold Ring",
      itemPrice: "8,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Golden Armor",
      itemPrice: "20,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Golden Legs",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Goo Shell",
      itemPrice: "4,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Griffin Shield",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Guardian Halberd",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Hammer of Wrath",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Headchopper",
      itemPrice: "6,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Heavy Mace",
      itemPrice: "50,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Heavy Machete",
      itemPrice: "90",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Heavy Trident",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Helmet of the Lost",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Heroic Axe",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Hibiscus Dress",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Hieroglyph Banner",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Horn (Ring)",
      itemPrice: "300",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Jade Hammer",
      itemPrice: "25,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Krimhorn Helmet",
      itemPrice: "200",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lavos Armor",
      itemPrice: "16,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Leaf Legs",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Leopard Armor",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Leviathan's Amulet",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Light Shovel",
      itemPrice: "300",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lightning Boots",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lightning Headband",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lightning Legs",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lightning Pendant",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lightning Robe",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Lunar Staff",
      itemPrice: "5,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magic Plate Armor",
      itemPrice: "90,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magma Amulet",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magma Boots",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magma Coat",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magma Legs",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Magma Monocle",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mammoth Fur Cape",
      itemPrice: "6,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mammoth Fur Shorts",
      itemPrice: "850",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mammoth Whopper",
      itemPrice: "300",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mastermind Shield",
      itemPrice: "50,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Medusa Shield",
      itemPrice: "9,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mercenary Sword",
      itemPrice: "12,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Model Ship",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mycological Bow",
      itemPrice: "35,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Mystic Blade",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Naginata",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Nightmare Blade",
      itemPrice: "35,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Noble Axe",
      itemPrice: "10,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Norse Shield",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Onyx Pendant",
      itemPrice: "3,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Orcish Maul",
      itemPrice: "6,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Oriental Shoes",
      itemPrice: "15,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pair of Iron Fists",
      itemPrice: "4,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Paladin Armor",
      itemPrice: "15,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Patched Boots",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pharaoh Banner",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pharaoh Sword",
      itemPrice: "23,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Boots",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Hat",
      itemPrice: "1,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Knee Breeches",
      itemPrice: "200",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Shirt",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Voodoo Doll",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Platinum Amulet",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Ragnir Helmet",
      itemPrice: "400",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Relic Sword",
      itemPrice: "25,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Rift Bow",
      itemPrice: "45,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Rift Crossbow",
      itemPrice: "45,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Rift Lance",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Rift Shield",
      itemPrice: "50,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Ring of the Sky",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Royal Axe",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Ruby Necklace",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Ruthless Axe",
      itemPrice: "45,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Sacred Tree Amulet",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Sapphire Hammer",
      itemPrice: "7,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Scarab Amulet",
      itemPrice: "200",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Scarab Shield",
      itemPrice: "2,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Shockwave Amulet",
      itemPrice: "3,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Silver Brooch",
      itemPrice: "150",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Silver Dagger",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Skull Helmet",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Skullcracker Armor",
      itemPrice: "18,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Spiked Squelcher",
      itemPrice: "5,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Steel Boots",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Swamplair Armor",
      itemPrice: "16,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Taurus Mace",
      itemPrice: "500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Tempest Shield",
      itemPrice: "35,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Terra Amulet",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Terra Boots",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Terra Hood",
      itemPrice: "2,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Terra Legs",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Terra Mantle",
      itemPrice: "11,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "The Justice Seeker",
      itemPrice: "40,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Tortoise Shield",
      itemPrice: "150",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Vile Axe",
      itemPrice: "30,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Voodoo Doll",
      itemPrice: "400",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "War Axe",
      itemPrice: "12,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "War Horn",
      itemPrice: "8,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Witch Hat",
      itemPrice: "5,000",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Wyvern Fang",
      itemPrice: "1,500",
      npcName: "Rashid",
      tradeType: "buy",
    },
    {
      itemName: "Colourful Feather",
      itemPrice: "110",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Large)",
      itemPrice: "5",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Medium)",
      itemPrice: "5",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Empty Potion Flask (Small)",
      itemPrice: "5",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Golden Lotus Brooch",
      itemPrice: "270",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Hellspawn Tail",
      itemPrice: "475",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Mammoth Tusk",
      itemPrice: "100",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Orc Tusk",
      itemPrice: "700",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Peacock Feather Fan",
      itemPrice: "350",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Sabretooth",
      itemPrice: "400",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Spider Silk",
      itemPrice: "100",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Tusk",
      itemPrice: "100",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Vial",
      itemPrice: "5",
      npcName: "Tarun",
      tradeType: "buy",
    },
    {
      itemName: "Great Health Potion",
      itemPrice: "225",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Great Mana Potion",
      itemPrice: "144",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Great Spirit Potion",
      itemPrice: "228",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Health Potion",
      itemPrice: "50",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Mana Potion",
      itemPrice: "56",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Strong Health Potion",
      itemPrice: "115",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Strong Mana Potion",
      itemPrice: "93",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Supreme Health Potion",
      itemPrice: "625",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Health Potion",
      itemPrice: "379",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Mana Potion",
      itemPrice: "438",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Ultimate Spirit Potion",
      itemPrice: "438",
      npcName: "Tarun",
      tradeType: "sell",
    },
    {
      itemName: "Ankh",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Necklace",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Dwarven Ring",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Energy Ring",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Glacial Rod",
      itemPrice: "6,500",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Hailstorm Rod",
      itemPrice: "3,000",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Life Ring",
      itemPrice: "50",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Might Ring",
      itemPrice: "250",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Moonlight Rod",
      itemPrice: "200",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Muck Rod",
      itemPrice: "6,000",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Mysterious Fetish",
      itemPrice: "50",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Necrotic Rod",
      itemPrice: "1,000",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Northwind Rod",
      itemPrice: "1,500",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Protection Amulet",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Ring of Healing",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Silver Amulet",
      itemPrice: "50",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Snakebite Rod",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Springsprout Rod",
      itemPrice: "3,600",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Strange Talisman",
      itemPrice: "30",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Terra Rod",
      itemPrice: "2,000",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Time Ring",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Underworld Rod",
      itemPrice: "4,400",
      npcName: "Yaman",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Necklace",
      itemPrice: "1,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Dwarven Ring",
      itemPrice: "2,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Enchanted Chicken Wing",
      itemPrice: "Boots of Haste",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Energy Ring",
      itemPrice: "2,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Flask of Warrior's Sweat",
      itemPrice: "4 Warrior Helmets",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Life Ring",
      itemPrice: "900",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Magic Sulphur",
      itemPrice: "3 Fire Swords",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Might Ring",
      itemPrice: "5,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Protection Amulet",
      itemPrice: "700",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Ring of Healing",
      itemPrice: "2,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Silver Amulet",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Spirit Container",
      itemPrice: "2 Royal Helmets.",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Strange Talisman",
      itemPrice: "100",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Time Ring",
      itemPrice: "2,000",
      npcName: "Yaman",
      tradeType: "sell",
    },
    {
      itemName: "Abomination's Eye",
      itemPrice: "650,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Abomination's Tail",
      itemPrice: "700,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Abomination's Tongue",
      itemPrice: "950,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Acorn",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Afflicted Strider Head",
      itemPrice: "900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Afflicted Strider Worms",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Alptramun's Toothbrush",
      itemPrice: "270,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ancient Belt Buckle",
      itemPrice: "260",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ancient Liche Bone",
      itemPrice: "28,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Angel Figurine",
      itemPrice: "36,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Antlers",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ape Fur",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Apron",
      itemPrice: "1,300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Badger Fur",
      itemPrice: "15",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bakragore's Amalgamation",
      itemPrice: "2,000,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bamboo Stick",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Banana Sash",
      itemPrice: "55",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Basalt Core",
      itemPrice: "5,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Basalt Crumbs",
      itemPrice: "3,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Basalt Fetish",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Basalt Figurine",
      itemPrice: "160",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bashmu Fang",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bashmu Feather",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bashmu Tongue",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bat Decoration",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bat Wing",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bear Paw",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Beast's Nightmare-Cushion",
      itemPrice: "630,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bed of Nails",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Beer Tap",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Beetle Carapace",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Behemoth Claw",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Black Hood",
      itemPrice: "190",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Black Wool",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blazing Bone",
      itemPrice: "610",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blemished Spawn Abdomen",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blemished Spawn Head",
      itemPrice: "800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blemished Spawn Tail",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bloated Maggot",
      itemPrice: "5,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blood Preservation",
      itemPrice: "320",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blood Tincture in a Vial",
      itemPrice: "360",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blooded Worm",
      itemPrice: "4,700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bloody Dwarven Beard",
      itemPrice: "110",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bloody Pincers",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bloody Tears",
      itemPrice: "70,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blue Glass Plate",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blue Goanna Scale",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Blue Piece of Cloth",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Boar Man Hoof",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Boggy Dreads",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bola",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bone Fetish",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bone Shoulderplate",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bone Toothpick",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bonecarving Knife",
      itemPrice: "190",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bonelord Eye",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bones of Zorvorax",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bony Tail",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Book Page",
      itemPrice: "640",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Book of Necromantic Rituals",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Book of Prayers",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bowl of Terror Sweat",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brain Head's Giant Neuron",
      itemPrice: "100,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brain Head's Left Hemisphere",
      itemPrice: "90,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brain Head's Right Hemisphere",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brainstealer's Brain",
      itemPrice: "300,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brainstealer's Brainwave",
      itemPrice: "440,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brainstealer's Tissue",
      itemPrice: "240,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bright Bell",
      itemPrice: "220",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brimstone Fangs",
      itemPrice: "380",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brimstone Shell",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Crossbow",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Draken Mail",
      itemPrice: "340",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Halberd",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Helmet",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Iks Cuirass",
      itemPrice: "640",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Iks Faulds",
      itemPrice: "530",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Iks Headpiece",
      itemPrice: "560",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Iks Sandals",
      itemPrice: "440",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Iks Spear",
      itemPrice: "685",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Key Ring",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Longbow",
      itemPrice: "130",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Macuahuitl",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Ring of Ending",
      itemPrice: "4,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Shamanic Staff",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Slicer",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Throwing Axe",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Broken Visor",
      itemPrice: "1,900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brooch of Embracement",
      itemPrice: "14,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Brown Piece of Cloth",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bunch of Troll Hair",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Bundle of Cursed Straw",
      itemPrice: "800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Capricious Heart",
      itemPrice: "2,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Capricious Robe",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Carniphila Seeds",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Carnisylvan Bark",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Carnisylvan Finger",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Carnivostrich Feather",
      itemPrice: "630",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Carrion Worm Fang",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cat's Paw",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cave Chimera Head",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cave Chimera Leg",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cave Devourer Eyes",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cave Devourer Legs",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cave Devourer Maw",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Centipede Leg",
      itemPrice: "28",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chargoz Igneous Obsidian",
      itemPrice: "1,100,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chasm Spawn Abdomen",
      itemPrice: "240",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chasm Spawn Head",
      itemPrice: "850",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chasm Spawn Tail",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cheese Cutter",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cheesy Figurine",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cheesy Membership Card",
      itemPrice: "120,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chicken Feather",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chitinous Mouth (Baron from Below)",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Chitinous Mouth (Count of the Core)",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cliff Strider Claw",
      itemPrice: "800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Closed Pocket Sundial",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cobra Crest",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cobra Tongue",
      itemPrice: "15",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Colourful Feather",
      itemPrice: "110",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Colourful Feathers",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Colourful Snail Shell",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Compass",
      itemPrice: "45",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Compound Eye",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Condensed Energy",
      itemPrice: "260",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Coral Branch",
      itemPrice: "360",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Corrupt Naga Scales",
      itemPrice: "570",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Corrupted Flag",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Countess Sorrow's Frozen Tear",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cow Bell",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crab Man Claws",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crab Pincers",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cracked Alabaster Vase",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crawler Head Plating",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crawler's Essence",
      itemPrice: "3,700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crown (Plant)",
      itemPrice: "2,700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cruelty's Chest",
      itemPrice: "720,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cruelty's Claw",
      itemPrice: "640,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cry-Stal",
      itemPrice: "3,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crystal Bone",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Crystallized Anger",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cultish Mask",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cultish Robe",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cultish Symbol",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Curious Matter",
      itemPrice: "430",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Curl of Hair",
      itemPrice: "320,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cursed Bone",
      itemPrice: "6,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cursed Shoulder Spikes",
      itemPrice: "320",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Cyclops Toe",
      itemPrice: "55",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Damaged Armor Plates",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Damaged Worm Head",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Damselfly Eye",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Damselfly Wing",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dandelion Seeds",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dangerous Proto Matter",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dark Bell",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dark Bell (Silver)",
      itemPrice: "310,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dark Obsidian Splinter",
      itemPrice: "4,400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dark Rosary",
      itemPrice: "48",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Darklight Basalt Chunk",
      itemPrice: "3,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Darklight Core (Object)",
      itemPrice: "4,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Darklight Figurine",
      itemPrice: "3,400,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Darklight Matter",
      itemPrice: "5,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dead Weight",
      itemPrice: "450",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Decayed Finger Bone",
      itemPrice: "5,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Breaktime Snack",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Claw",
      itemPrice: "430",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Guard Belt Buckle",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Ridge",
      itemPrice: "360",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Scales",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepling Warts",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deeptags",
      itemPrice: "290",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepworm Jaws",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepworm Spike Roots",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Deepworm Spikes",
      itemPrice: "800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Demon Dust",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Demon Horn",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Demonic Finger",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Demonic Skeletal Hand",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Diabolic Skull",
      itemPrice: "19,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Diremaw Brainpan",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Diremaw Legs",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dirty Turban",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Distorted Heart",
      itemPrice: "2,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Distorted Robe",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Downy Feather",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dowser",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dracola's Eye",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dracoyle Statue",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dracoyle Statue (Enchanted)",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Blood",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Claw",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Priest's Wandtip",
      itemPrice: "175",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dragon Tongue",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dragon's Tail",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Draken Sulphur",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Draken Wristbands",
      itemPrice: "430",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dream Essence Egg",
      itemPrice: "205",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Dung Ball",
      itemPrice: "130",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Earflap",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Elder Bonelord Tentacle",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Elven Astral Observer",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Elven Hoof",
      itemPrice: "115",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Elven Scouting Glass",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Elvish Talisman",
      itemPrice: "45",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Emerald Tortoise Shell",
      itemPrice: "2,150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Empty Honey Glass",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Enchanted Chicken Wing",
      itemPrice: "20,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Energy Ball",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Energy Vein",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ensouled Essence",
      itemPrice: "820",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Essence of a Bad Dream",
      itemPrice: "360",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eye of Corruption",
      itemPrice: "390",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eye of a Deepling",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eye of a Weeper",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eyeless Devourer Legs",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eyeless Devourer Maw",
      itemPrice: "420",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Eyeless Devourer Tongue",
      itemPrice: "900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fafnar Symbol",
      itemPrice: "950",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fairy Wings",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Falcon Crest",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fern",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fiery Heart",
      itemPrice: "375",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fig Leaf",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Bakragore",
      itemPrice: "5,400,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Cruelty",
      itemPrice: "3,100,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Greed",
      itemPrice: "2,900,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Hatred",
      itemPrice: "2,700,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Malice",
      itemPrice: "2,800,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Megalomania",
      itemPrice: "5,000,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Figurine of Spite",
      itemPrice: "3,000,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fir Cone",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fish Fin",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Flask of Embalming Fluid",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Flask of Warrior's Sweat",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Flotsam",
      itemPrice: "330",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fox Paw",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frazzle Skin",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frazzle Tongue",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frost Giant Pelt",
      itemPrice: "160",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frosty Ear of a Troll",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frosty Heart",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Frozen Lightning",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Fur Shred",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gauze Bandage",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Geomancer's Robe",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Geomancer's Staff",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ghastly Dragon Head",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ghostly Tissue",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ghoul Snack",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Giant Eye",
      itemPrice: "380",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Giant Tentacle",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Girlish Hair Decoration",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Girtablilu Warrior Carapace",
      itemPrice: "520",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gland",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Glistening Bone",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Glob of Acid Slime",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Glob of Mercury",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Glob of Tar",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gloom Wolf Fur",
      itemPrice: "70",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Glowing Rune",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Goanna Claw",
      itemPrice: "260",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Goanna Meat",
      itemPrice: "190",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Goblet of Gloom",
      itemPrice: "12,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Goblin Ear",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gold-Brocaded Cloth",
      itemPrice: "175",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Brush",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Cheese Wedge",
      itemPrice: "6,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Dustbin",
      itemPrice: "7,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Lotus Brooch",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Mask",
      itemPrice: "38,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Skull",
      itemPrice: "9,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Sun Coin",
      itemPrice: "11,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Golden Tiger Coin",
      itemPrice: "11,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Goosebump Leather",
      itemPrice: "650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gore Horn (Item)",
      itemPrice: "2,900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gorerilla Mane",
      itemPrice: "2,750",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gorerilla Tail",
      itemPrice: "2,650",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Grant of Arms",
      itemPrice: "950",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Grappling Hook",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Greed's Arm",
      itemPrice: "950,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Green Bandage",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Green Dragon Leather",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Green Dragon Scale",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Green Glass Plate",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Green Piece of Cloth",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Grimace",
      itemPrice: "120,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Gruesome Fan",
      itemPrice: "15,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Guidebook",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hair of a Banshee",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Half-Digested Piece of Meat",
      itemPrice: "55",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Half-Digested Stones",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Half-Eaten Brain",
      itemPrice: "85",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hand",
      itemPrice: "1,450",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hardened Bone",
      itemPrice: "70",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Harpoon of a Giant Snail",
      itemPrice: "15,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Harpy Feathers",
      itemPrice: "730",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hatched Rorc Egg",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Haunted Piece of Wood",
      itemPrice: "115",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hazardous Heart",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hazardous Robe",
      itemPrice: "3,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Head (Brachiodemon)",
      itemPrice: "3,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Head (Many Faces)",
      itemPrice: "3,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Headpecker Beak",
      itemPrice: "2,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Headpecker Feather",
      itemPrice: "1,300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Heaven Blossom",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hellhound Slobber",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hellspawn Tail",
      itemPrice: "475",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hemp Rope",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hideous Chunk",
      itemPrice: "510",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "High Guard Flag",
      itemPrice: "550",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "High Guard Shoulderplates",
      itemPrice: "130",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Holy Ash",
      itemPrice: "160",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Holy Orchid",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Honeycomb",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Horn of Kalyassa",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Horoscope",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Huge Shell",
      itemPrice: "15,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Huge Spiky Snail Shell",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Human Teeth",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Humongous Chunk",
      itemPrice: "540",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hunter's Quiver",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hydra Head",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Hydrophytes",
      itemPrice: "220",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ice Flower (Item)",
      itemPrice: "370",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ichgahal's Fungal Infestation",
      itemPrice: "900,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Incantation Notes",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Infernal Heart",
      itemPrice: "2,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Infernal Robe",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Inkwell (Black)",
      itemPrice: "720",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Instable Proto Matter",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Iron Ore",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ivory Carving",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ivory Comb",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Izcandar's Snow Globe",
      itemPrice: "180,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Izcandar's Sundial",
      itemPrice: "225,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jagged Sickle",
      itemPrice: "150,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jaws",
      itemPrice: "3,900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jewelled Belt",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jungle Moa Claw",
      itemPrice: "160",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jungle Moa Egg",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Jungle Moa Feather",
      itemPrice: "140",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Katex' Blood",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Key to the Drowned Library",
      itemPrice: "330",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Kollos Shell",
      itemPrice: "420",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Kongra's Shoulderpad",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lamassu Hoof",
      itemPrice: "330",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lamassu Horn",
      itemPrice: "240",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lancer Beetle Shell",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lancet",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lavafungus Head",
      itemPrice: "900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lavafungus Ring",
      itemPrice: "390",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lavaworm Jaws",
      itemPrice: "1,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lavaworm Spike Roots",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lavaworm Spikes",
      itemPrice: "750",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Legionnaire Flags",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Liodile Fang",
      itemPrice: "480",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lion Cloak Patch",
      itemPrice: "190",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lion Crest",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lion Seal",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lion's Mane",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Little Bowl of Myrrh",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lizard Essence",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lizard Heart",
      itemPrice: "530",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lizard Leather",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lizard Scale",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Longing Eyes",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lost Basher's Spike",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lost Bracers",
      itemPrice: "140",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lost Husher's Staff",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lost Soul (Item)",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Luminescent Crystal Pickaxe",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Luminous Orb",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lump of Dirt",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Lump of Earth",
      itemPrice: "130",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mad Froth",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Magic Sulphur",
      itemPrice: "8,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Makara Fin",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Makara Tongue",
      itemPrice: "320",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Malice's Horn",
      itemPrice: "620,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Malice's Spine",
      itemPrice: "850,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Malofur's Lunchbox",
      itemPrice: "240,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mammoth Tusk",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mantassin Tail",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Manticore Ear",
      itemPrice: "310",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Manticore Tail",
      itemPrice: "220",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mantosaurus Jaw",
      itemPrice: "2,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Marsh Stalker Beak",
      itemPrice: "65",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Marsh Stalker Feather",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Maxxenius Head",
      itemPrice: "500,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Meat Hammer",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Medal of Valiance",
      itemPrice: "410,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Megalomania's Essence",
      itemPrice: "1,900,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Megalomania's Skull",
      itemPrice: "1,500,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mercurial Wing",
      itemPrice: "2,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Milk Churn",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Minotaur Horn",
      itemPrice: "75",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Minotaur Leather",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Miraculum",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Moon Compass",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Moon Pin",
      itemPrice: "18,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Morbid Tapestry",
      itemPrice: "30,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Morshabaal's Brain",
      itemPrice: "5,000,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Morshabaal's Extract",
      itemPrice: "3,250,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mould Heart",
      itemPrice: "2,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mould Robe",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mouldy Powder",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mr. Punish's Handcuffs",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Murcion's Mycelium",
      itemPrice: "950,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mutated Bat Ear",
      itemPrice: "420",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mutated Flesh",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mutated Rat Tail",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Mystical Hourglass",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Naga Archer Scales",
      itemPrice: "340",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Naga Armring",
      itemPrice: "390",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Naga Earring",
      itemPrice: "380",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Naga Warrior Scales",
      itemPrice: "340",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Necromantic Robe",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Nettle Blossom",
      itemPrice: "75",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Nettle Spit",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Nighthunter Wing",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Noble Amulet",
      itemPrice: "430,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Noble Cape",
      itemPrice: "425,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Noble Turban",
      itemPrice: "430",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Nose Ring",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Odd Organ",
      itemPrice: "410",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ogre Ear Stud",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ogre Nose Ring",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Old Girtablilu Carapace",
      itemPrice: "570",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Old Royal Diary",
      itemPrice: "220,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "One of Timira's Many Heads",
      itemPrice: "215,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Orc Leather",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Orc Tooth",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Orcish Gear",
      itemPrice: "85",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pair of Hellflayer Horns",
      itemPrice: "1,300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pair of Old Bracers",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pale Worm's Scalp",
      itemPrice: "489,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Parder Fur",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Parder Tooth",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Patch of Fine Cloth",
      itemPrice: "1,350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Peacock Feather Fan",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pelvis Bone",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Perfect Behemoth Fang",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Petrified Scream",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Phantasmal Hair",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Archer Armor",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Crocodile Leather",
      itemPrice: "15",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Dead Brain",
      itemPrice: "420",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Massacre's Shell",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Scarab Shell",
      itemPrice: "45",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Swampling Wood",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Timira's Sensors",
      itemPrice: "150,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Piece of Warrior Armor",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pieces of Magic Chalk",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pig Foot",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pile of Grave Earth",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pirat's Tail",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pirate Coin",
      itemPrice: "110",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Plagueroot Offshoot",
      itemPrice: "280,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Plasma Pearls",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Plasmatic Lightning",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Poison Gland",
      itemPrice: "210",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Poison Spider Shell",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Poisonous Slime",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Polar Bear Paw",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pool of Chitinous Glue",
      itemPrice: "480",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Porcelain Mask",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Powder Herb",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Prehemoth Claw",
      itemPrice: "2,300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Prehemoth Horns",
      itemPrice: "3,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Pristine Worm Head",
      itemPrice: "15,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Protective Charm",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Purified Soul (Netherworld)",
      itemPrice: "260",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Purple Robe",
      itemPrice: "110",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Putrefactive Figurine",
      itemPrice: "3,200,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Quara Bone",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Quara Eye",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Quara Pincers",
      itemPrice: "410",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Quara Tentacle",
      itemPrice: "140",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Quill",
      itemPrice: "1,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rare Earth",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ratmiral's Hat",
      itemPrice: "150,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ravenous Circlet",
      itemPrice: "220,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Red Dragon Leather",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Red Dragon Scale",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Red Goanna Scale",
      itemPrice: "270",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Red Hair Dye",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Red Piece of Cloth",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rhindeer Antlers",
      itemPrice: "680",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rhino Hide",
      itemPrice: "175",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rhino Horn",
      itemPrice: "265",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rhino Horn Carving",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ripptor Claw",
      itemPrice: "2,600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ripptor Scales",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Ritual Tooth",
      itemPrice: "135",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rod (Creature Product)",
      itemPrice: "2,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rogue Naga Scales",
      itemPrice: "570",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Roots",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rope Belt",
      itemPrice: "66",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rorc Egg",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rorc Feather",
      itemPrice: "70",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rotten Feather",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rotten Heart",
      itemPrice: "74,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rotten Piece of Cloth",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rotten Roots",
      itemPrice: "3,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Rotten Vermin Ichor",
      itemPrice: "4,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sabretooth",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sabretooth Fur",
      itemPrice: "2,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Safety Pin",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sample of Monster Blood",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sandcrawler Shell",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scale of Corruption",
      itemPrice: "680",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scale of Gelidrazah",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scarab Pincers",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scorpion Charm",
      itemPrice: "620",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scorpion Tail",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scroll of Heroic Deeds",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Scythe Leg",
      itemPrice: "450",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sea Horse Figurine",
      itemPrice: "42,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sea Serpent Scale",
      itemPrice: "520",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Seeds",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Shaggy Tail",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Shamanic Hood",
      itemPrice: "45",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Shamanic Talisman",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Shark Fins",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Shimmering Beetles",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sight of Surrender's Eye",
      itemPrice: "3,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Signet Ring",
      itemPrice: "480,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silencer Claws",
      itemPrice: "390",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silencer Resonating Chamber",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silken Bookmark",
      itemPrice: "1,300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silky Fur",
      itemPrice: "35",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silver Foxmouse Coin",
      itemPrice: "11,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silver Hand Mirror",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Silver Moon Coin",
      itemPrice: "11,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Single Human Eye",
      itemPrice: "1,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Skeleton Decoration",
      itemPrice: "3,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Skull Belt",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Skull Fetish",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Skull Shatterer",
      itemPrice: "170",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Skunk Tail",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Slimy Leg",
      itemPrice: "4,500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Energy Ball",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Flask of Eyedrops",
      itemPrice: "95",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Notebook",
      itemPrice: "480",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Oil Lamp",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Pitchfork",
      itemPrice: "70",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Treasure Chest",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Small Tropical Fish",
      itemPrice: "380",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Smoldering Eye",
      itemPrice: "470,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Snake Skin",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sniper Gloves",
      itemPrice: "2,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Solid Rage",
      itemPrice: "310",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Some Grimeleech Wings",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Soul Stone",
      itemPrice: "6,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spark Sphere",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Claw",
      itemPrice: "290",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Legs",
      itemPrice: "310",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Stings",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sparkion Tail",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spectral Gold Nugget",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spectral Silver Nugget",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spellsinger's Seal",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sphinx Feather",
      itemPrice: "470",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sphinx Tiara",
      itemPrice: "360",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spider Fangs",
      itemPrice: "10",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spider Silk",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spidris Mandible",
      itemPrice: "450",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spiked Iron Ball",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spirit Container",
      itemPrice: "40,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spite's Spirit",
      itemPrice: "840,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spitter Nose",
      itemPrice: "340",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Spooky Blue Eye",
      itemPrice: "95",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Srezz' Eye",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Stalking Seeds",
      itemPrice: "1,800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Star Herb",
      itemPrice: "15",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Stone Herb",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Stone Wing",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Stonerefiner's Skull",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Strand of Medusa Hair",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Strange Proto Matter",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Strange Symbol",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Streaked Devourer Eyes",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Streaked Devourer Legs",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Streaked Devourer Maw",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Striped Fur",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sulphider Shell",
      itemPrice: "2,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sulphur Powder",
      itemPrice: "1,900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Sun Brooch",
      itemPrice: "18,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Swamp Grass",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Swampling Moss",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Swarmer Antenna",
      itemPrice: "130",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tail of Corruption",
      itemPrice: "240",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tarantula Egg",
      itemPrice: "80",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tarnished Rhino Figurine",
      itemPrice: "320",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tattered Piece of Robe",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Telescope Eye",
      itemPrice: "1,600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tentacle Piece",
      itemPrice: "5,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tentacle of Tentugly",
      itemPrice: "27,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tentugly's Eye",
      itemPrice: "52,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tentugly's Jaws",
      itemPrice: "80,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Terramite Eggs",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Terramite Legs",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Terramite Shell",
      itemPrice: "170",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Terrorbird Beak",
      itemPrice: "95",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "The Handmaiden's Protector",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "The Imperor's Trident",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "The Plasmother's Remains",
      itemPrice: "50,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Thick Fur",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Thorn",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tiara",
      itemPrice: "11,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Token of Love",
      itemPrice: "440,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tooth File",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tooth of Tazhadur",
      itemPrice: "10,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Torn Shirt",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Trapped Bad Dream Monster",
      itemPrice: "900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tremendous Tyrant Head",
      itemPrice: "930",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tremendous Tyrant Shell",
      itemPrice: "740",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Troll Green",
      itemPrice: "25",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Trollroot",
      itemPrice: "50",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tunnel Tyrant Head",
      itemPrice: "500",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tunnel Tyrant Shell",
      itemPrice: "700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Turtle Shell",
      itemPrice: "90",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Tusk",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Two-Headed Turtle Heads",
      itemPrice: "460",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Undead Heart",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Undertaker Fangs",
      itemPrice: "2,700",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Unholy Bone",
      itemPrice: "480",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Urmahlullu's Mane",
      itemPrice: "490,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Urmahlullu's Paw",
      itemPrice: "245,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Urmahlullu's Tail",
      itemPrice: "210,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Utua's Poison",
      itemPrice: "230",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vampire Dust",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vampire Teeth",
      itemPrice: "275",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vampire's Cape Chain",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Varnished Diremaw Brainpan",
      itemPrice: "750",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Varnished Diremaw Legs",
      itemPrice: "670",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Veal",
      itemPrice: "40",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vemiath's Infused Basalt",
      itemPrice: "1,000,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Venison",
      itemPrice: "55",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vexclaw Talon",
      itemPrice: "1,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vial of Hatred",
      itemPrice: "737,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vibrant Heart",
      itemPrice: "2,100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Vibrant Robe",
      itemPrice: "1,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Violet Glass Plate",
      itemPrice: "2,150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Volatile Proto Matter",
      itemPrice: "300",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Warmaster's Wristguards",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Warwolf Fur",
      itemPrice: "30",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Waspoid Claw",
      itemPrice: "320",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Waspoid Wing",
      itemPrice: "190",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Weaver's Wandtip",
      itemPrice: "250",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werebadger Claws",
      itemPrice: "160",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werebadger Skull",
      itemPrice: "185",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werebear Fur",
      itemPrice: "185",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werebear Skull",
      itemPrice: "195",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wereboar Hooves",
      itemPrice: "175",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wereboar Tusks",
      itemPrice: "165",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werecrocodile Tongue",
      itemPrice: "570",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werefox Tail",
      itemPrice: "200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werehyaena Nose",
      itemPrice: "220",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werehyaena Talisman",
      itemPrice: "350",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werepanther Claw",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Weretiger Tooth",
      itemPrice: "490",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werewolf Fangs",
      itemPrice: "180",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Werewolf Fur",
      itemPrice: "380",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "White Piece of Cloth",
      itemPrice: "100",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Widow's Mandibles",
      itemPrice: "110",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wild Flowers",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wimp Tooth Chain",
      itemPrice: "120",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Winged Tail",
      itemPrice: "800",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Winter Wolf Fur",
      itemPrice: "20",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Witch Broom",
      itemPrice: "60",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Withered Pauldrons",
      itemPrice: "850",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Withered Scalp",
      itemPrice: "900",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wolf Paw",
      itemPrice: "70",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wood",
      itemPrice: "5",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wool",
      itemPrice: "15",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Worm Sponge",
      itemPrice: "4,200",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Writhing Brain",
      itemPrice: "370,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Writhing Heart",
      itemPrice: "185,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wyrm Scale",
      itemPrice: "400",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Wyvern Talisman",
      itemPrice: "265",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Yellow Piece of Cloth",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Yielowax",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Yirkas' Egg",
      itemPrice: "280",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Young Lich Worm",
      itemPrice: "25,000",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Zaogun Flag",
      itemPrice: "600",
      npcName: "Yasir",
      tradeType: "buy",
    },
    {
      itemName: "Zaogun Shoulderplates",
      itemPrice: "150",
      npcName: "Yasir",
      tradeType: "buy",
    },
  ],
};
