import ContactView from "../../components/views/ContactView";

const PageContact = () => {
  return (
    <>
      <ContactView />
    </>
  );
};

export default PageContact;
