import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import {
  fadeIn,
  slideDown,
  slideIn,
  slideUp,
} from "../../../../utility/effects";
import { speedItems, speedSpells } from "../../../../data/speed";
import { ImageToBase64 } from "../../../../libs/functions";

const SpeedView = () => {
  const [level, setLevel] = useState(1);
  const [description, setDescription] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedSpells, setSelectedSpells] = useState([]);
  const [selectedMount, setSelectedMount] = useState(false);

  const baseSpeedValue = 109;

  const calculateSpeed = useCallback(() => {
    const lvl = parseInt(level);
    let baseSpeed = baseSpeedValue + lvl;
    let itemSpeed = 0;
    let hasItem = false;

    selectedSpells.forEach((spell) => {
      baseSpeed = spell.formula(baseSpeed);
      hasItem = true;
    });

    speedItems.forEach((item) => {
      if (selectedItems.includes(item.name)) {
        hasItem = true;
        itemSpeed += item.speed;
      }
    });

    if (selectedMount) {
      baseSpeed += 10;
    }

    const modifiedSpeed = baseSpeed + itemSpeed;

    setDescription(
      <p className="text-lg font-normal">
        Sua <span className="text-secondary">velocidade</span> é de{" "}
        <span className="text-yellow-400">{modifiedSpeed}</span>.
        {hasItem && (
          <span>
            {" "}
            Isso é equivalente a{" "}
            <span className="text-secondary">velocidade</span> de um level{" "}
            <span className="text-yellow-400">
              {Math.max(Math.ceil(modifiedSpeed - baseSpeedValue), itemSpeed)}
            </span>
            .
          </span>
        )}
      </p>
    );
  }, [level, selectedItems, selectedSpells, selectedMount]);

  useEffect(() => {
    calculateSpeed();
  }, [calculateSpeed]);

  const handleItemChange = (itemName) => {
    setSelectedItems((prevItems) =>
      prevItems.includes(itemName)
        ? prevItems.filter((item) => item !== itemName)
        : [...prevItems, itemName]
    );
  };

  const handleSpellChange = (spell) => {
    setSelectedSpells((prevSpells) =>
      prevSpells.some((prevSpell) => prevSpell.name === spell.name)
        ? prevSpells.filter((prevSpell) => prevSpell.name !== spell.name)
        : [...prevSpells, spell]
    );
  };

  const handleMountChange = () => {
    setSelectedMount(!selectedMount);
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Velocidade</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">Preencha os campos abaixo.</li>
              <li className="list-decimal">
                Deixe que nossa inteligência artificial fará os cálculos para
                você.
              </li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          className="w-full flex flex-col items-center"
          variants={slideUp}
        >
          Qual o level do personagem?
          <input
            type="number"
            value={level}
            className="input input-bordered input-secondary w-full max-w-xs my-4"
            onChange={(e) => setLevel(e.target.value)}
            min="1"
            step="1"
          />
          <div className="flex flex-col gap-0 md:gap-4 max-w-4xl mx-auto">
            <fieldset className="p-4 border border-secondary rounded-md bg-base-300">
              <legend className="text-lg font-bold">Outros</legend>
              <div className="flex items-center checkbox-container">
                <ImageToBase64
                  className="h-8 w-8 mr-1"
                  src="/images/tibia/mounts/Fleeting_Knowledge.gif"
                  alt="Montaria Fleeting Knowledge"
                />
                <label className="flex gap-2 my-2">
                  <input
                    type="checkbox"
                    onChange={handleMountChange}
                    className="checkbox checkbox-secondary"
                  />
                  Usando montaria?
                </label>
              </div>
            </fieldset>

            <fieldset className="p-4 border border-secondary rounded-md bg-base-300">
              <legend className="text-lg font-bold">Magias</legend>
              <div className="flex gap-4 w-full flex-wrap">
                {speedSpells.map((spell) => (
                  <div
                    key={spell.name}
                    className="flex items-center checkbox-container"
                  >
                    <ImageToBase64
                      className="h-8 w-8 mr-1"
                      src={spell.image}
                      alt={spell.name}
                    />
                    <label key={spell.name} className="flex gap-2 my-2">
                      <input
                        type="checkbox"
                        onChange={() => handleSpellChange(spell)}
                        className="checkbox checkbox-secondary"
                      />
                      {spell.description}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>

            <fieldset className="p-4 border border-secondary rounded-md bg-base-300">
              <legend className="text-lg font-bold">Itens</legend>
              <div className="flex gap-4 w-full flex-wrap">
                {speedItems.map((item) => (
                  <div
                    key={item.name}
                    className="flex items-center checkbox-container"
                  >
                    <ImageToBase64
                      className="h-8 w-8 mr-1"
                      src={item.image}
                      alt={item.name}
                    />
                    <label key={item.name} className="flex gap-2 my-2">
                      <input
                        type="checkbox"
                        onChange={() => handleItemChange(item.name)}
                        className="checkbox checkbox-secondary"
                      />
                      {item.name}
                    </label>
                  </div>
                ))}
              </div>
            </fieldset>
          </div>
          {level > 0 && (
            <motion.div
              className="mt-4 bg-base-300 p-4 rounded-md shadow-md text-center max-w-4xl mx-auto"
              variants={slideDown}
            >
              <span className="font-normal">{description}</span>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default SpeedView;
