import React from "react";
import { formatExp, formatGainExp } from "../../../../libs/functions";

const HuntAnalyzerItemResult = ({ title, exp, includeStamina }) => (
  <div className="alert bg-base-100 mb-4">
    <strong className="text-lg text-primary">{title}</strong> - XP ganha:{" "}
    {formatGainExp(exp)}{" "}
    <span className={includeStamina ? "text-green-600" : "text-warning"}>
      +{formatExp(exp)}
    </span>
  </div>
);

export default HuntAnalyzerItemResult;
