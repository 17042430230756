import { motion } from "framer-motion";
import { fadeIn, slideIn } from "../../utility/effects";
import { PROJECT_NAME } from "../../utility/constants";
import { ImageToBase64 } from "../../libs/functions";

const ContactView = () => {
  const emailLink = process.env.REACT_APP_CONTACT_EMAIL;
  const instagramLink = process.env.REACT_APP_INSTAGRAM;

  return (
    <motion.div
      className="max-w-screen-xl mx-auto p-4 my-24 md:my-16 pt-16 flex flex-col md:flex-row items-center"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      <div className="md:w-1/2 md:ml-8">
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center md:text-left"
          variants={slideIn}
        >
          Contato <span className="text-yellow-400">{PROJECT_NAME}</span>
        </motion.h1>

        <motion.p
          className="text-md mb-4 text-center md:text-left"
          variants={slideIn}
        >
          Entre em contato conosco para quaisquer dúvidas, sugestões ou
          feedback.
        </motion.p>

        <motion.h2 className="text-2xl font-bold mt-6 mb-2" variants={slideIn}>
          Informações de Contato
        </motion.h2>

        <motion.ul className="list-disc ml-8" variants={slideIn}>
          <motion.li className="mb-2">
            <strong>Email:</strong>{" "}
            <a
              href={`mailto:${emailLink}`}
              className="text-accent hover:underline"
            >
              {emailLink}
            </a>
          </motion.li>
          <motion.li className="mb-2">
            <strong>Em jogo:</strong>{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={`https://www.tibia.com/community/?name=${process.env.REACT_APP_CHARACTER_NAME}`}
              className="text-accent hover:underline"
            >
              {process.env.REACT_APP_CHARACTER_NAME}
            </a>
          </motion.li>
          <motion.li className="mb-2">
            <strong>Pelo Instagram:</strong>{" "}
            <a
              rel="noopener noreferrer"
              target="_blank"
              href={instagramLink}
              className="text-accent hover:underline"
            >
              {process.env.REACT_APP_INSTAGRAM_NAME}
            </a>
          </motion.li>
        </motion.ul>

        <motion.p
          className="text-md mt-6 text-center md:text-left"
          variants={slideIn}
        >
          Ficamos felizes em ouvir de você! Responderemos o mais rápido
          possível.
        </motion.p>
      </div>

      <ImageToBase64
        src="/images/tibia/map.png"
        alt="Imagem de contato"
        className="max-w-full md:w-1/2 mb-6 md:mb-0 rounded-md"
        variants={slideIn}
      />
    </motion.div>
  );
};

export default ContactView;
