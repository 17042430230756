import React, { useState } from "react";
import { motion } from "framer-motion";
import { npcTrade } from "../../../data/npctrade";
import { fadeIn, slideIn } from "../../../utility/effects";

const NpcItemPrice = () => {
  const tradeList = npcTrade.tradeList;
  const [filterNpc, setFilterNpc] = useState("All");
  const [filterType, setFilterType] = useState("All");

  const filteredList = tradeList.filter((item) => {
    const npcMatch = filterNpc === "All" || item.npcName === filterNpc;
    const typeMatch = filterType === "All" || item.tradeType === filterType;

    return npcMatch && typeMatch;
  });

  const npcOptions = [...new Set(tradeList.map((item) => item.npcName))];
  const typeOptions = [...new Set(tradeList.map((item) => item.tradeType))];

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Comércio de <span className="text-yellow-400">NPC</span>
        </motion.h1>

        <div className="flex space-x-4 mb-8 mx-auto max-w-md lg:max-w-full flex-wrap items-center justify-center">
          <label htmlFor="npcFilter" className="mb-4 lg:mb-0">
            Filtrar por NPC:
          </label>
          <select
            id="npcFilter"
            onChange={(e) => setFilterNpc(e.target.value)}
            value={filterNpc}
            className="select select-bordered select-secondary w-full max-w-xs"
          >
            <option value="All">Todos</option>
            {npcOptions.map((npc, index) => (
              <option key={index} value={npc}>
                {npc}
              </option>
            ))}
          </select>

          <label htmlFor="typeFilter" className="my-4 lg:my-0">
            Filtrar por Tipo:
          </label>
          <select
            id="typeFilter"
            onChange={(e) => setFilterType(e.target.value)}
            value={filterType}
            className="select select-bordered select-secondary w-full max-w-xs"
          >
            <option value="All">Todos</option>
            {typeOptions.map((type, index) => (
              <option key={index} value={type}>
                {type === "buy" ? "Compra" : "Venda"}
              </option>
            ))}
          </select>
        </div>

        <table className="table-fixed w-full table-zebra border border-secondary">
          <thead>
            <tr>
              <th className="py-2 px-4 border-b">Item</th>
              <th className="py-2 px-4 border-b">Preço</th>
              <th className="py-2 px-4 border-b">NPC</th>
              <th className="py-2 px-4 border-b">Tipo de Comércio</th>
            </tr>
          </thead>
          <tbody>
            {filteredList.map((item, index) => (
              <tr key={index}>
                <td className="py-2 px-4 border-b">{item.itemName}</td>
                <td className="py-2 px-4 border-b">{item.itemPrice}</td>
                <td className="py-2 px-4 border-b">{item.npcName}</td>
                <td className="py-2 px-4 border-b">{item.tradeType}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </motion.div>
    </>
  );
};

export default NpcItemPrice;
