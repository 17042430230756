const PROJECT_NAME = "Exori Gran";

const API_BASE_URL =
  process.env.NODE_ENV === "production"
    ? process.env.REACT_APP_API_BASE_URL_PROD
    : process.env.REACT_APP_API_BASE_URL_DEV;

const TIBIA_WORLDS_URL =
  "https://www.tibia.com/community/?subtopic=worlds&world=";
const TIBIA_CHARACTER_PAGE_URL = "https://www.tibia.com/community/?name=";
const TIBIA_HOUSES_PAGE_URL =
  "https://www.tibia.com/community/?subtopic=houses&page=view&world=";
const TIBIA_GUILDS_PAGE_URL =
  "https://www.tibia.com/community/?subtopic=guilds&page=view&GuildName=";

export {
  API_BASE_URL,
  PROJECT_NAME,
  TIBIA_CHARACTER_PAGE_URL,
  TIBIA_GUILDS_PAGE_URL,
  TIBIA_HOUSES_PAGE_URL,
  TIBIA_WORLDS_URL,
};
