import AboutView from "../../components/views/AboutView";

const PageAbout = () => {
  return (
    <>
      <AboutView />
    </>
  );
};

export default PageAbout;
