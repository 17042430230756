import PartyFinderView from "../../../components/views/tools/party-finder/PartyFinder";

const PagePartyFinder = () => {
  return (
    <>
      <PartyFinderView />
    </>
  );
};

export default PagePartyFinder;
