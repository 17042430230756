import BlessingsView from "../../../components/views/calculators/blessings";

const PageBlessings = () => {
  return (
    <>
      <BlessingsView />
    </>
  );
};

export default PageBlessings;
