import axios from "axios";
import { useState, useEffect } from "react";

///////////////// API CALLS ////////////////
const MAX_RETRIES = 3;

export const fetchWebsite = async (url) => {
  let retries = 0;

  try {
    const response = await axios.get(url, { timeout: 5000 });
    return response.data;
  } catch (error) {
    if (retries < MAX_RETRIES) {
      retries++;
      console.error(`Retrying (${retries}/${MAX_RETRIES})...`);
      return fetchWebsite(url);
    } else {
      throw error;
    }
  }
};

///////////////// OTHER FUNCIONS ///////////////
export const ImageToBase64 = ({ src, className, alt, onClick }) => {
  const [base64, setBase64] = useState("");

  useEffect(() => {
    const loadImage = async () => {
      try {
        const response = await fetch(src);
        const blob = await response.blob();
        const reader = new FileReader();

        reader.onloadend = () => {
          const base64Data = reader.result;
          setBase64(base64Data);
        };

        reader.readAsDataURL(blob);
      } catch (error) {
        console.error("Error loading image:", error);
      }
    };

    loadImage();
  }, [src]);

  if (!src) {
    return null;
  }

  if (!className) {
    className = "";
  }

  if (!alt) {
    alt = "";
  }

  if (!onClick) {
    onClick = () => {};
  }

  return (
    <img
      src={base64 || src}
      className={className}
      alt={alt}
      onClick={onClick}
      width={500} // Set the desired width of the image
      height={300} // Set the desired height of the image
    />
  );
};

export function formatDateUTC(inputDate) {
  const dateChunks = inputDate.split(/\s|,/); // Split by space or comma
  const monthAbbreviation = dateChunks[0].substring(0, 3);
  const monthMap = {
    Jan: "01",
    Feb: "02",
    Mar: "03",
    Apr: "04",
    May: "05",
    Jun: "06",
    Jul: "07",
    Aug: "08",
    Sep: "09",
    Oct: "10",
    Nov: "11",
    Dec: "12",
  };

  const timeChunks = dateChunks[4].split(":");
  const formattedDate = `${dateChunks[2]}-${monthMap[monthAbbreviation]}-${dateChunks[1]}T${timeChunks[0]}:${timeChunks[1]}:${timeChunks[2]}Z`;

  return formattedDate;
}

export const formatDate = (dateString) => {
  const options = { year: "numeric", month: "numeric", day: "numeric" };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

/////////////// PARTY HUNT ///////////////
export function getPayments(partyData) {
  const withoutFirstSection = removeFirstSection(partyData);
  const players = findPlayers(withoutFirstSection);
  const huntData = getPartyHuntData(players, withoutFirstSection);
  const paymentsResult = findPayments(
    huntData.map((player) => {
      return { name: player.name, balance: player.balance };
    })
  );

  return {
    error: false,
    tops: getPerformanceData(huntData),
    numberOfPlayers: players.length,
    individualProfit: Number(paymentsResult.individualProfit.toFixed()),
    payments: paymentsResult.payments,
  };
}

function getPerformanceData(huntData) {
  const tops = {
    damage: [],
    healing: [],
  };

  Object.keys(tops).forEach((value) => {
    tops[value] = huntData
      .map((data) => ({
        name: data.name,
        total: data[value],
      }))
      .sort((a, b) => b.total - a.total);
  });

  return tops;
}

function getPartyHuntData(players, partyData) {
  let tempPartyData = partyData;
  const completedData = players.reduce((acc, cur, i) => {
    const lootIndex = tempPartyData.indexOf("Loot:");
    const suppliesIndex = tempPartyData.indexOf("Supplies:");
    const balanceIndex = tempPartyData.indexOf("Balance:");
    const damageIndex = tempPartyData.indexOf("Damage:");
    const healingIndex = tempPartyData.indexOf("Healing:");

    const tempObj = {
      name: cur,
      loot: Number(
        tempPartyData
          .substring(lootIndex + "Loot:".length, suppliesIndex)
          .replaceAll(",", "")
      ),
      supplies: Number(
        tempPartyData
          .substring(suppliesIndex + "Supplies:".length, balanceIndex)
          .replaceAll(",", "")
      ),
      balance: Number(
        tempPartyData
          .substring(balanceIndex + "Balance:".length, damageIndex)
          .replaceAll(",", "")
      ),
      damage: Number(
        tempPartyData
          .substring(damageIndex + "Damage:".length, healingIndex)
          .replaceAll(",", "")
      ),
      healing: Number(
        tempPartyData
          .substring(
            healingIndex + "Healing:".length,
            players[i + 1]
              ? tempPartyData.indexOf(players[i + 1])
              : tempPartyData.length
          )
          .replaceAll(",", "")
      ),
    };

    acc.push(tempObj);

    if (players.length > i + 1) {
      tempPartyData = tempPartyData.substring(
        tempPartyData.indexOf(players[i + 1]),
        tempPartyData.length
      );
    }
    return acc;
  }, []);

  return completedData;
}

function removeFirstSection(partyData) {
  let tempData = partyData;
  const balanceIndex = partyData.indexOf("Balance:") + 8;

  tempData = tempData.replace("(Leader)", "");

  tempData = tempData.substring(balanceIndex, partyData.length);

  return tempData;
}

function findPlayers(partyData) {
  let temp = partyData;
  const names = [];
  for (let i = 0; i < getNumberOfPlayers(partyData); i++) {
    if (temp.indexOf("Loot:") > -1) {
      const name = temp.substring(0, temp.indexOf("Loot:"));
      names.push(
        name
          .replace(/[0-9]/g, "")
          .replaceAll(",", "")
          .replace(/(\r\n|\n|\r)/gm, "")
          .replaceAll("-", "")
          .trim()
      );
      temp = temp.slice(temp.indexOf("Healing:") + "Healing:".length);
    }
  }

  return names;
}

function findPayments(playersAndBalances) {
  const numberOfPlayers = playersAndBalances.length;
  const totalProfit = playersAndBalances.reduce((acc, cur) => {
    acc += cur.balance;
    return acc;
  }, 0);
  const individualProfit = totalProfit / numberOfPlayers;

  const correctedBalance = [];
  const payments = [];

  playersAndBalances.forEach((playerAndBalance) => {
    const name = playerAndBalance.name;
    const balance = individualProfit - playerAndBalance.balance;

    correctedBalance.push({ name, balance });
  });

  playersAndBalances.forEach((playerAndBalance, i) => {
    if (correctedBalance[i]["balance"] < 0) {
      while (Math.abs(correctedBalance[i]["balance"]) > 5) {
        for (let j = 0; j < numberOfPlayers; j++) {
          if (correctedBalance[j]["balance"] > 0) {
            if (
              correctedBalance[j]["balance"] >
              Math.abs(correctedBalance[i]["balance"])
            ) {
              correctedBalance[j]["balance"] =
                correctedBalance[j]["balance"] + correctedBalance[i]["balance"];
              payments.push({
                name: correctedBalance[i]["name"],
                amount: Number(
                  Math.abs(correctedBalance[i]["balance"]).toFixed()
                ),
                payTo: correctedBalance[j]["name"],
              });
              correctedBalance[i]["balance"] = 0;
            } else {
              correctedBalance[i]["balance"] =
                correctedBalance[i]["balance"] + correctedBalance[j]["balance"];
              correctedBalance[j]["balance"] = Math.round(
                correctedBalance[j]["balance"]
              );
              payments.push({
                name: correctedBalance[i]["name"],
                amount: Number(
                  Math.abs(correctedBalance[j]["balance"]).toFixed()
                ),
                payTo: correctedBalance[j]["name"],
              });
              correctedBalance[j]["balance"] = 0;
            }
          }
        }
      }
    }
  });

  return {
    payments: payments.filter((payment) => payment.amount !== 0),
    individualProfit,
  };
}

function getNumberOfPlayers(partyDataWithoutFirstSection) {
  return (partyDataWithoutFirstSection.match(/Damage:/g) || []).length;
}

export function validatePartyData(partyData) {
  if (
    !partyData ||
    !partyData.includes("Balance") ||
    !partyData.includes("Supplies") ||
    !partyData.includes("Loot") ||
    !partyData.includes("Session data") ||
    !partyData.includes("Loot Type") ||
    partyData.length < 50
  ) {
    return false;
  }
  return true;
}

export function ConvertoGoldToK(value) {
  return `${Math.round(value / 1000)}k`;
}

export function ConvertGold(value) {
  return `${Math.round(value / 1000).toFixed()}`;
}

export function getCurrency(number) {
  if (number >= 1e9) {
    return "KKK";
  } else if (number >= 1e6) {
    return "KK";
  } else if (number >= 1e3) {
    return "K";
  } else {
    return "GP";
  }
}

//////////////// HUNT ANALYZER ///////////////
export function analyzeHuntData(huntData) {
  const killedMonsters = findKilledMonsters(huntData);
  const lootedItems = findLootedItems(huntData);

  return {
    error: false,
    killedMonsters,
    lootedItems,
  };
}

function findKilledMonsters(huntData) {
  const monstersIndex = huntData.indexOf("Killed Monsters:");
  const lootIndex = huntData.indexOf("Looted Items:");

  if (monstersIndex === -1 || lootIndex === -1) {
    return [];
  }

  const monstersData = huntData
    .substring(monstersIndex + "Killed Monsters:".length, lootIndex)
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean);

  return monstersData;
}

function findLootedItems(huntData) {
  const lootIndex = huntData.indexOf("Looted Items:");

  if (lootIndex === -1) {
    return [];
  }

  const itemsData = huntData
    .substring(lootIndex + "Looted Items:".length)
    .split("\n")
    .map((line) => line.trim())
    .filter(Boolean);

  return itemsData;
}

export function validateHuntData(huntData) {
  if (
    !huntData ||
    !huntData.includes("Killed Monsters") ||
    !huntData.includes("Looted Items")
  ) {
    return false;
  }
  return true;
}

export function extractNumbersFromString(inputData) {
  if (typeof inputData !== "string") {
    return [];
  }

  const numbers = inputData.match(/\d+/g);
  return numbers ? numbers.map(Number) : [];
}

export function extractLettersFromString(inputData) {
  if (typeof inputData !== "string") {
    return "";
  }

  const letters = inputData.match(/[a-zA-Z]+/g);
  return letters ? letters.join(" ") : "";
}

export function getCreatureName(inputData) {
  if (typeof inputData !== "string") {
    return "";
  }

  const result = inputData.replace(/\d+[xX]?\s*/g, "");
  return result.trim();
}

export function formatExp(number) {
  if (number >= 1e3 && number < 1e6) {
    return (number / 1e3).toFixed(1) + "K";
  } else if (number >= 1e6 && number < 1e9) {
    return (number / 1e6).toFixed(1) + "KK";
  } else if (number >= 1e9) {
    return (number / 1e9).toFixed(1) + "KKK";
  } else {
    return number.toString();
  }
}

export function formatGold(number) {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(number % 1e9 === 0 ? 0 : 1) + "KKK";
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(number % 1e6 === 0 ? 0 : 1) + "KK";
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(number % 1e3 === 0 ? 0 : 1) + "K";
  } else {
    return number.toString() + " GP";
  }
}

export function formatQuantity(number) {
  if (number >= 1e9) {
    return (number / 1e9).toFixed(number % 1e9 === 0 ? 0 : 1);
  } else if (number >= 1e6) {
    return (number / 1e6).toFixed(number % 1e6 === 0 ? 0 : 1);
  } else if (number >= 1e3) {
    return (number / 1e3).toFixed(number % 1e3 === 0 ? 0 : 1);
  } else {
    return number.toString();
  }
}

export function formatTime(durationInHours) {
  const years = Math.floor(durationInHours / (24 * 365));
  const days = Math.floor((durationInHours % (24 * 365)) / 24);
  const hours = Math.floor(durationInHours % 24);
  const minutes = Math.round((durationInHours % 1) * 60);

  const formattedTime = [];

  if (years > 0) {
    formattedTime.push(
      <span key="years">
        <span className="text-primary">{years}</span>{" "}
        {years === 1 ? "ano" : "anos"}
      </span>
    );
  }

  if (days > 0) {
    formattedTime.push(
      <span key="days">
        <span className="text-primary">{days}</span>{" "}
        {days === 1 ? "dia" : "dias"}
      </span>
    );
  }

  if (hours > 0) {
    formattedTime.push(
      <span key="hours">
        <span className="text-primary">{hours}</span>{" "}
        {hours === 1 ? "hora" : "horas"}
      </span>
    );
  }

  if (minutes > 0) {
    formattedTime.push(
      <span key="minutes">
        <span className="text-primary">{minutes}</span>{" "}
        {minutes === 1 ? "minuto" : "minutos"}
      </span>
    );
  }

  return formattedTime.reduce(
    (acc, curr) => (acc.length === 0 ? [curr] : [...acc, ", ", curr]),
    []
  );
}

export function formatGoldEx(gold) {
  const roundedGold = Math.round(gold * 100) / 100;
  const formatedGold = new Intl.NumberFormat("pt-BR").format(roundedGold);
  return formatedGold;
}

export function formatNumberEx(gold) {
  const roundedGold = Math.round(gold * 100) / 100;
  const formatedGold = new Intl.NumberFormat("pt-BR").format(roundedGold);
  return formatedGold;
}

export function formatValuePerWeight(valuePerWeight) {
  if (valuePerWeight >= 1e3) {
    return (valuePerWeight / 1e3).toFixed(2) + "K/oz";
  } else {
    return valuePerWeight.toFixed(2) + "/oz";
  }
}

export function formatWeight(weight) {
  if (weight >= 1e9) {
    return (weight / 1e9).toFixed(weight % 1e9 === 0 ? 0 : 1) + "oz";
  } else if (weight >= 1e6) {
    return (weight / 1e6).toFixed(weight % 1e6 === 0 ? 0 : 1) + "oz";
  } else if (weight >= 1e3) {
    return (weight / 1e3).toFixed(weight % 1e3 === 0 ? 0 : 1) + "oz";
  } else {
    return weight.toString() + " oz";
  }
}

export function formatGainExp(exp) {
  const roundedExp = Math.round(exp * 100) / 100;
  const formattedExp = new Intl.NumberFormat("pt-BR").format(roundedExp);
  return formattedExp;
}

export const calculateExp = (
  creature,
  includeStamina,
  includeBoost,
  includeDoubleXP,
  preySelection
) => {
  if (!creature) {
    return 0;
  }

  const baseExp = creature ? parseInt(creature.exp.replace(",", "")) : 0;
  let modifiedExp = baseExp;

  if (includeStamina) {
    modifiedExp = Math.ceil(baseExp * 1.5);
  }

  if (includeBoost) {
    modifiedExp = Math.ceil(modifiedExp * 1.5);
  }

  if (includeDoubleXP) {
    modifiedExp = Math.ceil(modifiedExp * 2); // Double XP bonus
  }

  const selectedPreys = Object.keys(preySelection).filter(
    (creatureName) => preySelection[creatureName]
  );

  const preyBonus = selectedPreys.includes(creature.name.toLowerCase())
    ? 0.4
    : 0;

  return Math.ceil(modifiedExp * (1 + preyBonus));
};

export function capitalizeFirstLetter(str) {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export function calculateSessionTime(huntSessionData) {
  const sessionIndex = huntSessionData.indexOf("Session:");

  if (sessionIndex === -1) {
    return "Tempo de Sessão Indefinido";
  }

  const sessionTime = extractTimeString(huntSessionData, sessionIndex);

  return sessionTime;
}

function extractTimeString(huntData, index) {
  const timeLine = huntData
    .substring(index + "Session:".length)
    .split("\n")[0]
    .trim();

  return timeLine;
}

export const formatXpChange = (xpChange) => {
  const xpValue = parseInt(xpChange.replace(/[^\d-]/g, ""));
  const colorClass = xpValue >= 0 ? "text-green-500" : "text-red-500";
  return <span className={colorClass}>{xpChange}</span>;
};

export const formatExperienceNumbers = (
  num,
  decimals,
  leadingZero,
  parens,
  commas
) => {
  if (isNaN(parseInt(num))) return "NaN";
  let tmpNum = num;
  let sign = num < 0 ? -1 : 1;

  tmpNum *= Math.pow(10, decimals);
  tmpNum = Math.round(Math.abs(tmpNum));
  tmpNum /= Math.pow(10, decimals);
  tmpNum *= sign;

  let tmpNumStr = String(tmpNum);
  if (!leadingZero && num < 1 && num > -1 && num !== 0)
    if (num > 0) tmpNumStr = tmpNumStr.substring(1, tmpNumStr.length);
    else tmpNumStr = "-" + tmpNumStr.substring(2, tmpNumStr.length);

  if (commas && (num >= 1000 || num <= -1000)) {
    let iStart = tmpNumStr.indexOf(".");
    if (iStart < 0) iStart = tmpNumStr.length;

    iStart -= 3;
    let z = 1;
    while (iStart >= 1) {
      if (z === 1)
        tmpNumStr =
          tmpNumStr.substring(0, iStart) +
          "," +
          tmpNumStr.substring(iStart, tmpNumStr.length);
      else
        tmpNumStr =
          tmpNumStr.substring(0, iStart) +
          "." +
          tmpNumStr.substring(iStart, tmpNumStr.length);
      iStart -= 3;
      z++;
    }
  }
  if (parens && num < 0)
    tmpNumStr = "(" + tmpNumStr.substring(1, tmpNumStr.length) + ")";

  return tmpNumStr;
};
