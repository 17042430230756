import StaminaView from "../../../components/views/calculators/stamina";

const PageStamina = () => {
  return (
    <>
      <StaminaView />
    </>
  );
};

export default PageStamina;
