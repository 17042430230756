import React from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn } from "../../utility/effects";
import { PROJECT_NAME } from "../../utility/constants";
import { Link } from "react-router-dom";

const DonateView = () => {
  const paypalLink =
    process.env.REACT_APP_PAYPAL_LINK || "https://www.paypal.com/";

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Apoie o <span className="text-yellow-400">{PROJECT_NAME}</span>
        </motion.h1>

        <div
          role="alert"
          className="alert alert-warning mb-4 max-w-4xl mx-auto"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current shrink-0 h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
            />
          </svg>
          <span>
            <strong>Todas</strong> as <strong>doações</strong> serão convertidas
            para manter os <strong>custos</strong> com o servidor que mantém
            nosso site online.
          </span>
        </div>

        <div className="md:flex justify-between">
          {/* Por que doar? */}
          <div className="md:w-1/2 mb-8 md:mb-0">
            <motion.h2
              className="text-2xl font-bold mt-6 mb-2"
              variants={slideIn}
            >
              Por que doar?
            </motion.h2>
            <motion.p className="text-md mb-4" variants={slideIn}>
              Com milhões de dados em nosso banco (personagens, mortes,
              histórico de experiência e muitas ferramentas exclusivas). A
              manutenção de nossos servidores tem um custo significativo devido
              à grande quantidade de dados que gerenciamos. Essa despesa é
              necessária para fornecer serviços de alta qualidade.
            </motion.p>
            <motion.p className="text-md mb-4" variants={slideIn}>
              Nosso site, diferente dos demais sites, não contém nenhum anúncio
              para nos ajudar a manter de pé este projeto. Por isso contamos com
              as doações espontâneas de nosso usuários para ajudar a manter
              nosso site online.
            </motion.p>
            <motion.p className="text-md mb-4" variants={slideIn}>
              Doe-nos se você gosta do nosso fansite e quer ajudar a manter
              nosso trabalho online.
            </motion.p>
          </div>

          {/* Como doar? */}
          <div className="md:w-1/2 md:ml-4">
            <motion.h2
              className="text-2xl font-bold mt-6 mb-2"
              variants={slideIn}
            >
              Como doar Tibia Coins?
            </motion.h2>
            <motion.ul className="list-disc ml-8" variants={slideIn}>
              <motion.li>
                Faça login no jogo e clique no botão{" "}
                <strong className="text-secondary">Store</strong> no cliente do
                jogo.
              </motion.li>
              <motion.li>
                Clique em{" "}
                <strong className="text-secondary">
                  Gift Tibia Coins to another character
                </strong>
                .
              </motion.li>
              <motion.li>
                No campo <strong className="text-secondary">Recipient</strong>,
                insira nosso personagem{" "}
                <strong className="text-secondary">{process.env.REACT_APP_CHARACTER_NAME}</strong>, defina a
                quantidade de Tibia Coins e clique em{" "}
                <strong className="text-secondary">Gift</strong>.
              </motion.li>
              <motion.li>
                Confirme sua doação nos enviando uma{" "}
                <strong>captura de tela</strong> da sua doação! Entre em contato
                conosco através da{" "}
                <Link to="/contact" className="text-accent hover:underline">
                  página de contato
                </Link>
              </motion.li>
            </motion.ul>

            <motion.h2
              className="text-2xl font-bold mt-6 mb-2"
              variants={slideIn}
            >
              Como doar via PayPal ou Pix?
            </motion.h2>
            <motion.ul className="list-disc ml-8" variants={slideIn}>
              <motion.li>
                Você pode doar via{" "}
                <strong className="text-secondary">Pix</strong> ou{" "}
                <strong className="text-secondary">PayPal</strong>:
              </motion.li>
              {/* Opção Pix */}
              <motion.li className="flex items-center mb-2" variants={slideIn}>
                <span>
                  Chave Pix:{" "}
                  <strong className="text-secondary">jprzimba@gmail.com</strong>
                </span>
              </motion.li>
              {/* Opção PayPal */}
              <motion.li className="flex items-center" variants={slideIn}>
                <span>
                  Ou se preferir via{" "}
                  <a
                    rel="noopener noreferrer"
                    href={paypalLink}
                    target="_blank"
                    className="text-accent hover:underline"
                  >
                    <strong className="text-secondary">PayPal</strong>
                  </a>
                </span>
              </motion.li>
            </motion.ul>
          </div>
        </div>
      </motion.div>
    </>
  );
};

export default DonateView;
