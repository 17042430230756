import React, { useState, useEffect, useCallback } from "react";
import ShareablePlayers from "./ShareablePlayers";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";

const PartyFinderView = () => {
  const [charName, setCharName] = useState("");
  const [charData, setCharData] = useState({
    name: "",
    level: 0,
    world: "",
    vocation: "",
    minAndMax: { min: 0, max: 0 },
  });
  const [onlinePlayers, setOnlinePlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const searchChar = async () => {
    const CHAR_DATA_URL = `https://api.tibiadata.com/v4/character/${charName}`;
    try {
      setIsLoading(true);
      const rawCharData = await fetch(CHAR_DATA_URL);
      const charData = (await rawCharData.json())?.character?.character;

      if (!charData?.name) {
        setIsLoading(false);
        setOnlinePlayers([]);
        alert("Personagem não encontrado");
        return;
      }

      const { name, level, world, vocation } = charData;
      const min = Math.round(level / 1.5);
      const max = Math.round(level * 1.5);

      setCharData({
        name,
        level,
        world,
        vocation,
        minAndMax: { min, max },
      });
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  };

  const searchWorld = useCallback(async () => {
    const WORLD_DATA_URL = `https://api.tibiadata.com/v4/world/${charData.world}`;
    try {
      setIsLoading(true);
      const rawWorldData = await fetch(WORLD_DATA_URL);
      const worldData = await rawWorldData.json();
      setOnlinePlayers(worldData?.world?.online_players || []);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      setOnlinePlayers([]);
    }
  }, [charData.world]);

  useEffect(() => {
    if (!charData.name || !charData.level || !charData.world) return;
    searchWorld();
  }, [charData, searchWorld]);

  const handleSubmit = (e) => {
    e.preventDefault();
    searchChar();
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Party <span className="text-yellow-400">Finder</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ol className="mb-4 ml-4">
              <li className="list-decimal">
                Insira o nome do seu personagem no campo de busca.
              </li>
              <li className="list-decimal">
                Clique em <span className="text-secondary">Buscar</span> para
                encontrar jogadores na sua faixa de shared experience.
              </li>
              <li className="list-decimal">
                Visualize a lista de jogadores disponíveis para hunts
                compartilhadas, incluindo informações sobre o nível do
                personagem.
              </li>
            </ol>
          </div>
        </motion.div>

        <form onSubmit={handleSubmit}>
          <motion.div
            className="flex items-center justify-center"
            variants={slideUp}
          >
            <input
              onChange={(e) => setCharName(e.currentTarget.value)}
              placeholder="Nome do personagem"
              value={charName}
              type="text"
              className="input input-bordered input-secondary w-full max-w-xs"
            />
            <button
              type="submit"
              className="ml-2 btn btn-secondary cursor-pointer"
            >
              Buscar
            </button>
          </motion.div>
        </form>

        <ShareablePlayers
          levelToShare={charData.minAndMax}
          onlinePlayers={onlinePlayers}
          isLoading={isLoading}
        />
      </motion.div>
    </>
  );
};

export default PartyFinderView;
