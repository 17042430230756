import React from "react";
import {
  FaHome,
  FaInfoCircle,
  FaTools,
  FaBookOpen,
  FaCalculator,
  FaEnvelope,
  FaLock,
  FaDollarSign,
  FaCubes,
} from "react-icons/fa";

const links = [
  {
    url: "/",
    text: "Início",
    icon: <FaHome className="w-5 h-5" />,
  },

  {
    url: "/contact",
    text: "Contato",
    icon: <FaEnvelope className="w-5 h-5" />,
  },
  {
    url: "/security-hints",
    text: "Discas de Segurança",
    icon: <FaLock className="w-5 h-5" />,
  },
  {
    url: "/donate",
    text: "Doações",
    icon: <FaDollarSign className="w-5 h-5" />,
  },
  {
    url: "/about",
    text: "Sobre",
    icon: <FaInfoCircle className="w-5 h-5" />,
  },
  {
    text: "Biblioteca",
    icon: <FaBookOpen className="w-5 h-5" />,
    subLinks: [
      {
        url: "/library/boss-list",
        text: "Bosses Boostáveis",
        image: "/images/tibia/creatures/Urmahlullu_the_Weakened.gif",
      },
      {
        url: "/library/dream-scar",
        text: "Bosses da Dream Scar",
        image: "/images/tibia/creatures/bosses/Malofur_Mangrinder.gif",
      },
      {
        url: "/library/npc-item-price",
        text: "Comércio de NPC's",
        image: "/images/tibia/npc/Balao_NPC_Trade.png",
      },
    ],
  },
  {
    text: "Calculadoras",
    icon: <FaCalculator className="w-5 h-5" />,
    subLinks: [
      {
        url: "/calculators/blessings",
        text: "Calculadora de Bless",
        image: "/images/tibia/items/Ceremonial_Ankh.gif",
      },
      {
        url: "/calculators/exercise-weapons",
        text: "Calculadora de Exercise Weapons",
        image: "/images/tibia/others/Lasting_Exercise_Axe.gif",
      },
      {
        url: "/calculators/experience",
        text: "Calculadora de Experiência",
        image: "/images/tibia/exp-table.png",
      },
      {
        url: "/calculators/shared-exp",
        text: "Calculadora de Experiência Compartilhada",
        image: "/images/tibia/creatures/Dawnfire_Asura.gif",
      },
      {
        url: "/calculators/loot-by-weight",
        text: "Calculadora de Loot por Peso",
        image: "/images/tibia/items/backpacks/Bag.gif",
      },
      {
        url: "/calculators/magic-shield",
        text: "Calculadora de Magic Shield",
        image: "/images/tibia/Magic_Shield.gif",
      },
      {
        url: "/calculators/stamina",
        text: "Calculadora de Stamina",
        image: "/images/tibia/others/stamina-icon.png",
      },
      {
        url: "/calculators/speed",
        text: "Calculadora de Velocidade",
        image: "/images/tibia/items/boots/Boots_of_Haste.gif",
      },
    ],
  },
  {
    text: "Estatísticas",
    icon: <FaCubes className="w-5 h-5" />,
    subLinks: [
      {
        url: "/community/highscores",
        text: "Ranking Tibiano",
        image: "/images/tibia/others/Champion's_Cup.gif",
      },
    ],
  },
  {
    text: "Ferramentas",
    icon: <FaTools className="w-5 h-5" />,
    subLinks: [
      {
        url: "/tools/hunt-analyzer",
        text: "Analizador de Hunt",
        image: "/images/tibia/XP_Boost.gif",
      },
      {
        url: "/tools/loot-spliter",
        text: "Loot Spliter",
        image: "/images/tibia/items/Crystal_Coin.gif",
      },
      {
        url: "/tools/party-finder",
        text: "Party Finder",
        image: "/images/tibia/party_finder.png",
      },
    ],
  },
];

export { links };
