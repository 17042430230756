import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";

const StaminaView = () => {
  const [staminaLevel, setStaminaLevel] = useState("");
  const [targetStamina, setTargetStamina] = useState("");
  const [staminaResults, setStaminaResults] = useState("");
  const staminaResultsRef = useRef("");

  const calcFullStaminaDate = (millisToFullStamina) => {
    const now = new Date();
    const fullStaminaTimestamp = now.getTime() + millisToFullStamina;
    return new Date(fullStaminaTimestamp).toLocaleString();
  };

  const findTimeToRenegerate = (stamina) => {
    let hours = parseInt(stamina.substring(0, 2));
    let minutes = parseInt(stamina.substring(3, 5));

    if (minutes > 60) {
      return "invalid input";
    } else if (hours >= 42) {
      return "0";
    } else if (hours < 39) {
      let hours_remaining = 38 - hours;
      let minutes_remaining = 60 - minutes;
      return hours_remaining * 180 + minutes_remaining * 3 + 1080;
    } else {
      let hours_remaining = 41 - hours;
      let minutes_remaining = 60 - minutes;
      return hours_remaining * 360 + minutes_remaining * 6;
    }
  };

  const findTimeToRenegerateTargetStamina = (currentStamina, targetStamina) => {
    let currentHours = parseInt(currentStamina.substring(0, 2));
    let currentMinutes = parseInt(currentStamina.substring(3, 5));

    let targetHours = parseInt(targetStamina.substring(0, 2));
    let targetMinutes = parseInt(targetStamina.substring(3, 5));

    if (currentMinutes > 60 || targetMinutes > 60) {
      return "invalid input";
    } else if (currentHours >= 42) {
      return "0";
    } else if (currentHours < 39 && targetHours < 39) {
      let hours_remaining = targetHours - currentHours;
      let minutes_remaining = targetMinutes - currentMinutes;
      return hours_remaining * 180 + minutes_remaining * 3;
    } else if (currentHours >= 39 && targetHours >= 39) {
      let hours_remaining = targetHours - currentHours;
      let minutes_remaining = targetMinutes - currentMinutes;
      return hours_remaining * 360 + minutes_remaining * 6;
    } else {
      let hours_remaining = 38 - currentHours;
      let minutes_remaining = 60 - currentMinutes;

      return (
        hours_remaining * 180 +
        minutes_remaining * 3 +
        (targetHours - 39) * 360 +
        targetMinutes * 6
      );
    }
  };

  const calculateStamina = useCallback(() => {
    let timeToFullStamina = 0;
    if (targetStamina) {
      timeToFullStamina = findTimeToRenegerateTargetStamina(
        staminaLevel,
        targetStamina
      );
    } else {
      timeToFullStamina = findTimeToRenegerate(staminaLevel);
    }

    if (timeToFullStamina === "invalid input") {
      staminaResultsRef.current = "Entrada inválida";
    } else {
      const fullStaminaDate = calcFullStaminaDate(
        timeToFullStamina * 60 * 1000
      );

      const fullStaminaInfo = "Sua stamina estará pronta em " + fullStaminaDate;

      if (timeToFullStamina >= 1440) {
        let days = Math.floor(timeToFullStamina / 1440);
        let hours = Math.floor((timeToFullStamina % 1440) / 60);
        let minutes = timeToFullStamina % 60;

        staminaResultsRef.current = (
          <p className="text-lg font-normal">
            Para recuperar da{" "}
            <span className="text-primary">{staminaLevel}</span> para{" "}
            <span className="text-primary">{targetStamina}</span> stamina, você
            deve descansar por{" "}
            <span className="text-primary">
              {days} dia{days !== 1 ? "s" : ""}, {hours} hora
              {hours !== 1 ? "s" : ""}{" "}
              {minutes > 0 && `e ${minutes} minuto${minutes !== 1 ? "s" : ""}`}
            </span>
            . <span className="text-secondary">{fullStaminaInfo}</span>
          </p>
        );
      } else {
        let hours = Math.floor(timeToFullStamina / 60);
        let minutes = timeToFullStamina % 60;

        staminaResultsRef.current = (
          <p className="text-lg font-normal">
            Para recuperar da{" "}
            <span className="text-primary">{staminaLevel}</span> para{" "}
            <span className="text-primary">{targetStamina || "full"}</span>{" "}
            stamina, você precisa descansar por{" "}
            <span className="text-primary">
              {hours} hora{hours !== 1 ? "s" : ""}{" "}
              {minutes > 0 && `e ${minutes} minuto${minutes !== 1 ? "s" : ""}`}
            </span>
            . <span className="text-secondary">{fullStaminaInfo}</span>
          </p>
        );
      }
    }
    setStaminaResults(staminaResultsRef.current);
  }, [staminaLevel, targetStamina]);

  useEffect(() => {
    calculateStamina();
  }, [calculateStamina]);

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Stamina</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 p-4 rounded-lg shadow-md mb-8 relative">
            <ol className="list-decimal ml-6">
              <li className="mb-2">
                Preencha o campo{" "}
                <span className="text-primary">Qual seu nível de stamina?</span>{" "}
                com o seu nível atual.
              </li>
              <li className="mb-2">
                Preencha o campo{" "}
                <span className="text-primary">Qual a stamina desejada?</span>{" "}
                com a stamina desejada.
              </li>
              <li className="mb-2">
                Nossa inteligência artificial calculará o tempo necessário para
                recuperar a stamina.
              </li>
            </ol>
          </div>
        </motion.div>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <div className="flex flex-col">
            <div className="mb-2">
              <label>Qual seu nível de stamina?</label>
              <input
                type="text"
                value={staminaLevel}
                className="input input-bordered input-neutral w-full my-2"
                placeholder="Ex: 39:00"
                onChange={(e) => setStaminaLevel(e.target.value)}
              />
            </div>
            <div className="mb-2">
              <label>Qual a stamina desejada?</label>
              <input
                type="text"
                value={targetStamina}
                className="input input-bordered input-neutral w-full my-2"
                placeholder="Ex: 42:00"
                onChange={(e) => setTargetStamina(e.target.value)}
              />
            </div>
            {staminaLevel !== "" && (
              <motion.div
                className="bg-base-300 p-4 rounded-md shadow-md text-center max-w-4xl mx-auto"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
              >
                <span className="font-normal">{staminaResults}</span>
              </motion.div>
            )}
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default StaminaView;
