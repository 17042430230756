export const experienceTable = {
  creatures: [
    {
      name: "Rotworm",
      exp: "40",
      expRatio: "0.615",
    },
    {
      name: "Wolf",
      exp: "18",
      expRatio: "0.72",
    },
    {
      name: "Rabbit",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Stone Golem",
      exp: "160",
      expRatio: "0.593",
    },
    {
      name: "Badger",
      exp: "5",
      expRatio: "0.217",
    },
    {
      name: "Bear",
      exp: "23",
      expRatio: "0.288",
    },
    {
      name: "Cave Rat",
      exp: "10",
      expRatio: "0.333",
    },
    {
      name: "Hyaena",
      exp: "20",
      expRatio: "0.333",
    },
    {
      name: "Lion",
      exp: "30",
      expRatio: "0.375",
    },
    {
      name: "Pig",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Polar Bear",
      exp: "28",
      expRatio: "0.329",
    },
    {
      name: "Skunk",
      exp: "3",
      expRatio: "0.15",
    },
    {
      name: "Winter Wolf",
      exp: "20",
      expRatio: "0.667",
    },
    {
      name: "Flamingo",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Parrot",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Panda",
      exp: "23",
      expRatio: "0.288",
    },
    {
      name: "Spit Nettle",
      exp: "20",
      expRatio: "0.133",
    },
    {
      name: "Crab",
      exp: "30",
      expRatio: "0.545",
    },
    {
      name: "Tiger",
      exp: "40",
      expRatio: "0.533",
    },
    {
      name: "Terror Bird",
      exp: "150",
      expRatio: "0.5",
    },
    {
      name: "Crocodile",
      exp: "40",
      expRatio: "0.381",
    },
    {
      name: "Chicken",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Bat",
      exp: "10",
      expRatio: "0.333",
    },
    {
      name: "Elephant",
      exp: "160",
      expRatio: "0.5",
    },
    {
      name: "Blood Crab",
      exp: "160",
      expRatio: "0.552",
    },
    {
      name: "Carrion Worm",
      exp: "70",
      expRatio: "0.483",
    },
    {
      name: "Seagull",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Tortoise",
      exp: "90",
      expRatio: "0.486",
    },
    {
      name: "Mammoth",
      exp: "160",
      expRatio: "0.5",
    },
    {
      name: "Magicthrower",
      exp: "18",
      expRatio: "0.18",
    },
    {
      name: "Deathslicer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Plaguethrower",
      exp: "18",
      expRatio: "0.18",
    },
    {
      name: "Serpent Spawn",
      exp: "3,050",
      expRatio: "1.017",
    },
    {
      name: "Yeti",
      exp: "460",
      expRatio: "0.484",
    },
    {
      name: "Frost Troll",
      exp: "23",
      expRatio: "0.418",
    },
    {
      name: "Vampire",
      exp: "305",
      expRatio: "0.642",
    },
    {
      name: "Minotaur Mage",
      exp: "150",
      expRatio: "0.968",
    },
    {
      name: "Minotaur",
      exp: "50",
      expRatio: "0.5",
    },
    {
      name: "Minotaur Archer",
      exp: "65",
      expRatio: "0.65",
    },
    {
      name: "Minotaur Guard",
      exp: "160",
      expRatio: "0.865",
    },
    {
      name: "Amazon",
      exp: "60",
      expRatio: "0.545",
    },
    {
      name: "Ashmunrah",
      exp: "3,100",
      expRatio: "0.62",
    },
    {
      name: "Priestess",
      exp: "420",
      expRatio: "1.077",
    },
    {
      name: "Stalker",
      exp: "90",
      expRatio: "0.75",
    },
    {
      name: "Thornback Tortoise",
      exp: "150",
      expRatio: "0.5",
    },
    {
      name: "Valkyrie",
      exp: "85",
      expRatio: "0.447",
    },
    {
      name: "Dwarf Guard",
      exp: "165",
      expRatio: "0.673",
    },
    {
      name: "Elf",
      exp: "42",
      expRatio: "0.42",
    },
    {
      name: "Lich",
      exp: "900",
      expRatio: "1.023",
    },
    {
      name: "Dwarf Soldier",
      exp: "70",
      expRatio: "0.519",
    },
    {
      name: "Bug",
      exp: "18",
      expRatio: "0.621",
    },
    {
      name: "Elf Scout",
      exp: "75",
      expRatio: "0.469",
    },
    {
      name: "Swamp Troll",
      exp: "25",
      expRatio: "0.455",
    },
    {
      name: "Goblin",
      exp: "25",
      expRatio: "0.5",
    },
    {
      name: "Poison Spider",
      exp: "22",
      expRatio: "0.846",
    },
    {
      name: "Ghoul",
      exp: "85",
      expRatio: "0.85",
    },
    {
      name: "Scarab",
      exp: "120",
      expRatio: "0.375",
    },
    {
      name: "Larva",
      exp: "44",
      expRatio: "0.629",
    },
    {
      name: "Scorpion",
      exp: "45",
      expRatio: "1",
    },
    {
      name: "Snake",
      exp: "10",
      expRatio: "0.667",
    },
    {
      name: "Orc Leader",
      exp: "270",
      expRatio: "0.6",
    },
    {
      name: "Orc Rider",
      exp: "110",
      expRatio: "0.611",
    },
    {
      name: "Orc Shaman",
      exp: "110",
      expRatio: "0.957",
    },
    {
      name: "Orc Berserker",
      exp: "195",
      expRatio: "0.929",
    },
    {
      name: "Orc Spearman",
      exp: "38",
      expRatio: "0.362",
    },
    {
      name: "Orc Warlord",
      exp: "670",
      expRatio: "0.705",
    },
    {
      name: "Orc Warrior",
      exp: "50",
      expRatio: "0.4",
    },
    {
      name: "Demon Skeleton",
      exp: "240",
      expRatio: "0.6",
    },
    {
      name: "Skeleton",
      exp: "35",
      expRatio: "0.7",
    },
    {
      name: "Quara Pincher",
      exp: "1,200",
      expRatio: "0.667",
    },
    {
      name: "Wild Warrior",
      exp: "60",
      expRatio: "0.444",
    },
    {
      name: "Wasp",
      exp: "24",
      expRatio: "0.686",
    },
    {
      name: "Bonebeast",
      exp: "580",
      expRatio: "1.126",
    },
    {
      name: "Crypt Shambler",
      exp: "195",
      expRatio: "0.591",
    },
    {
      name: "Grorlam",
      exp: "2,400",
      expRatio: "0.8",
    },
    {
      name: "Centipede",
      exp: "34",
      expRatio: "0.486",
    },
    {
      name: "Gargoyle",
      exp: "150",
      expRatio: "0.6",
    },
    {
      name: "Ghost",
      exp: "120",
      expRatio: "0.8",
    },
    {
      name: "Mummy",
      exp: "150",
      expRatio: "0.625",
    },
    {
      name: "Dipthrah",
      exp: "2,900",
      expRatio: "0.69",
    },
    {
      name: "Mahrdis",
      exp: "3,050",
      expRatio: "0.782",
    },
    {
      name: "Vashresamun",
      exp: "2,950",
      expRatio: "0.738",
    },
    {
      name: "Adept of the Cult",
      exp: "400",
      expRatio: "0.93",
    },
    {
      name: "Efreet",
      exp: "410",
      expRatio: "0.745",
    },
    {
      name: "Thalas",
      exp: "2,950",
      expRatio: "0.72",
    },
    {
      name: "Rahemos",
      exp: "3,100",
      expRatio: "0.838",
    },
    {
      name: "Gazer",
      exp: "90",
      expRatio: "0.75",
    },
    {
      name: "Bandit",
      exp: "65",
      expRatio: "0.265",
    },
    {
      name: "Smuggler",
      exp: "48",
      expRatio: "0.369",
    },
    {
      name: "Lizard Snakecharmer",
      exp: "210",
      expRatio: "0.646",
    },
    {
      name: "Butterfly (Purple)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Pirate Cutthroat",
      exp: "175",
      expRatio: "0.538",
    },
    {
      name: "Sibang",
      exp: "105",
      expRatio: "0.467",
    },
    {
      name: "Kongra",
      exp: "115",
      expRatio: "0.338",
    },
    {
      name: "Quara Pincher Scout",
      exp: "600",
      expRatio: "0.774",
    },
    {
      name: "Merlkin",
      exp: "145",
      expRatio: "0.617",
    },
    {
      name: "Lizard Sentinel",
      exp: "110",
      expRatio: "0.415",
    },
    {
      name: "Dworc Fleshhunter",
      exp: "40",
      expRatio: "0.471",
    },
    {
      name: "Azure Frog",
      exp: "20",
      expRatio: "0.333",
    },
    {
      name: "Coral Frog",
      exp: "20",
      expRatio: "0.333",
    },
    {
      name: "Acolyte of the Cult",
      exp: "300",
      expRatio: "0.769",
    },
    {
      name: "Enlightened of the Cult",
      exp: "500",
      expRatio: "0.714",
    },
    {
      name: "Novice of the Cult",
      exp: "100",
      expRatio: "0.351",
    },
    {
      name: "Pirate Buccaneer",
      exp: "250",
      expRatio: "0.588",
    },
    {
      name: "Pirate Corsair",
      exp: "350",
      expRatio: "0.519",
    },
    {
      name: "Pirate Ghost",
      exp: "250",
      expRatio: "0.909",
    },
    {
      name: "Pirate Marauder",
      exp: "125",
      expRatio: "0.595",
    },
    {
      name: "Pirate Skeleton",
      exp: "85",
      expRatio: "0.447",
    },
    {
      name: "Quara Hydromancer",
      exp: "800",
      expRatio: "0.727",
    },
    {
      name: "Quara Predator Scout",
      exp: "400",
      expRatio: "0.449",
    },
    {
      name: "Quara Mantassin",
      exp: "400",
      expRatio: "0.5",
    },
    {
      name: "Toad",
      exp: "60",
      expRatio: "0.444",
    },
    {
      name: "Water Elemental",
      exp: "650",
      expRatio: "1.182",
    },
    {
      name: "Massive Water Elemental",
      exp: "1,100",
      expRatio: "0.88",
    },
    {
      name: "Island Troll",
      exp: "20",
      expRatio: "0.4",
    },
    {
      name: "Orchid Frog",
      exp: "20",
      expRatio: "0.333",
    },
    {
      name: "Crimson Frog",
      exp: "20",
      expRatio: "0.333",
    },
    {
      name: "Green Frog",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Thul",
      exp: "2,700",
      expRatio: "0.915",
    },
    {
      name: "Quara Mantassin Scout",
      exp: "100",
      expRatio: "0.455",
    },
    {
      name: "Quara Hydromancer Scout",
      exp: "800",
      expRatio: "0.727",
    },
    {
      name: "Fernfang",
      exp: "600",
      expRatio: "1.5",
    },
    {
      name: "Hand of Cursed Fate",
      exp: "5,000",
      expRatio: "0.667",
    },
    {
      name: "Phantasm",
      exp: "4,400",
      expRatio: "1.114",
    },
    {
      name: "Son of Verminor",
      exp: "5,900",
      expRatio: "0.694",
    },
    {
      name: "Dwarf Geomancer",
      exp: "265",
      expRatio: "0.697",
    },
    {
      name: "Apprentice Sheng",
      exp: "150",
      expRatio: "1.579",
    },
    {
      name: "Wyvern",
      exp: "515",
      expRatio: "0.648",
    },
    {
      name: "Munster",
      exp: "35",
      expRatio: "0.603",
    },
    {
      name: "Flamethrower",
      exp: "18",
      expRatio: "0.18",
    },
    {
      name: "The Evil Eye",
      exp: "750",
      expRatio: "0.625",
    },
    {
      name: "The Horned Fox",
      exp: "300",
      expRatio: "1.132",
    },
    {
      name: "The Old Widow",
      exp: "4,200",
      expRatio: "1.313",
    },
    {
      name: "Brutus Bloodbeard",
      exp: "795",
      expRatio: "0.511",
    },
    {
      name: "Deadeye Devious",
      exp: "750",
      expRatio: "0.517",
    },
    {
      name: "General Murius",
      exp: "450",
      expRatio: "0.818",
    },
    {
      name: "Lethal Lissy",
      exp: "500",
      expRatio: "0.345",
    },
    {
      name: "Ron the Ripper",
      exp: "500",
      expRatio: "0.333",
    },
    {
      name: "Tiquandas Revenge",
      exp: "2,635",
      expRatio: "1.464",
    },
    {
      name: "Lavahole",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Demon (Goblin)",
      exp: "25",
      expRatio: "0.5",
    },
    {
      name: "The Handmaiden",
      exp: "7,500",
      expRatio: "0.385",
    },
    {
      name: "Magic Pillar",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Witch",
      exp: "120",
      expRatio: "0.4",
    },
    {
      name: "Webster",
      exp: "1,200",
      expRatio: "0.407",
    },
    {
      name: "Spirit of Water",
      exp: "850",
      expRatio: "0.607",
    },
    {
      name: "Norgle Glacierbeard",
      exp: "2,100",
      expRatio: "0.491",
    },
    {
      name: "Spirit of Earth",
      exp: "800",
      expRatio: "0.667",
    },
    {
      name: "Slim",
      exp: "580",
      expRatio: "0.566",
    },
    {
      name: "Kreebosh the Exile",
      exp: "350",
      expRatio: "0.496",
    },
    {
      name: "The Dark Dancer",
      exp: "435",
      expRatio: "0.54",
    },
    {
      name: "The Hag",
      exp: "510",
      expRatio: "0.545",
    },
    {
      name: "Deathbringer",
      exp: "5,100",
      expRatio: "0.604",
    },
    {
      name: "Darakan the Executioner",
      exp: "1,600",
      expRatio: "0.46",
    },
    {
      name: "Grimgor Guteater",
      exp: "670",
      expRatio: "0.58",
    },
    {
      name: "Drasilla",
      exp: "700",
      expRatio: "0.556",
    },
    {
      name: "Orcus the Cruel",
      exp: "280",
      expRatio: "0.583",
    },
    {
      name: "Svoren the Mad",
      exp: "3,000",
      expRatio: "0.475",
    },
    {
      name: "Fallen Mooh'Tah Master Ghar",
      exp: "4,400",
      expRatio: "0.551",
    },
    {
      name: "Quara Constrictor",
      exp: "250",
      expRatio: "0.556",
    },
    {
      name: "Orc",
      exp: "25",
      expRatio: "0.357",
    },
    {
      name: "Elf Arcanist",
      exp: "175",
      expRatio: "0.795",
    },
    {
      name: "Cat",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Squirrel",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Poacher",
      exp: "70",
      expRatio: "0.778",
    },
    {
      name: "Troll Champion",
      exp: "40",
      expRatio: "0.533",
    },
    {
      name: "Cyclops Drone",
      exp: "200",
      expRatio: "0.615",
    },
    {
      name: "Cyclops Smith",
      exp: "255",
      expRatio: "0.586",
    },
    {
      name: "Dark Magician",
      exp: "185",
      expRatio: "0.569",
    },
    {
      name: "Dark Apprentice",
      exp: "100",
      expRatio: "0.444",
    },
    {
      name: "Mechanical Fighter",
      exp: "255",
      expRatio: "0.607",
    },
    {
      name: "Dwarf Henchman",
      exp: "15",
      expRatio: "0.043",
    },
    {
      name: "Thief (Creature)",
      exp: "5",
      expRatio: "0.083",
    },
    {
      name: "Assassin",
      exp: "105",
      expRatio: "0.6",
    },
    {
      name: "Mad Technomancer",
      exp: "55",
      expRatio: "0.031",
    },
    {
      name: "Koshei the Deathless",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Kitty",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Slime",
      exp: "160",
      expRatio: "1.067",
    },
    {
      name: "Fire Devil",
      exp: "145",
      expRatio: "0.725",
    },
    {
      name: "Dwarf Dispenser",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Dworc Voodoomaster",
      exp: "55",
      expRatio: "0.688",
    },
    {
      name: "Nomad (Basic)",
      exp: "60",
      expRatio: "0.375",
    },
    {
      name: "Dryad",
      exp: "190",
      expRatio: "0.613",
    },
    {
      name: "Goblin Assassin",
      exp: "52",
      expRatio: "0.693",
    },
    {
      name: "Rotworm Queen",
      exp: "75",
      expRatio: "0.714",
    },
    {
      name: "Massive Energy Elemental",
      exp: "950",
      expRatio: "0.864",
    },
    {
      name: "Eye of the Seven",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Skeleton Warrior",
      exp: "45",
      expRatio: "0.692",
    },
    {
      name: "Ungreez",
      exp: "500",
      expRatio: "0.061",
    },
    {
      name: "Massive Fire Elemental",
      exp: "1,400",
      expRatio: "0.778",
    },
    {
      name: "Wisp",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Overcharged Energy Element",
      exp: "1,300",
      expRatio: "1.083",
    },
    {
      name: "Zugurosh",
      exp: "10,000",
      expRatio: "0.11",
    },
    {
      name: "Massive Earth Elemental",
      exp: "950",
      expRatio: "0.714",
    },
    {
      name: "Golgordan",
      exp: "10,000",
      expRatio: "0.25",
    },
    {
      name: "Latrivan",
      exp: "10,000",
      expRatio: "0.4",
    },
    {
      name: "Young Sea Serpent",
      exp: "1,000",
      expRatio: "0.952",
    },
    {
      name: "Roaring Water Elemental",
      exp: "1,300",
      expRatio: "0.743",
    },
    {
      name: "Jagged Earth Elemental",
      exp: "1,300",
      expRatio: "0.867",
    },
    {
      name: "Dwarf Miner",
      exp: "60",
      expRatio: "0.5",
    },
    {
      name: "Charged Energy Elemental",
      exp: "450",
      expRatio: "0.9",
    },
    {
      name: "Blazing Fire Elemental",
      exp: "450",
      expRatio: "0.692",
    },
    {
      name: "Sir Valorcrest",
      exp: "1,800",
      expRatio: "1.125",
    },
    {
      name: "Goblin Scavenger",
      exp: "37",
      expRatio: "0.617",
    },
    {
      name: "Lord of the Elements",
      exp: "8,000",
      expRatio: "1",
    },
    {
      name: "The Weakened Count",
      exp: "450",
      expRatio: "0.608",
    },
    {
      name: "Energy Overlord",
      exp: "2,800",
      expRatio: "0.7",
    },
    {
      name: "Smuggler Baron Silvertoe",
      exp: "170",
      expRatio: "0.607",
    },
    {
      name: "Earth Overlord",
      exp: "2,800",
      expRatio: "0.7",
    },
    {
      name: "Fire Overlord",
      exp: "2,800",
      expRatio: "0.7",
    },
    {
      name: "Arachir the Ancient One",
      exp: "1,800",
      expRatio: "1.125",
    },
    {
      name: "Big Boss Trolliver",
      exp: "105",
      expRatio: "0.7",
    },
    {
      name: "Ice Overlord",
      exp: "2,800",
      expRatio: "0.7",
    },
    {
      name: "High Templar Cobrass",
      exp: "515",
      expRatio: "1.256",
    },
    {
      name: "The Big Bad One",
      exp: "170",
      expRatio: "0.567",
    },
    {
      name: "Xenia",
      exp: "255",
      expRatio: "1.275",
    },
    {
      name: "Zevelon Duskbringer",
      exp: "1,800",
      expRatio: "1.286",
    },
    {
      name: "Slick Water Elemental",
      exp: "450",
      expRatio: "0.818",
    },
    {
      name: "Barbaria",
      exp: "355",
      expRatio: "1.029",
    },
    {
      name: "Diblis the Fair",
      exp: "1,800",
      expRatio: "1.2",
    },
    {
      name: "The Frog Prince",
      exp: "1",
      expRatio: "0.018",
    },
    {
      name: "Warlord Ruzad",
      exp: "1,700",
      expRatio: "1.133",
    },
    {
      name: "Zarabustor",
      exp: "8,000",
      expRatio: "1.569",
    },
    {
      name: "Stonecracker",
      exp: "3,500",
      expRatio: "0.538",
    },
    {
      name: "Hell Hole",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Captain Jones",
      exp: "620",
      expRatio: "1.117",
    },
    {
      name: "Hero",
      exp: "1,200",
      expRatio: "0.857",
    },
    {
      name: "The Old Whopper",
      exp: "750",
      expRatio: "0.955",
    },
    {
      name: "Pillar",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "The Count",
      exp: "450",
      expRatio: "0.36",
    },
    {
      name: "Foreman Kneebiter",
      exp: "445",
      expRatio: "0.781",
    },
    {
      name: "Hairman the Huge",
      exp: "335",
      expRatio: "0.558",
    },
    {
      name: "The Imperor",
      exp: "8,000",
      expRatio: "0.533",
    },
    {
      name: "Chakoya Windcaller",
      exp: "48",
      expRatio: "0.571",
    },
    {
      name: "Chakoya Toolshaper",
      exp: "40",
      expRatio: "0.5",
    },
    {
      name: "Chakoya Tribewarden",
      exp: "40",
      expRatio: "0.588",
    },
    {
      name: "Frostfur",
      exp: "35",
      expRatio: "0.538",
    },
    {
      name: "Bloodpaw",
      exp: "50",
      expRatio: "0.5",
    },
    {
      name: "Bovinus",
      exp: "60",
      expRatio: "0.4",
    },
    {
      name: "Achad",
      exp: "70",
      expRatio: "0.378",
    },
    {
      name: "Colerian the Barbarian",
      exp: "90",
      expRatio: "0.34",
    },
    {
      name: "The Hairy One",
      exp: "115",
      expRatio: "0.354",
    },
    {
      name: "Axeitus Headbanger",
      exp: "140",
      expRatio: "0.384",
    },
    {
      name: "Rocky",
      exp: "190",
      expRatio: "0.487",
    },
    {
      name: "Avalanche (Creature)",
      exp: "305",
      expRatio: "0.555",
    },
    {
      name: "Cursed Gladiator",
      exp: "215",
      expRatio: "0.494",
    },
    {
      name: "The Pit Lord",
      exp: "2,500",
      expRatio: "0.474",
    },
    {
      name: "Crystal Spider",
      exp: "900",
      expRatio: "0.72",
    },
    {
      name: "Barbarian Bloodwalker",
      exp: "195",
      expRatio: "0.639",
    },
    {
      name: "Penguin",
      exp: "1",
      expRatio: "0.03",
    },
    {
      name: "The Masked Marauder",
      exp: "3,500",
      expRatio: "0.478",
    },
    {
      name: "Ushuriel",
      exp: "10,000",
      expRatio: "0.317",
    },
    {
      name: "Barbarian Headsplitter",
      exp: "85",
      expRatio: "0.85",
    },
    {
      name: "Barbarian Skullhunter",
      exp: "85",
      expRatio: "0.63",
    },
    {
      name: "Barbarian Brutetamer",
      exp: "90",
      expRatio: "0.621",
    },
    {
      name: "The Obliverator",
      exp: "6,000",
      expRatio: "0.665",
    },
    {
      name: "Spirit of Fire",
      exp: "950",
      expRatio: "0.43",
    },
    {
      name: "Braindeath",
      exp: "985",
      expRatio: "0.804",
    },
    {
      name: "Dire Penguin",
      exp: "119",
      expRatio: "0.688",
    },
    {
      name: "Ice Golem",
      exp: "295",
      expRatio: "0.766",
    },
    {
      name: "Frost Giant",
      exp: "150",
      expRatio: "0.556",
    },
    {
      name: "Silver Rabbit",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Mutated Human",
      exp: "150",
      expRatio: "0.625",
    },
    {
      name: "Mercury Blob",
      exp: "180",
      expRatio: "1.2",
    },
    {
      name: "Mad Scientist",
      exp: "205",
      expRatio: "0.631",
    },
    {
      name: "Gladiator",
      exp: "90",
      expRatio: "0.486",
    },
    {
      name: "Gang Member",
      exp: "70",
      expRatio: "0.237",
    },
    {
      name: "Damaged Worker Golem",
      exp: "95",
      expRatio: "0.365",
    },
    {
      name: "Mutated Rat",
      exp: "450",
      expRatio: "0.818",
    },
    {
      name: "Mutated Tiger",
      exp: "750",
      expRatio: "0.682",
    },
    {
      name: "Nightstalker",
      exp: "500",
      expRatio: "0.714",
    },
    {
      name: "Nightmare Scion",
      exp: "1,350",
      expRatio: "0.964",
    },
    {
      name: "Undead Gladiator",
      exp: "800",
      expRatio: "0.8",
    },
    {
      name: "War Golem",
      exp: "2,310",
      expRatio: "0.537",
    },
    {
      name: "Death Blob",
      exp: "300",
      expRatio: "0.938",
    },
    {
      name: "Frost Giantess",
      exp: "150",
      expRatio: "0.545",
    },
    {
      name: "Rift Brood",
      exp: "1,600",
      expRatio: "0.542",
    },
    {
      name: "Rift Scythe",
      exp: "2,000",
      expRatio: "0.556",
    },
    {
      name: "Rift Worm",
      exp: "1,195",
      expRatio: "0.427",
    },
    {
      name: "Marziel",
      exp: "3,000",
      expRatio: "1.579",
    },
    {
      name: "Lersatio",
      exp: "2,500",
      expRatio: "1.52",
    },
    {
      name: "Morik the Gladiator",
      exp: "160",
      expRatio: "0.13",
    },
    {
      name: "Sharptooth",
      exp: "1,600",
      expRatio: "0.516",
    },
    {
      name: "Inky",
      exp: "250",
      expRatio: "0.333",
    },
    {
      name: "Splasher",
      exp: "500",
      expRatio: "0.294",
    },
    {
      name: "Yalahari (Creature)",
      exp: "5",
      expRatio: "0.033",
    },
    {
      name: "Diseased Bill",
      exp: "300",
      expRatio: "0.3",
    },
    {
      name: "Diseased Dan",
      exp: "300",
      expRatio: "0.375",
    },
    {
      name: "Diseased Fred",
      exp: "300",
      expRatio: "0.273",
    },
    {
      name: "Pythius the Rotten (Creature)",
      exp: "7,000",
      expRatio: "0.778",
    },
    {
      name: "Crazed Beggar",
      exp: "35",
      expRatio: "0.35",
    },
    {
      name: "Shadow of Marziel",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Shadow of Lersatio",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ice Witch",
      exp: "580",
      expRatio: "0.892",
    },
    {
      name: "Armenius (Creature)",
      exp: "500",
      expRatio: "0.909",
    },
    {
      name: "Shadow of Boreth",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Gravelord Oshuran",
      exp: "2,400",
      expRatio: "0.774",
    },
    {
      name: "Servant Golem",
      exp: "5",
      expRatio: "0.004",
    },
    {
      name: "Ghostly Apparition",
      exp: "120",
      expRatio: "0.8",
    },
    {
      name: "Rift Phantom",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Husky",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Dharalion",
      exp: "570",
      expRatio: "1.5",
    },
    {
      name: "Countess Sorrow",
      exp: "13,000",
      expRatio: "2",
    },
    {
      name: "Cyclops",
      exp: "150",
      expRatio: "0.577",
    },
    {
      name: "Blightwalker",
      exp: "5,850",
      expRatio: "0.657",
    },
    {
      name: "Black Knight",
      exp: "1,600",
      expRatio: "0.889",
    },
    {
      name: "Azerus",
      exp: "6,000",
      expRatio: "0.231",
    },
    {
      name: "Worker Golem",
      exp: "1,250",
      expRatio: "0.85",
    },
    {
      name: "Diabolic Imp",
      exp: "2,900",
      expRatio: "1.487",
    },
    {
      name: "Quara Predator",
      exp: "1,600",
      expRatio: "0.727",
    },
    {
      name: "Lost Soul",
      exp: "4,000",
      expRatio: "0.69",
    },
    {
      name: "Spectre",
      exp: "2,100",
      expRatio: "1.556",
    },
    {
      name: "Yakchal",
      exp: "4,400",
      expRatio: "0.765",
    },
    {
      name: "Betrayed Wraith",
      exp: "3,500",
      expRatio: "0.833",
    },
    {
      name: "Madareth",
      exp: "10,000",
      expRatio: "0.133",
    },
    {
      name: "Dracola",
      exp: "11,000",
      expRatio: "0.679",
    },
    {
      name: "Arthei",
      exp: "4,000",
      expRatio: "0.952",
    },
    {
      name: "Boreth",
      exp: "1,800",
      expRatio: "1.286",
    },
    {
      name: "Necropharus",
      exp: "1,050",
      expRatio: "1.4",
    },
    {
      name: "Undead Jester",
      exp: "5",
      expRatio: "0.014",
    },
    {
      name: "Dark Monk",
      exp: "145",
      expRatio: "0.763",
    },
    {
      name: "The Snapper",
      exp: "150",
      expRatio: "0.5",
    },
    {
      name: "Merikh the Slaughterer",
      exp: "1,500",
      expRatio: "0.75",
    },
    {
      name: "Bride of Night",
      exp: "450",
      expRatio: "1.636",
    },
    {
      name: "Killer Rabbit",
      exp: "160",
      expRatio: "0.78",
    },
    {
      name: "Hot Dog",
      exp: "190",
      expRatio: "0.376",
    },
    {
      name: "Berserker Chicken",
      exp: "220",
      expRatio: "0.473",
    },
    {
      name: "Demon Parrot",
      exp: "225",
      expRatio: "0.625",
    },
    {
      name: "Evil Sheep Lord",
      exp: "340",
      expRatio: "0.85",
    },
    {
      name: "Evil Sheep",
      exp: "240",
      expRatio: "0.686",
    },
    {
      name: "Doom Deer",
      exp: "200",
      expRatio: "0.494",
    },
    {
      name: "Doctor Perhaps",
      exp: "325",
      expRatio: "0.684",
    },
    {
      name: "Dirtbeard",
      exp: "375",
      expRatio: "0.595",
    },
    {
      name: "Mephiles",
      exp: "415",
      expRatio: "1",
    },
    {
      name: "Boogey",
      exp: "475",
      expRatio: "0.511",
    },
    {
      name: "Monstor",
      exp: "575",
      expRatio: "0.599",
    },
    {
      name: "Evil Mastermind",
      exp: "675",
      expRatio: "0.521",
    },
    {
      name: "Rottie the Rotworm",
      exp: "40",
      expRatio: "0.615",
    },
    {
      name: "The Bloodtusk",
      exp: "300",
      expRatio: "0.5",
    },
    {
      name: "Shardhead",
      exp: "650",
      expRatio: "0.813",
    },
    {
      name: "Vampire Pig",
      exp: "165",
      expRatio: "0.541",
    },
    {
      name: "Zombie",
      exp: "280",
      expRatio: "0.56",
    },
    {
      name: "Leviathan",
      exp: "5,000",
      expRatio: "0.833",
    },
    {
      name: "Carniphila",
      exp: "150",
      expRatio: "0.588",
    },
    {
      name: "Bonelord",
      exp: "170",
      expRatio: "0.654",
    },
    {
      name: "Troll Legionnaire",
      exp: "140",
      expRatio: "0.667",
    },
    {
      name: "Gozzler",
      exp: "180",
      expRatio: "0.75",
    },
    {
      name: "Mad Sheep",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Butterfly (Red)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Butterfly (Blue)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Enraged Bookworm",
      exp: "55",
      expRatio: "0.379",
    },
    {
      name: "Harbinger of Darkness",
      exp: "8,700",
      expRatio: "0.073",
    },
    {
      name: "Spawn of Despair",
      exp: "2,550",
      expRatio: "0.073",
    },
    {
      name: "Gloombringer",
      exp: "6,000",
      expRatio: "0.12",
    },
    {
      name: "Eclipse Knight",
      exp: "4,650",
      expRatio: "0.103",
    },
    {
      name: "Dreadbeast",
      exp: "250",
      expRatio: "0.313",
    },
    {
      name: "Fahim the Wise",
      exp: "1,500",
      expRatio: "0.75",
    },
    {
      name: "Deathspawn",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Infernal Frog",
      exp: "190",
      expRatio: "0.29",
    },
    {
      name: "Duskbringer",
      exp: "2,600",
      expRatio: "0.732",
    },
    {
      name: "Shadow Hound",
      exp: "600",
      expRatio: "1.081",
    },
    {
      name: "Nightslayer",
      exp: "250",
      expRatio: "0.625",
    },
    {
      name: "Doomsday Cultist",
      exp: "100",
      expRatio: "0.8",
    },
    {
      name: "Bane of Light",
      exp: "750",
      expRatio: "0.682",
    },
    {
      name: "Midnight Spawn",
      exp: "900",
      expRatio: "0.45",
    },
    {
      name: "Midnight Warrior",
      exp: "750",
      expRatio: "0.75",
    },
    {
      name: "Herald of Gloom",
      exp: "450",
      expRatio: "1.324",
    },
    {
      name: "Rat",
      exp: "5",
      expRatio: "0.25",
    },
    {
      name: "Orc Marauder",
      exp: "205",
      expRatio: "0.872",
    },
    {
      name: "Lizard Zaogun",
      exp: "1,700",
      expRatio: "0.575",
    },
    {
      name: "Lizard Legionnaire",
      exp: "1,100",
      expRatio: "0.786",
    },
    {
      name: "Lizard Chosen",
      exp: "2,200",
      expRatio: "0.721",
    },
    {
      name: "Insect Swarm",
      exp: "40",
      expRatio: "0.8",
    },
    {
      name: "Terramite",
      exp: "160",
      expRatio: "0.438",
    },
    {
      name: "Sandcrawler",
      exp: "20",
      expRatio: "0.667",
    },
    {
      name: "Killer Caiman",
      exp: "900",
      expRatio: "0.6",
    },
    {
      name: "Lancer Beetle",
      exp: "275",
      expRatio: "0.688",
    },
    {
      name: "Enraged Squirrel",
      exp: "15",
      expRatio: "0.429",
    },
    {
      name: "The Voice of Ruin",
      exp: "3,500",
      expRatio: "0.636",
    },
    {
      name: "The Collector",
      exp: "100",
      expRatio: "0.294",
    },
    {
      name: "Undead Mine Worker",
      exp: "45",
      expRatio: "0.692",
    },
    {
      name: "Undead Prospector",
      exp: "85",
      expRatio: "0.85",
    },
    {
      name: "Renegade Orc",
      exp: "270",
      expRatio: "0.6",
    },
    {
      name: "Eternal Guardian",
      exp: "1,800",
      expRatio: "0.72",
    },
    {
      name: "Battlemaster Zunzu",
      exp: "2,500",
      expRatio: "0.625",
    },
    {
      name: "Shard of Corruption",
      exp: "5",
      expRatio: "0.008",
    },
    {
      name: "Mooh'Tah Master",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Baron Brute",
      exp: "3,000",
      expRatio: "0.597",
    },
    {
      name: "The Axeorcist",
      exp: "3,000",
      expRatio: "0.588",
    },
    {
      name: "Menace",
      exp: "3,250",
      expRatio: "0.542",
    },
    {
      name: "Doomhowl",
      exp: "3,750",
      expRatio: "0.441",
    },
    {
      name: "Rocko",
      exp: "3,400",
      expRatio: "0.34",
    },
    {
      name: "Dreadwing",
      exp: "3,750",
      expRatio: "0.441",
    },
    {
      name: "Fatality",
      exp: "3,250",
      expRatio: "0.542",
    },
    {
      name: "Tremorak",
      exp: "1,300",
      expRatio: "0.13",
    },
    {
      name: "Incineron",
      exp: "3,500",
      expRatio: "0.5",
    },
    {
      name: "Haunter",
      exp: "4,000",
      expRatio: "0.471",
    },
    {
      name: "The Dreadorian",
      exp: "4,000",
      expRatio: "0.444",
    },
    {
      name: "Coldheart",
      exp: "3,500",
      expRatio: "0.5",
    },
    {
      name: "Tirecz",
      exp: "6,000",
      expRatio: "0.24",
    },
    {
      name: "The Blightfather",
      exp: "400",
      expRatio: "1",
    },
    {
      name: "Grand Mother Foulscale",
      exp: "1,400",
      expRatio: "0.757",
    },
    {
      name: "Cublarc the Plunderer",
      exp: "400",
      expRatio: "1",
    },
    {
      name: "Dreadmaw",
      exp: "1,500?",
      expRatio: "?",
    },
    {
      name: "Lizard Dragon Priest",
      exp: "1,320",
      expRatio: "0.91",
    },
    {
      name: "Lizard Gate Guardian",
      exp: "2,500",
      expRatio: "0.5",
    },
    {
      name: "Fleabringer",
      exp: "100",
      expRatio: "0.377",
    },
    {
      name: "Flamecaller Zazrak",
      exp: "3,031",
      expRatio: "1.127",
    },
    {
      name: "Behemoth",
      exp: "2,500",
      expRatio: "0.625",
    },
    {
      name: "Rift Lord",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "War Wolf",
      exp: "55",
      expRatio: "0.393",
    },
    {
      name: "Draken Elite",
      exp: "4,200",
      expRatio: "0.757",
    },
    {
      name: "Brimstone Bug",
      exp: "900",
      expRatio: "0.692",
    },
    {
      name: "Heoni",
      exp: "515",
      expRatio: "0.572",
    },
    {
      name: "The Keeper",
      exp: "3,205",
      expRatio: "0.08",
    },
    {
      name: "Fury of the Emperor",
      exp: "550~",
      expRatio: "?",
    },
    {
      name: "Snake God Essence",
      exp: "7,410",
      expRatio: "0.114",
    },
    {
      name: "Lizard Abomination",
      exp: "9,700?",
      expRatio: "?",
    },
    {
      name: "Lizard Noble",
      exp: "2,000",
      expRatio: "0.286",
    },
    {
      name: "Scorn of the Emperor",
      exp: "450",
      expRatio: "0.01",
    },
    {
      name: "Spite of the Emperor",
      exp: "500~",
      expRatio: "?",
    },
    {
      name: "Lizard Magistratus",
      exp: "2,000",
      expRatio: "0.32",
    },
    {
      name: "Teleskor",
      exp: "70",
      expRatio: "0.875",
    },
    {
      name: "Snake Thing",
      exp: "8,400?",
      expRatio: "?",
    },
    {
      name: "Mutated Zalamon",
      exp: "10,980~",
      expRatio: "?",
    },
    {
      name: "Elder Bonelord",
      exp: "280",
      expRatio: "0.56",
    },
    {
      name: "Devovorga",
      exp: "65,000?",
      expRatio: "?",
    },
    {
      name: "Teneshpar",
      exp: "20,000",
      expRatio: "1.333",
    },
    {
      name: "Chikhaton",
      exp: "20,000",
      expRatio: "1",
    },
    {
      name: "Bloom of Doom",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Spawn of Devovorga",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Vulnerable Cocoon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cocoon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dwarf",
      exp: "45",
      expRatio: "0.5",
    },
    {
      name: "Grandfather Tridian",
      exp: "1,400",
      expRatio: "0.778",
    },
    {
      name: "Stampor",
      exp: "780",
      expRatio: "0.65",
    },
    {
      name: "Boar",
      exp: "60",
      expRatio: "0.303",
    },
    {
      name: "Undead Cavebear",
      exp: "600",
      expRatio: "1.333",
    },
    {
      name: "Spectral Scum",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Ghost Rat",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Slime Puddle",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Travelling Merchant",
      exp: "65",
      expRatio: "0.65",
    },
    {
      name: "Bane Bringer",
      exp: "400",
      expRatio: "0.16",
    },
    {
      name: "Soul Spark",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Cake Golem",
      exp: "100",
      expRatio: "0.225",
    },
    {
      name: "Bog Raider",
      exp: "800",
      expRatio: "0.615",
    },
    {
      name: "Glitterscale",
      exp: "700",
      expRatio: "0.7",
    },
    {
      name: "Draken Spellweaver",
      exp: "3,100",
      expRatio: "0.62",
    },
    {
      name: "Draken Abomination",
      exp: "3,800",
      expRatio: "0.608",
    },
    {
      name: "Sandstone Scorpion",
      exp: "680",
      expRatio: "0.756",
    },
    {
      name: "Askarak Lord",
      exp: "1,200",
      expRatio: "0.571",
    },
    {
      name: "Askarak Prince",
      exp: "1,700",
      expRatio: "0.654",
    },
    {
      name: "Bog Frog",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Death Priest",
      exp: "750",
      expRatio: "0.938",
    },
    {
      name: "Desperate White Deer",
      exp: "35",
      expRatio: "0.636",
    },
    {
      name: "Diamond Servant",
      exp: "700",
      expRatio: "0.7",
    },
    {
      name: "Dromedary",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Elder Mummy",
      exp: "560",
      expRatio: "0.659",
    },
    {
      name: "Enraged White Deer",
      exp: "165",
      expRatio: "0.647",
    },
    {
      name: "Feverish Citizen",
      exp: "30",
      expRatio: "0.24",
    },
    {
      name: "Filth Toad",
      exp: "90",
      expRatio: "0.486",
    },
    {
      name: "Firestarter",
      exp: "80",
      expRatio: "0.444",
    },
    {
      name: "Ghoulish Hyaena",
      exp: "195",
      expRatio: "0.488",
    },
    {
      name: "Golden Servant",
      exp: "450",
      expRatio: "0.818",
    },
    {
      name: "Grave Guard",
      exp: "485",
      expRatio: "0.674",
    },
    {
      name: "Honour Guard",
      exp: "55",
      expRatio: "0.647",
    },
    {
      name: "Horse (Taupe)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Insectoid Scout",
      exp: "150",
      expRatio: "0.652",
    },
    {
      name: "Iron Servant",
      exp: "210",
      expRatio: "0.6",
    },
    {
      name: "Mad Mage",
      exp: "1,800",
      expRatio: "0.72",
    },
    {
      name: "Sacred Spider",
      exp: "330",
      expRatio: "0.6",
    },
    {
      name: "Shaburak Prince",
      exp: "1,700",
      expRatio: "0.654",
    },
    {
      name: "Slug",
      exp: "70",
      expRatio: "0.275",
    },
    {
      name: "Starving Wolf",
      exp: "65",
      expRatio: "0.765",
    },
    {
      name: "Thornfire Wolf",
      exp: "200",
      expRatio: "0.333",
    },
    {
      name: "Tomb Servant",
      exp: "215",
      expRatio: "0.453",
    },
    {
      name: "Groam",
      exp: "180",
      expRatio: "0.45",
    },
    {
      name: "Askarak Demon",
      exp: "900",
      expRatio: "0.6",
    },
    {
      name: "Troll Guard",
      exp: "25",
      expRatio: "0.417",
    },
    {
      name: "Wild Dog",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Running Elite Orc Guard",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Furious Orc Berserker",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Weakened Demon",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "White Deer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Incredibly Old Witch",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Energized Raging Mage",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Shaburak Demon",
      exp: "900",
      expRatio: "0.6",
    },
    {
      name: "Spider Queen",
      exp: "120",
      expRatio: "0.012",
    },
    {
      name: "Butterfly (Yellow)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Clay Guardian",
      exp: "400",
      expRatio: "0.64",
    },
    {
      name: "Crystal Wolf",
      exp: "275",
      expRatio: "0.367",
    },
    {
      name: "Horestis",
      exp: "3,500",
      expRatio: "0.583",
    },
    {
      name: "Massacre",
      exp: "20,000",
      expRatio: "0.625",
    },
    {
      name: "Ghazbaran",
      exp: "15,000",
      expRatio: "0.195",
    },
    {
      name: "Destroyer",
      exp: "2,500",
      expRatio: "0.676",
    },
    {
      name: "Lizard Templar",
      exp: "155",
      expRatio: "0.378",
    },
    {
      name: "Draken Warmaster",
      exp: "2,400",
      expRatio: "0.578",
    },
    {
      name: "Lizard High Guard",
      exp: "1,450",
      expRatio: "0.806",
    },
    {
      name: "Hellspawn",
      exp: "2,550",
      expRatio: "0.729",
    },
    {
      name: "Black Sheep",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Tarantula",
      exp: "120",
      expRatio: "0.533",
    },
    {
      name: "Plaguesmith",
      exp: "3,555",
      expRatio: "0.431",
    },
    {
      name: "Devovorga (Invincible)",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Elf Overseer",
      exp: "290",
      expRatio: "0.58",
    },
    {
      name: "Shaburak Lord",
      exp: "1,200",
      expRatio: "0.571",
    },
    {
      name: "Fleshcrawler",
      exp: "1,000",
      expRatio: "0.69",
    },
    {
      name: "Ethershreck",
      exp: "5,600",
      expRatio: "0.589",
    },
    {
      name: "The Bloodweb",
      exp: "1,450",
      expRatio: "0.829",
    },
    {
      name: "Deathbine",
      exp: "340",
      expRatio: "0.648",
    },
    {
      name: "Ribstride",
      exp: "1,100",
      expRatio: "1.1",
    },
    {
      name: "Flameborn",
      exp: "2,550",
      expRatio: "0.729",
    },
    {
      name: "Gorgo",
      exp: "7,000",
      expRatio: "1.556",
    },
    {
      name: "Sulphur Scuttler",
      exp: "900",
      expRatio: "0.692",
    },
    {
      name: "Necromancer",
      exp: "580",
      expRatio: "1",
    },
    {
      name: "Orshabaal",
      exp: "10,000",
      expRatio: "0.444",
    },
    {
      name: "Morgaroth",
      exp: "15,000",
      expRatio: "0.273",
    },
    {
      name: "Wild Horse",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Demodras",
      exp: "6,000",
      expRatio: "1.333",
    },
    {
      name: "Zulazza the Corruptor",
      exp: "10,000",
      expRatio: "0.215",
    },
    {
      name: "The Mutated Pumpkin",
      exp: "30,000",
      expRatio: "0.06",
    },
    {
      name: "Crustacea Gigantica",
      exp: "1,800",
      expRatio: "1.125",
    },
    {
      name: "Hide",
      exp: "240",
      expRatio: "0.48",
    },
    {
      name: "Deer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "The Halloween Hare",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Energy Elemental",
      exp: "550",
      expRatio: "1.1",
    },
    {
      name: "Fire Elemental",
      exp: "220",
      expRatio: "0.786",
    },
    {
      name: "Hatebreeder",
      exp: "10,000",
      expRatio: "0.556",
    },
    {
      name: "Mutated Bat",
      exp: "615",
      expRatio: "0.683",
    },
    {
      name: "Deepsea Blood Crab",
      exp: "180",
      expRatio: "0.563",
    },
    {
      name: "Grynch Clan Goblin",
      exp: "4",
      expRatio: "0.05",
    },
    {
      name: "Blistering Fire Elemental",
      exp: "1,300",
      expRatio: "0.867",
    },
    {
      name: "Hellgorak",
      exp: "10,000",
      expRatio: "0.387",
    },
    {
      name: "Hellfire Fighter",
      exp: "3,120",
      expRatio: "0.821",
    },
    {
      name: "Dog",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Wrath of the Emperor",
      exp: "600",
      expRatio: "0.011",
    },
    {
      name: "Acolyte of Darkness",
      exp: "200",
      expRatio: "0.615",
    },
    {
      name: "Souleater",
      exp: "1,300",
      expRatio: "1.182",
    },
    {
      name: "Essence of Darkness",
      exp: "30",
      expRatio: "?",
    },
    {
      name: "Spirit of Light",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Deepling Worker",
      exp: "130",
      expRatio: "0.684",
    },
    {
      name: "Kerberos",
      exp: "10,000",
      expRatio: "0.909",
    },
    {
      name: "Shark",
      exp: "700",
      expRatio: "0.583",
    },
    {
      name: "Jellyfish",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Calamary",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Fish (Creature)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Northern Pike (Creature)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Obujos",
      exp: "20,000",
      expRatio: "0.571",
    },
    {
      name: "Tanjis",
      exp: "15,000",
      expRatio: "0.5",
    },
    {
      name: "Insectoid Worker",
      exp: "650",
      expRatio: "0.684",
    },
    {
      name: "Waspoid",
      exp: "830",
      expRatio: "0.755",
    },
    {
      name: "Swarmer",
      exp: "350",
      expRatio: "0.761",
    },
    {
      name: "Crawler",
      exp: "1,000",
      expRatio: "0.69",
    },
    {
      name: "Floor Blob",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Kollos",
      exp: "2,400",
      expRatio: "0.632",
    },
    {
      name: "Spidris",
      exp: "2,600",
      expRatio: "0.703",
    },
    {
      name: "Ladybug",
      exp: "70",
      expRatio: "0.275",
    },
    {
      name: "Swarmer Hatchling",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Lesser Swarmer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Hive Pore",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Spitter",
      exp: "1,100",
      expRatio: "0.733",
    },
    {
      name: "Hive Overseer",
      exp: "5,500",
      expRatio: "0.733",
    },
    {
      name: "Giant Spider (Wyda)",
      exp: "12",
      expRatio: "0.6",
    },
    {
      name: "Deepling Warrior",
      exp: "1,500",
      expRatio: "0.938",
    },
    {
      name: "Spidris Elite",
      exp: "4,000",
      expRatio: "0.8",
    },
    {
      name: "Terrified Elephant",
      exp: "160",
      expRatio: "0.5",
    },
    {
      name: "Elvira Hammerthrust",
      exp: "165",
      expRatio: "0.673",
    },
    {
      name: "Poodle",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Slippery Northern Pike",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Mornenion",
      exp: "115",
      expRatio: "0.605",
    },
    {
      name: "Bane Lord",
      exp: "500",
      expRatio: "0.167",
    },
    {
      name: "Mirade (Creature)",
      exp: "30,000",
      expRatio: "0.3",
    },
    {
      name: "Tjured (Creature)",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Rejana (Creature)",
      exp: "30,000",
      expRatio: "0.3",
    },
    {
      name: "Wolf (Nostalgia)",
      exp: "15",
      expRatio: "0.6",
    },
    {
      name: "Bear (Nostalgia)",
      exp: "21",
      expRatio: "0.263",
    },
    {
      name: "Wasp (Nostalgia)",
      exp: "21",
      expRatio: "0.6",
    },
    {
      name: "Bug (Nostalgia)",
      exp: "15",
      expRatio: "0.517",
    },
    {
      name: "Spider (Nostalgia)",
      exp: "10",
      expRatio: "0.5",
    },
    {
      name: "Bonelord (Nostalgia)",
      exp: "157",
      expRatio: "0.604",
    },
    {
      name: "Giant Spider (Nostalgia)",
      exp: "840",
      expRatio: "0.646",
    },
    {
      name: "Craban (Creature)",
      exp: "200",
      expRatio: "?",
    },
    {
      name: "Denson Larika (Creature)",
      exp: "3,000?",
      expRatio: "?",
    },
    {
      name: "Robby the Reckless",
      exp: "110",
      expRatio: "0.71",
    },
    {
      name: "Delany (Creature)",
      exp: "30,000",
      expRatio: "0.3",
    },
    {
      name: "Siramal (Creature)",
      exp: "35,000",
      expRatio: "0.35",
    },
    {
      name: "Bolfrim (Creature)",
      exp: "35,000",
      expRatio: "0.35",
    },
    {
      name: "Jesse the Wicked",
      exp: "180",
      expRatio: "0.643",
    },
    {
      name: "Dragon Hatchling",
      exp: "185",
      expRatio: "0.487",
    },
    {
      name: "Dragon",
      exp: "700",
      expRatio: "0.7",
    },
    {
      name: "Frost Dragon Hatchling",
      exp: "745",
      expRatio: "0.931",
    },
    {
      name: "Draptor",
      exp: "2,400",
      expRatio: "0.8",
    },
    {
      name: "Dragon Lord Hatchling",
      exp: "645",
      expRatio: "0.86",
    },
    {
      name: "Frost Dragon",
      exp: "2,100",
      expRatio: "1.167",
    },
    {
      name: "Undead Dragon",
      exp: "7,200",
      expRatio: "0.862",
    },
    {
      name: "Chayenne (Creature)",
      exp: "40,000",
      expRatio: "?",
    },
    {
      name: "Baby Pet of Chayenne",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Noble Pet of Chayenne",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Count Tofifti (Creature)",
      exp: "40,000?",
      expRatio: "?",
    },
    {
      name: "Yellow Toilet Paper Man",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Penciljack (Creature)",
      exp: "35,000",
      expRatio: "0.233",
    },
    {
      name: "Lionet (Creature)",
      exp: "35,000",
      expRatio: "0.35",
    },
    {
      name: "Muse of Penciljack",
      exp: "1,050",
      expRatio: "?",
    },
    {
      name: "Lyxoph (Creature)",
      exp: "10,000",
      expRatio: "0.222",
    },
    {
      name: "Skyrr (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Grimrat",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Grims Little Hamster",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Tin Lizzard of Lyxoph",
      exp: "5,500 ?",
      expRatio: "?",
    },
    {
      name: "Fazzrah",
      exp: "2,600",
      expRatio: "0.88",
    },
    {
      name: "Bruise Payne",
      exp: "1,000",
      expRatio: "0.625",
    },
    {
      name: "Manta Ray",
      exp: "125",
      expRatio: "0.184",
    },
    {
      name: "Deepling Spellsinger",
      exp: "1,000",
      expRatio: "1.176",
    },
    {
      name: "Deepling Scout",
      exp: "160",
      expRatio: "0.667",
    },
    {
      name: "Deepling Guard",
      exp: "2,100",
      expRatio: "1.105",
    },
    {
      name: "Tromphonyte",
      exp: "1,300",
      expRatio: "0.433",
    },
    {
      name: "Paiz the Pauperizer",
      exp: "6,300",
      expRatio: "0.741",
    },
    {
      name: "Tormentor",
      exp: "3,200",
      expRatio: "0.78",
    },
    {
      name: "Deepling Elite",
      exp: "3,000",
      expRatio: "0.938",
    },
    {
      name: "Deepling Tyrant",
      exp: "4,200",
      expRatio: "0.933",
    },
    {
      name: "Hemming",
      exp: "2,850",
      expRatio: "0.95",
    },
    {
      name: "Zanakeph",
      exp: "9,900",
      expRatio: "0.762",
    },
    {
      name: "Maw",
      exp: "6,500",
      expRatio: "0.793",
    },
    {
      name: "Fleshslicer",
      exp: "5,500",
      expRatio: "0.965",
    },
    {
      name: "Chopper",
      exp: "5,400",
      expRatio: "0.75",
    },
    {
      name: "Mindmasher",
      exp: "6,000",
      expRatio: "0.632",
    },
    {
      name: "Shadowstalker",
      exp: "4,000",
      expRatio: "0.656",
    },
    {
      name: "Rotspit",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Deepling Brawler",
      exp: "260",
      expRatio: "0.684",
    },
    {
      name: "Grave Robber",
      exp: "65",
      expRatio: "0.394",
    },
    {
      name: "Crypt Defiler",
      exp: "70",
      expRatio: "0.378",
    },
    {
      name: "Deepling Master Librarian",
      exp: "1,900",
      expRatio: "1.118",
    },
    {
      name: "Crystalcrusher",
      exp: "500",
      expRatio: "0.877",
    },
    {
      name: "Enslaved Dwarf",
      exp: "2,700",
      expRatio: "0.711",
    },
    {
      name: "Lost Berserker",
      exp: "4,400",
      expRatio: "0.746",
    },
    {
      name: "Gnomevil",
      exp: "45,000",
      expRatio: "0.18",
    },
    {
      name: "Mushroom Sniffer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Wiggler",
      exp: "900",
      expRatio: "0.75",
    },
    {
      name: "Mamma Longlegs",
      exp: "2,200",
      expRatio: "1.222",
    },
    {
      name: "Lagatos",
      exp: "35,189",
      expRatio: "?",
    },
    {
      name: "Tormented Ghost",
      exp: "5",
      expRatio: "0.024",
    },
    {
      name: "The Noxious Spawn",
      exp: "6,000",
      expRatio: "0.632",
    },
    {
      name: "Magma Crawler",
      exp: "2,700",
      expRatio: "0.563",
    },
    {
      name: "Dragonling",
      exp: "2,200",
      expRatio: "0.846",
    },
    {
      name: "Ironblight",
      exp: "4,400",
      expRatio: "0.667",
    },
    {
      name: "Hideous Fungus",
      exp: "2,900",
      expRatio: "0.63",
    },
    {
      name: "Humongous Fungus",
      exp: "2,600",
      expRatio: "0.765",
    },
    {
      name: "Stone Devourer",
      exp: "2,900",
      expRatio: "0.69",
    },
    {
      name: "Orewalker",
      exp: "4,800",
      expRatio: "0.667",
    },
    {
      name: "Cliff Strider",
      exp: "5,700",
      expRatio: "0.606",
    },
    {
      name: "Anmothra",
      exp: "15,000",
      expRatio: "2.143",
    },
    {
      name: "Irahsae",
      exp: "15,000",
      expRatio: "0.429",
    },
    {
      name: "Phrodomo",
      exp: "44,000",
      expRatio: "0.489",
    },
    {
      name: "Fury",
      exp: "3,600",
      expRatio: "0.878",
    },
    {
      name: "Dark Torturer",
      exp: "4,650",
      expRatio: "0.633",
    },
    {
      name: "The Many",
      exp: "4,000",
      expRatio: "0.8",
    },
    {
      name: "Esmeralda",
      exp: "600",
      expRatio: "0.75",
    },
    {
      name: "Abyssador",
      exp: "50,000",
      expRatio: "0.147",
    },
    {
      name: "Deathstrike",
      exp: "40,000",
      expRatio: "0.2",
    },
    {
      name: "Gnarlhound",
      exp: "60",
      expRatio: "0.303",
    },
    {
      name: "Modified Gnarlhound",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Vulcongra",
      exp: "1,100",
      expRatio: "0.688",
    },
    {
      name: "Enraged Crystal Golem",
      exp: "550",
      expRatio: "0.786",
    },
    {
      name: "Damaged Crystal Golem",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Rukor Zad",
      exp: "380",
      expRatio: "1",
    },
    {
      name: "Yaga the Crone",
      exp: "375",
      expRatio: "0.605",
    },
    {
      name: "Humorless Fungus",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Armadile",
      exp: "2,900",
      expRatio: "0.763",
    },
    {
      name: "Lava Golem",
      exp: "6,200",
      expRatio: "0.689",
    },
    {
      name: "Weeper",
      exp: "4,800",
      expRatio: "0.706",
    },
    {
      name: "Vampire Bride",
      exp: "1,050",
      expRatio: "0.875",
    },
    {
      name: "Yielothax",
      exp: "1,250",
      expRatio: "0.833",
    },
    {
      name: "Versperoth",
      exp: "30,000",
      expRatio: "0.3",
    },
    {
      name: "Strange Slime",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Infected Weeper",
      exp: "1,200",
      expRatio: "0.4",
    },
    {
      name: "Parasite",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Raging Mage",
      exp: "3,250",
      expRatio: "0.929",
    },
    {
      name: "Sloth Wraith",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Ship Core",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Minion of Versperoth",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Marid",
      exp: "410",
      expRatio: "0.745",
    },
    {
      name: "Shredderthrower",
      exp: "18",
      expRatio: "0.18",
    },
    {
      name: "Nomad (Female)",
      exp: "60",
      expRatio: "0.375",
    },
    {
      name: "Nomad (Blue)",
      exp: "60",
      expRatio: "0.375",
    },
    {
      name: "Young Troll",
      exp: "12",
      expRatio: "0.4",
    },
    {
      name: "Berrypest",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Annihilon",
      exp: "15,000",
      expRatio: "0.323",
    },
    {
      name: "Green Djinn",
      exp: "215",
      expRatio: "0.652",
    },
    {
      name: "Blue Djinn",
      exp: "215",
      expRatio: "0.652",
    },
    {
      name: "Emerald Damselfly",
      exp: "35",
      expRatio: "0.389",
    },
    {
      name: "Marsh Stalker",
      exp: "50",
      expRatio: "0.5",
    },
    {
      name: "Salamander",
      exp: "25",
      expRatio: "0.357",
    },
    {
      name: "Swampling",
      exp: "45",
      expRatio: "0.563",
    },
    {
      name: "Bibby Bloodbath",
      exp: "1,500",
      expRatio: "1.25",
    },
    {
      name: "Corym Charlatan",
      exp: "150",
      expRatio: "0.6",
    },
    {
      name: "Corym Skirmisher",
      exp: "260",
      expRatio: "0.578",
    },
    {
      name: "Lost Basher",
      exp: "1,800",
      expRatio: "0.692",
    },
    {
      name: "Lost Husher",
      exp: "1,800",
      expRatio: "1.125",
    },
    {
      name: "Pigeon",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Water Buffalo",
      exp: "20",
      expRatio: "0.051",
    },
    {
      name: "Lost Thrower",
      exp: "1,200",
      expRatio: "0.706",
    },
    {
      name: "Drillworm",
      exp: "858",
      expRatio: "0.572",
    },
    {
      name: "Adventurer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Little Corym Charlatan",
      exp: "40",
      expRatio: "0.444",
    },
    {
      name: "Angry Adventurer",
      exp: "50",
      expRatio: "0.769",
    },
    {
      name: "Party Skeleton",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Earth Elemental",
      exp: "450",
      expRatio: "0.692",
    },
    {
      name: "Demon",
      exp: "6,000",
      expRatio: "0.732",
    },
    {
      name: "Corym Vanguard",
      exp: "490",
      expRatio: "0.7",
    },
    {
      name: "Mr. Punish",
      exp: "9,000",
      expRatio: "0.409",
    },
    {
      name: "Medusa",
      exp: "4,050",
      expRatio: "0.9",
    },
    {
      name: "Elder Wyrm",
      exp: "2,500",
      expRatio: "0.926",
    },
    {
      name: "Vampire Viscount",
      exp: "800",
      expRatio: "0.667",
    },
    {
      name: "Vicious Manbat",
      exp: "1,200",
      expRatio: "0.706",
    },
    {
      name: "White Shade",
      exp: "120",
      expRatio: "0.462",
    },
    {
      name: "Tarnished Spirit",
      exp: "120",
      expRatio: "0.8",
    },
    {
      name: "Shadow Pupil",
      exp: "410",
      expRatio: "0.911",
    },
    {
      name: "Blood Hand",
      exp: "750",
      expRatio: "1.071",
    },
    {
      name: "Blood Priest",
      exp: "900",
      expRatio: "1.098",
    },
    {
      name: "Forest Fury",
      exp: "235",
      expRatio: "0.49",
    },
    {
      name: "Rorc",
      exp: "105",
      expRatio: "0.404",
    },
    {
      name: "Undead Pet of Chayenne",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Kraknaknork",
      exp: "300",
      expRatio: "3.75",
    },
    {
      name: "Nightfiend",
      exp: "2,100",
      expRatio: "0.778",
    },
    {
      name: "Tyrn",
      exp: "6,900",
      expRatio: "0.575",
    },
    {
      name: "The Welter",
      exp: "11,000",
      expRatio: "0.44",
    },
    {
      name: "White Pale",
      exp: "390",
      expRatio: "0.78",
    },
    {
      name: "Shlorg",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "The Pale Count",
      exp: "28,000",
      expRatio: "0.56",
    },
    {
      name: "Bretzecutioner",
      exp: "3,700",
      expRatio: "0.661",
    },
    {
      name: "Willi Wasp",
      exp: "250",
      expRatio: "1",
    },
    {
      name: "Chizzoron the Distorter",
      exp: "4,000",
      expRatio: "0.25",
    },
    {
      name: "Wild Fury Magic",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Wild Nature Magic",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Wild Water Magic",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Wild Fire Magic",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Omruc",
      exp: "2,950",
      expRatio: "0.686",
    },
    {
      name: "Zomba",
      exp: "300",
      expRatio: "1",
    },
    {
      name: "Necromancer Servant",
      exp: "590",
      expRatio: "0.983",
    },
    {
      name: "Enraged Soul",
      exp: "120",
      expRatio: "0.8",
    },
    {
      name: "Ocyakao",
      exp: "490",
      expRatio: "0.7",
    },
    {
      name: "Hirintror",
      exp: "800",
      expRatio: "0.533",
    },
    {
      name: "Zushuka",
      exp: "9,000",
      expRatio: "0.6",
    },
    {
      name: "Furyosa",
      exp: "11,500",
      expRatio: "0.46",
    },
    {
      name: "Weakened Shlorg",
      exp: "6,500",
      expRatio: "0.65",
    },
    {
      name: "Spawn of the Welter",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Egg (Creature)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Demon Outcast",
      exp: "6,200",
      expRatio: "0.899",
    },
    {
      name: "Shock Head",
      exp: "2,300",
      expRatio: "0.548",
    },
    {
      name: "Sight of Surrender",
      exp: "17,000",
      expRatio: "0.607",
    },
    {
      name: "Silencer",
      exp: "5,100",
      expRatio: "0.944",
    },
    {
      name: "Terrorsleep",
      exp: "7,240",
      expRatio: "1.006",
    },
    {
      name: "Choking Fear",
      exp: "4,700",
      expRatio: "0.81",
    },
    {
      name: "Feversleep",
      exp: "5,060",
      expRatio: "0.858",
    },
    {
      name: "Nightmare of Gaz'haragoth",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Minion of Gaz'haragoth",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Zavarash",
      exp: "21,000",
      expRatio: "0.6",
    },
    {
      name: "Horadron",
      exp: "18,000",
      expRatio: "0.6",
    },
    {
      name: "Terofar",
      exp: "24,000",
      expRatio: "0.6",
    },
    {
      name: "Prince Drazzak",
      exp: "210,000",
      expRatio: "0.636",
    },
    {
      name: "Mawhawk",
      exp: "14,000",
      expRatio: "0.311",
    },
    {
      name: "Shiversleep",
      exp: "1,900",
      expRatio: "0.413",
    },
    {
      name: "Shimmying Butterfly",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Bad Dream",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Wounded Cave Draptor",
      exp: "50",
      expRatio: "10",
    },
    {
      name: "Omrafir",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Flame of Omrafir",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Phantasm (Weak)",
      exp: "1",
      expRatio: "0.015",
    },
    {
      name: "Hellhound",
      exp: "5,440",
      expRatio: "0.725",
    },
    {
      name: "Mooh'Tah Warrior",
      exp: "900",
      expRatio: "0.75",
    },
    {
      name: "Metal Gargoyle",
      exp: "1,278",
      expRatio: "0.609",
    },
    {
      name: "Minotaur Amazon",
      exp: "2,200",
      expRatio: "0.846",
    },
    {
      name: "Execowtioner",
      exp: "2,400",
      expRatio: "0.686",
    },
    {
      name: "Minotaur Hunter",
      exp: "1,700",
      expRatio: "1.214",
    },
    {
      name: "Worm Priestess",
      exp: "1,500",
      expRatio: "1.364",
    },
    {
      name: "Walker",
      exp: "2,200",
      expRatio: "0.733",
    },
    {
      name: "Blood Beast",
      exp: "1,000",
      expRatio: "0.625",
    },
    {
      name: "Devourer",
      exp: "1,755",
      expRatio: "0.924",
    },
    {
      name: "Glooth Blob",
      exp: "700",
      expRatio: "0.933",
    },
    {
      name: "Rustheap Golem",
      exp: "2,100",
      expRatio: "0.75",
    },
    {
      name: "Rot Elemental",
      exp: "750",
      expRatio: "0.882",
    },
    {
      name: "Bullwark",
      exp: "22,000",
      expRatio: "0.306",
    },
    {
      name: "Glooth Golem",
      exp: "1,606",
      expRatio: "0.595",
    },
    {
      name: "Glooth Anemone",
      exp: "1,755",
      expRatio: "0.731",
    },
    {
      name: "The Ravager",
      exp: "14,980",
      expRatio: "0.28",
    },
    {
      name: "Death Priest Shargon",
      exp: "~20,000",
      expRatio: "?",
    },
    {
      name: "Glooth Fairy",
      exp: "19,000",
      expRatio: "0.322",
    },
    {
      name: "Lisa",
      exp: "18,000",
      expRatio: "0.327",
    },
    {
      name: "Lesser Death Minion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Greater Death Minion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Superior Death Minion",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Necromantic Energy",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mountain Troll",
      exp: "12",
      expRatio: "0.4",
    },
    {
      name: "Troll-Trained Salamander",
      exp: "23",
      expRatio: "0.329",
    },
    {
      name: "Dawnfly",
      exp: "35",
      expRatio: "0.389",
    },
    {
      name: "Brittle Skeleton",
      exp: "35",
      expRatio: "0.7",
    },
    {
      name: "Muglex Clan Footman",
      exp: "25",
      expRatio: "0.5",
    },
    {
      name: "Muglex Clan Scavenger",
      exp: "37",
      expRatio: "0.617",
    },
    {
      name: "Salamander Trainer",
      exp: "70",
      expRatio: "0.318",
    },
    {
      name: "Woodling",
      exp: "40",
      expRatio: "0.5",
    },
    {
      name: "Juvenile Cyclops",
      exp: "130",
      expRatio: "0.5",
    },
    {
      name: "Sacred Snake",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Minotaur Bruiser",
      exp: "50",
      expRatio: "0.5",
    },
    {
      name: "Muglex Clan Assassin",
      exp: "48",
      expRatio: "0.64",
    },
    {
      name: "Crazed Dwarf",
      exp: "50",
      expRatio: "0.476",
    },
    {
      name: "Dawn Scorpion",
      exp: "45",
      expRatio: "0.692",
    },
    {
      name: "Lesser Fire Devil",
      exp: "110",
      expRatio: "0.629",
    },
    {
      name: "Meadow Strider",
      exp: "50",
      expRatio: "0.5",
    },
    {
      name: "Minotaur Occultist",
      exp: "100",
      expRatio: "0.8",
    },
    {
      name: "Minotaur Poacher",
      exp: "55",
      expRatio: "0.344",
    },
    {
      name: "Scar Tribe Shaman",
      exp: "85",
      expRatio: "0.739",
    },
    {
      name: "Scar Tribe Warrior",
      exp: "85",
      expRatio: "0.68",
    },
    {
      name: "Professor Maxxen",
      exp: "15,500",
      expRatio: "0.172",
    },
    {
      name: "Noble Lion",
      exp: "400",
      expRatio: "0.4",
    },
    {
      name: "Renegade Quara Constrictor",
      exp: "1,250",
      expRatio: "0.833",
    },
    {
      name: "Renegade Quara Hydromancer",
      exp: "1,800",
      expRatio: "0.9",
    },
    {
      name: "Renegade Quara Mantassin",
      exp: "1,000",
      expRatio: "0.833",
    },
    {
      name: "Renegade Quara Predator",
      exp: "2,700",
      expRatio: "0.831",
    },
    {
      name: "Renegade Quara Pincher",
      exp: "2,200",
      expRatio: "0.786",
    },
    {
      name: "Abyssal Calamary",
      exp: "200",
      expRatio: "0.667",
    },
    {
      name: "High Voltage Elemental",
      exp: "1,500",
      expRatio: "1",
    },
    {
      name: "Glooth-Generator",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Control Tower",
      exp: "3,000",
      expRatio: "0.4",
    },
    {
      name: "Unstable Tunnel",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glooth Battery",
      exp: "3,000",
      expRatio: "0.375",
    },
    {
      name: "Energy Pulse",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Glooth Masher",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glooth Trasher",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glooth Slasher",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glooth Horror",
      exp: "900?",
      expRatio: "?",
    },
    {
      name: "Feeble Glooth Horror",
      exp: "245?",
      expRatio: "?",
    },
    {
      name: "Weakened Glooth Horror",
      exp: "245",
      expRatio: "0.025",
    },
    {
      name: "Strong Glooth Horror",
      exp: "1,500?",
      expRatio: "?",
    },
    {
      name: "Empowered Glooth Horror",
      exp: "2,150?",
      expRatio: "?",
    },
    {
      name: "Tentacle of the Deep Terror",
      exp: "0?",
      expRatio: "?",
    },
    {
      name: "Deep Terror",
      exp: "35,000",
      expRatio: "0.35",
    },
    {
      name: "Glooth Bomb",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Tremor Worm",
      exp: "80,000?",
      expRatio: "?",
    },
    {
      name: "Depowered Minotaur",
      exp: "1,100",
      expRatio: "0.733",
    },
    {
      name: "Glooth Powered Minotaur",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Minotaur Totem",
      exp: "500",
      expRatio: "0.083",
    },
    {
      name: "Minotaur Invader",
      exp: "1,600",
      expRatio: "0.865",
    },
    {
      name: "Moohtant Wallbreaker",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glooth Brigand",
      exp: "1,900",
      expRatio: "0.792",
    },
    {
      name: "Glooth Bandit",
      exp: "2,000",
      expRatio: "0.769",
    },
    {
      name: "Troll Marauder",
      exp: "40",
      expRatio: "0.571",
    },
    {
      name: "Frost Spider",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Frost Servant",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Raging Fire",
      exp: "1,500",
      expRatio: "0.833",
    },
    {
      name: "Acid Blob",
      exp: "250",
      expRatio: "1",
    },
    {
      name: "Ancient Scarab",
      exp: "720",
      expRatio: "0.72",
    },
    {
      name: "Troll",
      exp: "20",
      expRatio: "0.4",
    },
    {
      name: "Werewolf",
      exp: "1,900",
      expRatio: "0.972",
    },
    {
      name: "The Plasmother",
      exp: "12,000",
      expRatio: "1.6",
    },
    {
      name: "Sheep",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Muddy Earth Elemental",
      exp: "450",
      expRatio: "0.692",
    },
    {
      name: "Monk",
      exp: "200",
      expRatio: "0.833",
    },
    {
      name: "Mimic",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Man in the Cave",
      exp: "777",
      expRatio: "1.602",
    },
    {
      name: "Infernalist",
      exp: "4,000",
      expRatio: "1.096",
    },
    {
      name: "Furious Troll",
      exp: "185",
      expRatio: "0.755",
    },
    {
      name: "Dworc Venomsniper",
      exp: "35",
      expRatio: "0.438",
    },
    {
      name: "Defiler",
      exp: "3,700",
      expRatio: "1.014",
    },
    {
      name: "Cobra",
      exp: "30",
      expRatio: "0.462",
    },
    {
      name: "Werebear",
      exp: "2,100",
      expRatio: "0.875",
    },
    {
      name: "Werebadger",
      exp: "1,600",
      expRatio: "0.941",
    },
    {
      name: "Wereboar",
      exp: "2,000",
      expRatio: "0.909",
    },
    {
      name: "Gloom Wolf",
      exp: "70",
      expRatio: "0.35",
    },
    {
      name: "Feroxa",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ghost Wolf",
      exp: "65",
      expRatio: "0.406",
    },
    {
      name: "Tainted Soul",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Redeemed Soul",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Omnivora",
      exp: "750",
      expRatio: "0.625",
    },
    {
      name: "Vicious Squire",
      exp: "900",
      expRatio: "0.9",
    },
    {
      name: "Renegade Knight",
      exp: "1,200",
      expRatio: "0.828",
    },
    {
      name: "Vile Grandmaster",
      exp: "1,500",
      expRatio: "0.882",
    },
    {
      name: "Dawnfire Asura",
      exp: "4,100",
      expRatio: "1.414",
    },
    {
      name: "Midnight Asura",
      exp: "4,100",
      expRatio: "1.323",
    },
    {
      name: "Ekatrix",
      exp: "200",
      expRatio: "0.4",
    },
    {
      name: "The Flaming Orchid",
      exp: "8,500",
      expRatio: "2.125",
    },
    {
      name: "Mahatheb",
      exp: "600",
      expRatio: "0.923",
    },
    {
      name: "Feroxa (Werewolf)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Feroxa (Killable Werewolf)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Feroxa (Gloom Wolf)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Oodok Witchmaster",
      exp: "550",
      expRatio: "1.222",
    },
    {
      name: "Tzumrah the Dazzler",
      exp: "1,700",
      expRatio: "1",
    },
    {
      name: "Canopic Jar",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Owin (Creature)",
      exp: "5,000",
      expRatio: "0.521",
    },
    {
      name: "Hydra",
      exp: "2,100",
      expRatio: "0.894",
    },
    {
      name: "Dragon Lord",
      exp: "2,100",
      expRatio: "1.105",
    },
    {
      name: "Arthom the Hunter",
      exp: "550",
      expRatio: "1.1",
    },
    {
      name: "The Mean Masher",
      exp: "6,000",
      expRatio: "0.75",
    },
    {
      name: "Vexclaw",
      exp: "6,248",
      expRatio: "0.735",
    },
    {
      name: "Grimeleech",
      exp: "7,216",
      expRatio: "0.76",
    },
    {
      name: "Ogre Savage",
      exp: "950",
      expRatio: "0.679",
    },
    {
      name: "Clomp",
      exp: "475",
      expRatio: "0.528",
    },
    {
      name: "Plagirath",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Shulgrax",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Tarbaz",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Zamulosh",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Ragiaz",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Razzagorn",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Ogre Brute",
      exp: "800",
      expRatio: "0.8",
    },
    {
      name: "Seacrest Serpent",
      exp: "2,600",
      expRatio: "0.867",
    },
    {
      name: "Ferumbras Mortal Shell",
      exp: "100,000",
      expRatio: "0.333",
    },
    {
      name: "Rage of Mazoran",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Ascending Ferumbras",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ferumbras Soul Splinter",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mazoran",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Dread Intruder",
      exp: "2,400",
      expRatio: "0.533",
    },
    {
      name: "Reality Reaver",
      exp: "2,480",
      expRatio: "0.636",
    },
    {
      name: "Breach Brood",
      exp: "1,760",
      expRatio: "0.503",
    },
    {
      name: "Enthralled Demon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rift Invader",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ferumbras Essence",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Destabilized Ferumbras",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rift Fragment",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Disgusting Ooze",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Death Dragon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Eruption of Destruction",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Sin Devourer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Guilt",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Despair",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Instable Breach Brood",
      exp: "1,100",
      expRatio: "0.5",
    },
    {
      name: "Instable Sparkion",
      exp: "1,350",
      expRatio: "0.711",
    },
    {
      name: "Stabilizing Reality Reaver",
      exp: "1,950",
      expRatio: "0.78",
    },
    {
      name: "Stabilizing Dread Intruder",
      exp: "1,900",
      expRatio: "0.679",
    },
    {
      name: "Charged Anomaly",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Anomaly",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Grim Reaper",
      exp: "5,500",
      expRatio: "1.41",
    },
    {
      name: "Spark of Destruction",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Outburst",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Charging Outburst",
      exp: "50,000",
      expRatio: "4.167",
    },
    {
      name: "Eradicator",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "Realityquake",
      exp: "20,000",
      expRatio: "0.182",
    },
    {
      name: "Aftershock",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Foreshock",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rupture",
      exp: "~112,000",
      expRatio: "?",
    },
    {
      name: "The Destruction",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Hunger",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Rage",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "World Devourer",
      exp: "~77,700",
      expRatio: "?",
    },
    {
      name: "Charger",
      exp: "85",
      expRatio: "0.053",
    },
    {
      name: "Overcharged Disruption",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Frenzy",
      exp: "~1,313",
      expRatio: "?",
    },
    {
      name: "Greed",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Disruption",
      exp: "~398",
      expRatio: "?",
    },
    {
      name: "Overcharge",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Burster",
      exp: "10,000",
      expRatio: "?",
    },
    {
      name: "Damage Resonance",
      exp: "~560",
      expRatio: "?",
    },
    {
      name: "Crackler",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Charged Disruption",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unstable Spark",
      exp: "1,500",
      expRatio: "0.143",
    },
    {
      name: "Depolarized Crackler",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Midnight Panther",
      exp: "900",
      expRatio: "0.75",
    },
    {
      name: "Jaul",
      exp: "30,000",
      expRatio: "0.333",
    },
    {
      name: "Warlock",
      exp: "4,000",
      expRatio: "1.143",
    },
    {
      name: "Sea Serpent",
      exp: "2,300",
      expRatio: "1.179",
    },
    {
      name: "Gaz'haragoth",
      exp: "1,000,000",
      expRatio: "2.857",
    },
    {
      name: "Frazzlemaw",
      exp: "3,740",
      expRatio: "0.912",
    },
    {
      name: "Ferumbras",
      exp: "35,000",
      expRatio: "0.389",
    },
    {
      name: "Guzzlemaw",
      exp: "6,050",
      expRatio: "0.945",
    },
    {
      name: "Retching Horror",
      exp: "4,100",
      expRatio: "0.774",
    },
    {
      name: "Juggernaut",
      exp: "11,200",
      expRatio: "0.56",
    },
    {
      name: "Twisted Shaper",
      exp: "1,750",
      expRatio: "0.7",
    },
    {
      name: "Shaper Matriarch",
      exp: "1,650",
      expRatio: "0.825",
    },
    {
      name: "Orclops Ravager",
      exp: "1,100",
      expRatio: "0.917",
    },
    {
      name: "Stone Rhino",
      exp: "1,800",
      expRatio: "0.6",
    },
    {
      name: "Dragonking Zyrtarch",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Broken Shaper",
      exp: "1,600",
      expRatio: "0.727",
    },
    {
      name: "Corrupted Soul",
      exp: "1,300",
      expRatio: "1.182",
    },
    {
      name: "Soul of Dragonking Zyrtarch",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Time Guardian",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Lady Tenebris",
      exp: "~50,000?",
      expRatio: "?",
    },
    {
      name: "Shadow Tentacle",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cave Parrot",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Orclops Doomhauler",
      exp: "1,200",
      expRatio: "0.706",
    },
    {
      name: "Squidgy Slime",
      exp: "55",
      expRatio: "0.367",
    },
    {
      name: "Frozen Man",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Solid Frozen Horror",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Frozen Minion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Icicle (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dragon Egg",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Soulcatcher",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Golden Servant Replica",
      exp: "450",
      expRatio: "0.225",
    },
    {
      name: "Diamond Servant Replica",
      exp: "700",
      expRatio: "0.35",
    },
    {
      name: "Animated Sword",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "A Shielded Astral Glyph",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Astral Source",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Bound Astral Power",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mounted Thorn Knight",
      exp: "15,000",
      expRatio: "?",
    },
    {
      name: "Possessed Tree",
      exp: "1,300",
      expRatio: "0.52",
    },
    {
      name: "Thorn Minion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unbound Blightwalker",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Time Keeper",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cosmic Energy Prism A",
      exp: "840",
      expRatio: "0.84",
    },
    {
      name: "Cosmic Energy Prism B",
      exp: "840",
      expRatio: "0.84",
    },
    {
      name: "Cosmic Energy Prism C",
      exp: "840",
      expRatio: "0.84",
    },
    {
      name: "Cosmic Energy Prism D",
      exp: "840",
      expRatio: "0.84",
    },
    {
      name: "The Freezing Time Guardian",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "The Blazing Time Guardian",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Iron Servant Replica",
      exp: "210",
      expRatio: "0.21",
    },
    {
      name: "Baby Dragon (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lost Time",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Time Waster",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Shadow Fiend",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unbound Defiler",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unbound Demon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unbound Demon Outcast",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Melting Frozen Horror",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Enraged Thorn Knight",
      exp: "30,000",
      expRatio: "?",
    },
    {
      name: "The Shielded Thorn Knight",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Elder Forest Fury",
      exp: "330",
      expRatio: "0.493",
    },
    {
      name: "Angry Demon",
      exp: "6,000",
      expRatio: "0.732",
    },
    {
      name: "An Astral Glyph",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Distorted Astral Source",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Piñata Dragon",
      exp: "50",
      expRatio: "0.005",
    },
    {
      name: "Tazhadur",
      exp: "9,000",
      expRatio: "0.9",
    },
    {
      name: "Kalyassa",
      exp: "9,000",
      expRatio: "0.9",
    },
    {
      name: "Dragon Servant",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dragon Wrath",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Haunted Dragon",
      exp: "6,500",
      expRatio: "1",
    },
    {
      name: "Spirit of Fertility",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Angry Plant",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Zorvorax",
      exp: "9,000",
      expRatio: "0.9",
    },
    {
      name: "Dragon Warden",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The First Dragon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "An Observer Eye",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Gelidrazah the Frozen",
      exp: "9,000",
      expRatio: "0.9",
    },
    {
      name: "Giant Spider 7.4",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider 7.6",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider 8.1",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Queen Nostalgia (5)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Brood 7.4",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider Brood 7.6",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider Brood 8.1",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Queen Nostalgia (2)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider Drone 7.4",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider Drone 7.6",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Drone 8.1",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Queen Nostalgia (3)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Hatchling 7.4",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Giant Spider Hatchling 7.6",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Hatchling 8.1",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Queen Nostalgia (1)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Warrior 7.4",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Warrior 7.6",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider Warrior 8.1",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Queen Nostalgia (4)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Knight Familiar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Paladin Familiar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Druid Familiar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Sorcerer Familiar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Furious Fire Elemental",
      exp: "220",
      expRatio: "0.786",
    },
    {
      name: "Pooka",
      exp: "500",
      expRatio: "1",
    },
    {
      name: "Swan Maiden",
      exp: "700",
      expRatio: "0.875",
    },
    {
      name: "Pixie",
      exp: "700",
      expRatio: "0.909",
    },
    {
      name: "Boogy",
      exp: "950",
      expRatio: "0.731",
    },
    {
      name: "Barkless Devotee",
      exp: "2,200",
      expRatio: "0.786",
    },
    {
      name: "The Manhunter",
      exp: "3,000",
      expRatio: "0.667",
    },
    {
      name: "Dark Faun",
      exp: "900",
      expRatio: "0.818",
    },
    {
      name: "Weakened Frazzlemaw",
      exp: "1,000",
      expRatio: "0.833",
    },
    {
      name: "Nymph",
      exp: "850",
      expRatio: "0.944",
    },
    {
      name: "Twisted Pooka",
      exp: "600",
      expRatio: "0.857",
    },
    {
      name: "Animated Clomp",
      exp: "475",
      expRatio: "0.528",
    },
    {
      name: "Animated Guzzlemaw",
      exp: "5,500",
      expRatio: "0.859",
    },
    {
      name: "Animated Mummy",
      exp: "150",
      expRatio: "0.625",
    },
    {
      name: "Animated Ogre Brute",
      exp: "800",
      expRatio: "0.8",
    },
    {
      name: "Animated Ogre Savage",
      exp: "950",
      expRatio: "0.679",
    },
    {
      name: "Animated Rotworm",
      exp: "40",
      expRatio: "0.615",
    },
    {
      name: "Animated Cyclops",
      exp: "150",
      expRatio: "0.577",
    },
    {
      name: "Putrid Mummy",
      exp: "900",
      expRatio: "0.6",
    },
    {
      name: "Minotaur Cult Prophet",
      exp: "1,100",
      expRatio: "0.647",
    },
    {
      name: "Orc Cult Minion",
      exp: "850",
      expRatio: "0.85",
    },
    {
      name: "Orc Cultist",
      exp: "950",
      expRatio: "0.704",
    },
    {
      name: "Orc Cult Inquisitor",
      exp: "1,150",
      expRatio: "0.767",
    },
    {
      name: "Orc Cult Priest",
      exp: "1,000",
      expRatio: "0.769",
    },
    {
      name: "Orc Cult Fanatic",
      exp: "1,100",
      expRatio: "0.846",
    },
    {
      name: "Enfeebled Silencer",
      exp: "1,100",
      expRatio: "1",
    },
    {
      name: "Kroazur",
      exp: "2,700",
      expRatio: "0.9",
    },
    {
      name: "Goldhanded Cultist",
      exp: "2,000",
      expRatio: "0.667",
    },
    {
      name: "Goldhanded Cultist Bride",
      exp: "2,000",
      expRatio: "0.667",
    },
    {
      name: "The Armored Voidborn",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Sandking",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Leiden",
      exp: "0?",
      expRatio: "?",
    },
    {
      name: "Cult Believer",
      exp: "850",
      expRatio: "0.872",
    },
    {
      name: "Cult Enforcer",
      exp: "1,000",
      expRatio: "0.87",
    },
    {
      name: "Cult Scholar",
      exp: "1,100",
      expRatio: "0.667",
    },
    {
      name: "Minotaur Cult Follower",
      exp: "950",
      expRatio: "0.594",
    },
    {
      name: "Minotaur Cult Zealot",
      exp: "1,350",
      expRatio: "0.75",
    },
    {
      name: "Sphere of Wrath",
      exp: "0?",
      expRatio: "?",
    },
    {
      name: "Minotaur Idol",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Misguided Bully",
      exp: "1,200",
      expRatio: "0.6",
    },
    {
      name: "Misguided Thief",
      exp: "1,200",
      expRatio: "0.667",
    },
    {
      name: "The False God",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Roaring Lion",
      exp: "800",
      expRatio: "0.8",
    },
    {
      name: "Sand Brood",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Enraged Sand Brood",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Sand Vortex",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Vermin Swarm",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Sinister Hermit (Yellow)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Sinister Hermit (Blue)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Freed Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Misguided Shadow",
      exp: "1,500",
      expRatio: "0.5",
    },
    {
      name: "Pillar of Draining",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Pillar of Healing",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Pillar of Summoning",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Pillar of Death",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Pillar of Protection",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Destroyed Pillar",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Dread Minion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Liquor Spirit",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Wine Cask (Creature)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Security Golem",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Containment Machine",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Voidshard",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Fox",
      exp: "15",
      expRatio: "0.682",
    },
    {
      name: "Werefox",
      exp: "1,600",
      expRatio: "1.067",
    },
    {
      name: "Cave Devourer",
      exp: "2,380",
      expRatio: "0.529",
    },
    {
      name: "Deepworm",
      exp: "2,300",
      expRatio: "0.657",
    },
    {
      name: "Diremaw",
      exp: "2,500",
      expRatio: "0.694",
    },
    {
      name: "Chasm Spawn",
      exp: "2,700",
      expRatio: "0.6",
    },
    {
      name: "Tunnel Tyrant",
      exp: "3,400",
      expRatio: "0.654",
    },
    {
      name: "Lava Lurker",
      exp: "4,000",
      expRatio: "0.678",
    },
    {
      name: "Mole",
      exp: "100",
      expRatio: "0.5",
    },
    {
      name: "Ghost of a Planegazer",
      exp: "1,100",
      expRatio: "0.759",
    },
    {
      name: "Lost Exile",
      exp: "1,800",
      expRatio: "1.125",
    },
    {
      name: "Stonerefiner",
      exp: "500",
      expRatio: "0.625",
    },
    {
      name: "Makeshift Home",
      exp: "1,800?",
      expRatio: "?",
    },
    {
      name: "Bloodback",
      exp: "4,000",
      expRatio: "0.769",
    },
    {
      name: "Darkfang",
      exp: "4,000",
      expRatio: "0.833",
    },
    {
      name: "Black Vixen",
      exp: "3,200",
      expRatio: "1",
    },
    {
      name: "Shadowpelt",
      exp: "4,600",
      expRatio: "0.767",
    },
    {
      name: "Cave Spider",
      exp: "2,000",
      expRatio: "1.111",
    },
    {
      name: "The Count of the Core",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ancient Spawn of Morgathla",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Gnome Pack Crawler",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lost Gnome",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Captured Dwarf",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Aggressive Lava",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Organic Matter",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ember Beast",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Fiery Blood",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Burrowing Beetle",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Fiery Heart (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Fire Empowered Duke",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Source of Corruption",
      exp: "0?",
      expRatio: "?",
    },
    {
      name: "The Remorseless Corruptor",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Zarcorix of Yalahar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Soul Reaper",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Stolen Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dark Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Corruptor of Souls",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Containment Crystal",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Dawn Bat",
      exp: "10",
      expRatio: "0.333",
    },
    {
      name: "Last Planegazer",
      exp: "10,000?",
      expRatio: "?",
    },
    {
      name: "Reflection of a Mage",
      exp: "3,250",
      expRatio: "0.929",
    },
    {
      name: "Reflection of Mawhawk",
      exp: "14,000",
      expRatio: "0.311",
    },
    {
      name: "Reflection of Obujos",
      exp: "20,000?",
      expRatio: "?",
    },
    {
      name: "Aggressive Matter",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "The Hungry Baron from Below",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Snail Slime",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Fallen Challenger",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Unbeatable Dragon",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Somewhat Beatable Dragon",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Dragon Essence",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ravenous Lava Lurker",
      exp: "4,000",
      expRatio: "0.8",
    },
    {
      name: "Sword of Vengeance",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ravenous Hunger",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Sparkion",
      exp: "1,520",
      expRatio: "0.563",
    },
    {
      name: "Museum Stone Golem",
      exp: "160",
      expRatio: "0.593",
    },
    {
      name: "Museum Stone Rhino",
      exp: "1,800",
      expRatio: "0.6",
    },
    {
      name: "Animated Ogre Shaman",
      exp: "625",
      expRatio: "0.781",
    },
    {
      name: "Knowledge Elemental",
      exp: "10,603",
      expRatio: "1.01",
    },
    {
      name: "Raxias",
      exp: "900",
      expRatio: "0.818",
    },
    {
      name: "Arctic Faun",
      exp: "300",
      expRatio: "1",
    },
    {
      name: "Deathling Scout",
      exp: "6,300",
      expRatio: "0.875",
    },
    {
      name: "Falcon Knight",
      exp: "5,985",
      expRatio: "0.665",
    },
    {
      name: "Falcon Paladin",
      exp: "6,544",
      expRatio: "0.77",
    },
    {
      name: "Undead Elite Gladiator",
      exp: "4,740",
      expRatio: "0.593",
    },
    {
      name: "Energetic Book",
      exp: "12,034",
      expRatio: "0.65",
    },
    {
      name: "Cursed Book",
      exp: "13,345",
      expRatio: "0.667",
    },
    {
      name: "Burning Book",
      exp: "11,934",
      expRatio: "0.663",
    },
    {
      name: "Icecold Book",
      exp: "12,750",
      expRatio: "0.607",
    },
    {
      name: "Guardian of Tales",
      exp: "9,204",
      expRatio: "0.614",
    },
    {
      name: "Energuardian of Tales",
      exp: "11,361",
      expRatio: "0.812",
    },
    {
      name: "Rage Squid",
      exp: "14,820",
      expRatio: "0.872",
    },
    {
      name: "Squid Warden",
      exp: "15,300",
      expRatio: "0.927",
    },
    {
      name: "Animated Feather",
      exp: "9,860",
      expRatio: "0.758",
    },
    {
      name: "Frost Flower Asura",
      exp: "4,200",
      expRatio: "1.2",
    },
    {
      name: "True Dawnfire Asura",
      exp: "7,475",
      expRatio: "0.879",
    },
    {
      name: "True Frost Flower Asura",
      exp: "7,069",
      expRatio: "1.767",
    },
    {
      name: "Floating Savant",
      exp: "8,000",
      expRatio: "1",
    },
    {
      name: "Brain Squid",
      exp: "17,672",
      expRatio: "0.982",
    },
    {
      name: "Flying Book",
      exp: "200",
      expRatio: "0.4",
    },
    {
      name: "Ink Blob",
      exp: "14,450",
      expRatio: "1.521",
    },
    {
      name: "Biting Book",
      exp: "9,350",
      expRatio: "1.438",
    },
    {
      name: "Grand Canon Dominus",
      exp: "11,000",
      expRatio: "0.733",
    },
    {
      name: "Grand Commander Soeren",
      exp: "12,000",
      expRatio: "0.706",
    },
    {
      name: "Grand Chaplain Gaunder",
      exp: "14,000",
      expRatio: "0.778",
    },
    {
      name: "Thawing Dragon Lord",
      exp: "2,100",
      expRatio: "0.084",
    },
    {
      name: "Bone Jaw",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Ghulosh' Deathgaze",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Concentrated Death",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Mean Minion",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Ghulosh",
      exp: "45,000",
      expRatio: "?",
    },
    {
      name: "Lokathmor",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Gorzindel",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Mazzinor",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Force Field",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Supercharged Mazzinor",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Wild Knowledge",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Demon Blood",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Neutral Deepling Warrior",
      exp: "1,500",
      expRatio: "0.938",
    },
    {
      name: "Stolen Knowledge of Summoning",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Stolen Knowledge of Armor",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Stolen Knowledge of Healing",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Stolen Knowledge of Lifesteal",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Stolen Knowledge of Spells",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Librarian",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Stolen Tome of Portals",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Dark Knowledge",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Jailer",
      exp: "45",
      expRatio: "0.692",
    },
    {
      name: "Dazed Leaf Golem",
      exp: "8,000",
      expRatio: "0.8",
    },
    {
      name: "Amarie (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Grodrik (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Knowledge Raider",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Grand Master Oberon",
      exp: "20,000",
      expRatio: "0.667",
    },
    {
      name: "Deathling Spellsinger",
      exp: "6,400",
      expRatio: "0.889",
    },
    {
      name: "Horse (Brown)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Horse (Grey)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Furious Scorpion",
      exp: "12,000",
      expRatio: "0.8",
    },
    {
      name: "Lava Lurker Attendant",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Spellstealer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Blazing Rose",
      exp: "10,000",
      expRatio: "?",
    },
    {
      name: "The Lily of Night",
      exp: "10,000",
      expRatio: "0.526",
    },
    {
      name: "The Book of Death",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Yalahari Despoiler",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Demon Slave",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rift Minion",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Rift Breacher",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Ravenous Beyondling",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Spawn of Havoc",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Imp Intruder",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rift Spawn",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "The Scion of Havoc",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Invading Demon",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Malicious Minion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "The Scourge of Oblivion",
      exp: "75,000",
      expRatio: "?",
    },
    {
      name: "Ice Dragon",
      exp: "2,300",
      expRatio: "0.92",
    },
    {
      name: "Brother Chill",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Brother Freeze",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Devourer of Secrets",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "War Servant",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Spider",
      exp: "900",
      expRatio: "0.692",
    },
    {
      name: "Skeleton Elite Warrior",
      exp: "4,500",
      expRatio: "0.577",
    },
    {
      name: "Moohtant",
      exp: "2,600",
      expRatio: "0.813",
    },
    {
      name: "Biting Cold",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "The Book of Secrets",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lloyd",
      exp: "~50,000",
      expRatio: "?",
    },
    {
      name: "Arachnophobica",
      exp: "4,700",
      expRatio: "0.94",
    },
    {
      name: "Burster Spectre",
      exp: "6,000",
      expRatio: "0.923",
    },
    {
      name: "Gazer Spectre",
      exp: "4,200",
      expRatio: "0.933",
    },
    {
      name: "Insane Siren",
      exp: "6,000",
      expRatio: "0.923",
    },
    {
      name: "Menacing Carnivor",
      exp: "2,112",
      expRatio: "0.603",
    },
    {
      name: "Ripper Spectre",
      exp: "3,500",
      expRatio: "0.921",
    },
    {
      name: "Soul-Broken Harbinger",
      exp: "5,800",
      expRatio: "0.921",
    },
    {
      name: "Spiky Carnivor",
      exp: "1,650",
      expRatio: "0.589",
    },
    {
      name: "Thanatursus",
      exp: "6,300",
      expRatio: "0.875",
    },
    {
      name: "Lumbering Carnivor",
      exp: "1,452",
      expRatio: "0.558",
    },
    {
      name: "Crazed Summer Rearguard",
      exp: "4,700",
      expRatio: "0.887",
    },
    {
      name: "Crazed Summer Vanguard",
      exp: "5,000",
      expRatio: "0.909",
    },
    {
      name: "Crazed Winter Rearguard",
      exp: "4,700",
      expRatio: "0.904",
    },
    {
      name: "Crazed Winter Vanguard",
      exp: "5,400",
      expRatio: "0.931",
    },
    {
      name: "Hibernal Moth",
      exp: "700",
      expRatio: "0.824",
    },
    {
      name: "Lacewing Moth",
      exp: "800",
      expRatio: "0.889",
    },
    {
      name: "Faceless Bane",
      exp: "14,000",
      expRatio: "0.824",
    },
    {
      name: "Lucifuga Aranea",
      exp: "10,000",
      expRatio: "0.833",
    },
    {
      name: "The Nightmare Beast",
      exp: "255,000",
      expRatio: "?",
    },
    {
      name: "Alptramun",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Izcandar the Banished",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Izcandar Champion of Winter",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Izcandar Champion of Summer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Malofur Mangrinder",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Cold of Winter",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "The Heat of Summer",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Nightmare Tendril",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Desperate Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Shatterer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Plagueroot",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Maxxenius",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "The Unarmored Voidborn",
      exp: "15,000",
      expRatio: "0.06",
    },
    {
      name: "Essence of Malice",
      exp: "150,000",
      expRatio: "0.6",
    },
    {
      name: "The Duke of the Depths",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Souldespoiler",
      exp: "50,000",
      expRatio: "0.172",
    },
    {
      name: "The Baron from Below",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Animated Snowman",
      exp: "400",
      expRatio: "0.889",
    },
    {
      name: "Baleful Bunny",
      exp: "450",
      expRatio: "0.9",
    },
    {
      name: "Wyrm",
      exp: "1,550",
      expRatio: "0.849",
    },
    {
      name: "Percht",
      exp: "600",
      expRatio: "0.968",
    },
    {
      name: "Schiach",
      exp: "580",
      expRatio: "0.967",
    },
    {
      name: "Bonny Bunny",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Warm Fire",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Percht Minion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Percht Queen",
      exp: "500",
      expRatio: "0.217",
    },
    {
      name: "Plant Abomination",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Plant Attendant",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Horrible Dream",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Nightmarish Dream",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Mind-Wrecking Dream",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Unpleasant Dream",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Whirling Blades",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Goblin Leader",
      exp: "75",
      expRatio: "1.5",
    },
    {
      name: "Hellflayer",
      exp: "11,000",
      expRatio: "0.786",
    },
    {
      name: "The Fettered Shatterer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Barkless Fanatic",
      exp: "2,500",
      expRatio: "0.781",
    },
    {
      name: "Vortex Spawn",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Murderous Ghost",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Death Reaper",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dreadful Disruptor",
      exp: "14,000",
      expRatio: "?",
    },
    {
      name: "Gryphon",
      exp: "1,000",
      expRatio: "0.313",
    },
    {
      name: "Lamassu",
      exp: "9,000",
      expRatio: "1.034",
    },
    {
      name: "Manticore",
      exp: "4,750",
      expRatio: "0.709",
    },
    {
      name: "Sphinx",
      exp: "6,980",
      expRatio: "0.821",
    },
    {
      name: "Urmahlullu the Immaculate",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cobra Assassin",
      exp: "6,980",
      expRatio: "0.851",
    },
    {
      name: "Cobra Scout",
      exp: "7,310",
      expRatio: "0.86",
    },
    {
      name: "Cobra Vizier",
      exp: "7,650",
      expRatio: "0.9",
    },
    {
      name: "Black Sphinx Acolyte",
      exp: "7,200",
      expRatio: "0.889",
    },
    {
      name: "Feral Sphinx",
      exp: "8,450",
      expRatio: "0.862",
    },
    {
      name: "Crypt Warden",
      exp: "8,400",
      expRatio: "1.012",
    },
    {
      name: "Young Goanna",
      exp: "5,250",
      expRatio: "0.755",
    },
    {
      name: "Adult Goanna",
      exp: "6,180",
      expRatio: "0.745",
    },
    {
      name: "Priestess of the Wild Sun",
      exp: "6,400",
      expRatio: "0.753",
    },
    {
      name: "Bragrumol",
      exp: "18,000",
      expRatio: "0.474",
    },
    {
      name: "Xogixath",
      exp: "22,000",
      expRatio: "?",
    },
    {
      name: "Ogre Ruffian",
      exp: "5,000",
      expRatio: "0.909",
    },
    {
      name: "Ogre Rowdy",
      exp: "4,200",
      expRatio: "0.933",
    },
    {
      name: "Ogre Sage",
      exp: "5,500",
      expRatio: "1.146",
    },
    {
      name: "Mozradek",
      exp: "21,000",
      expRatio: "0.75",
    },
    {
      name: "Sun-Marked Goanna",
      exp: "7,600",
      expRatio: "0.974",
    },
    {
      name: "Count Vlarkorth",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Custodian",
      exp: "27,500",
      expRatio: "0.585",
    },
    {
      name: "Gaffir",
      exp: "25,000",
      expRatio: "0.515",
    },
    {
      name: "Duke Krule",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Sir Nictros",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Sir Baeloc",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Earl Osam",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Scarlett Etzel",
      exp: "20,000",
      expRatio: "0.667",
    },
    {
      name: "Earworm",
      exp: "2,000",
      expRatio: "0.5",
    },
    {
      name: "King Zelos",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Wildness of Urmahlullu",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Wisdom of Urmahlullu",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Urmahlullu the Tamed",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cart Packed with Gold",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Soulless Minion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lord Azaram",
      exp: "55,000",
      expRatio: "?",
    },
    {
      name: "Dark Paladin",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dark Knight",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dark Sorcerer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dark Druid",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Burning Gladiator",
      exp: "7,350",
      expRatio: "0.735",
    },
    {
      name: "Cow",
      exp: "400",
      expRatio: "0.571",
    },
    {
      name: "Orger",
      exp: "550",
      expRatio: "0.786",
    },
    {
      name: "Bellicose Orger",
      exp: "500",
      expRatio: "0.714",
    },
    {
      name: "Loricate Orger",
      exp: "570",
      expRatio: "0.76",
    },
    {
      name: "Roast Pork",
      exp: "280",
      expRatio: "0.667",
    },
    {
      name: "Ugly Monster",
      exp: "1,000",
      expRatio: "0.1",
    },
    {
      name: "Annoying Dog",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Eating Dog",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Flimsy Lost Soul",
      exp: "4,500",
      expRatio: "1.125",
    },
    {
      name: "Mean Lost Soul",
      exp: "5,580",
      expRatio: "1.116",
    },
    {
      name: "Freakish Lost Soul",
      exp: "7,020",
      expRatio: "1.003",
    },
    {
      name: "Brain Head",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Evil Prospector",
      exp: "9,000",
      expRatio: "1.059",
    },
    {
      name: "Cursed Prospector",
      exp: "5,250",
      expRatio: "1.346",
    },
    {
      name: "Guard Captain Quaid",
      exp: "28,000",
      expRatio: "0.509",
    },
    {
      name: "Cerebellum",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unaz the Mean",
      exp: "22,000",
      expRatio: "0.786",
    },
    {
      name: "Irgix the Flimsy",
      exp: "18,000",
      expRatio: "0.75",
    },
    {
      name: "Vok the Freakish",
      exp: "27,000",
      expRatio: "0.844",
    },
    {
      name: "Thaian",
      exp: "9,000",
      expRatio: "0.18",
    },
    {
      name: "Thaian's Ghost",
      exp: "4,500",
      expRatio: "0.9",
    },
    {
      name: "The Unwelcome",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Wormling",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Brother Worm",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Dread Maiden",
      exp: "72,000",
      expRatio: "0.24",
    },
    {
      name: "Red Soul Stealer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Green Soul Stealer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blue Soul Stealer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Fear Feaster",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Horror",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Fear",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Phobia",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Symbol of Fear",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Empty",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Innocent Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Greed Worm",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Pale Worm",
      exp: "80,000",
      expRatio: "?",
    },
    {
      name: "Hunger Worm",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "A Weak Spot",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Wandering Prospector",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Bad Thought",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Rewar the Bloody",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Risen Soldier",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Fetter",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "The Red Knight",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Magnor Mournbringer",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Shard of Magnor",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Vampiric Blood",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Regenerating Mass",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Unleashed Hex",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Condensed Sin",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Azaram's Soul",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Tainted Soul Splinter",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Frozen Soul",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Magical Sphere",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Squire of Nictros",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Retainer of Baeloc",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Soul Scourge",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Nargol the Impaler",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Animated Moohtant",
      exp: "2,600",
      expRatio: "0.813",
    },
    {
      name: "The Lord of the Lice",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Animated Skunk",
      exp: "3?",
      expRatio: "?",
    },
    {
      name: "Nightmare",
      exp: "1,666",
      expRatio: "0.617",
    },
    {
      name: "Hunter",
      exp: "150",
      expRatio: "1",
    },
    {
      name: "King Chuck",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Great Schnitzel",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Fat Porker",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Orger Treasure",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Spawn of the Schnitzel",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Black Cobra",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Greater Canopic Jar",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Planestrider",
      exp: "9,000?",
      expRatio: "?",
    },
    {
      name: "Lost Ghost of a Planegazer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Planedweller",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Aggressive Chicken",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Agrestic Chicken",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Bony Sea Devil",
      exp: "17,328",
      expRatio: "0.722",
    },
    {
      name: "Brachiodemon",
      exp: "15,770",
      expRatio: "0.631",
    },
    {
      name: "Branchy Crawler",
      exp: "17,324",
      expRatio: "0.642",
    },
    {
      name: "Cloak of Terror",
      exp: "19,700",
      expRatio: "0.704",
    },
    {
      name: "Courage Leech",
      exp: "18,900",
      expRatio: "0.7",
    },
    {
      name: "Infernal Demon",
      exp: "17,430",
      expRatio: "0.545",
    },
    {
      name: "Many Faces",
      exp: "18,870",
      expRatio: "0.629",
    },
    {
      name: "Monk of the Order",
      exp: "200",
      expRatio: "0.833",
    },
    {
      name: "Rotten Golem",
      exp: "17,324",
      expRatio: "0.619",
    },
    {
      name: "Turbulent Elemental",
      exp: "17,230",
      expRatio: "0.615",
    },
    {
      name: "White Lion",
      exp: "2,300",
      expRatio: "0.852",
    },
    {
      name: "Werehyaena",
      exp: "2,200",
      expRatio: "0.815",
    },
    {
      name: "Werehyaena Shaman",
      exp: "2,200",
      expRatio: "0.88",
    },
    {
      name: "Vibrant Phantom",
      exp: "19,700",
      expRatio: "0.73",
    },
    {
      name: "Capricious Phantom",
      exp: "17,230",
      expRatio: "0.574",
    },
    {
      name: "Infernal Phantom",
      exp: "15,770",
      expRatio: "0.607",
    },
    {
      name: "Mould Phantom",
      exp: "17,780",
      expRatio: "0.635",
    },
    {
      name: "Distorted Phantom",
      exp: "18,870",
      expRatio: "0.726",
    },
    {
      name: "Ancient Lion Archer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ancient Lion Knight",
      exp: "8,100",
      expRatio: "0.89",
    },
    {
      name: "Ancient Lion Warlock",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lion Archer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Lion Knight",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Lion Warlock",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Usurper Archer",
      exp: "6,800",
      expRatio: "0.932",
    },
    {
      name: "Usurper Knight",
      exp: "6,900",
      expRatio: "0.841",
    },
    {
      name: "Usurper Warlock",
      exp: "7,000",
      expRatio: "0.933",
    },
    {
      name: "Druid's Apparition",
      exp: "18,870",
      expRatio: "0.755",
    },
    {
      name: "Knight's Apparition",
      exp: "18,870",
      expRatio: "0.755",
    },
    {
      name: "Paladin's Apparition",
      exp: "18,870",
      expRatio: "0.755",
    },
    {
      name: "Sorcerer's Apparition",
      exp: "18,870",
      expRatio: "0.755",
    },
    {
      name: "Goshnar's Cruelty",
      exp: "75,000",
      expRatio: "?",
    },
    {
      name: "Goshnar's Greed",
      exp: "150,000",
      expRatio: "?",
    },
    {
      name: "Goshnar's Hatred",
      exp: "75,000",
      expRatio: "?",
    },
    {
      name: "Goshnar's Malice",
      exp: "75,000",
      expRatio: "?",
    },
    {
      name: "Goshnar's Megalomania",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Goshnar's Spite",
      exp: "75,000",
      expRatio: "?",
    },
    {
      name: "Hazardous Phantom",
      exp: "66,000",
      expRatio: "0.943",
    },
    {
      name: "Mirror Image",
      exp: "27,000",
      expRatio: "0.771",
    },
    {
      name: "Fugue",
      exp: "23,000",
      expRatio: "0.719",
    },
    {
      name: "Usurper Commander",
      exp: "7,200",
      expRatio: "0.847",
    },
    {
      name: "Drume",
      exp: "25,000",
      expRatio: "0.714",
    },
    {
      name: "Katex Blood Tongue",
      exp: "5,000",
      expRatio: "0.794",
    },
    {
      name: "Srezz Yellow Eyes",
      exp: "4,800",
      expRatio: "0.774",
    },
    {
      name: "Utua Stone Sting",
      exp: "5,100",
      expRatio: "0.797",
    },
    {
      name: "Yirkas Blue Scales",
      exp: "4,900",
      expRatio: "0.778",
    },
    {
      name: "Crypt Warrior",
      exp: "4,200",
      expRatio: "0.538",
    },
    {
      name: "Kesar",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Hardened Usurper Archer",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Hardened Usurper Knight",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Hardened Usurper Warlock",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Lion Commander",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "True Midnight Asura",
      exp: "7,313",
      expRatio: "0.813",
    },
    {
      name: "Strange Rat",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Angry King Chuck",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Food Wagon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Celebrating Orger",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Slowing Slime",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Used Food Wagon",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Damned Soul",
      exp: "1",
      expRatio: "0.001",
    },
    {
      name: "Quara Constrictor Scout",
      exp: "200",
      expRatio: "0.444",
    },
    {
      name: "Bone Capsule",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blood Tear",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Channeling Earl Osam",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Mezlon the Defiler",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "The Last Lore Keeper",
      exp: "45,000?",
      expRatio: "?",
    },
    {
      name: "Exotic Cave Spider",
      exp: "1,400",
      expRatio: "0.737",
    },
    {
      name: "Exotic Bat",
      exp: "1,200",
      expRatio: "0.8",
    },
    {
      name: "Angry Elemental",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Pirat Cutthroat",
      exp: "1,800",
      expRatio: "0.692",
    },
    {
      name: "Pirat Bombardier",
      exp: "1,700",
      expRatio: "0.739",
    },
    {
      name: "Pirat Mate",
      exp: "2,400",
      expRatio: "0.75",
    },
    {
      name: "Werelioness",
      exp: "2,300",
      expRatio: "0.767",
    },
    {
      name: "Spyrat (Facing South)",
      exp: "2,500",
      expRatio: "0.833",
    },
    {
      name: "Spyrat (Facing North)",
      exp: "2,500",
      expRatio: "0.833",
    },
    {
      name: "Spyrat (Facing East)",
      exp: "2,500",
      expRatio: "0.833",
    },
    {
      name: "Spyrat (Facing West)",
      exp: "2,500",
      expRatio: "0.833",
    },
    {
      name: "Tentugly's Head",
      exp: "40,000",
      expRatio: "?",
    },
    {
      name: "The Hungerer",
      exp: "1,700",
      expRatio: "0.85",
    },
    {
      name: "Greedbeast",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Spy Rat",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Cheese Thief",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Ratmiral Blackwhiskers",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Pirat Guard",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Pirat Patrol",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Gust of Wind",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Sea Serpent (Journey)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Quara Saboteur",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Mean Termite",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Holy Bog Frog",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Tentacle",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Ogre Shaman",
      exp: "625",
      expRatio: "0.781",
    },
    {
      name: "Samael",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Servant of Tentugly",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ghastly Dragon",
      exp: "4,600",
      expRatio: "0.59",
    },
    {
      name: "Gravedigger",
      exp: "950",
      expRatio: "0.633",
    },
    {
      name: "Human (Creature)",
      exp: "50",
      expRatio: "0.333",
    },
    {
      name: "Mystic Energy",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Dreadful Harvester",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Malicious Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Weak Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Soulsnatcher",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Strong Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Powerful Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Weeping Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Spiteful Spitter",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Poor Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mean Maw",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "A Greedy Eye",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Hateful Soul",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Symbol of Hatred",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Soul Sphere",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Aspect of Power",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Lavaworm",
      exp: "6,500",
      expRatio: "0.867",
    },
    {
      name: "Tremendous Tyrant",
      exp: "6,950",
      expRatio: "0.604",
    },
    {
      name: "Varnished Diremaw",
      exp: "6,300",
      expRatio: "0.7",
    },
    {
      name: "Eyeless Devourer",
      exp: "6,000",
      expRatio: "0.6",
    },
    {
      name: "Blemished Spawn",
      exp: "5,300",
      expRatio: "0.589",
    },
    {
      name: "Afflicted Strider",
      exp: "6,550",
      expRatio: "0.655",
    },
    {
      name: "Lavafungus",
      exp: "6,200",
      expRatio: "0.861",
    },
    {
      name: "Cave Chimera",
      exp: "6,800",
      expRatio: "0.85",
    },
    {
      name: "Girtablilu Warrior",
      exp: "5,800",
      expRatio: "0.682",
    },
    {
      name: "Venerable Girtablilu",
      exp: "5,300",
      expRatio: "0.624",
    },
    {
      name: "Bashmu",
      exp: "5,000",
      expRatio: "0.61",
    },
    {
      name: "Juvenile Bashmu",
      exp: "4,500",
      expRatio: "0.6",
    },
    {
      name: "Hulking Carnisylvan",
      exp: "4,700",
      expRatio: "0.547",
    },
    {
      name: "Poisonous Carnisylvan",
      exp: "4,400",
      expRatio: "0.55",
    },
    {
      name: "Dark Carnisylvan",
      exp: "4,400",
      expRatio: "0.587",
    },
    {
      name: "Carnisylvan Sapling",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "The Brainstealer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Spider",
      exp: "12",
      expRatio: "0.6",
    },
    {
      name: "Domestikion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Hoodinion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Mearidion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Murmillion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Scissorion",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "The Mega Magmaoid",
      exp: "80,000",
      expRatio: "?",
    },
    {
      name: "Haunted Treeling",
      exp: "310",
      expRatio: "0.689",
    },
    {
      name: "Leaf Golem",
      exp: "45",
      expRatio: "0.5",
    },
    {
      name: "Abominable Spawn",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Glowing Lava",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Neferi the Spy",
      exp: "19,650",
      expRatio: "0.702",
    },
    {
      name: "Amenef the Burning",
      exp: "21,500",
      expRatio: "0.827",
    },
    {
      name: "Sister Hetai",
      exp: "20,500",
      expRatio: "0.82",
    },
    {
      name: "Lonely Frazzlemaw",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Deer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Snake",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Yielothax",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Rotworm",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Scorpion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Polar Bear",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lonely Souleater",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lesser Splinter of Madness",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Greater Splinter of Madness",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mighty Splinter of Madness",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Magma Fiend",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Instable Fire",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Raccoon Supplies",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Enusat the Onyx Wing",
      exp: "21,500",
      expRatio: "0.86",
    },
    {
      name: "Megasylvan Yselda",
      exp: "19,900",
      expRatio: "?",
    },
    {
      name: "Thieving Squirrel",
      exp: "15",
      expRatio: "0.273",
    },
    {
      name: "Fleeting Thought",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Brain Parasite",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Mental-Nexus",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Morshabaal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lord Retro",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Time Travelling Tourist",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Unexpected",
      exp: "1,800",
      expRatio: "0.514",
    },
    {
      name: "Unsolicited",
      exp: "1,750",
      expRatio: "0.473",
    },
    {
      name: "Unwanted",
      exp: "1,720",
      expRatio: "0.459",
    },
    {
      name: "Retros Treasure",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Uninvited",
      exp: "2,000",
      expRatio: "0.426",
    },
    {
      name: "Memory of a Carnisylvan",
      exp: "1,850",
      expRatio: "0.487",
    },
    {
      name: "Memory of a Werelion",
      exp: "1,800",
      expRatio: "0.464",
    },
    {
      name: "Memory of a Manticore",
      exp: "1,590",
      expRatio: "0.426",
    },
    {
      name: "Pig (Nostalgia)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Memory of a Book",
      exp: "1,770",
      expRatio: "0.482",
    },
    {
      name: "Memory of a Faun",
      exp: "1,600",
      expRatio: "0.457",
    },
    {
      name: "Memory of a Shaper",
      exp: "1,750",
      expRatio: "0.472",
    },
    {
      name: "Memory of an Ogre",
      exp: "1,680",
      expRatio: "0.471",
    },
    {
      name: "Memory of a Golem",
      exp: "1,620",
      expRatio: "0.443",
    },
    {
      name: "Memory of a Frazzlemaw",
      exp: "1,810",
      expRatio: "0.48",
    },
    {
      name: "Memory of a Fungus",
      exp: "1,660",
      expRatio: "0.464",
    },
    {
      name: "Memory of an Insectoid",
      exp: "1,610",
      expRatio: "0.444",
    },
    {
      name: "Memory of a Lizard",
      exp: "1,450",
      expRatio: "0.412",
    },
    {
      name: "Memory of a Vampire",
      exp: "1,550",
      expRatio: "0.425",
    },
    {
      name: "Memory of a Yalahari",
      exp: "1,640",
      expRatio: "0.463",
    },
    {
      name: "Memory of a Mammoth",
      exp: "1,830",
      expRatio: "0.475",
    },
    {
      name: "Memory of a Pirate",
      exp: "1,500",
      expRatio: "0.4",
    },
    {
      name: "Memory of a Hydra",
      exp: "1,800",
      expRatio: "0.507",
    },
    {
      name: "Memory of a Scarab",
      exp: "1,590",
      expRatio: "0.439",
    },
    {
      name: "Memory of an Amazon",
      exp: "1,480",
      expRatio: "0.411",
    },
    {
      name: "Memory of a Banshee",
      exp: "1,430",
      expRatio: "0.397",
    },
    {
      name: "Memory of a Hero",
      exp: "1,750",
      expRatio: "0.455",
    },
    {
      name: "Memory of an Elf",
      exp: "1,440",
      expRatio: "0.419",
    },
    {
      name: "Memory of a Dwarf",
      exp: "1,460",
      expRatio: "0.391",
    },
    {
      name: "Memory of a Wolf",
      exp: "1,640",
      expRatio: "0.451",
    },
    {
      name: "Lucky Dragon",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Minor Timedisplaced Anomaly (Rookgaard)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Enraged Morshabaal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Furious Morshabaal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Streaked Devourer",
      exp: "6,300",
      expRatio: "0.9",
    },
    {
      name: "Minor Timedisplaced Anomaly (Greenshore)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Minor Timedisplaced Anomaly (Ankrahmun)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Timedisplaced Anomaly (Banuta)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Timedisplaced Anomaly (Chazorai)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Timedisplaced Anomaly (Warzone)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Major Timedisplaced Anomaly (Rascacoon)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Major Timedisplaced Anomaly (Issavi)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Major Timedisplaced Anomaly (Fiehonja)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Great Timedisplaced Anomaly",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Wilting Leaf Golem",
      exp: "145",
      expRatio: "0.382",
    },
    {
      name: "Parcel Castle",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Time Distortion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Minor Bug",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Major Bug",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Toxic Tibia Troll",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Orc Musician",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Pirat Scoundrel",
      exp: "1,600",
      expRatio: "0.727",
    },
    {
      name: "Preceptor Lazare",
      exp: "10,000",
      expRatio: "?",
    },
    {
      name: "Symbol of Pain",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Naga Archer",
      exp: "5,150",
      expRatio: "1.11",
    },
    {
      name: "Naga Warrior",
      exp: "5,890",
      expRatio: "1.065",
    },
    {
      name: "Parder",
      exp: "1,100",
      expRatio: "0.917",
    },
    {
      name: "Makara",
      exp: "5,720",
      expRatio: "1.133",
    },
    {
      name: "Foam Stalker",
      exp: "3,120",
      expRatio: "0.693",
    },
    {
      name: "Jungle Moa",
      exp: "1,200",
      expRatio: "0.923",
    },
    {
      name: "Thorn Steed",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Tunnel Devourer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Two-Headed Turtle",
      exp: "2,930",
      expRatio: "0.585",
    },
    {
      name: "Mantosaurus",
      exp: "11,569",
      expRatio: "0.596",
    },
    {
      name: "Stalking Stalk",
      exp: "11,569",
      expRatio: "0.677",
    },
    {
      name: "Undertaker",
      exp: "13,543",
      expRatio: "0.674",
    },
    {
      name: "Sulphider",
      exp: "13,328",
      expRatio: "0.635",
    },
    {
      name: "Noxious Ripptor",
      exp: "13,190",
      expRatio: "0.581",
    },
    {
      name: "Hulking Prehemoth",
      exp: "12,690",
      expRatio: "0.613",
    },
    {
      name: "Nighthunter",
      exp: "12,647",
      expRatio: "0.659",
    },
    {
      name: "Gore Horn",
      exp: "12,595",
      expRatio: "0.611",
    },
    {
      name: "Mercurial Menace",
      exp: "12,095",
      expRatio: "0.654",
    },
    {
      name: "Emerald Tortoise",
      exp: "12,129",
      expRatio: "0.544",
    },
    {
      name: "Gorerilla",
      exp: "13,172",
      expRatio: "0.782",
    },
    {
      name: "Shrieking Cry-Stal",
      exp: "13,560",
      expRatio: "0.657",
    },
    {
      name: "Sulphur Spouter",
      exp: "11,517",
      expRatio: "0.606",
    },
    {
      name: "Plunder Patriarch",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Headpecker",
      exp: "12,026",
      expRatio: "0.738",
    },
    {
      name: "Fungosaurus",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Timira the Many-Headed",
      exp: "45,500",
      expRatio: "0.607",
    },
    {
      name: "The End of Days",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Kusuma",
      exp: "9,380",
      expRatio: "1.226",
    },
    {
      name: "The Primal Menace",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Magma Bubble",
      exp: "80,000",
      expRatio: "?",
    },
    {
      name: "The Winter Bloom",
      exp: "9,250",
      expRatio: "1.947",
    },
    {
      name: "The Moonlight Aster",
      exp: "9,250",
      expRatio: "2.056",
    },
    {
      name: "Rogue Naga",
      exp: "4,510",
      expRatio: "0.727",
    },
    {
      name: "Corrupt Naga",
      exp: "4,380",
      expRatio: "0.731",
    },
    {
      name: "Cave Hydra",
      exp: "2,100",
      expRatio: "0.894",
    },
    {
      name: "Vicious Lich",
      exp: "900",
      expRatio: "1.023",
    },
    {
      name: "Sharpclaw",
      exp: "3,000",
      expRatio: "0.909",
    },
    {
      name: "Sabretooth (Creature)",
      exp: "11,931",
      expRatio: "0.69",
    },
    {
      name: "Magma Crystal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lava Creature",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Giant Beaver",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lava Puddle (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lesser Magma Crystal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blocking Stalagmite",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Venerable Foam Stalker",
      exp: "3,740",
      expRatio: "0.662",
    },
    {
      name: "Greater Energy Elemental",
      exp: "950",
      expRatio: "0.864",
    },
    {
      name: "Greater Fire Elemental",
      exp: "1,400",
      expRatio: "0.778",
    },
    {
      name: "Vile Destroyer",
      exp: "2,500",
      expRatio: "0.676",
    },
    {
      name: "Solitary Frost Dragon",
      exp: "2,100",
      expRatio: "1.167",
    },
    {
      name: "Toxic Swarm",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Hungry Menace",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Unchained Fire",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Sulphider)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Pirat Artillerist",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Tibianus (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Nighthunter)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Mantosaurus)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Noxious Ripptor)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Emerald Tortoise)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Gore Horn)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Gorerilla)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Headpecker)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Hulking Prehemoth)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Sabretooth)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Primal Pack Beast (Stalking Stalk)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Allusion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dorokoll the Mystic",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Eshtaba the Conjurer",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Malkhar Deathbringer",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Eliz the Unyielding",
      exp: "50,000",
      expRatio: "?",
    },
    {
      name: "Morguthis",
      exp: "3,000",
      expRatio: "0.625",
    },
    {
      name: "Hard Times",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blissful Times",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Pillar (Zugurosh)",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Sulphur Menace",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Diamond Blossom",
      exp: "10,000",
      expRatio: "0.5",
    },
    {
      name: "Necromantic Focus",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Sulphur Geysir",
      exp: "--",
      expRatio: "?",
    },
    {
      name: "Rampaging Beer Elemental",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Elite Pirat",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rateye Ric",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mister Catkiller",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "1st Mate Ratticus",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Harpy",
      exp: "5,720",
      expRatio: "0.743",
    },
    {
      name: "Iks Chuka",
      exp: "1,310",
      expRatio: "1.056",
    },
    {
      name: "Iks Pututu",
      exp: "1,390",
      expRatio: "1.061",
    },
    {
      name: "Iks Ahpututu",
      exp: "1,700",
      expRatio: "1.043",
    },
    {
      name: "Boar Man",
      exp: "7,720",
      expRatio: "0.839",
    },
    {
      name: "Carnivostrich",
      exp: "7,290",
      expRatio: "0.884",
    },
    {
      name: "Liodile",
      exp: "6,860",
      expRatio: "0.798",
    },
    {
      name: "Crape Man",
      exp: "5,040",
      expRatio: "0.551",
    },
    {
      name: "Rhindeer",
      exp: "5,600",
      expRatio: "0.647",
    },
    {
      name: "Iks Aucar",
      exp: "1,150",
      expRatio: "0.757",
    },
    {
      name: "The Monster",
      exp: "30,000",
      expRatio: "0.667",
    },
    {
      name: "Ahau",
      exp: "17,500",
      expRatio: "1.944",
    },
    {
      name: "Cursed Ape",
      exp: "1,860",
      expRatio: "1.094",
    },
    {
      name: "Iks Churrascan",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Doctor Marrow",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Negative Spark",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Positive Spark",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Dangerous Apparatus",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Strange Machine (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Black Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Yellow Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blue Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Purple Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Green Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Red Mutagen",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blue Collector Unit",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Green Collector Unit",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Red Collector Unit",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blue Tube",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Green Tube",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Red Tube",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Collection Container",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Blue Container",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Green Container",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Red Container",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Lab Assistant",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Benevolent Iks Spirit",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Gnomish Explorer",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Gnorre Chyllson",
      exp: "4,000",
      expRatio: "0.559",
    },
    {
      name: "Lavaling",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Fireling",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Basaltwall",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Instable Lavaling",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Deadly Heat",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Magmaoid",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Blasting Flame",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Massive Magmaoid",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Banshee",
      exp: "900",
      expRatio: "0.9",
    },
    {
      name: "Princess Lumelia",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Abominable Servant",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Abomination Cultist",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "The Abomination",
      exp: "1,500,000?",
      expRatio: "?",
    },
    {
      name: "Spoiler",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Festival Cake (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Bot (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Font of Refreshment",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ice Barricade",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Hazard Golem",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Guardian Golem",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Ashes of Burning Hatred",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Spark of Burning Hatred",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Flame of Burning Hatred",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Blaze of Burning Hatred",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Clutter",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Faun",
      exp: "800",
      expRatio: "0.889",
    },
    {
      name: "Smelly Cheese",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Darklight Striker",
      exp: "22,200",
      expRatio: "0.747",
    },
    {
      name: "Bakragore",
      exp: "15,000,000",
      expRatio: "22.727",
    },
    {
      name: "Chagorz",
      exp: "3,250,000",
      expRatio: "?",
    },
    {
      name: "Vemiath",
      exp: "3,250,000",
      expRatio: "?",
    },
    {
      name: "Ichgahal",
      exp: "3,250,000",
      expRatio: "?",
    },
    {
      name: "Murcion",
      exp: "3,250,000",
      expRatio: "?",
    },
    {
      name: "Bloated Man-Maggot",
      exp: "21,570",
      expRatio: "0.68",
    },
    {
      name: "Converter",
      exp: "21,425",
      expRatio: "0.724",
    },
    {
      name: "Darklight Construct",
      exp: "22,050",
      expRatio: "0.685",
    },
    {
      name: "Darklight Emitter",
      exp: "20,600",
      expRatio: "0.749",
    },
    {
      name: "Darklight Matter",
      exp: "22,250",
      expRatio: "0.738",
    },
    {
      name: "Darklight Source",
      exp: "22,465",
      expRatio: "0.712",
    },
    {
      name: "Meandering Mushroom",
      exp: "21,980",
      expRatio: "0.755",
    },
    {
      name: "Mycobiontic Beetle",
      exp: "21,175",
      expRatio: "0.701",
    },
    {
      name: "Oozing Carcass",
      exp: "20,980",
      expRatio: "0.763",
    },
    {
      name: "Oozing Corpus",
      exp: "20,600",
      expRatio: "0.718",
    },
    {
      name: "Rotten Man-Maggot",
      exp: "22,625",
      expRatio: "0.727",
    },
    {
      name: "Sopping Carcass",
      exp: "23,425",
      expRatio: "0.716",
    },
    {
      name: "Sopping Corpus",
      exp: "22,465",
      expRatio: "0.673",
    },
    {
      name: "Walking Pillar",
      exp: "24,300",
      expRatio: "0.639",
    },
    {
      name: "Wandering Pillar",
      exp: "23,200",
      expRatio: "0.627",
    },
    {
      name: "Cunning Werepanther",
      exp: "3,620",
      expRatio: "0.842",
    },
    {
      name: "Werepanther",
      exp: "3,550",
      expRatio: "0.845",
    },
    {
      name: "Feral Werecrocodile",
      exp: "5,430",
      expRatio: "0.848",
    },
    {
      name: "Werecrocodile",
      exp: "4,140",
      expRatio: "0.784",
    },
    {
      name: "White Weretiger",
      exp: "5,200",
      expRatio: "0.852",
    },
    {
      name: "Weretiger",
      exp: "3,920",
      expRatio: "0.784",
    },
    {
      name: "White Tiger",
      exp: "40",
      expRatio: "0.533",
    },
    {
      name: "Bloodjaw",
      exp: "24,000",
      expRatio: "0.6",
    },
    {
      name: "Elder Bloodjaw",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Pillar of Dark Energy",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ayana the Crimson Curse",
      exp: "12,400",
      expRatio: "0.729",
    },
    {
      name: "Foxmouse",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Mushroom",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Echo of Murcion",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Echo of Chagorz",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Echo of Ichgahal",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Echo of Vemiath",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Decaying Totem",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Urmahlullu the Weakened",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Tamru the Black",
      exp: "11,200",
      expRatio: "0.723",
    },
    {
      name: "Radicular Totem",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Brokul",
      exp: "23,000",
      expRatio: "0.46",
    },
    {
      name: "Werelion",
      exp: "2,200",
      expRatio: "0.786",
    },
    {
      name: "Excess Heat",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Wailing Widow",
      exp: "450",
      expRatio: "0.529",
    },
    {
      name: "The Scourge of Oblivion (Charging)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Scourge of Oblivion (Reflective)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Soul Cage",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Community Manager (Creature)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Captured Beer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Strong Orger Beer",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Orc Cook",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Megasylvan Sapling (Creature)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Mindmasher (Weak)",
      exp: "0",
      expRatio: "?",
    },
    {
      name: "Minion of Skyrr",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Weak Spot",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Rum Barrel",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Spellstealer (Creator)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "The Spellstealer (Destructor)",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Albino Dragon",
      exp: "2,250",
      expRatio: "0.45",
    },
    {
      name: "Iks Yapunac",
      exp: "2,250",
      expRatio: "0.72",
    },
    {
      name: "Bulltaur Brute",
      exp: "4,900",
      expRatio: "0.747",
    },
    {
      name: "Atab",
      exp: "6,800",
      expRatio: "0.84",
    },
    {
      name: "Mitmah Vanguard",
      exp: "450,000",
      expRatio: "6.923",
    },
    {
      name: "Despor",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Bulltaur Alchemist",
      exp: "4,350",
      expRatio: "0.764",
    },
    {
      name: "Bulltaur Forgepriest",
      exp: "5,400",
      expRatio: "0.789",
    },
    {
      name: "Dragolisk",
      exp: "5,490",
      expRatio: "0.888",
    },
    {
      name: "Wardragon",
      exp: "6,320",
      expRatio: "0.908",
    },
    {
      name: "Mitmah Scout",
      exp: "3,230",
      expRatio: "0.82",
    },
    {
      name: "Mitmah Seer",
      exp: "4,580",
      expRatio: "0.991",
    },
    {
      name: "Mega Dragon",
      exp: "7,380",
      expRatio: "0.932",
    },
    {
      name: "Titano Dragon",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Pirate Ship",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Cellar Rat",
      exp: "0",
      expRatio: "0",
    },
    {
      name: "Greedok",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Vengar",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Crultor",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Maliz",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Vilear",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Bruton",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Mystic Jaguar",
      exp: "",
      expRatio: "?",
    },
    {
      name: "Ritualwater",
      exp: "?",
      expRatio: "?",
    },
    {
      name: "Ancient Ugly Monster",
      exp: "?",
      expRatio: "?",
    },
  ],
};
