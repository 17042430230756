import Footer from "../footer";
import Sidebar from "../navigation/Sidebar";
import TopMenu from "../navigation/TopMenu";

const Layout = ({ children }) => {
  return (
    <>
      <TopMenu />
      <Sidebar />
      <main>{children}</main>
      <Footer />
    </>
  );
};

export default Layout;
