import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  slideIn,
  fadeIn,
  slideUp,
  slideDown,
} from "../../../../utility/effects";

const SharedExpView = () => {
  const [partyLevel, setPartyLevel] = useState(0);

  const calculateLevels = () => {
    const minLevel = Math.ceil(partyLevel / 3) * 2;
    const maxLevel = Math.ceil((partyLevel * 3) / 2);
    return { minLevel, maxLevel };
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko max-w-lg mx-auto"
          variants={slideIn}
        >
          Calculadora de 
          <span className="text-yellow-400">Experiência Compartilhada</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ol className="mb-4 ml-4">
              <li className="list-decimal">
                Informe o nível do personagem de maior nível na sua party no
                campo{" "}
                <span className="text-primary">
                  Nível do level mais alto da Party
                </span>
                .
              </li>
              <li className="list-decimal">
                Certifique-se de que o número inserido seja válido e represente
                corretamente o nível do personagem mais alto na sua party.
              </li>
            </ol>
          </div>
        </motion.div>

        <motion.div
          className="flex flex-col items-center mb-8"
          initial="hidden"
          animate="visible"
          variants={slideUp}
        >
          <label htmlFor="partyLevel" className="text-lg mb-2">
            Nível do level mais alto da Party:
          </label>
          <input
            type="number"
            id="partyLevel"
            value={partyLevel}
            onChange={(e) => setPartyLevel(parseInt(e.target.value))}
            className="input input-bordered input-secondary w-full max-w-xs mb-8"
          />

          {partyLevel > 0 && (
            <motion.div
              className="bg-base-300 p-6 rounded-md shadow-md text-center"
              variants={slideDown}
            >
              <p className="text-secondary">
                Os membros da party estejam dentro deste intervalo para obter
                uma divisão mais eficiente da experiência.
              </p>
              <p className="text-green-500 font-bold mt-4">
                Mínimo: {calculateLevels().minLevel}
              </p>
              <p className="text-red-500 font-bold">
                Máximo: {calculateLevels().maxLevel}
              </p>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default SharedExpView;
