import React, { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../utility/effects";
import { API_BASE_URL } from "../../../utility/constants";
import RenderHighscores from "./RenderHighscores";

const HighscoreSearch = () => {
  const [world, setWorld] = useState(null);
  const [category, setCategory] = useState("");
  const [vocation, setVocation] = useState("");
  const [battleEyeProtection, setBattleEyeProtection] = useState("");
  const [worldtypes, setWorldtypes] = useState("");
  const [page, setPage] = useState(1);
  const [highscoreData, setHighscoreData] = useState({
    world: "All Worlds",
    category: "",
    vocation: "",
    highscoreList: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [worldOptions, setWorldOptions] = useState([]);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [vocationOptions, setVocationOptions] = useState([]);
  const [battleEyeOptions, setBattleEyeOptions] = useState([]);
  const [worldTypesOptions, setWorldTypesOptions] = useState([]);

  const searchHighscores = useCallback(async () => {
    const HIGHSCORE_API_URL = `/community/highscores`;
    try {
      setIsLoading(true);

      const finalWorld =
        world && world !== "All Worlds"
          ? world.charAt(0).toUpperCase() + world.slice(1)
          : "";

      const queryParams = new URLSearchParams();
      if (finalWorld) queryParams.append("world", finalWorld);
      if (category) queryParams.append("category", category);
      if (vocation) queryParams.append("vocation", vocation);
      if (battleEyeProtection)
        queryParams.append("beprotection", battleEyeProtection);
      if (worldtypes) queryParams.append("worldtypes", worldtypes);
      if (page) queryParams.append("page", page);

      const urlWithParams = `${API_BASE_URL}${HIGHSCORE_API_URL}?${queryParams.toString()}`;

      const response = await fetch(urlWithParams);
      const data = await response.json();

      setHighscoreData(data.highscores);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
    }
  }, [world, category, vocation, battleEyeProtection, worldtypes, page]);

  const fetchOptions = useCallback(async () => {
    try {
      const worldOptionsUrl = `${API_BASE_URL}/community/highscores/worlds`;
      const responseWorld = await fetch(worldOptionsUrl);
      const dataWorld = await responseWorld.json();
      setWorldOptions(dataWorld.worldOptions);

      const categoryOptionsUrl = `${API_BASE_URL}/community/highscores/category`;
      const responseCategory = await fetch(categoryOptionsUrl);
      const dataCategory = await responseCategory.json();
      setCategoryOptions(dataCategory.categoryOptions);

      const vocationOptionsUrl = `${API_BASE_URL}/community/highscores/vocations`;
      const responseVocation = await fetch(vocationOptionsUrl);
      const dataVocation = await responseVocation.json();
      setVocationOptions(dataVocation.vocationOptions);

      const battleEyeOptionsUrl = `${API_BASE_URL}/community/highscores/battleeye`;
      const responseBattleEye = await fetch(battleEyeOptionsUrl);
      const dataBattleEye = await responseBattleEye.json();
      setBattleEyeOptions(dataBattleEye.battleEyeOptions);

      searchHighscores();
    } catch (error) {
      console.error(error);
    }
  }, [searchHighscores]);

  const fetchWorldTypes = useCallback(async () => {
    try {
      const worldTypesUrl = `${API_BASE_URL}/community/highscores/worldtypes`;
      const response = await fetch(worldTypesUrl);
      const data = await response.json();
      setWorldTypesOptions(data.worldTypesCheckboxes);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchOptions();
    fetchWorldTypes();
  }, [fetchOptions, fetchWorldTypes]);

  useEffect(() => {
    if (world || category || vocation || battleEyeProtection) {
      searchHighscores();
    }
  }, [
    world,
    category,
    vocation,
    battleEyeProtection,
    worldtypes,
    page,
    searchHighscores,
  ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setPage(1);
    searchHighscores();
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Ranking <span className="text-yellow-400">Tibiano</span>
        </motion.h1>

        <form onSubmit={handleSubmit}>
          <motion.div
            className="flex items-center justify-center gap-4 flex-wrap"
            variants={slideUp}
          >
            <select
              value={world || ""}
              onChange={(e) => setWorld(e.target.value)}
              className="input input-bordered input-secondary"
            >
              <option value="">Selecione o Servidor</option>
              {worldOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
            <select
              value={category}
              onChange={(e) => setCategory(e.target.value)}
              className="input input-bordered input-secondary"
            >
              <option value="">Selecione a Categoria</option>
              {categoryOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
            <select
              value={vocation}
              onChange={(e) => setVocation(e.target.value)}
              className="input input-bordered input-secondary"
            >
              <option value="">Selecione a Vocação</option>
              {vocationOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
            <select
              value={battleEyeProtection}
              onChange={(e) => setBattleEyeProtection(e.target.value)}
              className="input input-bordered input-secondary"
            >
              <option value="">Tipo de Battle Eye</option>
              {battleEyeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
            <select
              value={worldtypes}
              onChange={(e) => setWorldtypes(e.target.value)}
              className="input input-bordered input-secondary"
            >
              <option value="">Tipo de Servidor</option>
              {worldTypesOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.name}
                </option>
              ))}
            </select>
          </motion.div>
        </form>

        <RenderHighscores
          highscoreList={highscoreData.highscoreList}
          isLoading={isLoading}
        />
      </motion.div>
    </>
  );
};

export default HighscoreSearch;
