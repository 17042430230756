import DonateView from "../../components/views/DonateView";

const PageDonate = () => {
  return (
    <>
      <DonateView />
    </>
  );
};

export default PageDonate;
