import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import {
  fadeIn,
  slideDown,
  slideIn,
  slideUp,
} from "../../../../utility/effects";
import { formatExperienceNumbers } from "../../../../libs/functions";

const ExperienceView = () => {
  const [currentExp, setCurrentExp] = useState(480);
  const [desiredLevel, setDesiredLevel] = useState(8);
  const [expResult, setExpResult] = useState(null);

  const currentExpRef = useRef(null);
  const desiredLevelRef = useRef(null);

  const calculateExpResult = useCallback(() => {
    const varCurrentExp = parseFloat(currentExp);
    const varDesiredLevel = parseFloat(desiredLevel) - 1;

    const varExp =
      (50 * varDesiredLevel * varDesiredLevel * varDesiredLevel -
        150 * varDesiredLevel * varDesiredLevel +
        400 * varDesiredLevel) /
      3;

    const varExpRes = varExp - varCurrentExp;

    /*
    if (varCurrentExp === "") {
      setCurrentExp(0);
      return;
    } else if (varDesiredLevel < 1) {
      setDesiredLevel(1);
      return;
    } else*/ if (varCurrentExp > varExp) {
      setExpResult(
        <p className="text-lg font-normal text-error">
          Seu level atual é maior do que o level desejado
        </p>
      );
      return;
    }

    setExpResult(
      <p className="text-lg font-normal">
        Você precisa{" "}
        <strong>
          <span className="text-yellow-500">
            {formatExperienceNumbers(varExpRes, 2, true, true, true)}
          </span>
        </strong>{" "}
        de experiência para o level{" "}
        <span className="text-secondary">
          <strong>{varDesiredLevel + 1}</strong>
        </span>
      </p>
    );
  }, [currentExp, desiredLevel]);

  useEffect(() => {
    calculateExpResult();
  }, [currentExp, desiredLevel, calculateExpResult]);

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Experiência</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">
                Coloque sua experiência atual (Ex: 150357).
              </li>
              <li className="list-decimal">Coloque o level desejado</li>
              <li className="list-decimal">
                Nossa inteligência artificial te mostrará os resultados.
              </li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          className="w-full flex flex-col md:flex-row flex-wrap justify-center items-center gap-4 mb-8"
          variants={slideUp}
        >
          Experiência atual:
          <input
            type="number"
            ref={currentExpRef}
            className="input input-bordered input-secondary w-full max-w-xs my-4"
            value={currentExp}
            onChange={(e) => setCurrentExp(e.target.value)}
            min="1"
            step="1"
          />
          Level desejado:
          <input
            type="number"
            ref={desiredLevelRef}
            className="input input-bordered input-secondary w-full max-w-xs my-4"
            value={desiredLevel}
            onChange={(e) => setDesiredLevel(e.target.value)}
            min="1"
            step="1"
          />
        </motion.div>

        {expResult && currentExp > 0 && desiredLevel > 0 && (
          <motion.div
            className="bg-base-300 p-4 rounded-md shadow-md text-center max-w-4xl mx-auto"
            variants={slideDown}
          >
            {expResult}
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default ExperienceView;
