import NpcItemPrice from "../../../components/library/npc-item-price";

const PageNpcItemPrice = () => {
  return (
    <>
      <NpcItemPrice />
    </>
  );
};

export default PageNpcItemPrice;
