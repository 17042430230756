import SecurityView from "../../components/views/SecurityView";

const PageSecurity = () => {
  return (
    <>
      <SecurityView />
    </>
  );
};

export default PageSecurity;
