import { motion } from "framer-motion";
import { fadeIn, slideIn } from "../../utility/effects";
import { PROJECT_NAME } from "../../utility/constants";
import { Link } from "react-router-dom";

const AboutView = () => {
  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Sobre o <span className="text-yellow-400">Site</span>
        </motion.h1>

        <motion.p className="text-md mb-4" variants={slideIn}>
          Bem-vindo ao {PROJECT_NAME}, sua fonte definitiva de informações e
          ferramentas para o mundo de Tibia. Nosso site foi criado para fornecer
          recursos valiosos para jogadores de Tibia, desde calculadoras de loot
          até utilitários para facilitar suas aventuras.
        </motion.p>
        <motion.h2 className="text-2xl font-bold mt-6 mb-2" variants={slideIn}>
          Funções Principais
        </motion.h2>
        <motion.ul className="list-disc ml-8" variants={slideIn}>
          <motion.li>Analizador de Hunt</motion.li>
          <motion.li>Calculadora de Experiência Compartilhada</motion.li>
          <motion.li>Calculadora de Loot em Party</motion.li>
          <motion.li>Calculadora de Loot/Peso</motion.li>
        </motion.ul>
        <motion.h2 className="text-2xl font-bold mt-6 mb-2" variants={slideIn}>
          Sobre o Criador
        </motion.h2>
        <motion.p className="text-md mb-4" variants={slideIn}>
          {PROJECT_NAME} foi criado por <strong>João Paulo Ricardo</strong>,
          mais conhecido como{" "}
          <a
            rel="noopener noreferrer"
            target="_blank"
            href={`https://www.tibia.com/community/?name=${process.env.REACT_APP_CHARACTER_NAME}`}
            className="text-accent hover:underline"
          >
            {process.env.REACT_APP_CHARACTER_NAME}
          </a>
          . Apaixonado por Tibia e com anos de vivência no jogo, {process.env.REACT_APP_CHARACTER_NAME} decidiu
          compartilhar sua paixão e conhecimento criando esta plataforma. Se
          você tiver alguma dúvida ou sugestão, sinta-se à vontade para entrar
          em contato com {process.env.REACT_APP_CHARACTER_NAME}.
        </motion.p>
        <motion.p className="text-md" variants={slideIn}>
          Obrigado por escolher{" "}
          <Link to="/" className="font-bold uppercase font-teko">
            Exori <span className="text-yellow-400">Gran</span>
          </Link>{" "}
          para aprimorar sua experiência em Tibia. Boas aventuras!
        </motion.p>
      </motion.div>
    </>
  );
};

export default AboutView;
