import { motion } from "framer-motion";
import { slideIn, slideUp } from "../../utility/effects";
import CharacterSearch from "../search/CharacterSearch";
import { Link } from "react-router-dom";

const HomeView = () => {
  return (
    <>
      <motion.div className="hero min-h-screen mt-14 bg-image">
        <div className="hero-content text-center">
          <motion.div className="max-w-md" variants={slideUp}>
            <motion.h1
              className="text-6xl font-bold mb-4 uppercase text-center font-teko"
              variants={slideIn}
            >
              Bem vindo ao  
              <span className="text-white">
                <Link to="/" className="font-bold uppercase font-teko">
                  Exori <span className="text-yellow-400">Gran</span>
                </Link>
              </span>
            </motion.h1>
            <motion.p className="py-6" variants={slideUp}>
              Sua melhor fonte de ferramentas para o mundo de Tibia.
            </motion.p>

            <CharacterSearch />
          </motion.div>
        </div>
      </motion.div>
    </>
  );
};

export default HomeView;
