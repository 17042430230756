import React, { useState } from "react";
import { HiX } from "react-icons/hi";
import { links } from "../../data/menu";
import { useGlobalContext } from "../../contexts/SidebarContext";
import { FaMinus, FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { ImageToBase64 } from "../../libs/functions";

const Sidebar = () => {
  const { isSidebarOpen, closeSidebar } = useGlobalContext();
  const [activeSubMenu, setActiveSubMenu] = useState(null);

  const handleSubMenuClick = (submenu) => {
    setActiveSubMenu(activeSubMenu === submenu ? null : submenu);
  };

  const handleLinkClick = () => {
    setActiveSubMenu(null);
    closeSidebar();
  };

  return (
    <div
      className={`transition-all duration-500 fixed top-0 z-50 ${
        isSidebarOpen ? "left-0" : "-left-64"
      }`}
    >
      <div className="flex h-screen overflow-hidden flex-col bg-base-100 w-64 px-4 py-8 border-r border-secondary min-h-screen relative">
        <button
          onClick={closeSidebar}
          className="absolute top-1 right-1 text-gray-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-gray-300 focus:outline-none ml-6 hover:bg-gray-200 hover:text-gray-800"
        >
          <HiX className="w-5 h-5" />
        </button>
        <Link
          to="/"
          onClick={closeSidebar}
          className="flex items-center justify-center mt-4 uppercase font-bold font-teko text-2xl md:text-3xl"
        >
          Exori <span className="text-yellow-400">Gran</span>
        </Link>

        <div className="flex flex-col mt-6 justify-between flex-1 overflow-y-auto">
          <nav className="text">
            {links.map((link, index) => {
              const { url, text, icon, subLinks } = link;
              const hasSubMenu = subLinks && subLinks.length > 0;
              return (
                <div key={index} className="relative">
                  <Link
                    to={url}
                    onClick={() =>
                      hasSubMenu ? handleSubMenuClick(text) : handleLinkClick()
                    }
                    className={`flex items-center px-4 py-2 mt-5 text-base-content ${
                      activeSubMenu === text ? "bg-base-300" : "bg-base-100"
                    }  hover:bg-base-300 transition-colors duration-200 transform rounded-md`}
                  >
                    {icon}
                    <span className="mx-4 font-medium">{text}</span>
                    {hasSubMenu && (
                      <span className="ml-auto">
                        {activeSubMenu === text ? <FaMinus /> : <FaPlus />}
                      </span>
                    )}
                  </Link>

                  {hasSubMenu && activeSubMenu === text && (
                    <div>
                      {subLinks.map((submenu, index) => (
                        <Link
                          key={index}
                          to={submenu.url}
                          onClick={closeSidebar}
                          className="text-sm border-b border-secondary flex items-center px-4 py-2 mt-1 text-base-content hover:bg-base-300 transition-colors duration-200 transform rounded-md"
                        >
                          <ImageToBase64
                            src={submenu.image}
                            alt={submenu.text}
                            className="w-6 h-6 mr-2"
                          />

                          {submenu.text}
                        </Link>
                      ))}
                    </div>
                  )}
                </div>
              );
            })}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
