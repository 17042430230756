import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";

const BlessingsView = () => {
  const [level, setLevel] = useState(120);
  const [henricus, setHenricus] = useState(true);
  const [enhanced, setEnhanced] = useState(true);
  const [twist, setTwist] = useState(false);

  const regularBlesses = (level, isHenricus) => {
    if (level < 121) {
      return isHenricus ? 1000 * (level - 20) * 1.1 : 1000 * (level - 20);
    } else {
      return isHenricus
        ? (100000 + 375 * (level - 120)) * 1.1
        : 100000 + 375 * (level - 120);
    }
  };

  const enhancedBlesses = (level) => {
    return level < 121 ? 520 * (level - 20) : 52000 + 200 * (level - 120);
  };

  const handleLevelChange = (e) => {
    let newLevel = parseInt(e.target.value, 10);
    setLevel(newLevel);
  };

  const handleCheckboxChange = (checkbox) => {
    switch (checkbox) {
      case "henricus":
        setHenricus(!henricus);
        break;
      case "enhanced":
        setEnhanced(!enhanced);
        break;
      case "twist":
        setTwist(!twist);
        break;
      default:
        break;
    }
  };

  const calculateTotalCost = () => {
    const regularCost = Math.round(regularBlesses(level, henricus) / 1000);
    const enhancedCost = enhanced
      ? Math.round(enhancedBlesses(level) / 1000)
      : 0;
    const twistCost = twist
      ? level > 270
        ? 50
        : Math.round((2000 + 200 * (level - 30)) / 1000)
      : 0;

    return regularCost + enhancedCost + twistCost;
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Blessings</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">
                Preencha os campos abaixo, informando o level do jogador e
                marcando as opções desejadas.
              </li>
              <li className="list-decimal">
                Digite o level do jogador no campo{" "}
                <span className="text-secondary">Level do jogador</span>.
              </li>
              <li className="list-decimal">
                Marque as opções desejadas:{" "}
                <span className="text-secondary">Bless do Henricus</span>,{" "}
                <span className="text-secondary">Enhanced Blessings</span>
                e/ou <span className="text-secondary">Twist of Fate</span>.
              </li>
              <li className="list-decimal">
                A I.A. irá calcular automaticamente os custos das Blessings com
                base nas opções escolhidas.
              </li>
              <li className="list-decimal">
                Os resultados serão exibidos abaixo, indicando o preço das
                Regular Blessings, Enhanced Blessings e Twist of Fate, bem como
                o custo total.
              </li>
            </ul>
          </div>

          <motion.div className="flex flex-col items-center" variants={slideUp}>
            <label className="my-4">
              Level do jogador:
              <input
                type="number"
                value={level}
                className="input input-bordered input-secondary w-full max-w-xs my-4"
                onChange={handleLevelChange}
                min="1"
                step="1"
              />
            </label>

            <div className="flex gap-0 mb-4 md:gap-4">
              <label className="my-2 flex gap-2">
                <input
                  type="checkbox"
                  checked={henricus}
                  className="checkbox checkbox-secondary"
                  onChange={() => handleCheckboxChange("henricus")}
                />
                {" Bless do Henricus"}
              </label>

              <label className="my-2 flex gap-2">
                <input
                  type="checkbox"
                  checked={enhanced}
                  className="checkbox checkbox-secondary"
                  onChange={() => handleCheckboxChange("enhanced")}
                />
                {" Enhanced Blessings"}
              </label>

              <label className="my-2 flex gap-2">
                <input
                  type="checkbox"
                  checked={twist}
                  className="checkbox checkbox-secondary"
                  onChange={() => handleCheckboxChange("twist")}
                />
                {" Twist of Fate"}
              </label>
            </div>

            {level > 0 && (
              <motion.div
                className="bg-base-300 p-4 rounded-md shadow-md text-center"
                variants={fadeIn}
              >
                <h2 className="text-lg font-bold mb-2">
                  Preço das Blessings para o level{" "}
                  <span className="text-secondary">{level}</span>
                </h2>
                <div className="mb-2">
                  <p className="text-base-content">
                    Regular Blessings:{" "}
                    <span className="font-bold text-secondary">
                      {Math.round(regularBlesses(level, henricus) / 1000)}
                    </span>{" "}
                    K
                  </p>
                  <p className="text-base-content">
                    Enhanced Blessings:{" "}
                    <span className="font-bold text-secondary">
                      {enhanced ? Math.round(enhancedBlesses(level) / 1000) : 0}
                    </span>{" "}
                    K
                  </p>
                  <p className="text-base-content">
                    Twist of Fate:{" "}
                    <span className="font-bold text-secondary">
                      {twist
                        ? level > 270
                          ? "50"
                          : Math.round((2000 + 200 * (level - 30)) / 1000)
                        : 0}
                    </span>{" "}
                    K
                  </p>
                </div>

                <p className="text-base-content">
                  Total:{" "}
                  <span className="font-bold text-secondary">
                    {calculateTotalCost()}
                  </span>{" "}
                  k
                </p>
              </motion.div>
            )}
          </motion.div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default BlessingsView;
