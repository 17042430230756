import React from "react";
import { TIBIA_WORLDS_URL } from "../../../utility/constants";
import Loader from "../../helpers/Loader";
import { Link } from "react-router-dom";

const RenderHighscores = ({ highscoreList, isLoading }) => {
  if (isLoading) {
    return <Loader />;
  }

  if (highscoreList.length === 0) {
    return (
      <p className="text-center mt-4">Nada carregado no momento, aguarda!</p>
    );
  }

  return (
    <div className="mt-4 overflow-auto">
      <table className="text-xs table border border-secondary table-zebra w-[400px] mx-auto md:w-full rounded">
        <thead className="bg-base-300 text-base-content">
          <tr>
            <th className="py-2 px-4">Rank</th>
            <th className="py-2 px-4">Name</th>
            <th className="py-2 px-4">Vocation</th>
            <th className="py-2 px-4">World</th>
            <th className="py-2 px-4">Level</th>
            <th className="py-2 px-4">Points</th>
          </tr>
        </thead>
        <tbody>
          {highscoreList.map((entry) => (
            <tr key={entry.rank}>
              <td className="">{entry.rank}</td>
              <td className="py-2 px-4">
                {" "}
                <Link
                  to={`/community/character/${entry.name}`}
                  className="hover:text-accent link-hover"
                >
                  {entry.name}
                </Link>
              </td>
              <td className="py-2 px-4">{entry.vocation}</td>
              <td className="py-2 px-4">
                {" "}
                <Link
                  to={`${TIBIA_WORLDS_URL}${entry.world}`}
                  className="hover:text-accent link-hover"
                  target="_blank"
                >
                  {entry.world}
                </Link>
              </td>
              <td className="py-2 px-4">{entry.level}</td>
              <td className="py-2 px-4">{entry.points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default RenderHighscores;
