import { Link } from "react-router-dom";
import {
  TIBIA_CHARACTER_PAGE_URL,
  TIBIA_GUILDS_PAGE_URL,
  TIBIA_HOUSES_PAGE_URL,
  TIBIA_WORLDS_URL,
} from "../../../utility/constants";
import { ImageToBase64 } from "../../../libs/functions";

const CharacterDetails = ({ characterData }) => {
  /*const arrayReplace = [
    "fire elemental of ",
    "fire devil of ",
    "orc eader of ",
    "orc berserker of ",
    "orc beserker of ",
    "gazzer of ",
  ];
  function removeSubstrings(inputString, substringsToRemove) {
    let result = inputString;
    substringsToRemove.forEach((substring) => {
      result = result.replace(new RegExp(substring, "g"), "");
    });
    return result.trim();
  }*/

  return (
    <>
      {characterData && (
        <div className="w-full mx-auto">
          <table className="table table-fixed table-zebra border border-secondary w-full mx-auto">
            <tbody>
              {characterData.character.name && (
                <tr>
                  <td className="font-bold">Nome:</td>
                  <td className="flex gap-2 items-center flex-wrap">
                    <Link
                      to={`/community/character/${characterData.character.name}`}
                      className="hover:text-accent link-hover"
                    >
                      {characterData.character.name}
                    </Link>

                    <Link
                      to={`${TIBIA_CHARACTER_PAGE_URL}${characterData.character.name}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-accent link-hover"
                    >
                      <ImageToBase64
                        src="/images/tibiaicon-x32.png"
                        width={500}
                        height={500}
                        alt="Tibia Icone"
                        priority
                        className="w-4 transition-transform transform hover:scale-105"
                      />
                    </Link>
                  </td>
                </tr>
              )}

              {characterData.character.former_names && (
                <tr>
                  <td className="font-bold">Nomes Antigos:</td>
                  <td>
                    {characterData.character.former_names.map(
                      (formerName, index, array) => (
                        <span key={index}>
                          {formerName}
                          {index < array.length - 1 && ", "}
                        </span>
                      )
                    )}
                  </td>
                </tr>
              )}

              {characterData.character.sex && (
                <tr>
                  <td className="font-bold">Sexo:</td>
                  <td>{characterData.character.sex}</td>
                </tr>
              )}

              {characterData.character.title && (
                <tr>
                  <td className="font-bold">Titulo:</td>
                  <td>{characterData.character.title}</td>
                </tr>
              )}

              {characterData.character.achievement_points && (
                <tr>
                  <td className="font-bold">Pontos de Conquistas:</td>
                  <td>{characterData.character.achievement_points}</td>
                </tr>
              )}

              {characterData.character.vocation && (
                <tr>
                  <td className="font-bold">Vocação:</td>
                  <td>{characterData.character.vocation}</td>
                </tr>
              )}

              {characterData.character.level && (
                <tr>
                  <td className="font-bold">Level:</td>
                  <td>{characterData.character.level}</td>
                </tr>
              )}

              {characterData.character.married_to && (
                <tr>
                  <td className="font-bold">
                    {characterData.character.sex === "male"
                      ? "Casado "
                      : "Casada "}
                    com:
                  </td>
                  <td>
                    <Link
                      to={`/character/${characterData.character.married_to}`}
                      className="hover:text-accent link-hover"
                    >
                      {characterData.character.married_to}
                    </Link>
                  </td>
                </tr>
              )}

              {characterData.character.world && (
                <tr>
                  <td className="font-bold">Server:</td>
                  <td>
                    <Link
                      to={`${TIBIA_WORLDS_URL}${characterData.character.world}`}
                      className="hover:text-accent link-hover"
                      target="_bnak"
                    >
                      {characterData.character.world}
                    </Link>
                  </td>
                </tr>
              )}

              {characterData.character.former_worlds &&
                characterData.character.former_worlds.length > 0 && (
                  <tr>
                    <td className="font-bold">
                      Server
                      {characterData.character.former_worlds.length > 1 &&
                        "s"}{" "}
                      Antigo
                      {characterData.character.former_worlds.length > 1 && "s"}
                    </td>
                    <td>
                      {characterData.character.former_worlds.map(
                        (server, index, array) => (
                          <span key={index}>
                            {server}
                            {index < array.length - 1 && ", "}
                          </span>
                        )
                      )}
                    </td>
                  </tr>
                )}

              {characterData.character.residence && (
                <tr>
                  <td className="font-bold">Morador de:</td>
                  <td>{characterData.character.residence}</td>
                </tr>
              )}

              {characterData.character.houses &&
                characterData.character.houses.length > 0 && (
                  <tr>
                    <td className="font-bold">
                      {" "}
                      Casa{characterData.character.houses.length > 1 && "s"}
                    </td>
                    <td>
                      {characterData.character.houses.map(
                        (house, index, array) => (
                          <span key={index}>
                            <Link
                              to={`${TIBIA_HOUSES_PAGE_URL}${characterData.character.world}&town=${house.town}&houseid=${house.houseid}&character=${characterData.character.name}&action=characters`}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="hover:text-accent link-hover"
                            >
                              {house.name}
                            </Link>

                            {index < array.length - 1 && ", "}
                          </span>
                        )
                      )}
                    </td>
                  </tr>
                )}

              {characterData.character.guild && (
                <tr>
                  <td className="font-bold">Guild:</td>
                  <td>
                    <Link
                      to={`${TIBIA_GUILDS_PAGE_URL}${characterData.character.guild.name.replace(
                        /\s/g,
                        "+"
                      )}&character=${encodeURIComponent(
                        characterData.character.name
                      )}&action=characters`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="hover:text-accent link-hover"
                    >
                      {characterData.character.guild.name}
                    </Link>{" "}
                    ({characterData.character.guild.rank})
                  </td>
                </tr>
              )}

              {characterData.character.last_login && (
                <tr>
                  <td className="font-bold">Último login</td>
                  <td>
                    {new Date(
                      characterData.character.last_login
                    ).toLocaleString()}
                  </td>
                </tr>
              )}

              {characterData.character.account_status && (
                <tr>
                  <td className="font-bold">Status da conta</td>
                  <td>{characterData.character.account_status}</td>
                </tr>
              )}

              {characterData.character.comment && (
                <tr>
                  <td className="font-bold">Comentário</td>
                  <td className="max-w-md overflow-auto">
                    {characterData.character.comment}
                  </td>
                </tr>
              )}

              {characterData.account_badges && (
                <tr>
                  <td className="font-bold">Crachás de Conta</td>
                  <td>
                    {characterData.account_badges.map((badge, index) => (
                      <div key={index} className="flex items-center mb-2">
                        <ImageToBase64
                          src={badge.icon_url}
                          alt={badge.name}
                          width={500}
                          height={500}
                          priority
                          className="w-6 h-6 mr-2"
                        />
                        <span>{badge.name}</span>
                      </div>
                    ))}
                  </td>
                </tr>
              )}

              {characterData.account_achievements &&
                characterData.account_achievements.some(
                  (achievement) => achievement.name.trim() !== ""
                ) && (
                  <tr>
                    <td className="font-bold">Conquistas</td>
                    <td>
                      {characterData.account_achievements
                        .filter((achievement) => achievement.name.trim() !== "")
                        .map((achievement, index) => (
                          <div key={index} className="flex items-center">
                            <span>{achievement.name}</span>
                            <span className="ml-2">
                              (Grade {achievement.grade})
                            </span>
                            {achievement.secret && (
                              <span className="ml-2 text-accent">
                                (Secreta)
                              </span>
                            )}
                          </div>
                        ))}
                    </td>
                  </tr>
                )}

              {/*
              {characterData.deaths && (
                <tr>
                  <td className="font-bold">Mortes</td>
                  <td>
                    {characterData.deaths.map((death, index) => (
                      <div key={index} className="mb-4 md:mb-0">
                        <p>
                          {characterData.character.sex === "male"
                            ? "Morto "
                            : "Morta "}{" "}
                          por{" "}
                          {death.killers.map((killer, killerIndex) => (
                            <span key={killerIndex}>
                              {killer.player ? (
                                <>
                                  <Link
                                    to={`/character/${encodeURIComponent(
                                      removeSubstrings(
                                        killer.name,
                                        arrayReplace
                                      )
                                    )}`}
                                    className="text-accent hover:underline"
                                  >
                                    {killer.name}
                                  </Link>{" "}
                                  {killerIndex < death.killers.length - 1 &&
                                    (killerIndex === death.killers.length - 2
                                      ? "e "
                                      : ", ")}
                                  {killer.summon &&
                                    ` - Summon: ${killer.summon}`}
                                </>
                              ) : (
                                <>
                                  {killer.name.replace(
                                    "fire elemental of ",
                                    ""
                                  )}{" "}
                                  {killerIndex < death.killers.length - 1 &&
                                    (killerIndex === death.killers.length - 2
                                      ? "e "
                                      : ", ")}
                                </>
                              )}
                            </span>
                          ))}
                          no nível {death.level} em{" "}
                          {new Date(death.time).toLocaleString()}
                        </p>
                        {death.assists.length > 0 && (
                          <>
                            <p className="font-bold">Assistentes:</p>
                            <ul>
                              {death.assists.map((assist, assistIndex) => (
                                <li key={assistIndex}>{assist}</li>
                              ))}
                            </ul>
                          </>
                        )}
                      </div>
                    ))}
                  </td>
                </tr>
              )}
                              */}
              {characterData.account_information && (
                <>
                  {characterData.account_information.created &&
                    new Date(characterData.account_information.created) !==
                      "Invalid Date" && (
                      <tr>
                        <td className="font-bold">Conta Criada:</td>
                        <td>
                          {new Date(
                            characterData.account_information.created
                          ).toLocaleString()}
                        </td>
                      </tr>
                    )}
                  <tr>
                    <td className="font-bold">Título de Lealdade:</td>
                    <td>{characterData.account_information.loyalty_title}</td>
                  </tr>
                </>
              )}

              {characterData.other_characters &&
                characterData.other_characters.length > 1 && (
                  <tr>
                    <td className="font-bold">Outros Personagens</td>
                    <td>
                      <ul className="list-none">
                        {characterData.other_characters.map(
                          (otherCharacter, index) => (
                            <li key={index}>
                              <Link
                                to={`/community/character/${otherCharacter.name}`}
                                className="hover:text-accent link-hover"
                              >
                                {otherCharacter.name}
                              </Link>
                            </li>
                          )
                        )}
                      </ul>
                    </td>
                  </tr>
                )}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

export default CharacterDetails;
