import { useGlobalContext } from "../../contexts/SidebarContext";

import { FaBars } from "react-icons/fa";

const ControlSidebar = () => {
  const { openSidebar, isSidebarOpen } = useGlobalContext();

  return (
    <button
      onClick={openSidebar}
      className={`${
        isSidebarOpen ? "-translate-x-96" : "translate-x-0"
      } z-50 fixed top-2 transition transform ease-linear duration-500 text-gray-600 w-8 h-8 rounded-full flex items-center justify-center active:bg-gray-300 focus:outline-none ml-6 hover:bg-gray-200 hover:text-gray-800`}
    >
      <FaBars className="w-5 h-5" />
    </button>
  );
};

export default ControlSidebar;
