import SharedExpView from "../../../components/views/calculators/shared-exp";

const PageSharedExp = () => {
  return (
    <>
      <SharedExpView />
    </>
  );
};

export default PageSharedExp;
