import React from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn } from "../../utility/effects";

const SecurityView = () => {
  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Dicas de <span className="text-yellow-400">Segurança</span>
        </motion.h1>

        <motion.ul className="list-disc ml-8" variants={slideIn}>
          <motion.li className="mb-2">
            <strong>Não forneça seus dados de conta a ninguém!</strong> Pessoas
            que fingem ser membros da Equipe CipSoft podem entrar em contato e
            pedir sua senha ou chave de recuperação. Eles podem dizer que
            precisam corrigir um erro, ou que desejam lhe dar itens gratuitos ou
            Tempo Premium. Isso é absurdo. Lembre-se sempre de que membros da
            Equipe CipSoft nunca pedirão sua senha ou chave de recuperação.
            Nunca forneça sua senha ou chave de recuperação a outras pessoas,
            nem mesmo à Equipe CipSoft. Também seja muito cuidadoso com o que
            você diz em bate-papos, e-mails, serviços de mensagens, redes
            sociais ou serviços VoIP como Ventrilo, TeamSpeak ou Skype!
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Não insira seus dados de conta em nenhum site, exceto
              https://www.tibia.com!
            </strong>{" "}
            Existem sites que afirmam fazer pesquisas, prometem itens gratuitos
            ou oferecem ferramentas de trapaça, etc. Normalmente, você é
            solicitado a inserir sua senha lá, mas o único objetivo do site é
            roubar sua conta. Uma prática comum são cartas dentro do jogo
            informando sobre uma punição e pedindo para inserir seus dados no
            site do Tibia. O link ali leva a um site falso. Se você foi punido
            por violar uma regra, nunca entraremos em contato por meio de uma
            carta no jogo! Sempre fique atento a sites que imitam o site oficial
            do Tibia. Tenha cuidado ao pesquisar Tibia usando um mecanismo de
            busca! Os resultados nem sempre levam ao site oficial. Existem sites
            falsos que tentam imitar o site do Tibia e aparecem nos resultados
            da pesquisa de tempos em tempos. Para garantir, adicione o site
            original aos favoritos ou digite o endereço www.tibia.com você
            mesmo. Somente o endereço www.tibia.com o levará ao site oficial do
            Tibia! Se à esquerda do endereço da web não houver um pequeno
            cadeado e o endereço não começar com https, você está em um site
            falso.
          </motion.li>

          <motion.li className="mb-2">
            <strong>Memorize bem sua senha!</strong> Nunca armazene sua senha
            sem criptografia em seu computador. Além disso, exclua qualquer
            e-mail que contenha dados da conta. Se alguém tiver acesso ao seu
            computador ou conta de e-mail, ele não deve encontrar sua senha ou
            chave de recuperação lá. Se você tiver problemas para memorizar seus
            dados da conta, use um gerenciador de senhas seguro, como o KeePass.
          </motion.li>

          <motion.li className="mb-2">
            <strong>Memorize bem sua senha!</strong> Nunca armazene sua senha
            sem criptografia em seu computador. Além disso, exclua qualquer
            e-mail que contenha dados da conta. Se alguém tiver acesso ao seu
            computador ou conta de e-mail, ele não deve encontrar sua senha ou
            chave de recuperação lá. Se você tiver problemas para memorizar seus
            dados da conta, use um gerenciador de senhas seguro, por exemplo, o{" "}
            <a
              href="https://keepass.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              KeePass
            </a>
            .
          </motion.li>

          <motion.li className="mb-2">
            <strong>Use senhas seguras!</strong> Sua senha deve conter uma
            combinação de letras maiúsculas e minúsculas, números e caracteres
            especiais. Nunca escolha partes do seu endereço de e-mail como senha
            e evite o uso de palavras comuns que os hackers possam adivinhar
            facilmente. O mesmo vale para senhas que se referem a você
            pessoalmente, como o nome do seu animal de estimação ou sua data de
            nascimento. Uma boa estratégia para criar uma senha segura é pensar
            em uma frase e escolher as primeiras letras de cada palavra. A frase
            ajudará você a lembrar da sua senha o tempo todo, e ninguém será
            capaz de adivinhá-la. Por fim, certifique-se de não usar a mesma
            senha para diferentes serviços. É importante que você use senhas
            diferentes para sua conta no Tibia e seu serviço de e-mail!
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Cuidado com arquivos suspeitos que podem ser usados para hackear
              seu computador!
            </strong>{" "}
            Algumas pessoas estão distribuindo arquivos que contêm software
            espião, como cavalos de Troia ou keyloggers. Software perigoso como
            esse é frequentemente disseminado por meio de sites, e-mails ou
            serviços de mensagens. Examine cuidadosamente qualquer link antes de
            clicar nele. Anexos de e-mail e downloads devem ser tratados com
            cautela. Acima de tudo, ferramentas de trapaça e clientes que não
            foram lançados pela CipSoft são frequentemente projetados para
            hackear sua conta no Tibia!
          </motion.li>

          <motion.li className="mb-2">
            <strong>Esteja ciente de e-mails de phishing!</strong> Como é
            possível falsificar o remetente de um e-mail, pode acontecer de você
            receber um e-mail que tenha Tibia ou CipSoft como remetente, mas que
            não foi enviado por nós. Esses e-mails geralmente contêm links ou
            anexos e são projetados para se parecerem com um e-mail oficial
            nosso. Não clique simplesmente nos links desses e-mails. Para
            garantir que você está acessando o site oficial, recomendamos
            fortemente que você digite manualmente www.tibia.com. Além disso,
            observe que nunca enviaremos e-mails com anexos, então nunca os
            abra!
          </motion.li>

          <motion.li className="mb-2">
            <strong>Atualize regularmente o software do seu computador!</strong>{" "}
            Versões antigas do seu sistema operacional ou de outros softwares
            frequentemente contêm falhas de segurança que foram corrigidas em
            versões mais recentes. Por exemplo, um hacker pode espionar dados do
            seu computador se você visitar um site com um navegador antigo.
            Idealmente, todos os programas no seu computador devem ser
            atualizados para a versão mais recente disponível. Mais importante
            ainda, atualize regularmente seu navegador e seu sistema operacional
            para garantir a melhor proteção possível do seu computador.
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Proteja seu computador usando programas de segurança atualizados!
            </strong>{" "}
            Recomendamos o uso de programas antivírus e firewall para evitar
            intrusões indesejadas em seu sistema. Certifique-se de atualizar seu
            firewall regularmente e o programa antivírus pelo menos uma vez por
            semana.
          </motion.li>

          <motion.li className="mb-2">
            <strong>Não jogue Tibia em redes públicas!</strong> Se você joga
            Tibia em um computador de acesso público, nunca pode ter certeza se
            este computador ou a rede à qual está conectado são seguros. Pode
            haver cavalos de Troia ou keyloggers instalados no computador por
            outras pessoas. Além disso, os dados que você envia por meio de
            redes públicas, como aquelas encontradas em escolas, bibliotecas ou
            cafés da Internet, podem ser facilmente registrados por hackers.
            Lembre-se de fechar sua lista de personagens após fazer logout do
            jogo e quiser deixar o computador, caso contrário, outro usuário
            pode acessar sua conta. É melhor evitar jogar em redes públicas
            completamente!
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Atribua sua conta do Tibia ao seu endereço de e-mail correto!
            </strong>{" "}
            O endereço de e-mail atribuído é a chave para sua conta do Tibia.
            Certifique-se de sempre ter acesso a esta conta de e-mail. Use sua
            conta de e-mail regularmente, pois muitos provedores de e-mail
            excluem contas inativas. Não crie uma conta de e-mail extra para sua
            conta do Tibia. Você pode esquecer de usá-la regularmente após algum
            tempo. Ajuste o endereço de e-mail atribuído a tempo se sua conta de
            e-mail atual estiver prestes a mudar. Além disso, gerencie sua conta
            de e-mail com o mesmo cuidado que sua conta do Tibia. Uma pessoa com
            acesso à sua conta de e-mail pode ser capaz de hackear sua conta do
            Tibia!
          </motion.li>

          <motion.li className="mb-2">
            <strong>Não compartilhe ou negocie contas!</strong> Existe um motivo
            pelo qual o compartilhamento e a negociação de contas são proibidos
            pelas Regras do Tibia. Lembre-se de que você está correndo um grande
            risco se fornecer os dados de sua conta a qualquer outra pessoa.
            Sabemos por experiência que até mesmo amigos próximos já roubaram ou
            arruinaram as contas uns dos outros. Além disso, pessoas que
            negociam suas contas frequentemente são enganadas pelo proprietário
            original e perdem suas contas. Por favor, observe que a CipSoft não
            fornecerá suporte no caso de uma conta ser perdida devido à
            negociação ou compartilhamento de contas!
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Confirme sua conta para obter sua chave de recuperação!
            </strong>{" "}
            Confirmar sua conta não apenas permitirá que você use todos os
            recursos de uma conta Tibia regular, mas também fornecerá sua
            própria chave de recuperação. A chave de recuperação permite que
            você reatribua sua conta imediatamente a um novo endereço de e-mail.
            Por esse motivo, a posse de uma chave de recuperação lhe dará
            controle total sobre sua conta. Além disso, ela é necessária para
            vincular ou desvincular uma conta de/para um autenticador.
          </motion.li>

          <motion.li className="mb-2">
            <strong>
              Registre sua conta e insira os dados de registro corretos!
            </strong>{" "}
            Ao registrar sua conta, você pode solicitar uma nova chave de
            recuperação via correio postal por uma pequena taxa, caso a tenha
            perdido. Certifique-se de inserir seu endereço correto e completo e
            lembre-se de manter seus dados de registro atualizados, pois nossa
            carta pode não chegar até você se você não fornecer o endereço
            postal correto. Além disso, jogadores que tenham inserido um número
            de telefone válido receberão um TAN de recuperação via SMS sempre
            que solicitarem uma nova chave de recuperação. O TAN de recuperação
            pode ser usado para alterar o endereço de e-mail de uma conta.
            Observe que o TAN de recuperação é válido por apenas 24 horas e só
            pode ser usado uma vez. Apenas jogadores registrados podem utilizar
            a gama completa de opções de recuperação de conta, portanto,
            certifique-se de registrar sua conta hoje, se ainda não o fez.
            Lembre-se de que você pode perder sua conta se outra pessoa obtiver
            acesso a ela e a registrar!
          </motion.li>

          <motion.li className="mb-2">
            <strong>Conecte-se ao Tibia usando um autenticador!</strong> Um
            autenticador oferece aos jogadores uma camada adicional de segurança
            para ajudar a prevenir o acesso não autorizado à conta. Todas as
            contas podem ser conectadas a um autenticador. No Tibia, os
            jogadores podem escolher entre dois métodos: um aplicativo
            autenticador de dois fatores e autenticação por código de e-mail de
            dois fatores. Se sua conta estiver conectada a um autenticador, será
            solicitado que você insira um token de autenticador válido, além do
            seu endereço de e-mail e senha, sempre que tentar fazer login no
            jogo ou no site do Tibia. Este token é gerado via e-mail ou um
            aplicativo autenticador, como Google Authenticator ou Authy. Basta
            baixar o aplicativo autenticador de sua escolha em seu dispositivo
            ou ativar a autenticação por código de e-mail de dois fatores. Faça
            login em sua conta e conecte um autenticador à sua conta do Tibia
            seguindo as etapas na seção{" "}
            <strong className="text-secondary">
              {" "}
              Autenticação de Dois Fatores
            </strong>{" "}
            em sua página de conta.
            <motion.div>
              <strong>
                Importante se você usar um aplicativo autenticador:
              </strong>
              <ol>
                <motion.li className="mb-2">
                  Antes de conectar sua conta do Tibia a um aplicativo
                  autenticador, você precisa inserir a chave de recuperação da
                  conta para comprovar que a possui. É muito importante ter a
                  chave de recuperação, pois, caso seu telefone celular quebre
                  ou seja perdido, a chave de recuperação é a única maneira de
                  desconectar sua conta do Tibia do autenticador. Você também
                  precisará da chave de recuperação caso perca o acesso ao
                  e-mail que você usou para autenticação por código de e-mail de
                  dois fatores.
                </motion.li>
                <motion.li className="mb-2">
                  Também é importante usar um aplicativo autenticador em um
                  dispositivo diferente daquele em que você joga Tibia. Um
                  autenticador no computador que você usa para jogar Tibia não
                  ajudará a proteger sua conta se alguém ganhar acesso ao seu
                  computador.
                </motion.li>
              </ol>
            </motion.div>
          </motion.li>
        </motion.ul>

        <div className="p-4">
          <strong className="block mb-2">
            O que fazer se sua conta foi hackeada
          </strong>

          <ol className="list-decimal ml-6">
            <li className="mb-4">
              <strong>Passo 1: Remova o Problema de Segurança!</strong>
              <p className="mt-2">
                A primeira coisa a fazer é descobrir como alguém obteve os dados
                da sua conta. Lembre-se de que, se você não remover o problema
                de segurança, o hacker pode acessar sua conta novamente. Abaixo,
                você encontrará uma lista de perguntas que podem ajudá-lo a
                identificar o problema de segurança e removê-lo.
              </p>

              <ol className="list-disc ml-6">
                <li className="mb-2">
                  Você baixou ou abriu arquivos que possivelmente continham
                  software espião, como cavalos de Troia ou keyloggers? Execute
                  um ou dois programas antivírus atualizados em seu computador.
                  Se encontrar arquivos infectados, a melhor solução será
                  formatar todos os discos rígidos e reinstalar o sistema do seu
                  computador. Somente assim você pode ter certeza de que removeu
                  todos os arquivos infectados.
                </li>

                <li className="mb-2">
                  De acordo com nossa experiência, a maioria dos casos de
                  hacking é causada por software espião. Por esse motivo, você
                  deve ser extremamente cuidadoso sempre que receber qualquer
                  arquivo, independentemente de quão confiável seja a fonte.
                </li>

                <li className="mb-2">
                  Você jogou Tibia em um computador que não é seu? Lembre-se
                  sempre de que você nunca pode ter certeza de que computadores
                  de outras pessoas não estão infectados por vírus ou
                  keyloggers. Informe o proprietário do outro computador que
                  pode haver um problema de segurança a ser resolvido. Evite
                  jogar em outros computadores, caso contrário, você corre o
                  risco de perder sua conta novamente.
                </li>

                <li className="mb-2">
                  Você forneceu seus dados de conta para outra pessoa ou
                  compartilhou sua conta com um amigo? É possível que alguém
                  tenha encontrado um pedaço de papel com seus dados de conta?
                  Lembre-se sempre de não fornecer seus dados de conta a
                  ninguém, nem mesmo a pessoas que afirmam ser membros da Equipe
                  CipSoft. Muitas vezes, amigos enganam uns aos outros se os
                  personagens forem bastante valiosos ou se eles estragam
                  personagens descuidadamente. Por esse motivo, não confie seus
                  personagens ganhos com esforço aos seus amigos. Certifique-se
                  de armazenar seus dados de conta em um local seguro.
                </li>

                <li className="mb-2">
                  É possível que sua conta de e-mail tenha sido hackeada e
                  alguém tenha encontrado um e-mail contendo sua senha ou chave
                  de recuperação? Nesse caso, proteja sua conta de e-mail
                  imediatamente com uma nova senha ou entre em contato com seu
                  provedor de e-mail para obter assistência. Você também pode
                  considerar a possibilidade de alterar o endereço de e-mail ao
                  qual sua conta Tibia está vinculada. Além disso,
                  familiarize-se com as diretrizes de segurança do seu provedor
                  de e-mail para evitar problemas futuros.
                </li>

                <li className="mb-2">
                  Você comprou a conta de alguém ou a trocou? Normalmente, o
                  detentor original de uma conta Tibia também possui a chave de
                  recuperação. Usando essa chave de recuperação, o detentor pode
                  recuperar sua conta a qualquer momento, atribuindo a conta a
                  um endereço de e-mail diferente. Por esse motivo, a troca de
                  contas é proibida pelas Regras Tibia. A CipSoft não fornecerá
                  suporte para contas que foram trocadas.
                </li>

                <li className="mb-2">
                  Você inseriu seu endereço de e-mail, senha ou chave de
                  recuperação em algum site que não seja www.tibia.com? Talvez
                  você tenha inserido seus dados de conta em um site que achou
                  que era www.tibia.com, mas não obteve o resultado esperado?
                  Lembre-se sempre de verificar cuidadosamente o endereço antes
                  de inserir seus dados de conta em um site. Somente se você vir
                  www.tibia.com, é seguro inserir seus dados de conta.
                </li>

                <li>
                  É possível que você tenha usado uma senha que poderia ser
                  facilmente adivinhada? Sempre certifique-se de usar uma senha
                  segura para sua conta Tibia que contenha uma mistura de letras
                  maiúsculas e minúsculas, números e caracteres especiais.
                </li>
              </ol>
            </li>

            <li className="mb-4">
              <strong>Passo 2: Recupere Sua Conta!</strong>
              <p className="mt-2">
                Depois de remover o problema de segurança, você pode recuperar o
                acesso à sua conta usando a Interface de Conta Perdida. Claro,
                você precisa provar que sua reivindicação à conta é justificada.
                Insira os dados solicitados e siga as instruções cuidadosamente.
                Entenda que não há como acessar sua conta perdida se a interface
                não puder ajudar você.
              </p>
            </li>

            <li>
              <strong>Passo 3: Siga as Dicas de Segurança!</strong>
              <p className="mt-2">
                Certifique-se de seguir as dicas de segurança mencionadas acima
                a partir de agora. Caso contrário, você pode cometer outro erro
                que um hacker pode explorar.
              </p>
            </li>
          </ol>
        </div>
      </motion.div>
    </>
  );
};

export default SecurityView;
