import Logo from "../logo";
import ControlSidebar from "./ControlSidebar";

const TopMenu = () => {
  return (
    <>
      <ControlSidebar />
      <Logo />
    </>
  );
};

export default TopMenu;
