import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { API_BASE_URL } from "../../utility/constants";

const CharacterSearch = () => {
  const [name, setName] = useState("");
  const navigateTo = useNavigate();

  const handleSearch = async (e) => {
    e.preventDefault();

    if (name === "") return;

    try {
      const response = await axios.get(
        `${API_BASE_URL}/community/characters/${name}`
      );

      navigateTo(`/community/character/${name}`, {
        characterData: response.data.characters,
      });
    } catch (error) {
      console.error("Erro ao buscar personagem:", error);
    }
  };

  return (
    <form onSubmit={handleSearch} className="mt-6">
      <input
        type="text"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Buscar personagem..."
        className="p-2 rounded-md outline-none w-full bg-transparent border border-secondary"
      />
    </form>
  );
};

export default CharacterSearch;
