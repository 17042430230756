import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import CopyToClipboardTable from "../../../helpers/CopyToClipboardTable";
import { slideUp } from "../../../../utility/effects";
import LoaderPartyFinder from "../../../helpers/LoaderPartyFinder";

const ShareablePlayers = ({ onlinePlayers, levelToShare, isLoading }) => {
  const [filteredPlayers, setFilteredPlayers] = useState(null);

  useEffect(() => {
    if (onlinePlayers.length === 0) return;

    const tempObj = { ed: [], ek: [], ms: [], rp: [] };

    onlinePlayers.forEach((onlinePlayer) => {
      if (
        onlinePlayer.level >= levelToShare.min &&
        onlinePlayer.level <= levelToShare.max
      ) {
        if (onlinePlayer.vocation === "Elder Druid") {
          tempObj.ed.push(onlinePlayer);
        }
        if (onlinePlayer.vocation === "Elite Knight") {
          tempObj.ek.push(onlinePlayer);
        }
        if (onlinePlayer.vocation === "Master Sorcerer") {
          tempObj.ms.push(onlinePlayer);
        }
        if (onlinePlayer.vocation === "Royal Paladin") {
          tempObj.rp.push(onlinePlayer);
        }
      }
    });

    setFilteredPlayers(tempObj);
  }, [onlinePlayers, levelToShare.min, levelToShare.max]);

  return (
    <div className="flex justify-center items-center">
      {isLoading ? (
        <LoaderPartyFinder />
      ) : (
        <>
          {filteredPlayers && (
            <motion.div
              className="grid grid-cols-1 md:grid-cols-2 lg:flex lg:space-x-4 mt-8"
              variants={slideUp}
            >
              <div className="w-full md:w-5/10 lg:w-1/4 mb-4 md:mb-0 max-h-[400px] overflow-y-auto">
                <table className="table-fixed table-zebra w-full bg-base-300">
                  <thead>
                    <tr>
                      <th className="border p-2 bg-base-300">Elder Druid</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top max-h-40 overflow-hidden">
                        {filteredPlayers?.ed.map((ed) => (
                          <CopyToClipboardTable
                            key={ed.name}
                            title={`${ed.name} - ${ed.level}`}
                            className="w-full"
                          >
                            {ed.name}
                          </CopyToClipboardTable>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full md:w-5/10 lg:w-1/4 mb-4 md:mb-0 max-h-[400px] overflow-y-auto">
                <table className="table-fixed table-zebra w-full bg-base-300">
                  <thead>
                    <tr>
                      <th className="border p-2 bg-base-300">Elite Knight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">
                        {filteredPlayers?.ek.map((ek) => (
                          <CopyToClipboardTable
                            key={ek.name}
                            title={`${ek.name} - ${ek.level}`}
                            className="w-full"
                          >
                            {ek.name}
                          </CopyToClipboardTable>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full md:w-5/10 lg:w-1/4 mb-4 md:mb-0 max-h-[400px] overflow-y-auto">
                <table className="table-fixed table-zebra w-full bg-base-300">
                  <thead>
                    <tr>
                      <th className="border p-2 bg-base-300">
                        Master Sorcerer
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">
                        {filteredPlayers?.ms.map((ms) => (
                          <CopyToClipboardTable
                            key={ms.name}
                            title={`${ms.name} - ${ms.level}`}
                            className="w-full"
                          >
                            {ms.name}
                          </CopyToClipboardTable>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="w-full md:w-5/10 lg:w-1/4 mb-4 md:mb-0 max-h-[400px] overflow-y-auto">
                <table className="table-fixed table-zebra w-full bg-base-300">
                  <thead>
                    <tr>
                      <th className="border p-2 bg-base-300">Royal Paladin</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="border p-2 align-top">
                        {filteredPlayers?.rp.map((rp) => (
                          <CopyToClipboardTable
                            key={rp.name}
                            title={`${rp.name} - ${rp.level}`}
                            className="w-full"
                          >
                            {rp.name}
                          </CopyToClipboardTable>
                        ))}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </motion.div>
          )}
        </>
      )}
    </div>
  );
};

export default ShareablePlayers;
