import React from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../utility/effects";
import { ImageToBase64 } from "../../libs/functions";

const PageNotFound = () => {
  return (
    <>
      <motion.div
        className={`max-w-screen-2xl mx-auto bg-center bg-no-repeat bg-cover bg-blend-multiply h-screen min-h-screen`}
        variants={fadeIn}
      >
        <div className="h-full flex flex-col justify-center items-center text-center min-h-screen">
          <motion.h1
            className="text-4xl text-white font-bold mb-4 uppercase"
            variants={slideIn}
          >
            Erro <span className="text-yellow-400">404</span>
          </motion.h1>
          <motion.p className="text-lg text-white mb-8 flex" variants={fadeIn}>
            A página que você procura não foi encontrada{" "}
            <ImageToBase64
              src="/images/tibia/items/Tome_of_Knowledge.gif"
              width={100}
              height={100}
              alt="Tome of Knowledge"
              className="w-10 transition-transform transform hover:scale-105"
            />
          </motion.p>
          <motion.a
            href="/"
            className="bg-yellow-400 hover:bg-yellow-500 text-black font-bold py-2 px-4 rounded-full text-lg transition duration-300"
            variants={slideUp}
          >
            Voltar ao site
          </motion.a>
        </div>
      </motion.div>
    </>
  );
};

export default PageNotFound;
