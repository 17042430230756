import useSWR from "swr";
import { API_BASE_URL } from "../../../utility/constants";
import { formatDate, formatXpChange } from "../../../libs/functions";

const fetcher = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data;
};

const PlayerExpComponent = ({ nick }) => {
  const { data, error } = useSWR(
    `${API_BASE_URL}/community/characters/${nick}/exp`,
    fetcher
  );

  if (error)
    return <div className="text-red-500">Erro ao carregar os dados</div>;
  if (!data) return <div className="text-gray-500">Carregando...</div>;

  const { bestExp, results, tfootData } = data;
  const sortedResults = [...results].sort(
    (a, b) => new Date(b.date) - new Date(a.date)
  );

  return (
    <div className="w-full mx-auto">
      <h2 className="text-2xl font-bold my-4">Melhor Exp</h2>
      <table className="table table-auto table-zebra border border-secondary text-xs max-w-md mx-auto">
        <thead className="bg-secondary text-gray-950">
          <tr>
            <th>Data</th>
            <th>Melhor XP</th>
            <th>Rank</th>
            <th>Level</th>
          </tr>
        </thead>
        <tbody>
          {bestExp.map((row, index) => (
            <tr key={index} className="text-xs">
              <td className="text-xs">{formatDate(row.date)}</td>
              <td>{formatXpChange(row.bestXP)}</td>
              <td>{row.rank}</td>
              <td>{row.level}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-full mx-auto my-6">
        <h2 className="text-2xl font-bold my-4">Exp Mensal</h2>
        <table className="table table-auto table-zebra border border-secondary text-xs">
          <thead className="bg-secondary text-gray-950">
            <tr>
              <th>Descrição</th>
              <th className="whitespace-pre-line">Mudança de XP</th>
              <th className="whitespace-pre-line">Rank da Voc</th>
              <th>Level</th>
            </tr>
          </thead>
          <tbody>
            <tr className="text-xs">
              <td>XP Alterada</td>
              <td>{formatXpChange(tfootData.expChange)}</td>
              <td>{tfootData.vocationRank}</td>
              <td>{tfootData.level}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="w-full mx-auto overflow-auto">
        <h2 className="text-2xl font-bold my-4">Exp Diária</h2>
        <table className="table table-auto table-zebra border border-secondary text-xs">
          <thead className="bg-secondary text-gray-950">
            <tr>
              <th>Data</th>
              <th className="whitespace-pre-line">Mudança de Exp</th>
              <th className="whitespace-pre-line">Rank da Voc</th>
              <th>Level</th>
              <th>Experiência</th>
              <th>Tempo Online</th>
              <th>Avg Exp/Hora</th>
            </tr>
          </thead>
          <tbody>
            {sortedResults.map((row, index) => (
              <tr key={index} className="text-xs">
                <td className="whitespace-pre-line">{formatDate(row.date)}</td>
                <td>{formatXpChange(row.expChange)}</td>
                <td>{row.vocationRank}</td>
                <td>{row.level}</td>
                <td>{row.experience}</td>
                <td>{row.onlineTime}</td>
                <td>{row.avgExpPerHour}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default PlayerExpComponent;
