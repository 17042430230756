import React from "react";
import { Link } from "react-router-dom";
import { useGlobalContext } from "../../contexts/SidebarContext";

const Logo = () => {
  const { closeSidebar } = useGlobalContext();

  return (
    <div className="w-full flex items-center justify-center bg-base-100 p-4 fixed top-0 z-10">
      <Link
        to="/"
        onClick={closeSidebar}
        className="flex items-center uppercase font-bold font-teko text-2xl md:text-3xl"
      >
        Exori <span className="text-yellow-400">Gran</span>
      </Link>
    </div>
  );
};

export default Logo;
