import { motion } from "framer-motion";
import useSWR from "swr";
import { fadeIn, slideIn } from "../../../utility/effects";
import { API_BASE_URL } from "../../../utility/constants";
import { ImageToBase64 } from "../../../libs/functions";
import Loader from "../../helpers/Loader";

const fetcher = async (url) => {
  const response = await fetch(url);
  const data = await response.json();
  return data || [];
};

const BossListComponent = () => {
  const { data: bosses, error } = useSWR(
    `${API_BASE_URL}/library/boostablebosses`,
    fetcher
  );
  const loading = !bosses && !error;

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Bosses <span className="text-yellow-400">Boostáveis</span>
        </motion.h1>

        {loading ? (
          <Loader />
        ) : (
          <table className="table-auto w-full table-zebra">
            <thead>
              <tr>
                <th className="px-4 py-2">ID</th>
                <th className="px-4 py-2">Nome</th>
                <th className="px-4 py-2">Imagem</th>
              </tr>
            </thead>
            <tbody>
              {bosses &&
                bosses.boostable_boss_list &&
                bosses.boostable_boss_list.map((boss, index) => (
                  <tr key={index}>
                    <td className="border border-secondary px-4 py-2">
                      {index + 1}
                    </td>
                    <td className="border border-secondary px-4 py-2">
                      {boss.name}
                    </td>
                    <td className="border border-secondary px-4 py-2">
                      <div className="relative h-16 w-16">
                        <ImageToBase64
                          src={boss.image}
                          alt={boss.name}
                          layout="fill"
                          objectFit="cover"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        )}
      </motion.div>
    </>
  );
};

export default BossListComponent;
