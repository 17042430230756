import React from "react";
import { formatExp, formatGainExp } from "../../../../libs/functions";

const HuntAnalyzerTotalExp = ({ gainedExperience, includeStamina }) => (
  <div className="mb-4">
    <h3 className="text-xl font-bold mb-2">EXP Total:</h3>
    <p>
      XP Ganha:{" "}
      <span className={includeStamina ? "text-green-500" : "text-warning"}>
        {formatGainExp(gainedExperience)} ({formatExp(gainedExperience)}){" "}
      </span>
    </p>
  </div>
);

export default HuntAnalyzerTotalExp;
