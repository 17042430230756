import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import {
  slideDown,
  fadeIn,
  slideIn,
  slideUp,
} from "../../../../utility/effects";

const MagicShieldView = () => {
  const [level, setLevel] = useState(14);
  const [magicLevel, setMagicLevel] = useState(1);
  const [description, setDescription] = useState("");
  const magicShieldRef = useRef("");

  const calculateMagicShield = useCallback(() => {
    let lvl = parseInt(level);
    let ml = parseInt(magicLevel);

    let newMagicShieldFormula = Math.ceil(300 + 7.6 * lvl + 7 * ml);
    let oldMagicShieldFormula = Math.ceil(8 * lvl + 7 * ml);
    let calculatedMagicShield = Math.max(
      newMagicShieldFormula,
      oldMagicShieldFormula
    );
    let itemBonus = Math.ceil(calculatedMagicShield * 1.08 + 80);

    if (lvl >= 14) {
      if (calculatedMagicShield > 0) {
        magicShieldRef.current = (
          <p className="text-lg font-normal">
            O total do seu{" "}
            <strong className="text-secondary">Escudo Mágico</strong> é{" "}
            <span className="text-yellow-400">{calculatedMagicShield}</span>{" "}
            pontos de mana, usando o spellbook com{" "}
            <strong className="text-secondary">Magic Capacity Booster</strong>{" "}
            você atingiria <span className="text-yellow-400">{itemBonus}</span>.
          </p>
        );
        setDescription(magicShieldRef.current);
      }
    } else {
      magicShieldRef.current = (
        <p className="text-lg font-normal text-error">
          Apenas jogadores acima do level <strong>14</strong> pode usar Magic
          Shield (Utamo Vita)
        </p>
      );
      setDescription(magicShieldRef.current);
    }
  }, [level, magicLevel]);

  useEffect(() => {
    calculateMagicShield();
  }, [calculateMagicShield]);

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Magic Shield</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">Preencha os campos abaixo.</li>
              <li className="list-decimal">
                Deixe que nossa inteligência artificial fará os calculos para
                você.
              </li>
              <li className="list-decimal">
                Coloque o M.L total do seu char, isso incluii{" "}
                <strong className="text-secondary">TODOS</strong> os bônus
                (Loyalty, Itens e etc...).
              </li>
            </ul>
          </div>
        </motion.div>

        <motion.div
          className="w-full flex flex-col md:flex-row flex-wrap justify-center items-center gap-4 mb-8"
          variants={slideUp}
        >
          Qual seu level?
          <input
            type="number"
            value={level}
            className="input input-bordered input-neutral w-full max-w-xs my-4"
            onChange={(e) => setLevel(e.target.value)}
            min="14"
            step="1"
          />
          Qual seu Magic Level?
          <input
            type="number"
            value={magicLevel}
            className="input input-bordered input-neutral w-full max-w-xs my-4"
            onChange={(e) => setMagicLevel(e.target.value)}
            min="1"
            step="1"
          />
          {level > 0 && magicLevel > 0 && (
            <motion.div
              className="bg-base-300 p-4 rounded-md shadow-md text-center max-w-4xl mx-auto"
              variants={slideDown}
            >
              <span className="font-normal">{description}</span>
            </motion.div>
          )}
        </motion.div>
      </motion.div>
    </>
  );
};

export default MagicShieldView;
