import React, { useState } from "react";
import { motion } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";
import { npcItems } from "../../../../data/items";
import {
  ImageToBase64,
  formatGold,
  formatValuePerWeight,
  formatWeight,
} from "../../../../libs/functions";

const LootByWeightView = () => {
  const [items, setItems] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedType, setSelectedType] = useState("Todos");

  const calculateLootValue = (item) => {
    return item.weight !== 0 ? item.sellPrice / item.weight : 0;
  };

  const decideLoot = () => {
    if (items.length === 0) {
      return (
        <div className="text-red-500 font-bold">Nenhum item selecionado!</div>
      );
    }

    const bestItem = items.reduce((prev, current) => {
      const valuePerWeightPrev = calculateLootValue(prev) / prev.weight;
      const valuePerWeightCurrent =
        calculateLootValue(current) / current.weight;

      return valuePerWeightCurrent > valuePerWeightPrev ? current : prev;
    });

    return (
      <div className="bg-base-300 p-4 rounded-md shadow-md">
        <p className="text-lg font-bold mb-2 text-secondary">Melhor item:</p>
        <p>
          <span className="font-semibold">Nome:</span> {bestItem.name}
        </p>
        <p>
          <span className="font-semibold">Preço:</span>{" "}
          {formatGold(bestItem.sellPrice)}
        </p>
        <p>
          <span className="font-semibold">Peso:</span>{" "}
          {formatWeight(bestItem.weight)}
        </p>
        <p>
          <span className="font-semibold">Preço por peso:</span>{" "}
          {formatValuePerWeight(bestItem.sellPrice / bestItem.weight)}
        </p>
      </div>
    );
  };

  const handleCheckboxChange = (itemName) => {
    const selectedItemData = npcItems.items.find(
      (item) => item.name === itemName
    );

    const existingItemIndex = items.findIndex(
      (item) => item.name === selectedItemData.name
    );

    if (existingItemIndex === -1) {
      setItems([
        ...items,
        {
          name: itemName,
          weight: selectedItemData.weight,
          sellPrice: selectedItemData.sellPrice,
          type: selectedItemData.type,
        },
      ]);
    } else {
      setItems(items.filter((item) => item.name !== itemName));
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleTypeChange = (event) => {
    setSelectedType(event.target.value);
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de <span className="text-yellow-400">Loot por Peso</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <div>
            <h2 className="text-2xl mb-4">Como Utilizar?</h2>
          </div>

          <div className="bg-base-300 border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ol className="list-decimal ml-4">
              <li>Escolha um item da lista abaixo.</li>
              <li>
                Marque ou desmarque o checkbox para adicionar ou remover o item
                da sua lista de loot.
              </li>
              <li>
                Repita o processo para outros itens que você tenha coletado
                durante sua hunt.
              </li>
              <li>
                Utilize os filtros e a busca para melhor encontar o item que
                você deseja.
              </li>
              <li>
                A calculadora mostrará o melhor item com base no valor do loot
                por peso.
              </li>
            </ol>
          </div>

          <div role="alert" className="alert alert-warning mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span>
              Todos os items tem como base o <strong>maior</strong> item de
              venda de NPC <br />
              Por exemplo se um item custa <strong>100</strong> gp num NPC e no
              outro custa <strong>350 </strong>
              gp, a calculadora vai usar o maior valor.
            </span>
          </div>

          <div role="alert" className="alert alert-error mb-4">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>

            <span>
              Cuidado com items <strong>RAROS</strong> por exemplo a{" "}
              <strong>Demon Legs</strong> que aparece na sua lista de items
              abaixo por causa de seu baixo valor em NPC, mas que é vendida por
              um valor maior no <strong>Market</strong> do jogo.
            </span>
          </div>

          <div className="bg-base-300 border border-base-100 p-4 rounded-lg shadow-md mb-8">
            <div className="w-full flex flex-col md:flex-row items-center justify-center my-4 gap-4">
              <label htmlFor="search" className="mr-2">
                Pesquisar por nome:
              </label>
              <input
                type="text"
                id="search"
                value={searchQuery}
                onChange={handleSearchChange}
                className="input input-bordered input-secondary"
              />

              <label htmlFor="type" className="mr-2">
                Filtrar por tipo:
              </label>
              <select
                id="type"
                value={selectedType}
                onChange={handleTypeChange}
                className="select select-bordered select-secondary"
              >
                <option value="Todos">Todos</option>
                <option value="Amulet">Amuletos e Colares</option>
                <option value="Ring">Anéis</option>
                <option value="Armor">Armaduras</option>
                <option value="Boots">Botas</option>
                <option value="Legs">Calças</option>
                <option value="Club">Clavas</option>
                <option value="Distance">Distância</option>
                <option value="Helmet">Elmos</option>
                <option value="Shield">Escudos</option>
                <option value="Sword">Espadas</option>
                <option value="Axe">Machados</option>
                <option value="Rod">Rods</option>
                <option value="Spellbook">Spellbooks</option>
                <option value="Wand">Wands</option>
              </select>
            </div>

            <div className="grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 mt-4">
              {npcItems.items
                .filter(
                  (item) =>
                    item.sellPrice > 0 &&
                    item.name
                      .toLowerCase()
                      .includes(searchQuery.toLowerCase()) &&
                    (selectedType === "Todos" ||
                      item.type === selectedType.toLocaleLowerCase())
                )
                .map((item) => (
                  <div
                    key={item.name}
                    className="flex items-center checkbox-container"
                  >
                    <ImageToBase64
                      className="h-8 w-8 mr-2"
                      src={item.image}
                      alt={item.name}
                    />
                    <input
                      type="checkbox"
                      id={item.name}
                      checked={items.some((i) => i.name === item.name)}
                      onChange={() => handleCheckboxChange(item.name)}
                      className="checkbox checkbox-secondary"
                    />
                    <label
                      htmlFor={item.name}
                      className="ml-2 flex items-center cursor-pointer"
                    >
                      {item.name}
                    </label>
                  </div>
                ))}
            </div>
          </div>

          <div className="mt-4">
            <p>{decideLoot()}</p>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default LootByWeightView;
