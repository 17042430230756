import HomeView from "../../components/views/HomeView";

const PageHome = () => {
  return (
    <>
      <HomeView />
    </>
  );
};

export default PageHome;
