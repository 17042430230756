import MagicShieldView from "../../../components/views/calculators/magic-shield";

const PageMagicShield = () => {
  return (
    <>
      <MagicShieldView />
    </>
  );
};

export default PageMagicShield;
