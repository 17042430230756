import BossListComponent from "../../../components/library/boss-list";

const PageBossList = () => {
  return (
    <>
      <BossListComponent />
    </>
  );
};

export default PageBossList;
