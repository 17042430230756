import { useState, useEffect, useCallback } from "react";
import { motion } from "framer-motion";
import axios from "axios";
import CharacterDetails from "../../../components/community/characters/CharacterDetails";
import Loader from "../../../components/helpers/Loader";
import { fadeIn, slideIn, slideUp } from "../../../utility/effects";
import { API_BASE_URL } from "../../../utility/constants";
import CharacterSearch from "../../../components/search/CharacterSearch";
import { useParams } from "react-router-dom";
import PlayerExpComponent from "../../../components/community/characters/PlayerExpComponent";

const PageCharacter = () => {
  const [characterData, setCharacterData] = useState(null);
  const { name } = useParams();

  const fetchCharacterData = useCallback(async () => {
    try {
      const response = await axios.get(
        `${API_BASE_URL}/community/characters/${name}`
      );
      setCharacterData(response.data);
    } catch (error) {
      console.error("Erro ao obter dados do personagem:", error);
    }
  }, [name]);

  useEffect(() => {
    if (name) {
      fetchCharacterData();
    }
  }, [name, fetchCharacterData]);

  if (!characterData) {
    return <Loader />;
  }

  const characterNotFound =
    characterData &&
    characterData.could_not_find_character &&
    Object.keys(characterData.could_not_find_character).length === 0;

  if (characterNotFound) {
    return (
      <div className="py-24 md:py-40 mx-4 md:mx-0">
        <div className="w-full max-w-2xl mx-auto flex items-center justify-center">
          <div className="flex flex-col justify-center items-center bg-base-300 p-8 rounded-md shadow-md">
            <p className="text-2xl font-semibold mb-4 text-error">
              Personagem não encontrado.
            </p>
            <p className="text-base-content mb-4">
              Parece que o personagem com o nome{" "}
              <span className="text-yellow-400">{name}</span> não foi
              encontrado. Verifique se o nome está correto ou use o campo abaixo
              para buscar um novo jogador.
            </p>
            <CharacterSearch />
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        variants={fadeIn}
      >
        <motion.div
          className="h-full flex flex-col justify-center items-center text-center min-h-screen px-2"
          variants={slideUp}
        >
          <motion.h1
            className="text-4xl font-bold mb-4 uppercase font-martel text-center"
            variants={slideIn}
          >
            Busca de <span className="text-yellow-400">{name}</span>
          </motion.h1>

          <div className="my-4">
            {" "}
            <CharacterSearch />
          </div>

          <CharacterDetails characterData={characterData} />
          <PlayerExpComponent nick={characterData.character.name} />
        </motion.div>
      </motion.div>
    </>
  );
};

export default PageCharacter;
