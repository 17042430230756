import React from "react";
import CopyToClipboard from "../../../helpers/CopyToClipboard";

const ToPay = ({ amount }) => (
  <strong>{Math.round(amount / 1000).toFixed()}k</strong>
);

const ResultItem = ({ name, amount, payTo }) => (
  <div className="border p-4 mb-4 rounded-md shadow-md bg-base-100">
    <div className="flex items-center justify-between">
      <div className="mx-2">
        <strong className="text-lg text-primary">{name}</strong> deve pagar{" "}
        <ToPay amount={amount} /> para{" "}
        <strong className="text-lg text-secondary">{payTo}</strong>
      </div>
      <CopyToClipboard title="">{`transfer ${amount} to ${payTo}`}</CopyToClipboard>
    </div>
  </div>
);

export default ResultItem;
