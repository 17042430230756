export const npcItems = {
  items: [
    // Helmets
    {
      name: "Alicorn Headguard",
      image: "/images/tibia/items/helmets/Alicorn_Headguard.gif",
      level: 400,
      vocation: "Paladins",
      slots: 2,
      arm: 11,
      bonus: "Distance Fighting +3",
      protection:
        "Physical +5%, Fire +5%, Earth +5%, Energy +5%, Ice +5%, Holy +5%, Death +5%",
      weight: 39,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Amazon Helmet",
      image: "/images/tibia/items/helmets/Amazon_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Ancient Tiara",
      image: "/images/tibia/items/helmets/Ancient_Tiara.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.2,
      drop: "Vashresamun.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Antler-Horn Helmet",
      image: "/images/tibia/items/helmets/Antler-Horn_Helmet.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      arm: 9,
      bonus: "Sword Fighting +1, Axe Fighting +1, Club Fighting +1",
      protection: "Physical +3%, Ice +7%",
      weight: 22,
      drop: "The Monster.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Arboreal Crown",
      image: "/images/tibia/items/helmets/Arboreal_Crown.gif",
      level: 400,
      vocation: "Druids",
      slots: 2,
      arm: 9,
      bonus: "Magic Level +3",
      protection: "Physical +2%, Ice +9%",
      weight: 12,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Arcanomancer Regalia",
      image: "/images/tibia/items/helmets/Arcanomancer_Regalia.gif",
      level: 400,
      vocation: "Sorcerers",
      slots: 2,
      arm: 9,
      bonus: "Magic Level +3",
      protection: "Physical +3%, Earth +7%",
      weight: 15,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Bandana",
      image: "/images/tibia/items/helmets/Bandana.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.5,
      drop: "Pirate Marauder.",
      sellPrice: "150",
      type: "helmet",
    },
    {
      name: "Batwing Hat",
      image: "/images/tibia/items/helmets/Batwing_Hat.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 3,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Bruise Payne, Mutated Bat.",
      sellPrice: "8000",
      type: "helmet",
    },
    {
      name: "Blue Spectacles",
      image: "/images/tibia/items/helmets/Blue_Spectacles.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 2.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Bonelord Helmet",
      image: "/images/tibia/items/helmets/Bonelord_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 46,
      drop: "Braindeath, Elder Bonelord, The Evil Eye, The Last Lore Keeper.",
      sellPrice: "7500",
      type: "helmet",
    },
    {
      name: "Brass Helmet",
      image: "/images/tibia/items/helmets/Brass_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 27,
      drop: "Barbarian Headsplitter, Barbarian Skullhunter, Chakoya Windcaller, Hunter, Minotaur, Minotaur Bruiser, Salamander, Undead Prospector, Zombie.",
      sellPrice: "30",
      type: "helmet",
    },
    {
      name: "Bridal Wreath",
      image: "/images/tibia/items/helmets/Bridal_Wreath.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Broken Iks Headpiece",
      image: "/images/tibia/items/helmets/Broken_Iks_Headpiece.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Ahau, Iks Chuka, Iks Pututu.",
      sellPrice: "560",
      type: "helmet",
    },
    {
      name: "Broken Visor",
      image: "/images/tibia/items/helmets/Broken_Visor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 14,
      drop: "Ninguém.",
      sellPrice: "1900",
      type: "helmet",
    },
    {
      name: "Ceremonial Mask",
      image: "/images/tibia/items/helmets/Ceremonial_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 40,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Chain Helmet",
      image: "/images/tibia/items/helmets/Chain_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 42,
      drop: "Bandit, Barbarian Bloodwalker, Gladiator, Juvenile Cyclops, Troll-Trained Salamander, Wild Warrior.",
      sellPrice: "17",
      type: "helmet",
    },
    {
      name: "Charmer's Tiara",
      image: "/images/tibia/items/helmets/Charmer's_Tiara.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 22,
      drop: "Lizard Snakecharmer, Serpent Spawn.",
      sellPrice: "900",
      type: "helmet",
    },
    {
      name: "Cobra Crown",
      image: "/images/tibia/items/helmets/Cobra_Crown.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 3,
      bonus: "Magic Level +2",
      protection: "Earth +5%, Fire -5%",
      weight: 8.5,
      drop: "Draken Elite, Hatebreeder, Ravenous Hunger.",
      sellPrice: "50000",
      type: "helmet",
    },
    {
      name: "Cobra Hood",
      image: "/images/tibia/items/helmets/Cobra_Hood.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      arm: 9,
      bonus: "Sword Fighting +1, Club Fighting +1, Axe Fighting +1",
      protection: "Physical +5%",
      weight: 27,
      drop: "Scarlett Etzel, Guard Captain Quaid.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Crest of the Deep Seas",
      image: "/images/tibia/items/helmets/Crest_of_the_Deep_Seas.gif",
      level: 80,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Speed +5",
      protection: "Ice +5%",
      weight: 45,
      drop: "Seacrest Serpent.",
      sellPrice: "10000",
      type: "helmet",
    },
    {
      name: "Crown",
      image: "/images/tibia/items/helmets/Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Crown Helmet",
      image: "/images/tibia/items/helmets/Crown_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29.5,
      drop: "Hero, Nightmare Scion, Orewalker, Renegade Knight, Roaring Lion, Ushuriel, Vile Grandmaster, Zanakeph.",
      sellPrice: "2500",
      type: "helmet",
    },
    {
      name: "Crusader Helmet",
      image: "/images/tibia/items/helmets/Crusader_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Bibby Bloodbath, Cliff Strider, Demon Outcast, Orc Warlord, Spitter, Thul, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "6000",
      type: "helmet",
    },
    {
      name: "Damaged Helmet",
      image: "/images/tibia/items/helmets/Damaged_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Dark Helmet",
      image: "/images/tibia/items/helmets/Dark_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 46,
      drop: "Black Knight, Cyclops, Cyclops Drone, Cyclops Smith, Frost Giant, Frost Giantess, Orc Warlord, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "250",
      type: "helmet",
    },
    {
      name: "Dark Whispers",
      image: "/images/tibia/items/helmets/Dark_Whispers.gif",
      level: 180,
      vocation: "Paladins",
      slots: 2,
      arm: 7,
      bonus: "Distance Fighting +2",
      protection: "Ice +3%",
      weight: 34,
      drop: "The Nightmare Beast.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Demon Helmet",
      image: "/images/tibia/items/helmets/Demon_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29.5,
      drop: "Lloyd.",
      sellPrice: "40000",
      type: "helmet",
    },
    {
      name: "Depth Galea",
      image: "/images/tibia/items/helmets/Depth_Galea.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      arm: 8,
      bonus: "Speed +300 *",
      protection: "Drowning +100%",
      weight: 46,
      drop: "Jaul.",
      sellPrice: "35000",
      type: "helmet",
    },
    {
      name: "Devil Helmet",
      image: "/images/tibia/items/helmets/Devil_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 50,
      drop: "Angry Demon, Demon, Demon Outcast, Golgordan, Grimeleech, Latrivan, Rage Squid, Razzagorn, Vexclaw.",
      sellPrice: "1000",
      type: "helmet",
    },
    {
      name: "Dragon Scale Helmet",
      image: "/images/tibia/items/helmets/Dragon_Scale_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32.5,
      drop: "Soul of Dragonking Zyrtarch.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Dwarven Helmet",
      image: "/images/tibia/items/helmets/Dwarven_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Physical +2%",
      weight: 42,
      drop: "The Horned Fox.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Eldritch Cowl",
      image: "/images/tibia/items/helmets/Eldritch_Cowl.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 2,
      arm: 7,
      bonus: "Magic Level +2",
      protection: "Ice +7%",
      weight: 26,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Eldritch Hood",
      image: "/images/tibia/items/helmets/Eldritch_Hood.gif",
      level: 250,
      vocation: "Druids",
      slots: 2,
      arm: 7,
      bonus: "Magic Level +2",
      protection: "Earth +7%",
      weight: 27,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Elite Draken Helmet",
      image: "/images/tibia/items/helmets/Elite_Draken_Helmet.gif",
      level: 100,
      vocation: "Paladins",
      slots: 1,
      arm: 9,
      bonus: "Distance Fighting +1",
      protection: "Death +3%",
      weight: 43,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Enchanted Werewolf Helmet (Axe)",
      image:
        "/images/tibia/items/helmets/Enchanted_Werewolf_Helmet_%28Axe%29.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Axe Fighting +1",
      protection: "Physical +4%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Enchanted Werewolf Helmet (Club)",
      image:
        "/images/tibia/items/helmets/Enchanted_Werewolf_Helmet_%28Club%29.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Club Fighting +1",
      protection: "Physical +4%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Enchanted Werewolf Helmet (Distance)",
      image:
        "/images/tibia/items/helmets/Enchanted_Werewolf_Helmet_%28Distance%29.gif",
      level: 100,
      vocation: "Paladins",
      slots: 0,
      arm: 9,
      bonus: "Distance Fighting +1",
      protection: "Physical +4%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Enchanted Werewolf Helmet (Magic)",
      image:
        "/images/tibia/items/helmets/Enchanted_Werewolf_Helmet_%28Magic%29.gif",
      level: 100,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 9,
      bonus: "Magic Level +1",
      protection: "Physical +4%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Enchanted Werewolf Helmet (Sword)",
      image:
        "/images/tibia/items/helmets/Enchanted_Werewolf_Helmet_%28Sword%29.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Sword Fighting +1",
      protection: "Physical +4%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Falcon Circlet",
      image: "/images/tibia/items/helmets/Falcon_Circlet.gif",
      level: 300,
      vocation: "Sorcerers and Druids",
      slots: 2,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Fire +9%",
      weight: 13,
      drop: "Grand Master Oberon.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Falcon Coif",
      image: "/images/tibia/items/helmets/Falcon_Coif.gif",
      level: 300,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 10,
      bonus: "Distance Fighting +2, Shielding +2",
      protection: "Physical +3%, Fire +10%",
      weight: 28,
      drop: "Grand Commander Soeren, Grand Master Oberon.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Feather Headdress",
      image: "/images/tibia/items/helmets/Feather_Headdress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 21,
      drop: "Terror Bird.",
      sellPrice: "850",
      type: "helmet",
    },
    {
      name: "Ferumbras' Candy Hat",
      image: "/images/tibia/items/helmets/Ferumbras'_Candy_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Ferumbras' Hat",
      image: "/images/tibia/items/helmets/Ferumbras'_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.5,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Flower Wreath",
      image: "/images/tibia/items/helmets/Flower_Wreath.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Dryad, Nymph, Swan Maiden.",
      sellPrice: "500",
      type: "helmet",
    },
    {
      name: "Full Helmet of the Ancients",
      image: "/images/tibia/items/helmets/Full_Helmet_of_the_Ancients.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 27.6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Fur Cap",
      image: "/images/tibia/items/helmets/Fur_Cap.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Galea Mortis",
      image: "/images/tibia/items/helmets/Galea_Mortis.gif",
      level: 220,
      vocation: "Sorcerers",
      slots: 2,
      arm: 7,
      bonus: "Magic Level +2",
      protection: "Death +6%, Holy -3%",
      weight: 23,
      drop: "King Zelos.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Gill Gugel",
      image: "/images/tibia/items/helmets/Gill_Gugel.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Magic Level +2",
      protection: "Earth +6%, Fire -6%",
      weight: 9,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "helmet",
    },
    {
      name: "Glacier Mask",
      image: "/images/tibia/items/helmets/Glacier_Mask.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Ice +4%, Energy -5%",
      weight: 10,
      drop: "Crazed Winter Rearguard, Crystal Spider, Icecold Book, Ice Golem, Seacrest Serpent, Shardhead, The Bloodweb, Two-Headed Turtle.",
      sellPrice: "2500",
      type: "helmet",
    },
    {
      name: "Gnome Helmet",
      image: "/images/tibia/items/helmets/Gnome_Helmet.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 2,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Physical +3%, Energy +8%, Ice -2%",
      weight: 24,
      drop: "The Count Of The Core.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Golden Crown",
      image: "/images/tibia/items/helmets/Golden_Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 20,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Golden Helmet",
      image: "/images/tibia/items/helmets/Golden_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Ninguém.",
      sellPrice: "420",
      type: "helmet",
    },
    {
      name: "Golden Horned Helmet",
      image: "/images/tibia/items/helmets/Golden_Horned_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 51,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Green Demon Helmet",
      image: "/images/tibia/items/helmets/Green_Demon_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Gryphon Mask",
      image: "/images/tibia/items/helmets/Gryphon_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 20,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Hat of the Mad",
      image: "/images/tibia/items/helmets/Hat_of_the_Mad.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 3,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Helmet of Nature",
      image: "/images/tibia/items/helmets/Helmet_of_Nature.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 11,
      drop: "Dirtbeard.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Helmet of the Ancients",
      image: "/images/tibia/items/helmets/Helmet_of_the_Ancients.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 27.6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Helmet of the Deep",
      image: "/images/tibia/items/helmets/Helmet_of_the_Deep.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Speed +300 *",
      protection: "Drowning +100%",
      weight: 210,
      drop: "Ninguém.",
      sellPrice: "5000",
      type: "helmet",
    },
    {
      name: "Helmet of The Lost",
      image: "/images/tibia/items/helmets/Helmet_of_The_Lost.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Lost Thrower",
      sellPrice: "2000",
      type: "helmet",
    },
    {
      name: "Helmet of Ultimate Terror",
      image: "/images/tibia/items/helmets/Helmet_of_Ultimate_Terror.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12.5,
      drop: "Monstor.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Horned Helmet",
      image: "/images/tibia/items/helmets/Horned_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 51,
      drop: "Ninguém.",
      sellPrice: "155",
      type: "helmet",
    },
    {
      name: "Horseman Helmet",
      image: "/images/tibia/items/helmets/Horseman_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 42,
      drop: "Assassin.",
      sellPrice: "280",
      type: "helmet",
    },
    {
      name: "Incandescent Crown",
      image: "/images/tibia/items/helmets/Incandescent_Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Iron Crown",
      image: "/images/tibia/items/helmets/Iron_Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Iron Helmet",
      image: "/images/tibia/items/helmets/Iron_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 30,
      drop: "Bandit, Crypt Defiler, Crypt Shambler, Demon Skeleton, Gladiator, Grave Robber, Menacing Carnivor, Nomad, Wild Warrior.",
      sellPrice: "150",
      type: "helmet",
    },
    {
      name: "Ivory Mask",
      image: "/images/tibia/items/helmets/Ivory_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Jade Hat",
      image: "/images/tibia/items/helmets/Jade_Hat.gif",
      level: 60,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 4,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Ethershreck, Ghastly Dragon, Hatebreeder, Ironblight, Ravenous Hunger, Zanakeph.",
      sellPrice: "9000",
      type: "helmet",
    },
    {
      name: "Jester Hat",
      image: "/images/tibia/items/helmets/Jester_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Krimhorn Helmet",
      image: "/images/tibia/items/helmets/Krimhorn_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 51,
      drop: "Barbarian Headsplitter.",
      sellPrice: "200",
      type: "helmet",
    },
    {
      name: "Laurel Wreath",
      image: "/images/tibia/items/helmets/Laurel_Wreath.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Leaf Crown",
      image: "/images/tibia/items/helmets/Leaf_Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Leather Helmet",
      image: "/images/tibia/items/helmets/Leather_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 22,
      drop: "Goblin, Goblin Assassin, Goblin Leader, Goblin Scavenger, Island Troll, Minotaur Mage, Minotaur Occultist, Muglex Clan Assassin, Muglex Clan Scavenger, Poacher, Smuggler, Troll, Werelioness, Young Troll.",
      sellPrice: "4",
      type: "helmet",
    },
    {
      name: "Legion Helmet",
      image: "/images/tibia/items/helmets/Legion_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 31,
      drop: "Ninguém.",
      sellPrice: "22",
      type: "helmet",
    },
    {
      name: "Lightning Headband",
      image: "/images/tibia/items/helmets/Lightning_Headband.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Energy +4%, Earth -5%",
      weight: 10,
      drop: "Anomaly, Burning Gladiator, Burster, Giant Spider, High Voltage Elemental, Hulking Carnisylvan, Outburst, Reality Reaver, Sphinx, Usurper Warlock, World Devourer.",
      sellPrice: "2500",
      type: "helmet",
    },
    {
      name: "Lion Spangenhelm",
      image: "/images/tibia/items/helmets/Lion_Spangenhelm.gif",
      level: 230,
      vocation: "Paladins",
      slots: 2,
      arm: 7,
      bonus: "Distance Fighting +2",
      protection: "Physical +3%, Earth +5%",
      weight: 30,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Mage Hat",
      image: "/images/tibia/items/helmets/Mage_Hat.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 2,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Ninguém.",
      sellPrice: "150",
      type: "helmet",
    },
    {
      name: "Mage's Cap",
      image: "/images/tibia/items/helmets/Mage's_Cap.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Fire +4%",
      weight: 10,
      drop: "Mad Mage.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Magician Hat",
      image: "/images/tibia/items/helmets/Magician_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.5,
      drop: "Rahemos.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Magma Monocle",
      image: "/images/tibia/items/helmets/Magma_Monocle.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Fire +4%, Ice -5%.",
      weight: 10,
      drop: "Burning Book, Diabolic Imp, Efreet, Essence Of Malice, Fahim the Wise, Feral Sphinx, Hellhound, Manticore, Marid, Merikh the Slaughterer, Sphinx, Urmahlullu the Weakened, Usurper Warlock.",
      sellPrice: "2500",
      type: "helmet",
    },
    {
      name: "Mighty Helm of Green Sparks",
      image: "/images/tibia/items/helmets/Mighty_Helm_of_Green_Sparks.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 46,
      drop: "Doctor Perhaps.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Mining Helmet",
      image: "/images/tibia/items/helmets/Mining_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Mining Helmet (Budrik)",
      image: "/images/tibia/items/helmets/Mining_Helmet_%28Budrik%29.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Mirror Mask",
      image: "/images/tibia/items/helmets/Mirror_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 16,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Mystic Turban",
      image: "/images/tibia/items/helmets/Mystic_Turban.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.5,
      drop: "Blood Hand, Blood Priest, Blue Djinn, Dawnfire Asura, Efreet, Fahim the Wise, Green Djinn, Ice Witch, Marid, Merikh the Slaughterer, Necromancer, Necropharus, Shadow Pupil.",
      sellPrice: "150",
      type: "helmet",
    },
    {
      name: "Odd Hat",
      image: "/images/tibia/items/helmets/Odd_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Dirtbeard.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Pair of Earmuffs",
      image: "/images/tibia/items/helmets/Pair_of_Earmuffs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Ice +2%",
      weight: 5,
      drop: "Ice Witch, Melting Frozen Horror.",
      sellPrice: "1500",
      type: "helmet",
    },
    {
      name: "Party Hat",
      image: "/images/tibia/items/helmets/Party_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.5,
      drop: "Ninguém.",
      sellPrice: "600",
      type: "helmet",
    },
    {
      name: "Pirate Hat",
      image: "/images/tibia/items/helmets/Pirate_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12.5,
      drop: "Pirate Corsair.",
      sellPrice: "1000",
      type: "helmet",
    },
    {
      name: "Porcelain Mask",
      image: "/images/tibia/items/helmets/Porcelain_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Mana Drain +10%",
      weight: 4.5,
      drop: "Ninguém.",
      sellPrice: "2000",
      type: "helmet",
    },
    {
      name: "Post Officers Hat",
      image: "/images/tibia/items/helmets/Post_Officers_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Prismatic Helmet",
      image: "/images/tibia/items/helmets/Prismatic_Helmet.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Shielding +1",
      protection: "Physical +5%",
      weight: 56,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "helmet",
    },
    {
      name: "Ragnir Helmet",
      image: "/images/tibia/items/helmets/Ragnir_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 31,
      drop: "Barbarian Skullhunter.",
      sellPrice: "400",
      type: "helmet",
    },
    {
      name: "Reflecting Crown",
      image: "/images/tibia/items/helmets/Reflecting_Crown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Royal Helmet",
      image: "/images/tibia/items/helmets/Royal_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 48,
      drop: "Albino Dragon, Dragon Lord, Frost Dragon, Hydra, Lost Berserker, Serpent Spawn, Shock Head, Terofar, The Enraged Thorn Knight, The Many, The Noxious Spawn, Undead Dragon, Ushuriel, Zanakeph.",
      sellPrice: "30000",
      type: "helmet",
    },
    {
      name: "Rubber Cap",
      image: "/images/tibia/items/helmets/Rubber_Cap.gif",
      level: 70,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Magic Level +1",
      protection: "Earth +3%, Fire -3%",
      weight: 8,
      drop: "Glooth Bandit, Glooth Brigand, Glooth Fairy, Glooth Golem, Rotten Golem.",
      sellPrice: "11000",
      type: "helmet",
    },
    {
      name: "Rusty Winged Helmet",
      image: "/images/tibia/items/helmets/Rusty_Winged_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Santa Hat",
      image: "/images/tibia/items/helmets/Santa_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.5,
      drop: "Ninguém.",
      sellPrice: "50Christmas Tokens",
      type: "helmet",
    },
    {
      name: "Sedge Hat",
      image: "/images/tibia/items/helmets/Sedge_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Shamanic Mask",
      image: "/images/tibia/items/helmets/Shamanic_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 31,
      drop: "Ogre Brute, Ogre Savage, Ogre Shaman.",
      sellPrice: "2000",
      type: "helmet",
    },
    {
      name: "Shroud of Despair",
      image: "/images/tibia/items/helmets/Shroud_of_Despair.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Death +3%",
      weight: 34,
      drop: "Tarbaz.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Silver Mask",
      image: "/images/tibia/items/helmets/Silver_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Ninguém",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Skull Helmet",
      image: "/images/tibia/items/helmets/Skull_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 42,
      drop: "Betrayed Wraith, Bretzecutioner, Destroyer, Hellflayer, Katex Blood Tongue, Lost Soul, Mad Mage, Prince Drazzak, Quara Predator, Ragiaz, Ribstride, Raging Mage, Renegade Quara Predator, Skeleton Elite Warrior, Srezz Yellow Eyes, Ushuriel, Terofar, The Unarmored Voidborn, Utua Stone Sting, Yirkas Blue Scales, Zanakeph.",
      sellPrice: "40000",
      type: "helmet",
    },
    {
      name: "Soldier Helmet",
      image: "/images/tibia/items/helmets/Soldier_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Dwarf Soldier.",
      sellPrice: "16",
      type: "helmet",
    },
    {
      name: "Sphinx Tiara",
      image: "/images/tibia/items/helmets/Sphinx_Tiara.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.5,
      drop: "Feral Sphinx, Sphinx.",
      sellPrice: "360",
      type: "helmet",
    },
    {
      name: "Spiritthorn Helmet",
      image: "/images/tibia/items/helmets/Spiritthorn_Helmet.gif",
      level: 400,
      vocation: "Knights",
      slots: 2,
      arm: 12,
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +6%, Energy +10%",
      weight: 44,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Spooky Hood",
      image: "/images/tibia/items/helmets/Spooky_Hood.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12.5,
      drop: "Unaz the Mean, Brain Head, The Dread Maiden.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Steel Helmet",
      image: "/images/tibia/items/helmets/Steel_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 46,
      drop: "Assassin, Black Knight, Crystal Spider, Dragon, Dwarf Guard, Foreman Kneebiter, Gargoyle, Giant Spider, Hide, High Templar Cobrass, Lizard Templar, Sandstone Scorpion, Tarantula, The Old Widow, Zombie.",
      sellPrice: "293",
      type: "helmet",
    },
    {
      name: "Stoic Iks Casque",
      image: "/images/tibia/items/helmets/Stoic_Iks_Casque.gif",
      level: 250,
      vocation: "Paladins",
      slots: 2,
      arm: 8,
      bonus: "Distance Fighting +2",
      protection: "Physical +3%, Energy + 6%",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Stoic Iks Headpiece",
      image: "/images/tibia/items/helmets/Stoic_Iks_Headpiece.gif",
      level: 250,
      vocation: "Druids",
      slots: 2,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Death +3%",
      weight: 21,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Strange Helmet",
      image: "/images/tibia/items/helmets/Strange_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 46,
      drop: "Albino Dragon, Dragon Lord, Frost Dragon, Icecold Book, Ice Golem, Lich, Quara Mantassin, Renegade Quara Mantassin, Serpent Spawn, Ushuriel, Vampire.",
      sellPrice: "500",
      type: "helmet",
    },
    {
      name: "Studded Helmet",
      image: "/images/tibia/items/helmets/Studded_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 24.5,
      drop: "Elf, Orc, Orc Spearman, Troll-Trained Salamander.",
      sellPrice: "20",
      type: "helmet",
    },
    {
      name: "Terra Helmet",
      image: "/images/tibia/items/helmets/Terra_Helmet.gif",
      level: 230,
      vocation: "Knights",
      slots: 1,
      arm: 9,
      bonus: "Sword Fighting +2, Club Fighting +2, Axe Fighting +2",
      protection: "Physical +5%, Earth +5%",
      weight: 31,
      drop: "Count Vlarkorth, Duke Krule, Earl Osam, Lord Azaram, Sir Baeloc.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Terra Hood",
      image: "/images/tibia/items/helmets/Terra_Hood.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Earth +4%, Fire -5%",
      weight: 10,
      drop: "Adult Goanna, Ancient Scarab, Ancient Spawn of Morgathla, Cobra Vizier, Crazed Winter Vanguard, Cursed Book, Draken Abomination, Glooth Bandit, Glooth Brigand, Hide, Hulking Carnisylvan, Ink Blob, Lamassu, Scarlett Etzel, Thanatursus, The Unarmored Voidborn, Usurper Warlock, Young Goanna.",
      sellPrice: "2500",
      type: "helmet",
    },
    {
      name: "The Crown of the Percht Queen (Fire)",
      image:
        "/images/tibia/items/helmets/The_Crown_of_the_Percht_Queen_%28Fire%29.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "The Crown of the Percht Queen (Ice)",
      image:
        "/images/tibia/items/helmets/The_Crown_of_the_Percht_Queen_%28Ice%29.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "The Epic Wisdom",
      image: "/images/tibia/items/helmets/The_Epic_Wisdom.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.05,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Tiara of Power",
      image: "/images/tibia/items/helmets/Tiara_of_Power.gif",
      level: 100,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 7,
      bonus: "Speed +20",
      protection: "Energy +8%",
      weight: 11.5,
      drop: "Anomaly, Outburst, Rupture, World Devourer.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Traditional Gamsbart Hat",
      image: "/images/tibia/items/helmets/Traditional_Gamsbart_Hat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 2.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Tribal Mask",
      image: "/images/tibia/items/helmets/Tribal_Mask.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Dworc Fleshhunter, Dworc Venomsniper, Dworc Voodoomaster, Midnight Asura, True Midnight Asura.",
      sellPrice: "250",
      type: "helmet",
    },
    {
      name: "Viking Helmet",
      image: "/images/tibia/items/helmets/Viking_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 39,
      drop: "Barbarian Headsplitter, Barbarian Skullhunter, Brittle Skeleton, Ghoul, Gnome Pack Crawler, Skeleton, Undead Prospector.",
      sellPrice: "66",
      type: "helmet",
    },
    {
      name: "Visage of the End Days",
      image: "/images/tibia/items/helmets/Visage_of_the_End_Days.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 38,
      drop: "Razzagorn.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Warrior Helmet",
      image: "/images/tibia/items/helmets/Warrior_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 68,
      drop: "Black Knight, Cult Enforcer, Enslaved Dwarf, Flameborn, Hellspawn, Hydra, Minotaur Amazon, Minotaur Cult Follower, Minotaur Invader, Orc Cult Fanatic, Orc Leader, Quara Pincher, Renegade Orc, Renegade Quara Pincher, Serpent Spawn, Spiky Carnivor, Terrorsleep, The Many, Ushuriel, Usurper Archer, Vicious Squire.",
      sellPrice: "5000",
      type: "helmet",
    },
    {
      name: "Werewolf Helmet",
      image: "/images/tibia/items/helmets/Werewolf_Helmet.gif",
      level: 100,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Speed +15",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Winged Helmet",
      image: "/images/tibia/items/helmets/Winged_Helmet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Ninguém.",
      sellPrice: "320",
      type: "helmet",
    },
    {
      name: "Witch Hat",
      image: "/images/tibia/items/helmets/Witch_Hat.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Mana Drain +5%",
      weight: 7.8,
      drop: "Essence Of Malice, Witch.",
      sellPrice: "5000",
      type: "helmet",
    },
    {
      name: "Wood Cape",
      image: "/images/tibia/items/helmets/Wood_Cape.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      arm: 3,
      bonus: "Distance Fighting +1",
      protection: "Earth +4%",
      weight: 11,
      drop: "Adult Goanna, Arctic Faun, Crazed Summer Rearguard, Dark Faun, Faun, Pirat Bombardier, Ravenous Hunger, Raxias, Soul-Broken Harbinger, Usurper Archer, Varnished Diremaw, Venerable Girtablilu, Rotten Golem.",
      sellPrice: "5000",
      type: "helmet",
    },
    {
      name: "Yalahari Mask",
      image: "/images/tibia/items/helmets/Yalahari_Mask.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 5,
      bonus: "Magic Level +2",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "",
      type: "helmet",
    },
    {
      name: "Zaoan Helmet",
      image: "/images/tibia/items/helmets/Zaoan_Helmet.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Physical +5%",
      weight: 66,
      drop: "Draken Abomination, Draken Elite, Ethershreck, Ghastly Dragon, Hatebreeder, Lizard Chosen, Lizard Gate Guardian.",
      sellPrice: "45000",
      type: "helmet",
    },

    //armor
    {
      name: "Albino Plate",
      image: "/images/tibia/items/armors/Albino_Plate.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 84,
      drop: "Ninguém.",
      sellPrice: "1500",
      type: "armor",
    },
    {
      name: "Amazon Armor",
      image: "/images/tibia/items/armors/Amazon_Armor.gif",
      level: 60,
      vocation: "Paladins",
      slots: 2,
      arm: 13,
      bonus: "Distance Fighting +3",
      protection: "Nenhuma.",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Arcane Dragon Robe",
      image: "/images/tibia/items/armors/Arcane_Dragon_Robe.gif",
      level: 300,
      vocation: "Sorcerers",
      slots: 1,
      arm: 15,
      bonus: "Magic Level +3, Energy Magic Level +1",
      protection: "Ice +12%, Fire -6%",
      weight: 43,
      drop: "Treasure Chest (Dragon Hoard).",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Ball Gown",
      image: "/images/tibia/items/armors/Ball_Gown.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Bear Skin",
      image: "/images/tibia/items/armors/Bear_Skin.gif",
      level: 230,
      vocation: "Druids",
      slots: 1,
      arm: 16,
      bonus: "Magic Level +4",
      protection: "earth +11%, Fire -2%",
      weight: 44,
      drop: "Lord Azaram, Duke Krule, Count Vlarkorth, Sir Baeloc.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Belted Cape",
      image: "/images/tibia/items/armors/Belted_Cape.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Bog Raider, Crazed Summer Vanguard, Gladiator, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "500",
      type: "armor",
    },
    {
      name: "Blue Robe",
      image: "/images/tibia/items/armors/Blue_Robe.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 22,
      drop: "Banshee, Enlightened of the Cult, Feversleep, Girtablilu Warrior, Hellgorak, Horadron, Lich, Lokathmor, Lumbering Carnivor, Midnight Asura, Quara Mantassin, Renegade Quara Mantassin, Spiky Carnivor, Tamru the Black, Terrorsleep, True Midnight Asura, Vashresamun, Warlock.",
      sellPrice: "10000",
      type: "armor",
    },
    {
      name: "Brass Armor",
      image: "/images/tibia/items/armors/Brass_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 80,
      drop: "Bandit, Crypt Defiler, Gladiator, Grave Robber, Hunter, Minotaur Archer, Minotaur Guard, Nomad, Nomad (Blue), Orc Warlord, Quara Constrictor, Quara Constrictor Scout, Renegade Quara Constrictor, The Horned Fox, Undead Gladiator, Wild Warrior.",
      sellPrice: "150",
      type: "armor",
    },
    {
      name: "Broken Iks Cuirass",
      image: "/images/tibia/items/armors/Broken_Iks_Cuirass.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Ahau, Iks Aucar.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Buckle",
      image: "/images/tibia/items/armors/Buckle.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 75,
      drop: "Lost Basher, Lost Exile, Lost Husher, Lost Thrower, Spiky Carnivor.",
      sellPrice: "7000",
      type: "armor",
    },
    {
      name: "Burial Shroud",
      image: "/images/tibia/items/armors/Burial_Shroud.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Death +5%",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Calopteryx Cape",
      image: "/images/tibia/items/armors/Calopteryx_Cape.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 12,
      bonus: "Magic Level +1",
      protection: "Ice +5%",
      weight: 20,
      drop: "Hive Overseer, Kollos, Spidris, Spidris Elite, Spitter, The Sandking, The Baron from Below.",
      sellPrice: "15000",
      type: "armor",
    },
    {
      name: "Cape",
      image: "/images/tibia/items/armors/Cape.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Ekatrix, Ghost, Lizard Snakecharmer, Quara Mantassin, Renegade Quara Mantassin, Tarnished Spirit, Two-Headed Turtle, Witch.",
      sellPrice: "9",
      type: "armor",
    },
    {
      name: "Chain Armor",
      image: "/images/tibia/items/armors/Chain_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 100,
      drop: "Barbarian Bloodwalker, Barbarian Brutetamer, Blood Crab, Deepsea Blood Crab, Dwarf Soldier, Juvenile Cyclops, Lizard Sentinel, Minotaur, Minotaur Bruiser, Minotaur Guard, Minotaur Poacher, Orc Berserker, Orc Shaman, Orc Warrior, Pirate Marauder, Quara Mantassin Scout, Scar Tribe Warrior, Valkyrie.",
      sellPrice: "70",
      type: "armor",
    },
    {
      name: "Coat",
      image: "/images/tibia/items/armors/Coat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 27,
      drop: "Ekatrix, Frost Troll, Witch.",
      sellPrice: "11",
      type: "armor",
    },
    {
      name: "Crown Armor",
      image: "/images/tibia/items/armors/Crown_Armor.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 99,
      drop: "Ashmunrah, Cliff Strider, Demon Outcast, Execowtioner, Gorzindel, Hand of Cursed Fate, Hellgorak, Hero, Horadron, Orewalker, Phantasm, Prince Drazzak, Quara Pincher, Renegade Knight, Renegade Quara Pincher, Serpent Spawn, The Noxious Spawn, Thul, Vile Grandmaster.",
      sellPrice: "12000",
      type: "armor",
    },
    {
      name: "Crystalline Armor",
      image: "/images/tibia/items/armors/Crystalline_Armor.gif",
      level: 60,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Ice +3%, Energy -3%",
      weight: 84.5,
      drop: "Armadile, Crystal Wolf, Diamond Servant, Diamond Servant Replica, Eradicator, Icecold Book, Ice Overlord, Mazzinor, Melting Frozen Horror, Orewalker, Prince Drazzak, Realityquake, Ravenous Hunger, Sea Serpent, The Count Of The Core, Tunnel Tyrant.",
      sellPrice: "16000",
      type: "armor",
    },
    {
      name: "Dark Armor",
      image: "/images/tibia/items/armors/Dark_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 120,
      drop: "Ancient Lion Knight, Behemoth, Bibby Bloodbath, Black Knight, Bretzecutioner, Destroyer, Gargoyle, Ghastly Dragon, Pirate Corsair, Spiky Carnivor, Werelion.",
      sellPrice: "400",
      type: "armor",
    },
    {
      name: "Dark Lord's Cape",
      image: "/images/tibia/items/armors/Dark_Lord's_Cape.gif",
      level: 65,
      vocation: "Sorcerers",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Death +8%, Holy -8%",
      weight: 29,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Dauntless Dragon Scale Armor",
      image: "/images/tibia/items/armors/Dauntless_Dragon_Scale_Armor.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      arm: 18,
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +8%",
      weight: 152,
      drop: "Treasure Chest (Dragon Hoard).",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Dawnfire Sherwani",
      image: "/images/tibia/items/armors/Dawnfire_Sherwani.gif",
      level: 270,
      vocation: "Sorcerers",
      slots: 1,
      arm: 16,
      bonus: "Magic Level +4",
      protection: "Fire +10%, Earth -2%",
      weight: 28,
      drop: "Timira the Many-Headed",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Demon Armor",
      image: "/images/tibia/items/armors/Demon_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 16,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 80,
      drop: "Ninguém.",
      sellPrice: "195",
      type: "armor",
    },
    {
      name: "Depth Lorica",
      image: "/images/tibia/items/armors/Depth_Lorica.gif",
      level: 150,
      vocation: "Paladins",
      slots: 2,
      arm: 16,
      bonus: "Distance Fighting +3",
      protection: "Death +5%",
      weight: 145,
      drop: "Jaul.",
      sellPrice: "30000",
      type: "armor",
    },
    {
      name: "Divine Plate",
      image: "/images/tibia/items/armors/Divine_Plate.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Death +10%, Holy -10%",
      weight: 75,
      drop: "Ferumbras Mortal Shell, Undead Dragon, Zanakeph.",
      sellPrice: "55000",
      type: "armor",
    },
    {
      name: "Doublet",
      image: "/images/tibia/items/armors/Doublet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Dawnfly, Gore Horn, Gorerilla, Hulking Prehemoth, Jungle Moa, Spiky Carnivor, Werehyaena Shaman.",
      sellPrice: "3",
      type: "armor",
    },
    {
      name: "Dragon Robe",
      image: "/images/tibia/items/armors/Dragon_Robe.gif",
      level: 75,
      vocation: "Sorcerers",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Fire +12%, Ice -12%",
      weight: 28.5,
      drop: "Ninguém.",
      sellPrice: "50000",
      type: "armor",
    },
    {
      name: "Dragon Scale Mail",
      image: "/images/tibia/items/armors/Dragon_Scale_Mail.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 15,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 114,
      drop: "Demodras, Dragon Lord, Frost Dragon, Paiz the Pauperizer, Soul of Dragonking Zyrtarch.",
      sellPrice: "40000",
      type: "armor",
    },
    {
      name: "Dream Shroud",
      image: "/images/tibia/items/armors/Dream_Shroud.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 12,
      bonus: "Magic Level +3",
      protection: "Energy +10%",
      weight: 25,
      drop: "Alptramun.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Dwarven Armor",
      image: "/images/tibia/items/armors/Dwarven_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Physical +5%",
      weight: 130,
      drop: "Ninguém.",
      sellPrice: "30000",
      type: "armor",
    },
    {
      name: "Earthborn Titan Armor",
      image: "/images/tibia/items/armors/Earthborn_Titan_Armor.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 15,
      bonus: "Axe Fighting +2",
      protection: "Earth +5%, Fire -5%",
      weight: 120,
      drop: "Ancient Spawn of Morgathla, Lord of the Elements, Terofar.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Earthheart Cuirass",
      image: "/images/tibia/items/armors/Earthheart_Cuirass.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Sword Fighting +4",
      protection: "Earth +8%, Fire -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Earthheart Hauberk",
      image: "/images/tibia/items/armors/Earthheart_Hauberk.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Axe Fighting +4",
      protection: "Earth +8%, Fire -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Earthheart Platemail",
      image: "/images/tibia/items/armors/Earthheart_Platemail.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Club Fighting +4",
      protection: "Earth +8%, Fire -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Earthmind Raiment",
      image: "/images/tibia/items/armors/Earthmind_Raiment.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 15,
      bonus: "Magic Level +4",
      protection: "Earth +8%, Fire -8%",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Earthsoul Tabard",
      image: "/images/tibia/items/armors/Earthsoul_Tabard.gif",
      level: 200,
      vocation: "Paladins",
      slots: 0,
      arm: 18,
      bonus: "Distance Fighting +4",
      protection: "Earth +8%, Fire -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Eldritch Cuirass",
      image: "/images/tibia/items/armors/Eldritch_Cuirass.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      arm: 16,
      bonus: "Nenhum.",
      protection: "Physical +10%",
      weight: 110,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Elite Draken Mail",
      image: "/images/tibia/items/armors/Elite_Draken_Mail.gif",
      level: 100,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 15,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 120,
      drop: "Draken Elite, Paiz the Pauperizer.",
      sellPrice: "50000",
      type: "armor",
    },
    {
      name: "Elven Mail",
      image: "/images/tibia/items/armors/Elven_Mail.gif",
      level: 0,
      vocation: "Todas",
      slots: 3,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 90,
      drop: "Ravenous Hunger.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Embrace of Nature",
      image: "/images/tibia/items/armors/Embrace_of_Nature.gif",
      level: 220,
      vocation: "Paladins",
      slots: 1,
      arm: 16,
      bonus: "Distance Fighting +4",
      protection: "Ice +11%, Energy -3%",
      weight: 24,
      drop: "Count Vlarkorth, Duke Krule, Lord Azaram, Sir Baeloc.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Ethno Coat",
      image: "/images/tibia/items/armors/Ethno_Coat.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 7,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 25.5,
      drop: "Ninguém.",
      sellPrice: "200",
      type: "armor",
    },
    {
      name: "Falcon Plate",
      image: "/images/tibia/items/armors/Falcon_Plate.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      arm: 18,
      bonus: "Shielding +4",
      protection: "Physical +12%",
      weight: 188,
      drop: "Grand Canon Dominus, Grand Master Oberon.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Fireborn Giant Armor",
      image: "/images/tibia/items/armors/Fireborn_Giant_Armor.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 15,
      bonus: "Sword Fighting +2",
      protection: "Fire +5%, Ice -5%",
      weight: 120,
      drop: "Ancient Spawn of Morgathla, Lord of the Elements.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Fireheart Cuirass",
      image: "/images/tibia/items/armors/Fireheart_Cuirass.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Sword Fighting +4",
      protection: "Fire +8%, Ice -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Fireheart Hauberk",
      image: "/images/tibia/items/armors/Fireheart_Hauberk.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Axe Fighting +4",
      protection: "Fire +8%, Ice -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Fireheart Platemail",
      image: "/images/tibia/items/armors/Fireheart_Platemail.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Club Fighting +4",
      protection: "Fire +8%, Ice -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Firemind Raiment",
      image: "/images/tibia/items/armors/Firemind_Raiment.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 15,
      bonus: "Magic Level +4",
      protection: "Fire +8%, Ice -8%",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Firesoul Tabard",
      image: "/images/tibia/items/armors/Firesoul_Tabard.gif",
      level: 200,
      vocation: "Paladins",
      slots: 0,
      arm: 18,
      bonus: "Distance Fighting +4",
      protection: "Fire +8%, Ice -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Flower Dress",
      image: "/images/tibia/items/armors/Flower_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Dryad.",
      sellPrice: "1000",
      type: "armor",
    },
    {
      name: "Focus Cape",
      image: "/images/tibia/items/armors/Focus_Cape.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 9,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 21,
      drop: "Dawnfire Asura, Draken Spellweaver, Girtablilu Warrior, Harpy, Hellgorak, Lizard Dragon Priest, Lumbering Carnivor, Nightmare Scion, Raging Mage, Reality Reaver, Sea Serpent, Tremendous Tyrant, Wyrm.",
      sellPrice: "6000",
      type: "armor",
    },
    {
      name: "Frostheart Cuirass",
      image: "/images/tibia/items/armors/Frostheart_Cuirass.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Sword Fighting +4",
      protection: "Ice +8%, Energy -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Frostheart Hauberk",
      image: "/images/tibia/items/armors/Frostheart_Hauberk.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Axe Fighting +4",
      protection: "Ice +8%, Energy -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Frostheart Platemail",
      image: "/images/tibia/items/armors/Frostheart_Platemail.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Club Fighting +4",
      protection: "Ice +8%, Energy -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Frostmind Raiment",
      image: "/images/tibia/items/armors/Frostmind_Raiment.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 15,
      bonus: "Magic Level +4",
      protection: "Ice +8%, Energy -8%",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Frostsoul Tabard",
      image: "/images/tibia/items/armors/Frostsoul_Tabard.gif",
      level: 200,
      vocation: "Paladins",
      slots: 0,
      arm: 18,
      bonus: "Distance Fighting +4",
      protection: "Ice +8%, Energy -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Frozen Plate",
      image: "/images/tibia/items/armors/Frozen_Plate.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Ice +7%, Energy -7%",
      weight: 75,
      drop: "Leviathan, Melting Frozen Horror, Renegade Quara Predator.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Fur Armor",
      image: "/images/tibia/items/armors/Fur_Armor.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Earth +5%, Ice +5%",
      weight: 49,
      drop: "Adult Goanna, Bloodback, Cave Chimera, Girtablilu Warrior, Lumbering Carnivor, Shadowpelt, Varnished Diremaw, Werebear, Wereboar, Young Goanna, Rotten Golem.",
      sellPrice: "5000",
      type: "armor",
    },
    {
      name: "Furious Frock",
      image: "/images/tibia/items/armors/Furious_Frock.gif",
      level: 130,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 12,
      bonus: "Magic Level +2",
      protection: "Fire +5%",
      weight: 34,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Ghost Chestplate",
      image: "/images/tibia/items/armors/Ghost_Chestplate.gif",
      level: 230,
      vocation: "Paladins",
      slots: 2,
      arm: 17,
      bonus: "Distance Fighting +2",
      protection: "Physical +3%",
      weight: 123,
      drop: "The Fear Feaster, The Pale Worm.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Gill Coat",
      image: "/images/tibia/items/armors/Gill_Coat.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 12,
      bonus: "Magic Level +1",
      protection: "Earth +10%, Fire -10%",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "armor",
    },
    {
      name: "Girl's Dress",
      image: "/images/tibia/items/armors/Girl's_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 13,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Glacier Robe",
      image: "/images/tibia/items/armors/Glacier_Robe.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Ice +8%, Energy -8%",
      weight: 22.5,
      drop: "Crazed Winter Rearguard, Crazed Winter Vanguard, Girtablilu Warrior, Icecold Book, Quara Pincher, Quara Predator, Srezz Yellow Eyes, Tarbaz, Tremendous Tyrant.",
      sellPrice: "11000",
      type: "armor",
    },
    {
      name: "Glooth Cape",
      image: "/images/tibia/items/armors/Glooth_Cape.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Earth +5%, Fire -5%",
      weight: 29,
      drop: "Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Fairy, Lisa, Tremor Worm.",
      sellPrice: "7000",
      type: "armor",
    },
    {
      name: "Gnome Armor",
      image: "/images/tibia/items/armors/Gnome_Armor.gif",
      level: 200,
      vocation: "Paladins",
      slots: 2,
      arm: 17,
      bonus: "Distance Fighting +3",
      protection: "Physical +4%, Energy +8%, Ice -2%",
      weight: 120,
      drop: "The Baron from Below.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Golden Armor",
      image: "/images/tibia/items/armors/Golden_Armor.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 14,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 80,
      drop: "Falcon Knight, Falcon Paladin, Ferumbras Mortal Shell, Golden Servant, Golden Servant Replica, Grand Canon Dominus, Grand Commander Soeren, Hellflayer, Hellgorak, Horadron, Horestis, Juggernaut, Katex Blood Tongue, Kerberos, Preceptor Lazare, The Last Lore Keeper, Undead Dragon, Warlock, Zanakeph.",
      sellPrice: "20000",
      type: "armor",
    },
    {
      name: "Goo Shell",
      image: "/images/tibia/items/armors/Goo_Shell.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Earth +2%, Fire -2%",
      weight: 58,
      drop: "Ninguém.",
      sellPrice: "4000",
      type: "armor",
    },
    {
      name: "Green Demon Armor",
      image: "/images/tibia/items/armors/Green_Demon_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 16,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 80,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Green Tunic",
      image: "/images/tibia/items/armors/Green_Tunic.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9.3,
      drop: "Elf Arcanist, Hero, Vicious Squire.",
      sellPrice: "25",
      type: "armor",
    },
    {
      name: "Greenwood Coat",
      image: "/images/tibia/items/armors/Greenwood_Coat.gif",
      level: 75,
      vocation: "Druids",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Earth +12%, Fire -12%",
      weight: 28.5,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "50000",
      type: "armor",
    },
    {
      name: "Heat Core",
      image: "/images/tibia/items/armors/Heat_Core.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 55,
      drop: "Glooth Brigand, Glooth Fairy, Glooth Golem.",
      sellPrice: "10000",
      type: "armor",
    },
    {
      name: "Heavy Metal T-Shirt",
      image: "/images/tibia/items/armors/Heavy_Metal_T-Shirt.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8,
      drop: "Boogey.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Hibiscus Dress",
      image: "/images/tibia/items/armors/Hibiscus_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 19,
      drop: "Boreth, Nymph, Vampire Bride, Wyrm.",
      sellPrice: "3000",
      type: "armor",
    },
    {
      name: "Jacket",
      image: "/images/tibia/items/armors/Jacket.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 24,
      drop: "Ninguém.",
      sellPrice: "1",
      type: "armor",
    },
    {
      name: "Knight Armor",
      image: "/images/tibia/items/armors/Knight_Armor.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 120,
      drop: "Black Knight, Brutus Bloodbeard, Crystal Spider, Deadeye Devious, Esmeralda, Falcon Knight, Fleshcrawler, Giant Spider, Grand Chaplain Gaunder, Hand of Cursed Fate, Hydra, Juggernaut, Lethal Lissy, Medusa, Mooh'Tah Warrior, Naga Warrior, Preceptor Lazare, Quara Hydromancer, Quara Hydromancer Scout, Rhindeer, Ron The Ripper, Stampor, Terrorsleep, The Bloodweb, The Many, The Old Widow, The Snapper, Tromphonyte, Undead Dragon, Usurper Archer, Zanakeph.",
      sellPrice: "5000",
      type: "armor",
    },
    {
      name: "Lavos Armor",
      image: "/images/tibia/items/armors/Lavos_Armor.gif",
      level: 60,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Fire +3%, Ice -3%",
      weight: 85,
      drop: "Annihilon, Fire Overlord, Terofar, Zavarash",
      sellPrice: "16000",
      type: "armor",
    },
    {
      name: "Leather Armor",
      image: "/images/tibia/items/armors/Leather_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 60,
      drop: "Dworc Fleshhunter, Dworc Venomsniper, Dworc Voodoomaster, Goblin, Goblin Assassin, Goblin Leader, Goblin Scavenger, Mountain Troll, Muglex Clan Assassin, Muglex Clan Footman, Muglex Clan Scavenger.",
      sellPrice: "12",
      type: "armor",
    },
    {
      name: "Leather Harness",
      image: "/images/tibia/items/armors/Leather_Harness.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29,
      drop: "Corym Charlatan, Corym Skirmisher, Corym Vanguard.",
      sellPrice: "750",
      type: "armor",
    },
    {
      name: "Leopard Armor",
      image: "/images/tibia/items/armors/Leopard_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 95,
      drop: "Assassin.",
      sellPrice: "1000",
      type: "armor",
    },
    {
      name: "Lightning Robe",
      image: "/images/tibia/items/armors/Lightning_Robe.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Energy +8%, Earth -8%",
      weight: 22.5,
      drop: "Anomaly, Elder Wyrm, High Voltage Elemental, Mad Mage, Prince Drazzak, Realityquake, Tyrn, Warlock.",
      sellPrice: "11000",
      type: "armor",
    },
    {
      name: "Lion Plate",
      image: "/images/tibia/items/armors/Lion_Plate.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      arm: 17,
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +6%",
      weight: 150,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Living Armor",
      image: "/images/tibia/items/armors/Living_Armor.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 13,
      bonus: "Magic Level +2",
      protection: "Earth +12%, Fire -5%",
      weight: 48,
      drop: "Plagueroot.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Magic Plate Armor",
      image: "/images/tibia/items/armors/Magic_Plate_Armor.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 17,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 85,
      drop: "Angry Demon, Demon, Ferumbras Mortal Shell, Grimeleech, Harpy, Hellflayer, Katex Blood Tongue, Rage Squid, Shulgrax, Terofar, The Source Of Corruption, Utua Stone Sting, Vexclaw, Yirkas Blue Scales.",
      sellPrice: "90000",
      type: "armor",
    },
    {
      name: "Magician's Robe",
      image: "/images/tibia/items/armors/Magician's_Robe.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "450",
      type: "armor",
    },
    {
      name: "Magma Coat",
      image: "/images/tibia/items/armors/Magma_Coat.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Fire +8%, Ice -8%",
      weight: 22.5,
      drop: "Burning Book, Crape Man, Dawnfire Asura, Diabolic Imp, Fire Overlord, Gazer Spectre, Girtablilu Warrior, Gorerilla, Guardian Of Tales, Hellfire Fighter, Hellhound, Insane Siren, King Zelos, Lava Golem, Manticore, Mazoran, Shaburak Lord, Shaburak Prince, Stalking Stalk, Urmahlullu the Weakened, The Baron from Below, The Flaming Orchid, The Sandking, Vulcongra, Weeper.",
      sellPrice: "11000",
      type: "armor",
    },
    {
      name: "Mammoth Fur Cape",
      image: "/images/tibia/items/armors/Mammoth_Fur_Cape.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 20,
      drop: "Barbaria, Barbarian Brutetamer, Ravenous Hunger, The Bloodtusk.",
      sellPrice: "6000",
      type: "armor",
    },
    {
      name: "Master Archer's Armor",
      image: "/images/tibia/items/armors/Master_Archer's_Armor.gif",
      level: 100,
      vocation: "Paladins",
      slots: 1,
      arm: 15,
      bonus: "Distance Fighting +3",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Midnight Tunic",
      image: "/images/tibia/items/armors/Midnight_Tunic.gif",
      level: 300,
      vocation: "Druids",
      slots: 1,
      arm: 16,
      bonus: "Magic Level +4",
      protection: "Physical +3%",
      weight: 27,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Molten Plate",
      image: "/images/tibia/items/armors/Molten_Plate.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Fire +7%, Ice -7%",
      weight: 75,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Mooh'tah Plate",
      image: "/images/tibia/items/armors/Mooh'tah_Plate.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 2,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 125,
      drop: "Minotaur Hunter, Minotaur Invader, Mooh'Tah Warrior, Bullwark.",
      sellPrice: "6000",
      type: "armor",
    },
    {
      name: "Mutated Skin Armor",
      image: "/images/tibia/items/armors/Mutated_Skin_Armor.gif",
      level: 270,
      vocation: "Paladins",
      slots: 2,
      arm: 17,
      bonus: "Distance Fighting +3",
      protection: "Physical +3%, Earth +8%",
      weight: 63,
      drop: "The Monster.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Mystical Dragon Robe",
      image: "/images/tibia/items/armors/Mystical_Dragon_Robe.gif",
      level: 300,
      vocation: "Druids",
      slots: 1,
      arm: 15,
      bonus: "Magic Level +3, Earth Magic Level +1",
      protection: "Fire +12%, Ice -6%",
      weight: 38,
      drop: "Treasure Chest (Dragon Hoard).",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Native Armor",
      image: "/images/tibia/items/armors/Native_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 80,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Noble Armor",
      image: "/images/tibia/items/armors/Noble_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 120,
      drop: "Ninguém.",
      sellPrice: "900",
      type: "armor",
    },
    {
      name: "Oceanborn Leviathan Armor",
      image: "/images/tibia/items/armors/Oceanborn_Leviathan_Armor.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 15,
      bonus: "Shielding +1",
      protection: "Ice +5%, Energy -5%",
      weight: 100,
      drop: "Lord of the Elements.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Old Cape",
      image: "/images/tibia/items/armors/Old_Cape.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Ornate Chestplate",
      image: "/images/tibia/items/armors/Ornate_Chestplate.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      arm: 16,
      bonus: "Shielding +3",
      protection: "Physical +8%",
      weight: 156,
      drop: "Jaul.",
      sellPrice: "60000",
      type: "armor",
    },
    {
      name: "Pair of Old Bracers",
      image: "/images/tibia/items/armors/Pair_of_Old_Bracers.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 2,
      drop: "Thaian.",
      sellPrice: "500",
      type: "armor",
    },
    {
      name: "Paladin Armor",
      image: "/images/tibia/items/armors/Paladin_Armor.gif",
      level: 0,
      vocation: "Paladins",
      slots: 1,
      arm: 12,
      bonus: "Distance Fighting +2",
      protection: "Nenhuma.",
      weight: 65,
      drop: "Annihilon, Bog Raider, Terofar, Zavarash.",
      sellPrice: "15000",
      type: "armor",
    },
    {
      name: "Pirate Shirt",
      image: "/images/tibia/items/armors/Pirate_Shirt.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 20,
      drop: "Pirate Buccaneer.",
      sellPrice: "500",
      type: "armor",
    },
    {
      name: "Plate Armor",
      image: "/images/tibia/items/armors/Plate_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 120,
      drop: "Ancient Lion Knight, Ancient Scarab, Behemoth, Black Knight, Bonebeast, Brutus Bloodbeard, Crystal Spider, Cursed Ape, Deadeye Devious, Destroyer, Dreadbeast, Giant Spider, High Templar Cobrass, Kongra, Lethal Lissy, Lizard Templar, Naga Warrior, Orc Cult Fanatic, Orc Leader, Orc Warlord, Pirate Buccaneer, Quara Pincher Scout, Ron The Ripper, Sacred Spider, The Snapper, Undead Elite Gladiator, Undead Gladiator, Valkyrie.",
      sellPrice: "400",
      type: "armor",
    },
    {
      name: "Prismatic Armor",
      image: "/images/tibia/items/armors/Prismatic_Armor.gif",
      level: 120,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 15,
      bonus: "Speed +15",
      protection: "Physical +5%",
      weight: 79,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "armor",
    },
    {
      name: "Ranger's Cloak",
      image: "/images/tibia/items/armors/Ranger's_Cloak.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 48,
      drop: "Ninguém.",
      sellPrice: "550",
      type: "armor",
    },
    {
      name: "Red Robe",
      image: "/images/tibia/items/armors/Red_Robe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 26,
      drop: "Adept of the Cult, Banshee, Pirate Ghost.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Red Tunic",
      image: "/images/tibia/items/armors/Red_Tunic.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 14,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Robe of the Ice Queen",
      image: "/images/tibia/items/armors/Robe_of_the_Ice_Queen.gif",
      level: 75,
      vocation: "Druids",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Ice +12%, Energy -12%",
      weight: 29.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Robe of the Underworld",
      image: "/images/tibia/items/armors/Robe_of_the_Underworld.gif",
      level: 100,
      vocation: "Sorcerers",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Death +12%, Holy -12%",
      weight: 31,
      drop: "Ancient Spawn of Morgathla.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Royal Draken Mail",
      image: "/images/tibia/items/armors/Royal_Draken_Mail.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 16,
      bonus: "Shielding +3",
      protection: "Physical +5%",
      weight: 130,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Royal Scale Robe",
      image: "/images/tibia/items/armors/Royal_Scale_Robe.gif",
      level: 100,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 12,
      bonus: "Magic Level +2",
      protection: "Fire +5%",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Scale Armor",
      image: "/images/tibia/items/armors/Scale_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 105,
      drop: "Barbarian Headsplitter, Barbarian Skullhunter, Dwarf Guard, Ghoul, Lizard Sentinel, Minotaur Archer, Mutated Human, Orc Rider, Pirate Cutthroat, Quara Predator Scout, Undead Prospector.",
      sellPrice: "75",
      type: "armor",
    },
    {
      name: "Simple Dress",
      image: "/images/tibia/items/armors/Simple_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Banshee, Zombie.",
      sellPrice: "50",
      type: "armor",
    },
    {
      name: "Skullcracker Armor",
      image: "/images/tibia/items/armors/Skullcracker_Armor.gif",
      level: 85,
      vocation: "Knights",
      slots: 0,
      arm: 14,
      bonus: "Nenhum.",
      protection: "Death +5%, Holy -5%",
      weight: 110,
      drop: "Annihilon, Grim Reaper, Juggernaut, Midnight Asura, Sopping Corpus, The Scourge of Oblivion, The Source Of Corruption, True Midnight Asura, Undead Dragon.",
      sellPrice: "18000",
      type: "armor",
    },
    {
      name: "Soulmantle",
      image: "/images/tibia/items/armors/Soulmantle.gif",
      level: 400,
      vocation: "Sorcerers",
      slots: 2,
      arm: 17,
      bonus: "Magic Level +4",
      protection: "Physical +4%",
      weight: 24,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Soulshell",
      image: "/images/tibia/items/armors/Soulshell.gif",
      level: 400,
      vocation: "Paladins",
      slots: 2,
      arm: 18,
      bonus: "Distance Fighting +4",
      protection: "Physical +3%, Fire +15%",
      weight: 123,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Soulshroud",
      image: "/images/tibia/items/armors/Soulshroud.gif",
      level: 400,
      vocation: "Druids",
      slots: 2,
      arm: 17,
      bonus: "Magic Level +4",
      protection: "Death +10%",
      weight: 24,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Spectral Dress",
      image: "/images/tibia/items/armors/Spectral_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "15000",
      type: "armor",
    },
    {
      name: "Spellweaver's Robe",
      image: "/images/tibia/items/armors/Spellweaver's_Robe.gif",
      level: 60,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Energy +10%, Earth -10%",
      weight: 23.5,
      drop: "Burning Gladiator, Draken Spellweaver, Ethershreck, Ghastly Dragon, Hatebreeder, Juvenile Bashmu, Lady Tenebris, Lloyd, Lokathmor, The Duke Of The Depths, Undead Dragon, Utua Stone Sting, Yirkas Blue Scales.",
      sellPrice: "12000",
      type: "armor",
    },
    {
      name: "Spirit Cloak",
      image: "/images/tibia/items/armors/Spirit_Cloak.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 26.5,
      drop: "Hellgorak, Nightstalker, Renegade Quara Hydromancer, Sea Serpent.",
      sellPrice: "350",
      type: "armor",
    },
    {
      name: "Spiritthorn Armor",
      image: "/images/tibia/items/armors/Spiritthorn_Armor.gif",
      level: 400,
      vocation: "Knights",
      slots: 2,
      arm: 20,
      bonus: "Sword Fighting +4, Club Fighting +4, Axe Fighting +4",
      protection: "Physical +13%",
      weight: 160,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Stoic Iks Chestplate",
      image: "/images/tibia/items/armors/Stoic_Iks_Chestplate.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      arm: 16,
      bonus: "Shielding +3",
      protection: "Physical +8%",
      weight: 142,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Stoic Iks Cuirass",
      image: "/images/tibia/items/armors/Stoic_Iks_Cuirass.gif",
      level: 250,
      vocation: "Druids",
      slots: 1,
      arm: 15,
      bonus: "Magic Level +4",
      protection: "Death +5%",
      weight: 44,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Studded Armor",
      image: "/images/tibia/items/armors/Studded_Armor.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 71,
      drop: "Brittle Skeleton, Crazed Dwarf, Dwarf, Dwarf Miner, Elf, Minotaur Bruiser, Minotaur Poacher, Orc, Scar Tribe Shaman, Troll Marauder, Woodling.",
      sellPrice: "25",
      type: "armor",
    },
    {
      name: "Summer Dress",
      image: "/images/tibia/items/armors/Summer_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Death +5%",
      weight: 10,
      drop: "Swan Maiden.",
      sellPrice: "1500",
      type: "armor",
    },
    {
      name: "Swamplair Armor",
      image: "/images/tibia/items/armors/Swamplair_Armor.gif",
      level: 60,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Earth +3%, Fire -3%",
      weight: 84.5,
      drop: "Cursed Book, Earth Overlord, Ink Blob, Paiz the Pauperizer, Serpent Spawn, The Enraged Thorn Knight, The Noxious Spawn.",
      sellPrice: "16000",
      type: "armor",
    },
    {
      name: "Swan Feather Cloak",
      image: "/images/tibia/items/armors/Swan_Feather_Cloak.gif",
      level: 60,
      vocation: "Todas",
      slots: 0,
      arm: 12,
      bonus: "Speed +10",
      protection: "Earth +12%, Energy +12%",
      weight: 4,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Terra Mantle",
      image: "/images/tibia/items/armors/Terra_Mantle.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Earth +8%, Fire -8%",
      weight: 22.5,
      drop: "Ancient Spawn of Morgathla, Askarak Lord, Askarak Prince, Banshee, Blightwalker, Choking Fear, Cursed Book, Drume, Earth Overlord, Esmeralda, Exotic Cave Spider, Fleshcrawler, Glooth Bandit, Glooth Brigand, Gorgo, Humongous Fungus, Hideous Fungus, Ink Blob, Medusa, Spiky Carnivor.",
      sellPrice: "11000",
      type: "armor",
    },
    {
      name: "The Rain Coat",
      image: "/images/tibia/items/armors/The_Rain_Coat.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Mephiles.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Thunderheart Cuirass",
      image: "/images/tibia/items/armors/Thunderheart_Cuirass.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Sword Fighting +4",
      protection: "Energy +8%, Earth -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Thunderheart Hauberk",
      image: "/images/tibia/items/armors/Thunderheart_Hauberk.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Axe Fighting +4",
      protection: "Energy +8%, Earth -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Thunderheart Platemail",
      image: "/images/tibia/items/armors/Thunderheart_Platemail.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      arm: 18,
      bonus: "Club Fighting +4",
      protection: "Energy +8%, Earth -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Thundermind Raiment",
      image: "/images/tibia/items/armors/Thundermind_Raiment.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 15,
      bonus: "Magic Level +4",
      protection: "Energy +8%, Earth -8%",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Thundersoul Tabard",
      image: "/images/tibia/items/armors/Thundersoul_Tabard.gif",
      level: 200,
      vocation: "Paladins",
      slots: 0,
      arm: 18,
      bonus: "Distance Fighting +4",
      protection: "Energy +8%, Earth -8%",
      weight: 145,
      drop: "Ninguém.",
      sellPrice: "100Silver Tokens",
      type: "armor",
    },
    {
      name: "Toga Mortis",
      image: "/images/tibia/items/armors/Toga_Mortis.gif",
      level: 220,
      vocation: "Sorcerers",
      slots: 1,
      arm: 16,
      bonus: "Magic Level +4",
      protection: "Death +6%",
      weight: 52,
      drop: "King Zelos.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Traditional Shirt",
      image: "/images/tibia/items/armors/Traditional_Shirt.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Tunic",
      image: "/images/tibia/items/armors/Tunic.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 15,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Unerring Dragon Scale Armor",
      image: "/images/tibia/items/armors/Unerring_Dragon_Scale_Armor.gif",
      level: 300,
      vocation: "Paladins",
      slots: 2,
      arm: 17,
      bonus: "Distance Fighting +3, Holy Magic Level +1",
      protection: "Energy +10%, Earth -5%",
      weight: 135,
      drop: "Treasure Chest (Dragon Hoard).",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Velvet Mantle",
      image: "/images/tibia/items/armors/Velvet_Mantle.gif",
      level: 75,
      vocation: "Sorcerers",
      slots: 0,
      arm: 12,
      bonus: "Nenhum.",
      protection: "Energy +12%, Earth -12%",
      weight: 28.5,
      drop: "Ferumbras Mortal Shell, The False God.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Voltage Armor",
      image: "/images/tibia/items/armors/Voltage_Armor.gif",
      level: 60,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 13,
      bonus: "Nenhum.",
      protection: "Energy +3%, Earth -3%",
      weight: 84.5,
      drop: "Energy Overlord, Hellgorak, Terofar, The Last Lore Keeper.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "White Dress",
      image: "/images/tibia/items/armors/White_Dress.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 24,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Windborn Colossus Armor",
      image: "/images/tibia/items/armors/Windborn_Colossus_Armor.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      arm: 15,
      bonus: "Club Fighting +2",
      protection: "Energy +5%, Earth -5%",
      weight: 120,
      drop: "Ninguém.",
      sellPrice: "50000",
      type: "armor",
    },
    {
      name: "Witchhunter's Coat",
      image: "/images/tibia/items/armors/Witchhunter's_Coat.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Death +2%, Holy -2%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Yalahari Armor",
      image: "/images/tibia/items/armors/Yalahari_Armor.gif",
      level: 80,
      vocation: "Knights",
      slots: 0,
      arm: 16,
      bonus: "Nenhum.",
      protection: "Death +3%",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "armor",
    },
    {
      name: "Zaoan Armor",
      image: "/images/tibia/items/armors/Zaoan_Armor.gif",
      level: 50,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 13,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 95.5,
      drop: "Draken Abomination, Draken Elite, Draken Warmaster, Ethershreck, Fazzrah, Ghastly Dragon, Lizard Chosen, Lizard High Guard, Lizard Legionnaire, Lizard Zaogun, Paiz the Pauperizer.",
      sellPrice: "14000",
      type: "armor",
    },
    {
      name: "Zaoan Robe",
      image: "/images/tibia/items/armors/Zaoan_Robe.gif",
      level: 60,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 11,
      bonus: "Nenhum.",
      protection: "Fire +10%, Ice -10%",
      weight: 24.5,
      drop: "Lizard Dragon Priest, Draken Spellweaver.",
      sellPrice: "12000",
      type: "armor",
    },
    //shield
    {
      name: "Adamant Shield",
      image: "/images/tibia/items/shields/Adamant_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 91,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Alicorn Quiver",
      image: "/images/tibia/items/shields/Alicorn_Quiver.gif",
      level: 400,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 20,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Amazon Shield",
      image: "/images/tibia/items/shields/Amazon_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 32,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 62,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Ancient Shield",
      image: "/images/tibia/items/shields/Ancient_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 27,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 61,
      drop: "Enslaved Dwarf, Enraged Soul, Ghost, Lich, Nightmare, Tarnished Spirit.",
      sellPrice: "900",
      type: "shield",
    },
    {
      name: "Aylie",
      image: "/images/tibia/items/shields/Aylie.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12.06,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Battle Shield",
      image: "/images/tibia/items/shields/Battle_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 23,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 62,
      drop: "Assassin, Cyclops, Cyclops Drone, Cyclops Smith, Demon Skeleton, Dwarf Guard, Elf Overseer, Foreman Kneebiter, Frost Giant, Frost Giantess, Gargoyle, Hellspawn, Minotaur Guard, Mutated Bat, Orc Rider, Pirate Buccaneer, The Horned Fox, War Golem.",
      sellPrice: "95",
      type: "shield",
    },
    {
      name: "Black Shield",
      image: "/images/tibia/items/shields/Black_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 18,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 42,
      drop: "Bruise Payne, Lost Basher, Lost Berserker, Magma Crawler, Mummy, Mutated Bat, Priestess, Sandstone Scorpion, Thanatursus, Werehyaena Shaman, Wiggler.",
      sellPrice: "800",
      type: "shield",
    },
    {
      name: "Blessed Shield",
      image: "/images/tibia/items/shields/Blessed_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 40,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 68,
      drop: "Ninguém",
      sellPrice: "650",
      type: "shield",
    },
    {
      name: "Blue Quiver",
      image: "/images/tibia/items/shields/Blue_Quiver.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 17,
      drop: "Ninguém.",
      sellPrice: "400",
      type: "shield",
    },
    {
      name: "Bone Shield",
      image: "/images/tibia/items/shields/Bone_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 20,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 55,
      drop: "Bonebeast, Chakoya Toolshaper, Chakoya Tribewarden, Chakoya Windcaller, Crypt Shambler, Dreadbeast, Dworc Fleshhunter, Necropharus, Ribstride, Tomb Servant.",
      sellPrice: "80",
      type: "shield",
    },
    {
      name: "Bonelord Shield",
      image: "/images/tibia/items/shields/Bonelord_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 28,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 47,
      drop: "Bonelord, Braindeath, Elder Bonelord.",
      sellPrice: "1200",
      type: "shield",
    },
    {
      name: "Brass Shield",
      image: "/images/tibia/items/shields/Brass_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 16,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 60,
      drop: "Bandit, Brittle Skeleton, Grand Master Oberon, Skeleton, Stalker, Wild Warrior.",
      sellPrice: "25",
      type: "shield",
    },
    {
      name: "Broken Wooden Shield",
      image: "/images/tibia/items/shields/Broken_Wooden_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 11,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 40,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Carapace Shield",
      image: "/images/tibia/items/shields/Carapace_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 36,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 55,
      drop: "Hive Overseer, Kollos, Spidris, Spidris Elite, Waspoid.",
      sellPrice: "32000",
      type: "shield",
    },
    {
      name: "Castle Shield",
      image: "/images/tibia/items/shields/Castle_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 28,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 49,
      drop: "Lich.",
      sellPrice: "5000",
      type: "shield",
    },
    {
      name: "Copper Shield",
      image: "/images/tibia/items/shields/Copper_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 19,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 63,
      drop: "Dwarf, Orc Warrior.",
      sellPrice: "50",
      type: "shield",
    },
    {
      name: "Crown Shield",
      image: "/images/tibia/items/shields/Crown_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 32,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 62,
      drop: "Annihilon, Demon Outcast, Flameborn, Hero, Lava Golem, Renegade Knight, Retching Horror, Soul-Broken Harbinger, Sulphider, Terofar, Varnished Diremaw, Vile Grandmaster.",
      sellPrice: "8000",
      type: "shield",
    },
    {
      name: "Dark Shield",
      image: "/images/tibia/items/shields/Dark_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 25,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Ancient Lion Knight, Grim Reaper, Pirate Corsair, Silencer, Thanatursus.",
      sellPrice: "400",
      type: "shield",
    },
    {
      name: "Death Gaze",
      image: "/images/tibia/items/shields/Death_Gaze.gif",
      level: 200,
      vocation: "Todas",
      slots: 1,
      def: 38,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 79,
      drop: "Ferumbras Mortal Shell, Ragiaz.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Demon Shield",
      image: "/images/tibia/items/shields/Demon_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 35,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 26,
      drop: "Angry Demon, Annihilon, Demon, Demon Outcast, Essence Of Malice, Ferumbras Mortal Shell, Golgordan, Grimeleech, Latrivan, Katex Blood Tongue, Rage Squid, Shulgrax, Terofar, Utua Stone Sting, Vexclaw, Yirkas Blue Scales, Zavarash.",
      sellPrice: "30000",
      type: "shield",
    },
    {
      name: "Dragon Shield",
      image: "/images/tibia/items/shields/Dragon_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 31,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 60,
      drop: "Dragon.",
      sellPrice: "4000",
      type: "shield",
    },
    {
      name: "Duality Doll",
      image: "/images/tibia/items/shields/Duality_Doll.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 21.05,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Dwarven Shield",
      image: "/images/tibia/items/shields/Dwarven_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 26,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 55,
      drop: "Ancient Lion Knight, Dwarf Soldier.",
      sellPrice: "100",
      type: "shield",
    },
    {
      name: "Eagle Shield",
      image: "/images/tibia/items/shields/Eagle_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 32,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 62,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Ectoplasmic Shield",
      image: "/images/tibia/items/shields/Ectoplasmic_Shield.gif",
      level: 180,
      vocation: "Knights",
      slots: 1,
      def: 37,
      bonus: "Axe Fighting +3, Club Fighting +3",
      protection: "Ice +7%, Fire -3%",
      weight: 58,
      drop: "Faceless Bane.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Eerie Song Book",
      image: "/images/tibia/items/shields/Eerie_Song_Book.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 13,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Eldritch Quiver",
      image: "/images/tibia/items/shields/Eldritch_Quiver.gif",
      level: 250,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 22,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Eldritch Shield",
      image: "/images/tibia/items/shields/Eldritch_Shield.gif",
      level: 270,
      vocation: "Knights",
      slots: 1,
      def: 39,
      bonus: "Nenhum.",
      protection: "Physical +4%",
      weight: 69,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Falcon Escutcheon",
      image: "/images/tibia/items/shields/Falcon_Escutcheon.gif",
      level: 300,
      vocation: "Knights and Paladins",
      slots: 1,
      def: 40,
      bonus: "Nenhum.",
      protection: "Physical +7%, Fire +15%",
      weight: 60,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Falcon Shield",
      image: "/images/tibia/items/shields/Falcon_Shield.gif",
      level: 300,
      vocation: "Knights and Paladins",
      slots: 1,
      def: 39,
      bonus: "Nenhum.",
      protection: "Physical +6%, Fire +10%",
      weight: 57,
      drop: "Grand Chaplain Gaunder, Grand Master Oberon.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Fiery Rainbow Shield",
      image: "/images/tibia/items/shields/Fiery_Rainbow_Shield.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      def: 36,
      bonus: "Shielding +3",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Gnome Shield",
      image: "/images/tibia/items/shields/Gnome_Shield.gif",
      level: 200,
      vocation: "Knights and Paladins",
      slots: 1,
      def: 38,
      bonus: "Shielding +2",
      protection: "Physical +6%, Energy +8%, Ice -2%",
      weight: 60,
      drop: "The Count Of The Core, The Duke Of The Depths, The Baron from Below.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Golden Blessed Shield",
      image: "/images/tibia/items/shields/Golden_Blessed_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 68,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Great Shield",
      image: "/images/tibia/items/shields/Great_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 38,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 84,
      drop: "Ferumbras Mortal Shell,Razzagorn, Soul of Dragonking Zyrtarch.",
      sellPrice: "480",
      type: "shield",
    },
    {
      name: "Griffin Shield",
      image: "/images/tibia/items/shields/Griffin_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 29,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 50,
      drop: "Ninguém",
      sellPrice: "3000",
      type: "shield",
    },
    {
      name: "Guardian Shield",
      image: "/images/tibia/items/shields/Guardian_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 30,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 55,
      drop: "Annihilon, Burning Book, Choking Fear, Cult Enforcer, Demon Skeleton, Diabolic Imp, Energy Elemental, Enslaved Dwarf, Fire Devil, Knowledge Elemental, Lost Berserker, Lost Exile, Lost Husher, Minotaur Invader, Mooh'Tah Warrior, Mutated Tiger, Spiky Carnivor, Terofar, Vicious Squire, Zavarash.",
      sellPrice: "2000",
      type: "shield",
    },
    {
      name: "Haunted Mirror Piece",
      image: "/images/tibia/items/shields/Haunted_Mirror_Piece.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 34,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 28,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Icy Rainbow Shield",
      image: "/images/tibia/items/shields/Icy_Rainbow_Shield.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      def: 39,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Journal Shield",
      image: "/images/tibia/items/shields/Journal_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 27,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Jungle Quiver",
      image: "/images/tibia/items/shields/Jungle_Quiver.gif",
      level: 150,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Lion Shield",
      image: "/images/tibia/items/shields/Lion_Shield.gif",
      level: 250,
      vocation: "Knights",
      slots: 1,
      def: 39,
      bonus: "Nenhum.",
      protection: "Physical +7%, Earth +10%",
      weight: 65,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Mastermind Shield",
      image: "/images/tibia/items/shields/Mastermind_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 37,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 57,
      drop: "Angry Demon, Annihilon, Demon, Falcon Knight, Falcon Paladin, Ferumbras Mortal Shell, Grand Canon Dominus, Hellflayer, Juggernaut, Latrivan, Preceptor Lazare, Rhindeer, Terofar, Vexclaw, Zavarash.",
      sellPrice: "50000",
      type: "shield",
    },
    {
      name: "Mathmaster Shield",
      image: "/images/tibia/items/shields/Mathmaster_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.14,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Meat Shield",
      image: "/images/tibia/items/shields/Meat_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Doctor Perhaps.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Medusa Shield",
      image: "/images/tibia/items/shields/Medusa_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 33,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 58,
      drop: "Gorgo, Hydra, Medusa, The Enraged Thorn Knight, The Many, The Souldespoiler.",
      sellPrice: "9000",
      type: "shield",
    },
    {
      name: "Mino Shield",
      image: "/images/tibia/items/shields/Mino_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 31,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 65,
      drop: "Minotaur Amazon, Minotaur Cult Follower, Minotaur Hunter, Thanatursus, The False God, Usurper Archer.",
      sellPrice: "3000",
      type: "shield",
    },
    {
      name: "Morshabaal's Mask",
      image: "/images/tibia/items/shields/Morshabaal's_Mask.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus:
        "Fire Magic Level +1, Ice Magic Level +1, Earth Magic Level +1, Energy Magic Level +1",
      protection: "Nenhuma.",
      weight: 41,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Naga Quiver",
      image: "/images/tibia/items/shields/Naga_Quiver.gif",
      level: 250,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Ice +2%",
      weight: 19,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Necromancer Shield",
      image: "/images/tibia/items/shields/Necromancer_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 37,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Nightmare Shield",
      image: "/images/tibia/items/shields/Nightmare_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 37,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Norse Shield",
      image: "/images/tibia/items/shields/Norse_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 28,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 41,
      drop: "Frost Giant, Frost Giantess.",
      sellPrice: "1500",
      type: "shield",
    },
    {
      name: "Ornamented Shield",
      image: "/images/tibia/items/shields/Ornamented_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 22,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 67,
      drop: "Ninguém",
      sellPrice: "1500",
      type: "shield",
    },
    {
      name: "Ornate Shield",
      image: "/images/tibia/items/shields/Ornate_Shield.gif",
      level: 130,
      vocation: "Knights",
      slots: 1,
      def: 36,
      bonus: "Nenhum.",
      protection: "Physical +5%",
      weight: 71,
      drop: "Jaul,Tanjis.",
      sellPrice: "42000",
      type: "shield",
    },
    {
      name: "Phoenix Shield",
      image: "/images/tibia/items/shields/Phoenix_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 34,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Ferumbras Mortal Shell, Mahrdis, The Time Guardian.",
      sellPrice: "16000",
      type: "shield",
    },
    {
      name: "Plate Shield",
      image: "/images/tibia/items/shields/Plate_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 17,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 65,
      drop: "Assassin, Cyclops, Cyclops Drone, Cyclops Smith, Crazed Dwarf, Elf, Gladiator, Gozzler, Iks Aucar, Juvenile Cyclops, Minotaur, Minotaur Bruiser, Minotaur Cult Follower, Mutated Rat, Orc Cult Fanatic, Orc Leader, Pirate Marauder, Renegade Orc, Rorc, Scar Tribe Warrior, Tarantula, Tortoise, Wailing Widow, War Golem, Werewolf.",
      sellPrice: "45",
      type: "shield",
    },
    {
      name: "Prismatic Shield",
      image: "/images/tibia/items/shields/Prismatic_Shield.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      def: 37,
      bonus: "Shielding +2",
      protection: "Physical +4%",
      weight: 72,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "shield",
    },
    {
      name: "Quiver",
      image: "/images/tibia/items/shields/Quiver.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 17,
      drop: "Ninguém.",
      sellPrice: "400",
      type: "shield",
    },
    {
      name: "Rainbow Shield",
      image: "/images/tibia/items/shields/Rainbow_Shield.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      def: 30,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Red Quiver",
      image: "/images/tibia/items/shields/Red_Quiver.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 17,
      drop: "Ninguém.",
      sellPrice: "400",
      type: "shield",
    },
    {
      name: "Rift Shield",
      image: "/images/tibia/items/shields/Rift_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 37,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 65,
      drop: "Ferumbras Mortal Shell, Grimeleech, Hellflayer, Shulgrax, The Scourge of Oblivion, Vexclaw.",
      sellPrice: "50000",
      type: "shield",
    },
    {
      name: "Rose Shield",
      image: "/images/tibia/items/shields/Rose_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 27,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Ninguém",
      sellPrice: "49",
      type: "shield",
    },
    {
      name: "Runic Ice Shield",
      image: "/images/tibia/items/shields/Runic_Ice_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 31,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 49,
      drop: "Melting Frozen Horror.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Salamander Shield",
      image: "/images/tibia/items/shields/Salamander_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 26,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 59,
      drop: "High Templar Cobrass, Lizard Templar.",
      sellPrice: "280",
      type: "shield",
    },
    {
      name: "Scarab Shield",
      image: "/images/tibia/items/shields/Scarab_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 25,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 47,
      drop: "Ancient Scarab, Ancient Spawn of Morgathla, Fleshcrawler, The Ravager.",
      sellPrice: "2000",
      type: "shield",
    },
    {
      name: "Sentinel Shield",
      image: "/images/tibia/items/shields/Sentinel_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 22,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 49,
      drop: "Lizard Sentinel.",
      sellPrice: "120",
      type: "shield",
    },
    {
      name: "Shield of Care",
      image: "/images/tibia/items/shields/Shield_of_Care.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 17,
      drop: "Monstor.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Shield of Corruption",
      image: "/images/tibia/items/shields/Shield_of_Corruption.gif",
      level: 80,
      vocation: "Knights",
      slots: 1,
      def: 36,
      bonus: "Sword Fighting +3",
      protection: "Nenhuma.",
      weight: 49,
      drop: "Draken Abomination, Essence Of Malice, Soul of Dragonking Zyrtarch, The Souldespoiler.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Shield of Destiny",
      image: "/images/tibia/items/shields/Shield_of_Destiny.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 68,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Shield of Endless Search",
      image: "/images/tibia/items/shields/Shield_of_Endless_Search.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 11,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Shield of Honour",
      image: "/images/tibia/items/shields/Shield_of_Honour.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 33,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 54,
      drop: "Ninguém",
      sellPrice: "520",
      type: "shield",
    },
    {
      name: "Shield of the White Knight",
      image: "/images/tibia/items/shields/Shield_of_the_White_Knight.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Mephiles.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Soulbastion",
      image: "/images/tibia/items/shields/Soulbastion.gif",
      level: 400,
      vocation: "Knights",
      slots: 1,
      def: 42,
      bonus: "Nenhum.",
      protection: "Physical +10%, Death +10%",
      weight: 58,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Sparking Rainbow Shield",
      image: "/images/tibia/items/shields/Sparking_Rainbow_Shield.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      def: 36,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Spike Shield",
      image: "/images/tibia/items/shields/Spike_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 28,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 59,
      drop: "Corym Charlatan, Corym Skirmisher, Corym Vanguard.",
      sellPrice: "0",
      type: "shield",
    },
    {
      name: "Steel Shield",
      image: "/images/tibia/items/shields/Steel_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 21,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Assassin, Bonelord, Braindeath, Crypt Defiler, Dragon, Elder Bonelord, Gladiator, Grave Robber,  Nomad, Pirate Cutthroat, Plaguesmith, Wild Warrior.",
      sellPrice: "80",
      type: "shield",
    },
    {
      name: "Strange Good Night Songs",
      image: "/images/tibia/items/shields/Strange_Good_Night_Songs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 13,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Studded Shield",
      image: "/images/tibia/items/shields/Studded_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 15,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 58,
      drop: "Ancient Lion Knight, Orc, Woodling.",
      sellPrice: "16",
      type: "shield",
    },
    {
      name: "Tempest Shield",
      image: "/images/tibia/items/shields/Tempest_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 36,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 51,
      drop: "Mazoran.",
      sellPrice: "35000",
      type: "shield",
    },
    {
      name: "Terran Rainbow Shield",
      image: "/images/tibia/items/shields/Terran_Rainbow_Shield.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      def: 37,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "The Dragon Spirit",
      image: "/images/tibia/items/shields/The_Dragon_Spirit.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 26.02,
      drop: "Ninguém.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "The Shield Nevermourn",
      image: "/images/tibia/items/shields/The_Shield_Nevermourn.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 25,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Dirtbeard.",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Tortoise Shield",
      image: "/images/tibia/items/shields/Tortoise_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 26,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Tortoise.",
      sellPrice: "150",
      type: "shield",
    },
    {
      name: "Tower Shield",
      image: "/images/tibia/items/shields/Tower_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 32,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 82,
      drop: "Albino Dragon, Annihilon, Armadile, Boar Man, Dragon Lord, Draken Warmaster, Enslaved Dwarf, Esmeralda, Eternal Guardian, Frost Dragon, Lizard High Guard, Lizard Zaogun, Lizard Chosen, Lost Berserker, Lost Exile, Lost Husher, Lost Soul, Mutated Rat, Retching Horror, Serpent Spawn, The Noxious Spawn.",
      sellPrice: "8000",
      type: "shield",
    },
    {
      name: "Tusk Shield",
      image: "/images/tibia/items/shields/Tusk_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 27,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 69,
      drop: "Clomp, Elephant, Mammoth, Stone Rhino, Terrified Elephant, The Bloodtusk.",
      sellPrice: "850",
      type: "shield",
    },
    {
      name: "Vampire Shield",
      image: "/images/tibia/items/shields/Vampire_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 34,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 38,
      drop: "Arthei, Boreth, Lersatio, Nightfiend, The Count, The Weakened Count, Vampire, Vampire Viscount.",
      sellPrice: "15000",
      type: "shield",
    },
    {
      name: "Viking Shield",
      image: "/images/tibia/items/shields/Viking_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 22,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 66,
      drop: "Ninguém",
      sellPrice: "85",
      type: "shield",
    },
    {
      name: "Warrior's Shield",
      image: "/images/tibia/items/shields/Warrior's_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      def: 33,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 75,
      drop: "Ancient Spawn of Morgathla, Crape Man, Deathling Scout, Deathling Spellsinger, Deepling Elite, Deepling Warrior, Thanatursus, The Sandking, Tremendous Tyrant.",
      sellPrice: "9000",
      type: "shield",
    },
    {
      name: "Wooden Shield",
      image: "/images/tibia/items/shields/Wooden_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 14,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 40,
      drop: "Big Boss Trolliver, Frost Troll, Island Troll, Troll, Troll Champion, Troll Marauder, Young Troll.",
      sellPrice: "5",
      type: "shield",
    },
    {
      name: "Demon Powered Shield",
      image: "/images/tibia/items/shields/Demon_Powered_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 50,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 50,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    {
      name: "Mercenary Shield",
      image: "/images/tibia/items/shields/Mercenary_Shield.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      def: 24,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 52,
      drop: "Ninguém",
      sellPrice: "",
      type: "shield",
    },
    //spellbook
    {
      name: "Spellbook of Enlightenment",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Enlightenment.gif",
      level: 30,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 18,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 19.5,
      drop: "Blood Hand, Frost Dragon Hatchling, Golden Servant, Golden Servant Replica, Iks Ahpututu, Iks Pututu, Jungle Moa, Lloyd, Mutated Rat, Omnivora, Shadow Pupil, Two-Headed Turtle.",
      sellPrice: "4000",
      type: "spellbook",
    },
    {
      name: "Spellscroll of Prophecies",
      image: "/images/tibia/items/spellbooks/Spellscroll_of_Prophecies.gif",
      level: 70,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 12,
      bonus: "Magic Level +3",
      protection: "Nenhuma.",
      weight: 26.5,
      drop: "Ferumbras Mortal Shell, Hellgorak, Horadron, The Time Guardian.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Spellbook of Ancient Arcana",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Ancient_Arcana.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 19,
      bonus: "Magic Level +4",
      protection: "Death +5%",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Spellbook of Warding",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Warding.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 22,
      bonus: "Magic Level +1",
      protection: "Nenhuma.",
      weight: 21,
      drop: "Anomaly, Blood Priest, Dark Carnisylvan, Energuardian Of Tales, Eradicator, Gorzindel, Guardian Of Tales, Hellgorak, High Voltage Elemental, Horadron, Lloyd, Lady Tenebris, Mad Mage, Massive Energy Elemental, Necromancer, Plagirath, Tremendous Tyrant, Usurper Commander, Varnished Diremaw.",
      sellPrice: "8000",
      type: "spellbook",
    },
    {
      name: "Spellbook of Mind Control",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Mind_Control.gif",
      level: 50,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 16,
      bonus: "Magic Level +2",
      protection: "Nenhuma.",
      weight: 24.5,
      drop: "Blood Priest, Carnivostrich, Choking Fear, Dawnfire Asura, Ferumbras Mortal Shell, Hellflayer, Hellgorak, Horadron, Infernalist, Lava Golem, Midnight Asura, Nighthunter, Raging Mage, Serpent Spawn, Soul-Broken Harbinger, Soul of Dragonking Zyrtarch, The Noxious Spawn, True Midnight Asura, Yirkas Blue Scales.",
      sellPrice: "13000",
      type: "spellbook",
    },
    {
      name: "Spellbook of Lost Souls",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Lost_Souls.gif",
      level: 60,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 20,
      bonus: "Magic Level +2",
      protection: "Nenhuma.",
      weight: 25.6,
      drop: "Eradicator, Ferumbras Mortal Shell, Hellgorak, Horadron, Lady Tenebris, The Souldespoiler.",
      sellPrice: "19000",
      type: "spellbook",
    },
    {
      name: "Spellbook",
      image: "/images/tibia/items/spellbooks/Spellbook.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 14,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Banshee, Bonelord, Braindeath, Dark Carnisylvan, Death Priest, Dwarf Geomancer, Elder Bonelord, Lich.",
      sellPrice: "150",
      type: "spellbook",
    },
    {
      name: "Spellbook of Dark Mysteries",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Dark_Mysteries.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 16,
      bonus: "Magic Level +3",
      protection: "Nenhuma.",
      weight: 28.5,
      drop: "Ferumbras Mortal Shell, Hellgorak, Horadron, Prince Drazzak.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Snake God's Wristguard",
      image: "/images/tibia/items/spellbooks/Snake_God's_Wristguard.gif",
      level: 100,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 14,
      bonus: "Magic Level +3",
      protection: "Nenhuma.",
      weight: 28,
      drop: "Draken Abomination, Draken Elite.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Depth Scutum",
      image: "/images/tibia/items/spellbooks/Depth_Scutum.gif",
      level: 120,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 25,
      bonus: "Magic Level +2",
      protection: "Nenhuma.",
      weight: 30,
      drop: "Ancient Spawn of Morgathla, Obujos, Reflection Of Obujos.",
      sellPrice: "36000",
      type: "spellbook",
    },
    {
      name: "Spellbook of Vigilance",
      image: "/images/tibia/items/spellbooks/Spellbook_of_Vigilance.gif",
      level: 130,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 20,
      bonus: "Magic Level +3",
      protection: "Earth +3%, Fire -3%",
      weight: 27,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "spellbook",
    },
    {
      name: "Crude Umbral Spellbook",
      image: "/images/tibia/items/spellbooks/Crude_Umbral_Spellbook.gif",
      level: 75,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 14,
      bonus: "Magic Level +1",
      protection: "Earth +2%, Energy +2%, Fire +2%, Ice +2%",
      weight: 40,
      drop: "Lady Tenebris.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Umbral Spellbook",
      image: "/images/tibia/items/spellbooks/Umbral_Spellbook.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 16,
      bonus: "Magic Level +2",
      protection: "Earth +3%, Energy +3%, Fire +3%, Ice +3%",
      weight: 35,
      drop: "Lady Tenebris.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Umbral Master Spellbook",
      image: "/images/tibia/items/spellbooks/Umbral_Master_Spellbook.gif",
      level: 250,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 20,
      bonus: "Magic Level +4",
      protection: "Earth +5%, Energy +5%, Fire +5%, Ice +5%",
      weight: 30,
      drop: "Ninguém",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Spellbook of the Novice",
      image: "/images/tibia/items/spellbooks/Spellbook_of_the_Novice.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      def: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 14,
      drop: "Ninguém.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Book of Lies",
      image: "/images/tibia/items/spellbooks/Book_of_Lies.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 18,
      bonus: "Magic Level +4",
      protection: "Nenhuma.",
      weight: 24,
      drop: "Lady Tenebris, Zamulosh.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Wooden Spellbook",
      image: "/images/tibia/items/spellbooks/Wooden_Spellbook.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 16,
      bonus: "Magic Level +2",
      protection: "Earth +5%",
      weight: 12,
      drop: "Arctic Faun, Boogy, Dark Faun, Noxious Ripptor, Pixie, Raxias, Ravenous Hunger, Young Goanna.",
      sellPrice: "12000",
      type: "spellbook",
    },
    {
      name: "Brain in a Jar",
      image: "/images/tibia/items/spellbooks/Brain_in_a_Jar.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 19,
      bonus: "Magic Level +3",
      protection: "Earth +7%",
      weight: 27,
      drop: "Maxxenius.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Shoulder Plate",
      image: "/images/tibia/items/spellbooks/Shoulder_Plate.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 26,
      bonus: "Magic Level +3",
      protection: "Earth +6%, Physical +2%",
      weight: 35,
      drop: "Malofur Mangrinder.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Spirit Guide",
      image: "/images/tibia/items/spellbooks/Spirit_Guide.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 17,
      bonus: "Magic Level +4",
      protection: "Energy +6%",
      weight: 18,
      drop: "Faceless Bane.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Lion Spellbook",
      image: "/images/tibia/items/spellbooks/Lion_Spellbook.gif",
      level: 220,
      vocation: "Sorcerers and Druids",
      slots: 1,
      def: 20,
      bonus: "Magic Level +4",
      protection: "Physical +3%, Ice +7%",
      weight: 25,
      drop: "Ancient Lion Knight, Drume",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Eldritch Tome",
      image: "/images/tibia/items/spellbooks/Eldritch_Tome.gif",
      level: 300,
      vocation: "Druids",
      slots: 1,
      def: 21,
      bonus: "Magic Level +4, Earth Magic Level +1",
      protection: "Fire +6%",
      weight: 26,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Eldritch Folio",
      image: "/images/tibia/items/spellbooks/Eldritch_Folio.gif",
      level: 300,
      vocation: "Sorcerers",
      slots: 1,
      def: 21,
      bonus: "Magic Level +4, Death Magic Level +1",
      protection: "Earth +6%",
      weight: 28,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Arboreal Tome",
      image: "/images/tibia/items/spellbooks/Arboreal_Tome.gif",
      level: 400,
      vocation: "Druids",
      slots: 1,
      def: 22,
      bonus: "Magic Level +5, Ice Magic Level +1, Healing Magic Level +1",
      protection: "Physical +4%, Energy +6%",
      weight: 23,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Arcanomancer Folio",
      image: "/images/tibia/items/spellbooks/Arcanomancer_Folio.gif",
      level: 400,
      vocation: "Sorcerers",
      slots: 1,
      def: 22,
      bonus: "Magic Level +5, Fire Magic Level +1, Energy Magic Level +1",
      protection: "Physical +3%, Fire +8%",
      weight: 26,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "spellbook",
    },
    {
      name: "Alchemist's Notepad",
      image: "/images/tibia/items/spellbooks/Alchemist's_Notepad.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 1,
      def: 20,
      bonus: "Magic Level +2, Fire Magic Level +3, Energy Magic Level +3",
      protection: "Death +5%",
      weight: 13,
      drop: "The Monster.",
      sellPrice: "",
      type: "spellbook",
    },
    //legs
    {
      name: "Alloy Legs",
      image: "/images/tibia/items/legs/Alloy_Legs.gif",
      level: 60,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 45,
      drop: "Control Tower, Metal Gargoyle, Yirkas Blue Scales.",
      sellPrice: "11000",
      type: "legs",
    },
    {
      name: "Bast Legs",
      image: "/images/tibia/items/legs/Bast_Legs.gif",
      level: 150,
      vocation: "Paladins",
      slots: 0,
      arm: 8,
      bonus: "Distance Fighting +2",
      protection: "Energy +4%",
      weight: 64,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Bast Skirt",
      image: "/images/tibia/items/legs/Bast_Skirt.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.5,
      drop: "Dworc Venomsniper.",
      sellPrice: "750",
      type: "legs",
    },
    {
      name: "Blue Legs",
      image: "/images/tibia/items/legs/Blue_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "15000",
      type: "legs",
    },
    {
      name: "Brass Legs",
      image: "/images/tibia/items/legs/Brass_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 5,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 38,
      drop: "Black Knight, Blood Crab, Deepsea Blood Crab, Orc Cult Fanatic, Orc Leader, Stalker, Tarantula, Undead Gladiator.",
      sellPrice: "49",
      type: "legs",
    },
    {
      name: "Broken Iks Faulds",
      image: "/images/tibia/items/legs/Broken_Iks_Faulds.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 15,
      drop: "Ahau, Iks Aucar, Iks Pututu.",
      sellPrice: "530",
      type: "legs",
    },
    {
      name: "Chain Legs",
      image: "/images/tibia/items/legs/Chain_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "The Horned Fox.",
      sellPrice: "25",
      type: "legs",
    },
    {
      name: "Chocolatey Dragon Scale Legs",
      image: "/images/tibia/items/legs/Chocolatey_Dragon_Scale_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Crown Legs",
      image: "/images/tibia/items/legs/Crown_Legs.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 65,
      drop: "Hellgorak, Hero, Horadron, Renegade Knight, Vile Grandmaster.",
      sellPrice: "12000",
      type: "legs",
    },
    {
      name: "Dawnfire Pantaloons",
      image: "/images/tibia/items/legs/Dawnfire_Pantaloons.gif",
      level: 300,
      vocation: "Sorcerers",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Physical +3%",
      weight: 30,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Demon Legs",
      image: "/images/tibia/items/legs/Demon_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "84",
      type: "legs",
    },
    {
      name: "Depth Ocrea",
      image: "/images/tibia/items/legs/Depth_Ocrea.gif",
      level: 130,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Mana Drain +15%",
      weight: 48,
      drop: "Tanjis.",
      sellPrice: "16000",
      type: "legs",
    },
    {
      name: "Dragon Scale Legs",
      image: "/images/tibia/items/legs/Dragon_Scale_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 10,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 48,
      drop: "Ninguém.",
      sellPrice: "180",
      type: "legs",
    },
    {
      name: "Dwarven Legs",
      image: "/images/tibia/items/legs/Dwarven_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Physical +3%",
      weight: 49,
      drop: "Ninguém.",
      sellPrice: "40000",
      type: "legs",
    },
    {
      name: "Eldritch Breeches",
      image: "/images/tibia/items/legs/Eldritch_Breeches.gif",
      level: 250,
      vocation: "Paladins",
      slots: 0,
      arm: 9,
      bonus: "Distance Fighting +2",
      protection: "Physical +2%, Holy +7%",
      weight: 69,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Elven Legs",
      image: "/images/tibia/items/legs/Elven_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 33,
      drop: "Ravenous Hunger.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Exotic Legs",
      image: "/images/tibia/items/legs/Exotic_Legs.gif",
      level: 130,
      vocation: "Knights",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Physical +4%",
      weight: 72,
      drop: "Ratmiral Blackwhiskers",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Fabulous Legs",
      image: "/images/tibia/items/legs/Fabulous_Legs.gif",
      level: 225,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 9,
      bonus:
        "Distance Fighting +2, Sword Fighting +2, Club Fighting +2, Axe Fighting +2",
      protection: "Physical +4%, Fire +2%",
      weight: 55,
      drop: "The Unwelcome, The Pale Worm",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Falcon Greaves",
      image: "/images/tibia/items/legs/Falcon_Greaves.gif",
      level: 300,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 10,
      bonus:
        "Distance Fighting +3, Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +7%, Ice +7%",
      weight: 36,
      drop: "Grand Master Oberon, Preceptor Lazare.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Gill Legs",
      image: "/images/tibia/items/legs/Gill_Legs.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 7,
      bonus: "Magic Level +1",
      protection: "Earth +8%, Fire -8%",
      weight: 28,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "legs",
    },
    {
      name: "Glacier Kilt",
      image: "/images/tibia/items/legs/Glacier_Kilt.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Ice +6%, Energy -6%",
      weight: 19,
      drop: "Cave Chimera, Crape Man, Ferumbras Mortal Shell, Grim Reaper, Icecold Book, Sea Serpent, Seacrest Serpent, Srezz Yellow Eyes, Tarbaz, The Bloodweb, Utua Stone Sting, Varnished Diremaw.",
      sellPrice: "11000",
      type: "legs",
    },
    {
      name: "Gnome Legs",
      image: "/images/tibia/items/legs/Gnome_Legs.gif",
      level: 200,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 9,
      bonus: "Magic Level +2",
      protection: "Energy +7%, Ice -2%",
      weight: 25,
      drop: "The Duke Of The Depths.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Golden Legs",
      image: "/images/tibia/items/legs/Golden_Legs.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 56,
      drop: "Angry Demon, Dark Torturer, Demon, Fury, Hellgorak, Juggernaut, Latrivan, Realityquake, Vexclaw, World Devourer.",
      sellPrice: "30000",
      type: "legs",
    },
    {
      name: "Grasshopper Legs",
      image: "/images/tibia/items/legs/Grasshopper_Legs.gif",
      level: 75,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 32,
      drop: "Crawler, Spitter, The Sandking, Waspoid.",
      sellPrice: "15000",
      type: "legs",
    },
    {
      name: "Green Demon Legs",
      image: "/images/tibia/items/legs/Green_Demon_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 9,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Icy Culottes",
      image: "/images/tibia/items/legs/Icy_Culottes.gif",
      level: 0,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Ice +8%",
      weight: 15,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Knight Legs",
      image: "/images/tibia/items/legs/Knight_Legs.gif",
      level: 0,
      vocation: "Knights and Paladins",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 70,
      drop: "Black Knight, Cliff Strider, Cult Enforcer, Crystal Spider, Flameborn, Foam Stalker, Giant Spider, Gore Horn, Hellgorak, Hellspawn, Hide, Horadron, Lost Basher, Menacing Carnivor, Mooh'Tah Warrior, Nightmare, Orewalker, Plaguesmith, The Bloodweb, The Old Widow, Tormentor, Two-Headed Turtle, Usurper Knight, Usurper Warlock, Vicious Squire.",
      sellPrice: "5000",
      type: "legs",
    },
    {
      name: "Leaf Legs",
      image: "/images/tibia/items/legs/Leaf_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Arctic Faun, Dark Faun, Dryad, Faun.",
      sellPrice: "500",
      type: "legs",
    },
    {
      name: "Leather Legs",
      image: "/images/tibia/items/legs/Leather_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 18,
      drop: "Bandit, Crazed Dwarf, Dwarf, Dwarf Miner, Gang Member, Minotaur Mage, Poacher, Smuggler, Usurper Knight.",
      sellPrice: "9",
      type: "legs",
    },
    {
      name: "Lederhosen",
      image: "/images/tibia/items/legs/Lederhosen.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Lightning Legs",
      image: "/images/tibia/items/legs/Lightning_Legs.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Energy +6%, Earth -6%",
      weight: 19,
      drop: "Anomaly, Black Knight, Burning Gladiator, Crape Man, Elder Wyrm, Energuardian Of Tales, Essence Of Malice, Ferumbras Mortal Shell, High Voltage Elemental, Horadron, Juvenile Bashmu, Lloyd, Massive Energy Elemental, Shulgrax, Tremendous Tyrant, Outburst, Urmahlullu the Weakened, Utua Stone Sting, Yielothax.",
      sellPrice: "11000",
      type: "legs",
    },
    {
      name: "Magma Legs",
      image: "/images/tibia/items/legs/Magma_Legs.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Fire +6%, Ice -6%",
      weight: 19,
      drop: "Feral Sphinx, Ferumbras Mortal Shell, Grimeleech, Guardian Of Tales, Hellfire Fighter, Hellflayer, Hellgorak, Hellhound, Insane Siren, Manticore, Massive Fire Elemental, Mazoran, Shaburak Demon, Sphinx, The Lily Of Night, The Time Guardian, Usurper Knight, Weeper, Yirkas Blue Scales.",
      sellPrice: "11000",
      type: "legs",
    },
    {
      name: "Mammoth Fur Shorts",
      image: "/images/tibia/items/legs/Mammoth_Fur_Shorts.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1.5,
      drop: "Barbarian Brutetamer.",
      sellPrice: "850",
      type: "legs",
    },
    {
      name: "Midnight Sarong",
      image: "/images/tibia/items/legs/Midnight_Sarong.gif",
      level: 250,
      vocation: "Druids",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Ice +7%",
      weight: 31,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Mutant Bone Kilt",
      image: "/images/tibia/items/legs/Mutant_Bone_Kilt.gif",
      level: 300,
      vocation: "Druids",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Physical +3%",
      weight: 35,
      drop: "The Monster.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Mutated Skin Legs",
      image: "/images/tibia/items/legs/Mutated_Skin_Legs.gif",
      level: 270,
      vocation: "Paladins",
      slots: 0,
      arm: 9,
      bonus: "Distance Fighting +2",
      protection: "Physical +4%, Earth +4%",
      weight: 52,
      drop: "The Monster.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Ornate Legs",
      image: "/images/tibia/items/legs/Ornate_Legs.gif",
      level: 185,
      vocation: "Knights",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Physical +5%",
      weight: 77,
      drop: "Jaul, Obujos, Reflection Of Obujos.",
      sellPrice: "40000",
      type: "legs",
    },
    {
      name: "Pirate Knee Breeches",
      image: "/images/tibia/items/legs/Pirate_Knee_Breeches.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Pirate Cutthroat.",
      sellPrice: "200",
      type: "legs",
    },
    {
      name: "Plate Legs",
      image: "/images/tibia/items/legs/Plate_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 7,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 50,
      drop: "Bibby Bloodbath, Bog Raider, Dragon, Giant Spider, Ghastly Dragon, Orc Cult Fanatic, Orc Leader, Orc Warlord, Renegade Orc, Sea Serpent, The Snapper, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "115",
      type: "legs",
    },
    {
      name: "Prismatic Legs",
      image: "/images/tibia/items/legs/Prismatic_Legs.gif",
      level: 150,
      vocation: "Paladins",
      slots: 0,
      arm: 8,
      bonus: "Distance Fighting +2",
      protection: "Physical +3%",
      weight: 71,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "legs",
    },
    {
      name: "Ranger Legs",
      image: "/images/tibia/items/legs/Ranger_Legs.gif",
      level: 0,
      vocation: "Paladins",
      slots: 0,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Sanguine Greaves",
      image: "/images/tibia/items/legs/Sanguine_Greaves.gif",
      level: 500,
      vocation: "Paladins",
      slots: 0,
      arm: 11,
      bonus: "Distance Fighting +4, Holy Magic Level +1",
      protection: "Protection Physical +7%, Energy +9%",
      weight: 32,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Sanguine Legs",
      image: "/images/tibia/items/legs/Sanguine_Legs.gif",
      level: 500,
      vocation: "Knights",
      slots: 0,
      arm: 12,
      bonus: "Sword Fighting +4, Axe Fighting +4, Club Fighting +4",
      protection: "Protection Physical +9%, Death +6%",
      weight: 38,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Soulful Legs",
      image: "/images/tibia/items/legs/Soulful_Legs.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +1",
      protection: "Holy +8%",
      weight: 38,
      drop: "The Unwelcome, The Pale Worm.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Soulshanks",
      image: "/images/tibia/items/legs/Soulshanks.gif",
      level: 400,
      vocation: "Sorcerers",
      slots: 0,
      arm: 10,
      bonus: "Magic Level +3",
      protection: "Death +10%",
      weight: 38,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Soulstrider",
      image: "/images/tibia/items/legs/Soulstrider.gif",
      level: 400,
      vocation: "Druids",
      slots: 0,
      arm: 10,
      bonus: "Magic Level +3",
      protection: "Fire +10%",
      weight: 38,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Stitched Mutant Hide Legs",
      image: "/images/tibia/items/legs/Stitched_Mutant_Hide_Legs.gif",
      level: 270,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Sword Fighting +2, Axe Fighting +2, Club Fighting +2",
      protection: "Physical +5%, Earth +5%",
      weight: 34,
      drop: "The Monster.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Stoic Iks Culet",
      image: "/images/tibia/items/legs/Stoic_Iks_Culet.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      arm: 9,
      bonus: "Sword Fighting +2, Axe Fighting +2, Club Fighting +2",
      protection: "Physical +4%, Energy + 2%",
      weight: 51,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Stoic Iks Faulds",
      image: "/images/tibia/items/legs/Stoic_Iks_Faulds.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 0,
      arm: 8,
      bonus: "Magic Level +2",
      protection: "Fire +6%",
      weight: 34,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Studded Legs",
      image: "/images/tibia/items/legs/Studded_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 26,
      drop: "Gang Member, Juvenile Cyclops, Orc Spearman, Scar Tribe Shaman, Scar Tribe Warrior.",
      sellPrice: "15",
      type: "legs",
    },
    {
      name: "Tatty Dragon Scale Legs",
      image: "/images/tibia/items/legs/Tatty_Dragon_Scale_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 38,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Terra Legs",
      image: "/images/tibia/items/legs/Terra_Legs.gif",
      level: 40,
      vocation: "Sorcerers and Druids",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Earth +6%, Fire -6%",
      weight: 19,
      drop: "Ancient Spawn of Morgathla, Askarak Demon, Blightwalker, Cursed Book, Drume, Enfeebled Silencer, Ferumbras Mortal Shell, Ghastly Dragon, Glooth Bandit, Glooth Brigand, Gorgo, Hideous Fungus, Humongous Fungus, Ink Blob, Ironblight, Liodile, Lost Exile, Lost Husher, Medusa, Menacing Carnivor, Noxious Ripptor, Silencer, Undertaker.",
      sellPrice: "11000",
      type: "legs",
    },
    {
      name: "Trousers of the Ancients",
      image: "/images/tibia/items/legs/Trousers_of_the_Ancients.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.3,
      drop: "Doctor Perhaps.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Wereboar Loincloth",
      image: "/images/tibia/items/legs/Wereboar_Loincloth.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 6,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 13,
      drop: "Bloodback, Wereboar.",
      sellPrice: "1500",
      type: "legs",
    },
    {
      name: "Yalahari Leg Piece",
      image: "/images/tibia/items/legs/Yalahari_Leg_Piece.gif",
      level: 80,
      vocation: "Paladins",
      slots: 0,
      arm: 8,
      bonus: "Distance Fighting +2",
      protection: "Death +5%",
      weight: 65,
      drop: "Ninguém.",
      sellPrice: "",
      type: "legs",
    },
    {
      name: "Zaoan Legs",
      image: "/images/tibia/items/legs/Zaoan_Legs.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 8,
      bonus: "Nenhum.",
      protection: "Physical +2%",
      weight: 66,
      drop: "Draken Abomination, Draken Elite, Draken Spellweaver, Draken Warmaster, Ethershreck, Fazzrah, Ghastly Dragon, Hatebreeder, Lizard Chosen, Lizard Gate Guardian, Lizard High Guard, Lizard Zaogun.",
      sellPrice: "14000",
      type: "legs",
    },
    //boots
    {
      name: "Alchemist's Boots",
      image: "/images/tibia/items/boots/Alchemist's_Boots.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Physical +2%",
      weight: 16,
      drop: "The Monster.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Badger Boots",
      image: "/images/tibia/items/boots/Badger_Boots.gif",
      level: 60,
      vocation: "Todas",
      slots: 1,
      arm: 2,
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Sharpclaw, The Baron from Below, Werebadger.",
      sellPrice: "7500",
      type: "boots",
    },
    {
      name: "Boots of Haste",
      image: "/images/tibia/items/boots/Boots_of_Haste.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Speed +20",
      protection: "Nenhuma.",
      weight: 7.5,
      drop: "Black Knight, Blood Hand, Blood Priest, Broken Shaper, Carnivostrich, Enfeebled Silencer, Golgordan, Hand of Cursed Fate, Hydra, King Zelos, Latrivan, Lloyd, Lokathmor, Mad Mage, Necromancer, Necromancer Servant, Necropharus, Nightmare, Nightstalker, Nymph, Omruc, Ravenous Hunger, Shadow Pupil, Silencer, Swan Maiden, Terofar, Tormentor, Vampire Bride, Zugurosh.",
      sellPrice: "30000",
      type: "boots",
    },
    {
      name: "Boots of Homecoming",
      image: "/images/tibia/items/boots/Boots_of_Homecoming.gif",
      level: 100,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 16,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Boots of Waterwalking",
      image: "/images/tibia/items/boots/Boots_of_Waterwalking.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Broken Iks Sandals",
      image: "/images/tibia/items/boots/Broken_Iks_Sandals.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.8,
      drop: "Ahau, Iks Ahpututu, Iks Aucar, Iks Pututu.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Bunnyslippers",
      image: "/images/tibia/items/boots/Bunnyslippers.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Yeti.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Cobra Boots",
      image: "/images/tibia/items/boots/Cobra_Boots.gif",
      level: 220,
      vocation: "Knights",
      slots: 1,
      arm: 3,
      bonus: "Speed +10",
      protection: "Physical +6%",
      weight: 9,
      drop: "Guard Captain Quaid, Ugly Monster.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Coconut Shoes",
      image: "/images/tibia/items/boots/Coconut_Shoes.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Dryad.",
      sellPrice: "500",
      type: "boots",
    },
    {
      name: "Crocodile Boots",
      image: "/images/tibia/items/boots/Crocodile_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Crocodile, Killer Caiman, The Snapper.",
      sellPrice: "1000",
      type: "boots",
    },
    {
      name: "Crystal Boots",
      image: "/images/tibia/items/boots/Crystal_Boots.gif",
      level: 70,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Ice +3%, Energy -3%",
      weight: 18.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Depth Calcei",
      image: "/images/tibia/items/boots/Depth_Calcei.gif",
      level: 150,
      vocation: "Knights",
      slots: 1,
      arm: 3,
      bonus: "Speed -5",
      protection: "Physical +5%",
      weight: 37,
      drop: "Jaul.",
      sellPrice: "25000",
      type: "boots",
    },
    {
      name: "Dragon Scale Boots",
      image: "/images/tibia/items/boots/Dragon_Scale_Boots.gif",
      level: 70,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Fire +3%, Ice -3%",
      weight: 18.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Draken Boots",
      image: "/images/tibia/items/boots/Draken_Boots.gif",
      level: 80,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 3,
      bonus: "Speed +15",
      protection: "Nenhuma.",
      weight: 25,
      drop: "Draken Abomination, Draken Elite, Hatebreeder.",
      sellPrice: "40000",
      type: "boots",
    },
    {
      name: "Feverbloom Boots",
      image: "/images/tibia/items/boots/Feverbloom_Boots.gif",
      level: 270,
      vocation: "Paladins",
      slots: 1,
      arm: 2,
      bonus: "Distance Fighting +1, Speed +15",
      protection: "Ice +7%",
      weight: 9.8,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Filthy Bunnyslippers",
      image: "/images/tibia/items/boots/Filthy_Bunnyslippers.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Firewalker Boots",
      image: "/images/tibia/items/boots/Firewalker_Boots.gif",
      level: 130,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Fire Field 90%",
      weight: 9.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Frostflower Boots",
      image: "/images/tibia/items/boots/Frostflower_Boots.gif",
      level: 270,
      vocation: "Knights",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Physical +5%, Ice +5%",
      weight: 11,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Fur Boots",
      image: "/images/tibia/items/boots/Fur_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 2,
      bonus: "Speed -6",
      protection: "Nenhuma.",
      weight: 12,
      drop: "Barbarian Brutetamer, Barbarian Bloodwalker, Barbarian Headsplitter, Barbarian Skullhunter, Bloodback.",
      sellPrice: "2000",
      type: "boots",
    },
    {
      name: "Glacier Shoes",
      image: "/images/tibia/items/boots/Glacier_Shoes.gif",
      level: 35,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Ice +5%, Energy -5%",
      weight: 7.5,
      drop: "Foam Stalker, Icecold Book, Ice Witch, Seacrest Serpent, Soul-Broken Harbinger, Two-Headed Turtle, Usurper Archer, Varnished Diremaw.",
      sellPrice: "2500",
      type: "boots",
    },
    {
      name: "Golden Boots",
      image: "/images/tibia/items/boots/Golden_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 31,
      drop: "Zugurosh, Prince Drazzak.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Green Demon Slippers",
      image: "/images/tibia/items/boots/Green_Demon_Slippers.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Guardian Boots",
      image: "/images/tibia/items/boots/Guardian_Boots.gif",
      level: 70,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Physical +2%, Holy -2%",
      weight: 22.5,
      drop: "Ethershreck, Ghastly Dragon, Hatebreeder, Lokathmor, The Time Guardian.",
      sellPrice: "35000",
      type: "boots",
    },
    {
      name: "Leather Boots",
      image: "/images/tibia/items/boots/Leather_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9,
      drop: "Big Boss Trolliver, Dwarf Guard, Elf, Island Troll, Swamp Troll, Troll, Troll Champion, Troll Marauder, Witch, Young Troll.",
      sellPrice: "2",
      type: "boots",
    },
    {
      name: "Lightning Boots",
      image: "/images/tibia/items/boots/Lightning_Boots.gif",
      level: 35,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Energy +5%, Earth -5%",
      weight: 7.5,
      drop: "Burning Gladiator, Elder Wyrm, Lich, Juvenile Bashmu, Mazzinor, Mercurial Menace, Pirat Bombardier, Prince Drazzak, War Golem.",
      sellPrice: "2500",
      type: "boots",
    },
    {
      name: "Magma Boots",
      image: "/images/tibia/items/boots/Magma_Boots.gif",
      level: 35,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Fire +5%, Ice -5%",
      weight: 7.5,
      drop: "Burning Gladiator, Crazed Summer Vanguard, Dragon Lord Hatchling, Feral Sphinx, Hellflayer, Hellhound, Infernalist, Lava Golem, Magma Crawler, Manticore, Massive Fire Elemental, Orc Warlord, Pirat Bombardier, Sphinx, Sulphider, The Souldespoiler.",
      sellPrice: "2500",
      type: "boots",
    },
    {
      name: "Make-do Boots",
      image: "/images/tibia/items/boots/Make-do_Boots.gif",
      level: 150,
      vocation: "Druids",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Ice +6%",
      weight: 14.5,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Makeshift Boots",
      image: "/images/tibia/items/boots/Makeshift_Boots.gif",
      level: 150,
      vocation: "Sorcerers",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Fire +6%",
      weight: 14.5,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Metal Spats",
      image: "/images/tibia/items/boots/Metal_Spats.gif",
      level: 50,
      vocation: "Knights and Paladins",
      slots: 1,
      arm: 1,
      bonus: "Nenhum.",
      protection: "Physical +1%",
      weight: 18,
      drop: "Glooth Battery, Gore Horn, Metal Gargoyle, Ogre Ruffian, Walker.",
      sellPrice: "2000",
      type: "boots",
    },
    {
      name: "Mutant Bone Boots",
      image: "/images/tibia/items/boots/Mutant_Bone_Boots.gif",
      level: 250,
      vocation: "Druids",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Death +3%",
      weight: 11,
      drop: "The Monster.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Oriental Shoes",
      image: "/images/tibia/items/boots/Oriental_Shoes.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Speed +15",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ancient Spawn of Morgathla, Dawnfire Asura, Midnight Asura, Ravenous Hunger, The Flaming Orchid, True Midnight Asura.",
      sellPrice: "15000",
      type: "boots",
    },
    {
      name: "Pair of Dreamwalkers",
      image: "/images/tibia/items/boots/Pair_of_Dreamwalkers.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Earth +8%",
      weight: 8,
      drop: "Alptramun.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Pair of Nightmare Boots",
      image: "/images/tibia/items/boots/Pair_of_Nightmare_Boots.gif",
      level: 140,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Energy +6%",
      weight: 13,
      drop: "Irgix the Flimsy, The Dread Maiden, The Pale Worm, Unaz the Mean, Vok the Freakish.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Pair of Soft Boots",
      image: "/images/tibia/items/boots/Pair_of_Soft_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Pair of Soulstalkers",
      image: "/images/tibia/items/boots/Pair_of_Soulstalkers.gif",
      level: 400,
      vocation: "Paladins",
      slots: 1,
      arm: 3,
      bonus: "Distance Fighting +1, Speed +20",
      protection: "Physical +5%",
      weight: 13,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Pair of Soulwalkers",
      image: "/images/tibia/items/boots/Pair_of_Soulwalkers.gif",
      level: 400,
      vocation: "Knights",
      slots: 1,
      arm: 4,
      bonus: "Sword Fighting +1, Club Fighting +1, Axe Fighting +1, Speed +15",
      protection: "Physical +7%, Fire +5%",
      weight: 13,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Patched Boots",
      image: "/images/tibia/items/boots/Patched_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "2000",
      type: "boots",
    },
    {
      name: "Pirate Boots",
      image: "/images/tibia/items/boots/Pirate_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8,
      drop: "Pirate Corsair.",
      sellPrice: "3000",
      type: "boots",
    },
    {
      name: "Prismatic Boots",
      image: "/images/tibia/items/boots/Prismatic_Boots.gif",
      level: 150,
      vocation: "Paladins",
      slots: 1,
      arm: 3,
      bonus: "Speed +15",
      protection: "Death +3%",
      weight: 18,
      drop: "Ninguém.",
      sellPrice: "10Major Crystalline Token",
      type: "boots",
    },
    {
      name: "Sandals",
      image: "/images/tibia/items/boots/Sandals.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Dark Monk, Elf Arcanist, Elf Scout, Monk.",
      sellPrice: "22",
      type: "boots",
    },
    {
      name: "Sanguine Boots",
      image: "/images/tibia/items/boots/Sanguine_Boots.gif",
      level: 500,
      vocation: "Sorcerers",
      slots: 1,
      arm: 3,
      bonus: "Magic Level +2, Speed +10, Death Magic Level +1",
      protection: "Protection Physical +2%, Ice +8%",
      weight: 19,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Sanguine Galoshes",
      image: "/images/tibia/items/boots/Sanguine_Galoshes.gif",
      level: 500,
      vocation: "Druids",
      slots: 1,
      arm: 3,
      bonus: "Magic Level +2, Speed +10, Healing Magic Level +1",
      protection: "Protection Physical +2%, Fire +8%",
      weight: 17,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Steel Boots",
      image: "/images/tibia/items/boots/Steel_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 3,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 29,
      drop: "Behemoth, Bretzecutioner, Cliff Strider, Control Tower, Dark Torturer, Destroyer, Eradicator, Fury, Gorzindel, Grimeleech, Hellgorak, Hive Overseer, Horadron, Kollos, Morguthis, Plaguesmith, Prince Drazzak, Retching Horror, Shaburak Lord, Shaburak Prince, Stonecracker, Terofar, Walker, War Golem, Zugurosh.",
      sellPrice: "30000",
      type: "boots",
    },
    {
      name: "Stoic Iks Boots",
      image: "/images/tibia/items/boots/Stoic_Iks_Boots.gif",
      level: 250,
      vocation: "Paladins",
      slots: 1,
      arm: 2,
      bonus: "Distance Fighting +1, Speed + 15",
      protection: "Fire +5%",
      weight: 15,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Stoic Iks Sandals",
      image: "/images/tibia/items/boots/Stoic_Iks_Sandals.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 1,
      arm: 2,
      bonus: "Magic Level +1",
      protection: "Ice +6%",
      weight: 9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Terra Boots",
      image: "/images/tibia/items/boots/Terra_Boots.gif",
      level: 35,
      vocation: "Sorcerers and Druids",
      slots: 1,
      arm: 2,
      bonus: "Nenhum.",
      protection: "Earth +5%, Fire -5%",
      weight: 7.5,
      drop: "Armadile, Choking Fear, Cobra Vizier, Crazed Winter Vanguard, Cursed Book, Deathbine, Dwarf Geomancer, Enfeebled Silencer, Exotic Cave Spider, Foam Stalker, Ghastly Dragon, Glooth Bandit, Glooth Brigand, Hideous Fungus, Hulking Carnisylvan, Humongous Fungus, Ink Blob, Lost Basher, Lost Berserker, Lost Exile, Lost Husher, Mercurial Menace, Ogre Sage, Omnivora, Pirat Bombardier, Ravenous Hunger, Shrieking Cry-Stal, Silencer, Terofar, Thanatursus, Undertaker.",
      sellPrice: "2500",
      type: "boots",
    },
    {
      name: "Traditional Leather Shoes",
      image: "/images/tibia/items/boots/Traditional_Leather_Shoes.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Treader of Torment",
      image: "/images/tibia/items/boots/Treader_of_Torment.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 4,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 36,
      drop: "Shulgrax.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Vampire Silk Slippers",
      image: "/images/tibia/items/boots/Vampire_Silk_Slippers.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 11.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Void Boots",
      image: "/images/tibia/items/boots/Void_Boots.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      arm: 2,
      bonus: "Speed +30",
      protection: "Energy +10%",
      weight: 15,
      drop: "Anomaly, Eradicator, Outburst, Rupture, World Devourer.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Winged Boots",
      image: "/images/tibia/items/boots/Winged_Boots.gif",
      level: 220,
      vocation: "Paladins",
      slots: 1,
      arm: 2,
      bonus: "Distance Fighting +1, Speed +15",
      protection: "Earth +7%",
      weight: 12,
      drop: "Bragrumol, Urmahlullu the Weakened",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Worn Firewalker Boots",
      image: "/images/tibia/items/boots/Worn_Firewalker_Boots.gif",
      level: 130,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 9.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Worn Soft Boots",
      image: "/images/tibia/items/boots/Worn_Soft_Boots.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: null,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Yetislippers",
      image: "/images/tibia/items/boots/Yetislippers.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      arm: 0,
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.4,
      drop: "Baleful Bunny.",
      sellPrice: "",
      type: "boots",
    },
    {
      name: "Zaoan Shoes",
      image: "/images/tibia/items/boots/Zaoan_Shoes.gif",
      level: 0,
      vocation: "Todas",
      slots: 1,
      arm: 1,
      bonus: "Speed +5",
      protection: "Nenhuma.",
      weight: 7,
      drop: "Draken Spellweaver, Draken Warmaster, Ethershreck, Fazzrah, Ghastly Dragon, Hatebreeder, Lizard Chosen, Lizard Dragon Priest, Lizard Gate Guardian, Lizard High Guard, Lizard Legionnaire, Lizard Zaogun, The Voice of Ruin.",
      sellPrice: "5000",
      type: "boots",
    },
    //axes
    {
      name: "Angelic Axe",
      image: "/images/tibia/items/axes/Angelic_Axe.gif",
      level: 45,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 56,
      drop: "Humongous Fungus, Mutated Tiger.",
      sellPrice: "5000",
      type: "axe",
    },
    {
      name: "Axe",
      image: "/images/tibia/items/axes/Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 12,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 40,
      drop: "Bandit, Crazed Dwarf, Crypt Defiler, Dwarf, Dwarf Miner, Grave Robber, Lumbering Carnivor, Minotaur, Minotaur Bruiser, Nomad, Orc, Salamander Trainer, Troll-Trained Salamander, Usurper Commander, Werehyaena, Wild Warrior.",
      sellPrice: "7",
      type: "axe",
    },
    {
      name: "Axe of Carving",
      image: "/images/tibia/items/axes/Axe_of_Carving.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Axe of Carving (Charged)",
      image: "/images/tibia/items/axes/Axe_of_Carving_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Carving (Heavily Charged)",
      image:
        "/images/tibia/items/axes/Axe_of_Carving_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Carving (Overcharged)",
      image: "/images/tibia/items/axes/Axe_of_Carving_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Destruction",
      image: "/images/tibia/items/axes/Axe_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Axe of Mayhem",
      image: "/images/tibia/items/axes/Axe_of_Mayhem.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Axe of Mayhem (Charged)",
      image: "/images/tibia/items/axes/Axe_of_Mayhem_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Mayhem (Heavily Charged)",
      image: "/images/tibia/items/axes/Axe_of_Mayhem_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Mayhem (Overcharged)",
      image: "/images/tibia/items/axes/Axe_of_Mayhem_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Remedy",
      image: "/images/tibia/items/axes/Axe_of_Remedy.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Axe of Remedy (Charged)",
      image: "/images/tibia/items/axes/Axe_of_Remedy_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Remedy (Heavily Charged)",
      image: "/images/tibia/items/axes/Axe_of_Remedy_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Axe of Remedy (Overcharged)",
      image: "/images/tibia/items/axes/Axe_of_Remedy_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Barbarian Axe",
      image: "/images/tibia/items/axes/Barbarian_Axe.gif",
      level: 20,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 28,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 51,
      drop: "Parder.",
      sellPrice: "185",
      type: "axe",
    },
    {
      name: "Battle Axe",
      image: "/images/tibia/items/axes/Battle_Axe.gif",
      level: 0,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 25,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Barbarian Bloodwalker, Cyclops Smith, Dwarf Soldier, Gozzler, Orc Berserker, Orc Cult Inquisitor, Young Sea Serpent.",
      sellPrice: "80",
      type: "axe",
    },
    {
      name: "Beastslayer Axe",
      image: "/images/tibia/items/axes/Beastslayer_Axe.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 61.5,
      drop: "Barbarian Bloodwalker, Choking Fear, Girtablilu Warrior, Glooth Bandit, Hellgorak, Horadron, Retching Horror, Thanatursus.",
      sellPrice: "1500",
      type: "axe",
    },
    {
      name: "Butcher's Axe",
      image: "/images/tibia/items/axes/Butcher's_Axe.gif",
      level: 45,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 41,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "-2",
      bonus: "Nenhum",
      weight: 63,
      drop: "Bibby Bloodbath, Dark Torturer, Execowtioner, Ghulosh, Glooth Brigand, Hellgorak, Horadron, Ogre Brute, Retching Horror, Shaburak Prince, Warlord Ruzad.",
      sellPrice: "18000",
      type: "axe",
    },
    {
      name: "Chopper of Carving",
      image: "/images/tibia/items/axes/Chopper_of_Carving.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Chopper of Carving (Charged)",
      image: "/images/tibia/items/axes/Chopper_of_Carving_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Carving (Heavily Charged)",
      image:
        "/images/tibia/items/axes/Chopper_of_Carving_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Carving (Overcharged)",
      image:
        "/images/tibia/items/axes/Chopper_of_Carving_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Destruction",
      image: "/images/tibia/items/axes/Chopper_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Chopper of Mayhem",
      image: "/images/tibia/items/axes/Chopper_of_Mayhem.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Chopper of Mayhem (Charged)",
      image: "/images/tibia/items/axes/Chopper_of_Mayhem_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 37,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Mayhem (Heavily Charged)",
      image:
        "/images/tibia/items/axes/Chopper_of_Mayhem_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Mayhem (Overcharged)",
      image: "/images/tibia/items/axes/Chopper_of_Mayhem_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Remedy",
      image: "/images/tibia/items/axes/Chopper_of_Remedy.gif",
      level: 100,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "axe",
    },
    {
      name: "Chopper of Remedy (Charged)",
      image: "/images/tibia/items/axes/Chopper_of_Remedy_%28Charged%29.gif",
      level: 150,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Remedy (Heavily Charged)",
      image:
        "/images/tibia/items/axes/Chopper_of_Remedy_%28Heavily_Charged%29.gif",
      level: 200,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Chopper of Remedy (Overcharged)",
      image: "/images/tibia/items/axes/Chopper_of_Remedy_%28Overcharged%29.gif",
      level: 250,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Axe Fighting +1",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Cobra Axe",
      image: "/images/tibia/items/axes/Cobra_Axe.gif",
      level: 220,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Ice",
      defense: 29,
      defenseModifier: "+2",
      bonus: "Axe Fighting +2",
      weight: 40,
      drop: "Gaffir, Scarlett Etzel, Ugly Monster.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Crude Umbral Axe",
      image: "/images/tibia/items/axes/Crude_Umbral_Axe.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 90,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Crude Umbral Chopper",
      image: "/images/tibia/items/axes/Crude_Umbral_Chopper.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 27,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 120,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Crystalline Axe",
      image: "/images/tibia/items/axes/Crystalline_Axe.gif",
      level: 120,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "+3",
      bonus: "Axe Fighting +1",
      weight: 76,
      drop: "Abyssador, Deathstrike, Gnomevil, The Sandking.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Daramian Axe",
      image: "/images/tibia/items/axes/Daramian_Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 17,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 41,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Daramian Waraxe",
      image: "/images/tibia/items/axes/Daramian_Waraxe.gif",
      level: 25,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 52.5,
      drop: "Ancient Scarab, Ancient Spawn of Morgathla, Grave Guard, The Ravager.",
      sellPrice: "1000",
      type: "axe",
    },
    {
      name: "Deepling Axe",
      image: "/images/tibia/items/axes/Deepling_Axe.gif",
      level: 80,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 91,
      drop: "Jaul, Obujos, Reflection Of Obujos.",
      sellPrice: "40000",
      type: "axe",
    },
    {
      name: "Demonwing Axe",
      image: "/images/tibia/items/axes/Demonwing_Axe.gif",
      level: 120,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 120,
      drop: "Ferumbras Mortal Shell, Hellgorak, Horadron, Prince Drazzak, The Source Of Corruption..",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Double Axe",
      image: "/images/tibia/items/axes/Double_Axe.gif",
      level: 25,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Behemoth, Black Knight, Cyclops Smith, Diabolic Imp, Dragon, Dwarf Guard, Fire Devil, Golgordan, Latrivan, Lethal Lissy, Minotaur Guard, Quara Predator, Quara Predator Scout, Stonecracker, The Horned Fox, Valkyrie.",
      sellPrice: "260",
      type: "axe",
    },
    {
      name: "Dragon Lance",
      image: "/images/tibia/items/axes/Dragon_Lance.gif",
      level: 60,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 16,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 67,
      drop: "Black Knight.",
      sellPrice: "9000",
      type: "axe",
    },
    {
      name: "Drakinata",
      image: "/images/tibia/items/axes/Drakinata.gif",
      level: 60,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 54,
      drop: "Draken Warmaster, Ethershreck, Ghastly Dragon, Hatebreeder, Lizard Legionnaire, Soul of Dragonking Zyrtarch.",
      sellPrice: "10000",
      type: "axe",
    },
    {
      name: "Dreaded Cleaver",
      image: "/images/tibia/items/axes/Dreaded_Cleaver.gif",
      level: 40,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "-3",
      bonus: "Nenhum",
      weight: 38,
      drop: "Arthei, Askarak Lord, Bloodback, Boreth, Bretzecutioner, Darkfang, Destroyer, Ghulosh, Glooth Brigand, Gorzindel, Hemming, Lersatio, Lokathmor, Mazzinor, Orclops Ravager, Ogre Savage, Shadowpelt, The Old Widow, Shulgrax, Werebear, Wereboar, Werewolf.",
      sellPrice: "15000",
      type: "axe",
    },
    {
      name: "Dwarven Axe",
      image: "/images/tibia/items/axes/Dwarven_Axe.gif",
      level: 20,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 31,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 82,
      drop: "Ninguém.",
      sellPrice: "1500",
      type: "axe",
    },
    {
      name: "Eldritch Greataxe",
      image: "/images/tibia/items/axes/Eldritch_Greataxe.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 56,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +3",
      weight: 65,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Execowtioner Axe",
      image: "/images/tibia/items/axes/Execowtioner_Axe.gif",
      level: 55,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 82,
      drop: "Execowtioner, The False God.",
      sellPrice: "12000",
      type: "axe",
    },
    {
      name: "Executioner",
      image: "/images/tibia/items/axes/Executioner.gif",
      level: 85,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 99,
      drop: "Hellgorak, Horadron, Kerberos, The Enraged Thorn Knight, Prince Drazzak, World Devourer.",
      sellPrice: "55000",
      type: "axe",
    },
    {
      name: "Falcon Battleaxe",
      image: "/images/tibia/items/axes/Falcon_Battleaxe.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 10,
      elementalDamage: "47 Energy",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +4",
      weight: 95,
      drop: "Grand Chaplain Gaunder, Grand Master Oberon.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Farmer's Avenger",
      image: "/images/tibia/items/axes/Farmer's_Avenger.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 17,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 25,
      drop: "Monstor.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Fire Axe",
      image: "/images/tibia/items/axes/Fire_Axe.gif",
      level: 35,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 27,
      elementalDamage: "11 Fire",
      defense: 16,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 40,
      drop: "Angry Demon, Demon, Essence Of Malice, Feral Sphinx, Golgordan, Guardian Of Tales, Hellfire Fighter, Latrivan, Lava Golem, Lost Basher, Lost Berserker, Lost Exile, Lost Husher, Mahrdis, Mazoran, Rage Squid, Sulphider, The Count Of The Core, The Duke Of The Depths, Vexclaw, Weeper.",
      sellPrice: "8000",
      type: "axe",
    },
    {
      name: "Gilded Eldritch Greataxe",
      image: "/images/tibia/items/axes/Gilded_Eldritch_Greataxe.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 56,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Axe Fighting +3",
      weight: 66,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Glooth Axe",
      image: "/images/tibia/items/axes/Glooth_Axe.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "26 Earth",
      defense: 1,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19,
      drop: "Blood Beast, Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Blob, Glooth Brigand, Glooth Golem, Glooth Fairy, Lisa, Rot Elemental.",
      sellPrice: "1500",
      type: "axe",
    },
    {
      name: "Glorious Axe",
      image: "/images/tibia/items/axes/Glorious_Axe.gif",
      level: 30,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 95,
      drop: "Bashmu, Enfeebled Silencer, Enraged Crystal Golem, Enslaved Dwarf, Lumbering Carnivor, Mutated Tiger, Orewalker, Silencer, Stone Devourer.",
      sellPrice: "3000",
      type: "axe",
    },
    {
      name: "Golden Axe",
      image: "/images/tibia/items/axes/Golden_Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 10,
      elementalDamage: "Nenhum",
      defense: 5,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19.89,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Golden Sickle",
      image: "/images/tibia/items/axes/Golden_Sickle.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 13,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19.5,
      drop: "Angry Demon, Blightwalker, Boogy, Demon, Priestess Of The Wild Sun.",
      sellPrice: "1000",
      type: "axe",
    },
    {
      name: "Grand Sanguine Battleaxe",
      image: "/images/tibia/items/axes/Grand_Sanguine_Battleaxe.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Death",
      defense: 35,
      defenseModifier: "0",
      bonus: "Axe Fighting +5",
      weight: 83,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Grand Sanguine Hatchet",
      image: "/images/tibia/items/axes/Grand_Sanguine_Hatchet.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Fire",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Axe Fighting +5",
      weight: 71,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Great Axe",
      image: "/images/tibia/items/axes/Great_Axe.gif",
      level: 95,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 90,
      drop: "Ferumbras Mortal Shell, Hellgorak, Horadron.",
      sellPrice: "300",
      type: "axe",
    },
    {
      name: "Guardian Axe",
      image: "/images/tibia/items/axes/Guardian_Axe.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 11,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 110,
      drop: "Deepling Guard, Deepling Tyrant, The Count Of The Core, Utua Stone Sting.",
      sellPrice: "9000",
      type: "axe",
    },
    {
      name: "Guardian Halberd",
      image: "/images/tibia/items/axes/Guardian_Halberd.gif",
      level: 55,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 110,
      drop: "Annihilon, Crape Man, Eternal Guardian, Mazoran, Poisonous Carnisylvan.",
      sellPrice: "11000",
      type: "axe",
    },
    {
      name: "Halberd",
      image: "/images/tibia/items/axes/Halberd.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 90,
      drop: "Annihilon, Barbarian Bloodwalker, Black Knight, Cyclops, Cyclops Drone, Esmeralda, Foam Stalker, Frost Giant, Golden Servant, Golden Servant Replica, Iron Servant, Lizard Sentinel, Mutated Rat, Orc Berserker, Orc Cult Inquisitor, Quara Mantassin, Quara Pincher, Quara Pincher Scout, Renegade Knight, Thanatursus, Vicious Squire, Vile Grandmaster, Wailing Widow, Werehyaena, Werewolf, Zombie.",
      sellPrice: "400",
      type: "axe",
    },
    {
      name: "Hand Axe",
      image: "/images/tibia/items/axes/Hand_Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 10,
      elementalDamage: "Nenhum",
      defense: 5,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 18,
      drop: "Big Boss Trolliver, Troll, Island Troll, Mountain Troll.",
      sellPrice: "75",
      type: "axe",
    },
    {
      name: "Hatchet",
      image: "/images/tibia/items/axes/Hatchet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 15,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 35,
      drop: "Brittle Skeleton, Crazed Dwarf, Dwarf, Juvenile Cyclops, Meadow Strider, Skeleton, The Horned Fox, Woodling.",
      sellPrice: "25",
      type: "axe",
    },
    {
      name: "Headchopper",
      image: "/images/tibia/items/axes/Headchopper.gif",
      level: 35,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "6000",
      type: "axe",
    },
    {
      name: "Heavy Trident",
      image: "/images/tibia/items/axes/Heavy_Trident.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 17,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 90,
      drop: "Deathling Scout, Deathling Spellsinger, Deepling Brawler, Deepling Elite, Deepling Guard, Deepling Scout, Deepling Tyrant, Deepling Warrior, Deepling Worker.",
      sellPrice: "2000",
      type: "axe",
    },
    {
      name: "Hellforged Axe",
      image: "/images/tibia/items/axes/Hellforged_Axe.gif",
      level: 110,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 28,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 88,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Heroic Axe",
      image: "/images/tibia/items/axes/Heroic_Axe.gif",
      level: 60,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 61,
      drop: "Ninguém.",
      sellPrice: "30000",
      type: "axe",
    },
    {
      name: "Hive Scythe",
      image: "/images/tibia/items/axes/Hive_Scythe.gif",
      level: 70,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 75,
      drop: "Hive Overseer, Kollos, Spidris, Spidris Elite, Waspoid.",
      sellPrice: "17000",
      type: "axe",
    },
    {
      name: "Ice Hatchet",
      image: "/images/tibia/items/axes/Ice_Hatchet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 4,
      elementalDamage: "11 Ice",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 35,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Impaler",
      image: "/images/tibia/items/axes/Impaler.gif",
      level: 85,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "-2",
      bonus: "Nenhum",
      weight: 82,
      drop: "Ferumbras Mortal Shell, Mr. Punish, The Souldespoiler.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Knight Axe",
      image: "/images/tibia/items/axes/Knight_Axe.gif",
      level: 25,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 33,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 59,
      drop: "Black Knight, Execowtioner, Girtablilu Warrior, Hellhound, Hulking Carnisylvan, Kerberos, Lost Basher, Lost Berserker, Lost Exile, Lost Husher, Minotaur Invader, Morguthis, Poisonous Carnisylvan, Skeleton Elite Warrior, Thanatursus, The Enraged Thorn Knight, Tremendous Tyrant, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "2000",
      type: "axe",
    },
    {
      name: "Lion Axe",
      image: "/images/tibia/items/axes/Lion_Axe.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Earth",
      defense: 31,
      defenseModifier: "+2",
      bonus: "Axe Fighting +3",
      weight: 79,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Mino Lance",
      image: "/images/tibia/items/axes/Mino_Lance.gif",
      level: 45,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 65,
      drop: "Minotaur Amazon, Minotaur Cult Follower.",
      sellPrice: "7000",
      type: "axe",
    },
    {
      name: "Mythril Axe",
      image: "/images/tibia/items/axes/Mythril_Axe.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 28,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Naga Axe",
      image: "/images/tibia/items/axes/Naga_Axe.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Energy",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Axe Fighting +3",
      weight: 55,
      drop: "Timira the Many-Headed",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Naginata",
      image: "/images/tibia/items/axes/Naginata.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 78,
      drop: "Ninguém.",
      sellPrice: "2000",
      type: "axe",
    },
    {
      name: "Noble Axe",
      image: "/images/tibia/items/axes/Noble_Axe.gif",
      level: 35,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 39,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 38,
      drop: "Blood Hand, Blood Priest, Crape Man, Essence Of Malice, Fury, Hellgorak, Horadron, Necromancer, Prince Drazzak, Serpent Spawn, Tamru the Black, The Noxious Spawn, Utua Stone Sting, Werelion.",
      sellPrice: "10000",
      type: "axe",
    },
    {
      name: "Obsidian Lance",
      image: "/images/tibia/items/axes/Obsidian_Lance.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 34,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 80,
      drop: "Energy Elemental, High Voltage Elemental, Killer Caiman, Lizard Sentinel, Mutated Bat, Orc Rider, Orc Marauder, Quara Hydromancer Scout, Rorc, Stalker, Thanatursus.",
      sellPrice: "500",
      type: "axe",
    },
    {
      name: "Ogre Choppa",
      image: "/images/tibia/items/axes/Ogre_Choppa.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 75,
      drop: "Ogre Rowdy, Ogre Savage.",
      sellPrice: "1500",
      type: "axe",
    },
    {
      name: "Orcish Axe",
      image: "/images/tibia/items/axes/Orcish_Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 23,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 45,
      drop: "Bibby Bloodbath, Orc Cult Fanatic, Orc Cult Inquisitor, Orc Cult Minion, Orc Cultist, Orc Marauder, Orc Rider, Orc Warlord, Orclops Doomhauler, Orclops Ravager, Rorc.",
      sellPrice: "350",
      type: "axe",
    },
    {
      name: "Ornamented Axe",
      image: "/images/tibia/items/axes/Ornamented_Axe.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 61.5,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "20000",
      type: "axe",
    },
    {
      name: "Phantasmal Axe",
      image: "/images/tibia/items/axes/Phantasmal_Axe.gif",
      level: 180,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 5,
      elementalDamage: "49 Fire",
      defense: 32,
      defenseModifier: "0",
      bonus: "Axe Fighting +2",
      weight: 72,
      drop: "Brain Head, The Pale Worm.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Plague Bite",
      image: "/images/tibia/items/axes/Plague_Bite.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 26,
      elementalDamage: "26 Earth",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 99,
      drop: "Plagirath.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Ravager's Axe",
      image: "/images/tibia/items/axes/Ravager's_Axe.gif",
      level: 70,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 52.5,
      drop: "Ancient Spawn of Morgathla, Morguthis.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Ravenwing",
      image: "/images/tibia/items/axes/Ravenwing.gif",
      level: 65,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Reaper's Axe",
      image: "/images/tibia/items/axes/Reaper's_Axe.gif",
      level: 70,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 59,
      drop: "Ragiaz.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Rift Lance",
      image: "/images/tibia/items/axes/Rift_Lance.gif",
      level: 70,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 28,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 66,
      drop: "Ferumbras Mortal Shell, Grimeleech, Hellflayer, Lloyd,Vexclaw, Ragiaz, Shulgrax, Tarbaz, The Souldespoiler, Zamulosh.",
      sellPrice: "30000",
      type: "axe",
    },
    {
      name: "Ripper Lance",
      image: "/images/tibia/items/axes/Ripper_Lance.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 28,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 80,
      drop: "Dworc Fleshhunter, Fleshslicer, Insectoid Scout, Mindmasher.",
      sellPrice: "500",
      type: "axe",
    },
    {
      name: "Royal Axe",
      image: "/images/tibia/items/axes/Royal_Axe.gif",
      level: 75,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 92,
      drop: "Ninguém.",
      sellPrice: "40000",
      type: "axe",
    },
    {
      name: "Ruthless Axe",
      image: "/images/tibia/items/axes/Ruthless_Axe.gif",
      level: 75,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 58,
      drop: "Anomaly, Hellhound, Kerberos, Rupture, Shulgrax, Skeleton Elite Warrior, Tarbaz, The Enraged Thorn Knight.",
      sellPrice: "45000",
      type: "axe",
    },
    {
      name: "Sanguine Battleaxe",
      image: "/images/tibia/items/axes/Sanguine_Battleaxe.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Death",
      defense: 35,
      defenseModifier: "0",
      bonus: "Axe Fighting +5",
      weight: 85,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Sanguine Hatchet",
      image: "/images/tibia/items/axes/Sanguine_Hatchet.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Fire",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Axe Fighting +5",
      weight: 72,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Scythe of the Reaper",
      image: "/images/tibia/items/axes/Scythe_of_the_Reaper.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 30,
      drop: "Boogey.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Sickle",
      image: "/images/tibia/items/axes/Sickle.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 5,
      elementalDamage: "Nenhum",
      defense: 4,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 10.5,
      drop: "Ekatrix, Thanatursus, Witch.",
      sellPrice: "3",
      type: "axe",
    },
    {
      name: "Solar Axe",
      image: "/images/tibia/items/axes/Solar_Axe.gif",
      level: 130,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 110,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Soulbiter",
      image: "/images/tibia/items/axes/Soulbiter.gif",
      level: 400,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 7,
      elementalDamage: "45 Death",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Axe Fighting +5",
      weight: 40,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Souleater (Axe)",
      image: "/images/tibia/items/axes/Souleater_%28Axe%29.gif",
      level: 400,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 10,
      elementalDamage: "47 Ice",
      defense: 34,
      defenseModifier: "0",
      bonus: "Axe Fighting +5",
      weight: 72,
      drop: "Ninguém.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Steel Axe",
      image: "/images/tibia/items/axes/Steel_Axe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 21,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 41,
      drop: "Ninguém.",
      sellPrice: "500",
      type: "axe",
    },
    {
      name: "Stonecutter Axe",
      image: "/images/tibia/items/axes/Stonecutter_Axe.gif",
      level: 90,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 99,
      drop: "Ninguém.",
      sellPrice: "320",
      type: "axe",
    },
    {
      name: "Throwing Axe",
      image: "/images/tibia/items/axes/Throwing_Axe.gif",
      level: 150,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+2",
      bonus: "Axe Fighting +2",
      weight: 47,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Titan Axe",
      image: "/images/tibia/items/axes/Titan_Axe.gif",
      level: 40,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 81,
      drop: "Armadile, Behemoth, Enslaved Dwarf, Execowtioner, Falcon Knight, Fleshslicer, Gorgo, Grand Chaplain Gaunder, Headpecker, Juggernaut, Lost Soul, Medusa, Minotaur Invader, Orewalker, Preceptor Lazare, Rhindeer, Silencer, Spidris, Spidris Elite, Thanatursus.",
      sellPrice: "4000",
      type: "axe",
    },
    {
      name: "Twin Axe",
      image: "/images/tibia/items/axes/Twin_Axe.gif",
      level: 50,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 64,
      drop: "Rahemos, Horestis.",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Umbral Axe",
      image: "/images/tibia/items/axes/Umbral_Axe.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 27,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 85,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Umbral Chopper",
      image: "/images/tibia/items/axes/Umbral_Chopper.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 115,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Umbral Master Axe",
      image: "/images/tibia/items/axes/Umbral_Master_Axe.gif",
      level: 250,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+3",
      bonus: "Axe Fighting +1",
      weight: 80,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Umbral Master Chopper",
      image: "/images/tibia/items/axes/Umbral_Master_Chopper.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 54,
      elementalDamage: "Nenhum",
      defense: 34,
      defenseModifier: "0",
      bonus: "Axe Fighting +3",
      weight: 110,
      drop: "Ninguém",
      sellPrice: "",
      type: "axe",
    },
    {
      name: "Vile Axe",
      image: "/images/tibia/items/axes/Vile_Axe.gif",
      level: 55,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 52,
      drop: "Dark Torturer, Ferumbras Mortal Shell, Grimeleech, Hellgorak, Horadron, Realityquake.",
      sellPrice: "30000",
      type: "axe",
    },
    {
      name: "War Axe",
      image: "/images/tibia/items/axes/War_Axe.gif",
      level: 65,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 61.5,
      drop: "Behemoth, Falcon Knight, Glooth Bandit, Hulking Prehemoth, Juggernaut, Katex Blood Tongue, Lost Basher, Nightmare, Outburst, Preceptor Lazare, Priestess Of The Wild Sun, Stonecracker, Stone Devourer, Srezz Yellow Eyes, The False God, Tormentor, Undead Dragon, Yirkas Blue Scales, Rotten Golem.",
      sellPrice: "12000",
      type: "axe",
    },
    {
      name: "Warrior's Axe",
      image: "/images/tibia/items/axes/Warrior's_Axe.gif",
      level: 40,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 88,
      drop: "Deathling Scout, Deathling Spellsinger, Deepling Elite, Deepling Warrior, Mean Lost Soul, Nighthunter, Stalking Stalk, Thanatursus, Utua Stone Sting.",
      sellPrice: "11000",
      type: "axe",
    },
    {
      name: "Zaoan Halberd",
      image: "/images/tibia/items/axes/Zaoan_Halberd.gif",
      level: 25,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 37,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 87,
      drop: "Draken Warmaster, Eternal Guardian, Ethershreck, Ghastly Dragon, Hatebreeder, Lizard Legionnaire, Wailing Widow.",
      sellPrice: "500",
      type: "axe",
    },
    //club
    {
      name: "Abyss Hammer",
      image: "/images/tibia/items/clubs/Abyss_Hammer.gif",
      level: 60,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 55,
      drop: "Ferumbras Mortal Shell, Grimeleech, Hand of Cursed Fate, Lady Tenebris, Malofur Mangrinder, Maxxenius, Phantasm, Prince Drazzak, Renegade Quara Predator, Tarbaz, The Nightmare Beast, The Unarmored Voidborn.",
      sellPrice: "20000",
      type: "club",
    },
    {
      name: "Amber Staff",
      image: "/images/tibia/items/clubs/Amber_Staff.gif",
      level: 40,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 35,
      drop: "Adept of the Cult, Enlightened of the Cult, Fleshcrawler, Hellhound, Juvenile Bashmu, Outburst, Ragiaz, Rupture, Sulphider, The Count Of The Core, World Devourer.",
      sellPrice: "8000",
      type: "club",
    },
    {
      name: "Arcane Staff",
      image: "/images/tibia/items/clubs/Arcane_Staff.gif",
      level: 75,
      vocation: "Todas",
      slots: 2,
      hands: "Duas",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 40,
      drop: "Izcandar the Banished, Lady Tenebris, Plagueroot, The Nightmare Beast, The Sandking, The Scourge of Oblivion.",
      sellPrice: "42000",
      type: "club",
    },
    {
      name: "Banana Staff",
      image: "/images/tibia/items/clubs/Banana_Staff.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 25,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 50,
      drop: "Merlkin.",
      sellPrice: "1000",
      type: "club",
    },
    {
      name: "Battle Hammer",
      image: "/images/tibia/items/clubs/Battle_Hammer.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 24,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "Black Knight, Cyclops Smith, Demon Skeleton, Dwarf Guard, Plaguesmith, Tortoise, Young Sea Serpent, Zombie.",
      sellPrice: "120",
      type: "club",
    },
    {
      name: "Blessed Sceptre",
      image: "/images/tibia/items/clubs/Blessed_Sceptre.gif",
      level: 75,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 39,
      drop: "Ninguém.",
      sellPrice: "40000",
      type: "club",
    },
    {
      name: "Bone Club",
      image: "/images/tibia/items/clubs/Bone_Club.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 12,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 39,
      drop: "Bonebeast, Dreadbeast, Goblin, Goblin Assassin, Goblin Leader, Goblin Scavenger, Muglex Clan Footman, Muglex Clan Scavenger, Necropharus, Pirate Skeleton, Salamander Trainer, Troll-Trained Salamander.",
      sellPrice: "5",
      type: "club",
    },
    {
      name: "Bonebreaker",
      image: "/images/tibia/items/clubs/Bonebreaker.gif",
      level: 55,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 120,
      drop: "Ancient Spawn of Morgathla, Armadile, Darkfang, Diamond Servant, Diamond Servant Replica, Hemming, Leviathan, Ogre Brute, Outburst, Ragiaz, The Unarmored Voidborn, War Golem, Werewolf, Worker Golem, Zavarash.",
      sellPrice: "10000",
      type: "club",
    },
    {
      name: "Brutetamer's Staff",
      image: "/images/tibia/items/clubs/Brutetamer's_Staff.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 38,
      drop: "Barbarian Brutetamer",
      sellPrice: "1500",
      type: "club",
    },
    {
      name: "Chaos Mace",
      image: "/images/tibia/items/clubs/Chaos_Mace.gif",
      level: 45,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 63,
      drop: "Alptramun, Anomaly, Bretzecutioner, Destroyer, Ferumbras Mortal Shell, Gorzindel, Izcandar the Banished, Juvenile Bashmu, Lost Basher, Lost Berserker, Malofur Mangrinder, Moohtant, Nightstalker, Outburst, Plagueroot, Rupture, Shulgrax, The Scourge of Oblivion, The Shatterer, Utua Stone Sting.",
      sellPrice: "9000",
      type: "club",
    },
    {
      name: "Clerical Mace",
      image: "/images/tibia/items/clubs/Clerical_Mace.gif",
      level: 20,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 28,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 58,
      drop: "Adept of the Cult, Braindeath, Dwarf Geomancer, Gozzler, Ice Witch, Necromancer, Necropharus, Priestess, Shadow Pupil, Swan Maiden.",
      sellPrice: "170",
      type: "club",
    },
    {
      name: "Club",
      image: "/images/tibia/items/clubs/Club.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 7,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 25,
      drop: "Ninguém",
      sellPrice: "1",
      type: "club",
    },
    {
      name: "Club of the Fury",
      image: "/images/tibia/items/clubs/Club_of_the_Fury.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 5,
      drop: "Boogey.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Cobra Club",
      image: "/images/tibia/items/clubs/Cobra_Club.gif",
      level: 220,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Fire",
      defense: 29,
      defenseModifier: "+2",
      bonus: "Club Fighting +2",
      weight: 25,
      drop: "Scarlett Etzel, Ugly Monster.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Cranial Basher",
      image: "/images/tibia/items/clubs/Cranial_Basher.gif",
      level: 60,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "-2",
      bonus: "Nenhum",
      weight: 78,
      drop: "Ninguém.",
      sellPrice: "30000",
      type: "club",
    },
    {
      name: "Crowbar",
      image: "/images/tibia/items/clubs/Crowbar.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 5,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 21,
      drop: "Behemoth, Destroyer, Faceless Bane, Girtablilu Warrior.",
      sellPrice: "50",
      type: "club",
    },
    {
      name: "Crude Umbral Hammer",
      image: "/images/tibia/items/clubs/Crude_Umbral_Hammer.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 27,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 170,
      drop: "The Last Lore Keeper.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Crude Umbral Mace",
      image: "/images/tibia/items/clubs/Crude_Umbral_Mace.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 90,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Crystal Mace",
      image: "/images/tibia/items/clubs/Crystal_Mace.gif",
      level: 35,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 38,
      elementalDamage: "Nenhum",
      defense: 16,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 80,
      drop: "Boar Man, Eradicator, Icecold Book, Ironblight, Juvenile Bashmu, Melting Frozen Horror, Stone Devourer, Tamru the Black, The Baron from Below, Tunnel Tyrant, Utua Stone Sting, Vashresamun, Walker, Zamulosh.",
      sellPrice: "12000",
      type: "club",
    },
    {
      name: "Daramian Mace",
      image: "/images/tibia/items/clubs/Daramian_Mace.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 21,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "Scarab, Sandstone Scorpion.",
      sellPrice: "110",
      type: "club",
    },
    {
      name: "Dark Trinity Mace",
      image: "/images/tibia/items/clubs/Dark_Trinity_Mace.gif",
      level: 120,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "-1",
      bonus: "Nenhum",
      weight: 99,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Deepling Squelcher",
      image: "/images/tibia/items/clubs/Deepling_Squelcher.gif",
      level: 48,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 28,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 89,
      drop: "Deepling Guard, Deepling Tyrant.",
      sellPrice: "7000",
      type: "club",
    },
    {
      name: "Deepling Staff",
      image: "/images/tibia/items/clubs/Deepling_Staff.gif",
      level: 38,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 88,
      drop: "Deepling Master Librarian, Deepling Spellsinger.",
      sellPrice: "4000",
      type: "club",
    },
    {
      name: "Demonbone",
      image: "/images/tibia/items/clubs/Demonbone.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 38,
      defenseModifier: "-2",
      bonus: "Nenhum",
      weight: 180,
      drop: "Annihilon, Zavarash.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Diamond Sceptre",
      image: "/images/tibia/items/clubs/Diamond_Sceptre.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 34,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 15,
      drop: "Annihilon, Cursed Book, Enfeebled Silencer, Girtablilu Warrior, Gore Horn, Hulking Carnisylvan, Icecold Book, Ice Witch, Juvenile Bashmu, Massive Earth Elemental, Nightmare Scion, Ogre Ruffian, Poisonous Carnisylvan, Silencer, Swan Maiden, Varnished Diremaw, Zavarash.",
      sellPrice: "3000",
      type: "club",
    },
    {
      name: "Drachaku",
      image: "/images/tibia/items/clubs/Drachaku.gif",
      level: 55,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 60,
      drop: "Soul of Dragonking Zyrtarch.",
      sellPrice: "10000",
      type: "club",
    },
    {
      name: "Dragon Hammer",
      image: "/images/tibia/items/clubs/Dragon_Hammer.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 32,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 97,
      drop: "Dragon, Juggernaut, Orc Warlord.",
      sellPrice: "2000",
      type: "club",
    },
    {
      name: "Dragonbone Staff",
      image: "/images/tibia/items/clubs/Dragonbone_Staff.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 18,
      drop: "Dragon, Elder Wyrm, Girtablilu Warrior, Hulking Carnisylvan, Juvenile Bashmu, Soul of Dragonking Zyrtarch, Tremendous Tyrant, Undead Dragon, Wyrm, Zanakeph.",
      sellPrice: "3000",
      type: "club",
    },
    {
      name: "Eldritch Warmace",
      image: "/images/tibia/items/clubs/Eldritch_Warmace.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 6,
      elementalDamage: "50 Fire",
      defense: 33,
      defenseModifier: "0",
      bonus: "Club Fighting +3",
      weight: 82,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Energized Demonbone",
      image: "/images/tibia/items/clubs/Energized_Demonbone.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 40,
      defenseModifier: "-1",
      bonus: "Nenhum",
      weight: 180,
      drop: "Mazzinor.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Falcon Mace",
      image: "/images/tibia/items/clubs/Falcon_Mace.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 11,
      elementalDamage: "41 Energy",
      defense: 33,
      defenseModifier: "+3",
      bonus: "Club Fighting +3",
      weight: 68,
      drop: "Grand Chaplain Gaunder, Grand Master Oberon.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Ferumbras' Staff (Club)",
      image: "/images/tibia/items/clubs/Ferumbras'_Staff_(Club).gif",
      level: 100,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 20,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 29,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Furry Club",
      image: "/images/tibia/items/clubs/Furry_Club.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 31,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 42,
      drop: "Bloodback, Clomp, Headpecker, Hulking Prehemoth, Mammoth, Shadowpelt, The Bloodtusk, Werebear, Wereboar.",
      sellPrice: "1000",
      type: "club",
    },
    {
      name: "Giant Smithhammer",
      image: "/images/tibia/items/clubs/Giant_Smithhammer.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 24,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "Ninguém.",
      sellPrice: "250",
      type: "club",
    },
    {
      name: "Gilded Eldritch Warmace",
      image: "/images/tibia/items/clubs/Gilded_Eldritch_Warmace.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 6,
      elementalDamage: "50 Fire",
      defense: 33,
      defenseModifier: "0",
      bonus: "Club Fighting +3",
      weight: 83,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Glooth Club",
      image: "/images/tibia/items/clubs/Glooth_Club.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "26 Earth",
      defense: 1,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19,
      drop: "Blood Beast, Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Blob, Glooth Brigand, Glooth Fairy, Glooth Golem, Lisa.",
      sellPrice: "1500",
      type: "club",
    },
    {
      name: "Glooth Whip",
      image: "/images/tibia/items/clubs/Glooth_Whip.gif",
      level: 25,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 33,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 32,
      drop: "Glooth Anemone, Lisa.",
      sellPrice: "2500",
      type: "club",
    },
    {
      name: "Glutton's Mace",
      image: "/images/tibia/items/clubs/Glutton's_Mace.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 13,
      drop: "Doctor Perhaps.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Grand Sanguine Bludgeon",
      image: "/images/tibia/items/clubs/Grand_Sanguine_Bludgeon.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Earth",
      defense: 35,
      defenseModifier: "0",
      bonus: "Club Fighting +5",
      weight: 91,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Grand Sanguine Cudgel",
      image: "/images/tibia/items/clubs/Grand_Sanguine_Cudgel.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Death",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Club Fighting +5",
      weight: 68,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Hammer of Destruction",
      image: "/images/tibia/items/clubs/Hammer_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "club",
    },
    {
      name: "Hammer of Prophecy",
      image: "/images/tibia/items/clubs/Hammer_of_Prophecy.gif",
      level: 120,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 110,
      drop: "The Last Lore Keeper.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Hammer of Wrath",
      image: "/images/tibia/items/clubs/Hammer_of_Wrath.gif",
      level: 65,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ashmunrah, Cliff Strider, Crape Man, Gore Horn, Melting Frozen Horror, Omruc, Plaguesmith, The Shatterer.",
      sellPrice: "30000",
      type: "club",
    },
    {
      name: "Heavy Mace",
      image: "/images/tibia/items/clubs/Heavy_Mace.gif",
      level: 70,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 110,
      drop: "Annihilon, Falcon Knight, Hellflayer, Juggernaut, Preceptor Lazare, Rhindeer, The Source Of Corruption, Ushuriel, Zavarash.",
      sellPrice: "50000",
      type: "club",
    },
    {
      name: "Iron Hammer",
      image: "/images/tibia/items/clubs/Iron_Hammer.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 18,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 66,
      drop: "Ninguém.",
      sellPrice: "9",
      type: "club",
    },
    {
      name: "Jade Hammer",
      image: "/images/tibia/items/clubs/Jade_Hammer.gif",
      level: 70,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 32,
      drop: "Ferumbras Mortal Shell, Katex Blood Tongue, Plagirath, The Time Guardian, War Golem, Yirkas Blue Scales.",
      sellPrice: "25000",
      type: "club",
    },
    {
      name: "Jungle Flail",
      image: "/images/tibia/items/clubs/Jungle_Flail.gif",
      level: 150,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 89,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Lich Staff",
      image: "/images/tibia/items/clubs/Lich_Staff.gif",
      level: 40,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 41,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Life Preserver",
      image: "/images/tibia/items/clubs/Life_Preserver.gif",
      level: 15,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 27,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 46,
      drop: "Ancient Lion Knight, Corym Charlatan, Corym Skirmisher, Corym Vanguard, Werehyaena.",
      sellPrice: "300",
      type: "club",
    },
    {
      name: "Light Mace",
      image: "/images/tibia/items/clubs/Light_Mace.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 14,
      elementalDamage: "Nenhum",
      defense: 9,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 41,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Lion Hammer",
      image: "/images/tibia/items/clubs/Lion_Hammer.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Earth",
      defense: 31,
      defenseModifier: "+2",
      bonus: "Club Fighting +3",
      weight: 65,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Lunar Staff",
      image: "/images/tibia/items/clubs/Lunar_Staff.gif",
      level: 30,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 38,
      drop: "Adept of the Cult, Lloyd.",
      sellPrice: "5000",
      type: "club",
    },
    {
      name: "Mace",
      image: "/images/tibia/items/clubs/Mace.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 11,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 38,
      drop: "Bandit, Brittle Skeleton, Chakoya Toolshaper, Crypt Defiler, Filth Toad, Gang Member, Gladiator, Gnome Pack Crawler, Grave Robber, Honour Guard, Jailer, Juvenile Cyclops, Meadow Strider, Minotaur, Minotaur Bruiser, Nomad, Rotworm, Salamander, Skeleton, Skeleton Elite Warrior, Skeleton Warrior, Toad, Undead Mine Worker, Wild Warrior, Zombie.",
      sellPrice: "30",
      type: "club",
    },
    {
      name: "Mace of Destruction",
      image: "/images/tibia/items/clubs/Mace_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "club",
    },
    {
      name: "Maimer",
      image: "/images/tibia/items/clubs/Maimer.gif",
      level: 150,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 77,
      drop: "Ferumbras Mortal Shell, Plagirath, Razzagorn, Shulgrax, The False God, Zamulosh.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Mallet Handle",
      image: "/images/tibia/items/clubs/Mallet_Handle.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 24,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 15,
      drop: "The Count Of The Core.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Mammoth Whopper",
      image: "/images/tibia/items/clubs/Mammoth_Whopper.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 30,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 45,
      drop: "Chakoya Toolshaper, Chakoya Tribewarden, Mammoth, Skeleton Elite Warrior.",
      sellPrice: "300",
      type: "club",
    },
    {
      name: "Metal Bat",
      image: "/images/tibia/items/clubs/Metal_Bat.gif",
      level: 55,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 51,
      drop: "Metal Gargoyle, Rustheap Golem.",
      sellPrice: "9000",
      type: "club",
    },
    {
      name: "Moohtant Cudgel",
      image: "/images/tibia/items/clubs/Moohtant_Cudgel.gif",
      level: 60,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 85,
      drop: "Bullwark, Moohtant, Moohtant Wallbreaker.",
      sellPrice: "14000",
      type: "club",
    },
    {
      name: "Morning Star",
      image: "/images/tibia/items/clubs/Morning_Star.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 25,
      elementalDamage: "Nenhum",
      defense: 11,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 54,
      drop: "Acolyte of the Cult, Bonelord, Enraged Soul, Gargoyle, Ghost, Gozzler, Hellspawn, Lizard Templar, Menacing Carnivor, Plaguesmith, Tarnished Spirit, War Golem, Young Sea Serpent.",
      sellPrice: "100",
      type: "club",
    },
    {
      name: "Mortal Mace",
      image: "/images/tibia/items/clubs/Mortal_Mace.gif",
      level: 220,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Death",
      defense: 27,
      defenseModifier: "+2",
      bonus: "Club Fighting +2",
      weight: 41,
      drop: "King Zelos.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Mycological Mace",
      image: "/images/tibia/items/clubs/Mycological_Mace.gif",
      level: 120,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Club Fighting +1",
      weight: 59,
      drop: "Abyssador, Deathstrike, Gnomevil.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Naga Club",
      image: "/images/tibia/items/clubs/Naga_Club.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Club Fighting +3",
      weight: 60,
      drop: "Timira the Many-Headed",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Northern Star",
      image: "/images/tibia/items/clubs/Northern_Star.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 42,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Obsidian Truncheon",
      image: "/images/tibia/items/clubs/Obsidian_Truncheon.gif",
      level: 100,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 65,
      drop: "Annihilon, Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Ogre Klubba",
      image: "/images/tibia/items/clubs/Ogre_Klubba.gif",
      level: 50,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 79,
      drop: "Ogre Brute, Ogre Ruffian.",
      sellPrice: "2500",
      type: "club",
    },
    {
      name: "One Hit Wonder",
      image: "/images/tibia/items/clubs/One_Hit_Wonder.gif",
      level: 70,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 96,
      drop: "Bullwark.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Onyx Flail",
      image: "/images/tibia/items/clubs/Onyx_Flail.gif",
      level: 65,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 26,
      drop: "Annihilon, Flameborn, Hellspawn, Hellhound, Retching Horror, Zavarash.",
      sellPrice: "22000",
      type: "club",
    },
    {
      name: "Orcish Maul",
      image: "/images/tibia/items/clubs/Orcish_Maul.gif",
      level: 35,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 54,
      drop: "Ninguém.",
      sellPrice: "6000",
      type: "club",
    },
    {
      name: "Ornate Mace",
      image: "/images/tibia/items/clubs/Ornate_Mace.gif",
      level: 90,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 149,
      drop: "Jaul, Tanjis, The False God.",
      sellPrice: "42000",
      type: "club",
    },
    {
      name: "Pair of Iron Fists",
      image: "/images/tibia/items/clubs/Pair_of_Iron_Fists.gif",
      level: 50,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 17,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 45,
      drop: "Lost Basher, Ogre Ruffian, Orclops Doomhauler, Orclops Ravager, Shulgrax, The False God.",
      sellPrice: "4000",
      type: "club",
    },
    {
      name: "Queen's Sceptre",
      image: "/images/tibia/items/clubs/Queen's_Sceptre.gif",
      level: 55,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 23,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "20000",
      type: "club",
    },
    {
      name: "Resizer",
      image: "/images/tibia/items/clubs/Resizer.gif",
      level: 230,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 11,
      elementalDamage: "46 Ice",
      defense: 33,
      defenseModifier: "0",
      bonus: "Club Fighting +2",
      weight: 89,
      drop: "Malofur Mangrinder.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Rotten Demonbone",
      image: "/images/tibia/items/clubs/Rotten_Demonbone.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 40,
      defenseModifier: "-1",
      bonus: "Nenhum",
      weight: 180,
      drop: "Lokathmor.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Sanguine Bludgeon",
      image: "/images/tibia/items/clubs/Sanguine_Bludgeon.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Earth",
      defense: 35,
      defenseModifier: "0",
      bonus: "Club Fighting +5",
      weight: 93,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Sanguine Cudgel",
      image: "/images/tibia/items/clubs/Sanguine_Cudgel.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Death",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Club Fighting +5",
      weight: 69,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Sapphire Hammer",
      image: "/images/tibia/items/clubs/Sapphire_Hammer.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 37,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 21,
      drop: "Boar Man, Cliff Strider, Crystal Spider, Enslaved Dwarf, Icecold Book, Ironblight, Stone Devourer, The Bloodweb, The Souldespoiler, The Source Of Corruption.",
      sellPrice: "7000",
      type: "club",
    },
    {
      name: "Scythe",
      image: "/images/tibia/items/clubs/Scythe.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 8,
      elementalDamage: "Nenhum",
      defense: 3,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 30,
      drop: "Blightwalker, Grim Reaper.",
      sellPrice: "10",
      type: "club",
    },
    {
      name: "Shadow Sceptre",
      image: "/images/tibia/items/clubs/Shadow_Sceptre.gif",
      level: 35,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 39,
      elementalDamage: "Nenhum",
      defense: 17,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 41,
      drop: "Anomaly, Burning Book, Choking Fear, Elder Wyrm, Enfeebled Silencer, Ferumbras Mortal Shell, Lady Tenebris, Nightmare Scion, Phantasm, Silencer, Shulgrax, Spectre, The Last Lore Keeper, The Unarmored Voidborn.",
      sellPrice: "10000",
      type: "club",
    },
    {
      name: "Silver Mace",
      image: "/images/tibia/items/clubs/Silver_Mace.gif",
      level: 45,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 41,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 67,
      drop: "Ninguém.",
      sellPrice: "270",
      type: "club",
    },
    {
      name: "Skull Staff",
      image: "/images/tibia/items/clubs/Skull_Staff.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 36,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 17,
      drop: "Alptramun, Blightwalker, Blood Hand, Blood Priest, Dipthrah, Infernalist, Izcandar the Banished, Enlightened of the Cult, Glooth Bandit, Golgordan, Gravedigger, Hand of Cursed Fate, Juvenile Bashmu, Lady Tenebris, Latrivan, Lich, Lost Exile, Lost Husher, Lost Soul, Malofur Mangrinder, Maxxenius, Necromancer, Necropharus, Plagueroot, Ragiaz, The Last Lore Keeper, The Sandking, The Source Of Corruption, The Time Guardian, Warlock, World Devourer.",
      sellPrice: "6000",
      type: "club",
    },
    {
      name: "Skullcrusher",
      image: "/images/tibia/items/clubs/Skullcrusher.gif",
      level: 85,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 120,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Snake God's Sceptre",
      image: "/images/tibia/items/clubs/Snake_God's_Sceptre.gif",
      level: 82,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 120,
      drop: "Hatebreeder, Soul of Dragonking Zyrtarch.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Soulcrusher",
      image: "/images/tibia/items/clubs/Soulcrusher.gif",
      level: 400,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 6,
      elementalDamage: "46 Ice",
      defense: 33,
      defenseModifier: "+3",
      bonus: "Club Fighting +5",
      weight: 41,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Soulmaimer",
      image: "/images/tibia/items/clubs/Soulmaimer.gif",
      level: 400,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 10,
      elementalDamage: "47 Energy",
      defense: 35,
      defenseModifier: "0",
      bonus: "Club Fighting +5",
      weight: 89,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Spiked Squelcher",
      image: "/images/tibia/items/clubs/Spiked_Squelcher.gif",
      level: 30,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 41,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "Bloodback, Braindeath, Bretzecutioner, Cliff Strider, Cyclops Smith, Drillworm, Falcon Knight, Flameborn, Grand Chaplain Gaunder, Hellspawn, Juggernaut, Lost Basher, Lost Berserker, Lost Exile, Lost Husher, Moohtant, Orclops Doomhauler, Orclops Ravager, Retching Horror, Rustheap Golem, Shadowpelt, Stampor, Stone Devourer, The Souldespoiler, Tromphonyte, Werebear, Worker Golem.",
      sellPrice: "5000",
      type: "club",
    },
    {
      name: "Spiky Club",
      image: "/images/tibia/items/clubs/Spiky_Club.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 31,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 39,
      drop: "Corym Vanguard, Naga Warrior.",
      sellPrice: "300",
      type: "club",
    },
    {
      name: "Staff",
      image: "/images/tibia/items/clubs/Staff.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 10,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 38,
      drop: "Barbarian Brutetamer, Foam Stalker, Monk.",
      sellPrice: "1",
      type: "club",
    },
    {
      name: "Stale Bread of Ancientness",
      image: "/images/tibia/items/clubs/Stale_Bread_of_Ancientness.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 18,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 5,
      drop: "Mephiles.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Strange Mallet",
      image: "/images/tibia/items/clubs/Strange_Mallet.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 18,
      elementalDamage: "Nenhum",
      defense: 9,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Studded Club",
      image: "/images/tibia/items/clubs/Studded_Club.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 9,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 35,
      drop: "Big Boss Trolliver, Island Troll, Mountain Troll, Troll, Troll Champion, Troll Guard, Troll Marauder.",
      sellPrice: "10",
      type: "club",
    },
    {
      name: "Sulphurous Demonbone",
      image: "/images/tibia/items/clubs/Sulphurous_Demonbone.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 40,
      defenseModifier: "-1",
      bonus: "Nenhum",
      weight: 180,
      drop: "Gorzindel.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Swampling Club",
      image: "/images/tibia/items/clubs/Swampling_Club.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 17,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 31,
      drop: "Leaf Golem, Minotaur Occultist, Swampling, Wilting Leaf Golem, Woodling.",
      sellPrice: "40",
      type: "club",
    },
    {
      name: "Taurus Mace",
      image: "/images/tibia/items/clubs/Taurus_Mace.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      attack: 30,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 51,
      drop: "Minotaur Cult Zealot, Minotaur Mage, Worm Priestess.",
      sellPrice: "500",
      type: "club",
    },
    {
      name: "The Stomper",
      image: "/images/tibia/items/clubs/The_Stomper.gif",
      level: 100,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 115,
      drop: "Annihilon.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Thunder Hammer",
      image: "/images/tibia/items/clubs/Thunder_Hammer.gif",
      level: 85,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 125,
      drop: "Ninguém.",
      sellPrice: "450",
      type: "club",
    },
    {
      name: "Umbral Hammer",
      image: "/images/tibia/items/clubs/Umbral_Hammer.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 165,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Umbral Mace",
      image: "/images/tibia/items/clubs/Umbral_Mace.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 26,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 85,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Umbral Master Hammer",
      image: "/images/tibia/items/clubs/Umbral_Master_Hammer.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 55,
      elementalDamage: "Nenhum",
      defense: 34,
      defenseModifier: "0",
      bonus: "Club Fighting +3",
      weight: 160,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Umbral Master Mace",
      image: "/images/tibia/items/clubs/Umbral_Master_Mace.gif",
      level: 250,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+3",
      bonus: "Club Fighting +1",
      weight: 80,
      drop: "Ninguém",
      sellPrice: "",
      type: "club",
    },
    {
      name: "Unliving Demonbone",
      image: "/images/tibia/items/clubs/Unliving_Demonbone.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 40,
      defenseModifier: "-1",
      bonus: "Nenhum",
      weight: 180,
      drop: "Ghulosh.",
      sellPrice: "",
      type: "club",
    },
    {
      name: "War Hammer",
      image: "/images/tibia/items/clubs/War_Hammer.gif",
      level: 50,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 85,
      drop: "Crawler, Enslaved Dwarf, Furious Troll, Headpecker, Hero, Hulking Carnisylvan, Hulking Prehemoth, Hideous Fungus, Iks Aucar, Juvenile Bashmu, Ogre Ruffian, Ogre Savage, Plaguesmith, Renegade Knight, Rustheap Golem, Stampor, Thornback Tortoise, Toad, Vicious Squire, Vile Grandmaster, Worker Golem.",
      sellPrice: "1200",
      type: "club",
    },
    //Sword
    {
      name: "Assassin Dagger",
      image: "/images/tibia/items/swords/Assassin_Dagger.gif",
      level: 40,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "-2",
      bonus: "Nenhum",
      weight: 17,
      drop: "Draken Elite, Frazzlemaw, Fury, Guzzlemaw, Katex Blood Tongue, Liodile, Madareth, Mazzinor, Midnight Asura, Ripper Spectre, The Blazing Rose, The Flaming Orchid, True Midnight Asura, Usurper Archer.",
      sellPrice: "20000",
      type: "sword",
    },
    {
      name: "Berserker",
      image: "/images/tibia/items/swords/Berserker.gif",
      level: 65,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 130,
      drop: "Ferumbras Mortal Shell, Rupture, The Unarmored Voidborn, War Golem.",
      sellPrice: "40000",
      type: "sword",
    },
    {
      name: "Blacksteel Sword",
      image: "/images/tibia/items/swords/Blacksteel_Sword.gif",
      level: 35,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 59,
      drop: "Ninguém.",
      sellPrice: "6000",
      type: "sword",
    },
    {
      name: "Blade of Corruption",
      image: "/images/tibia/items/swords/Blade_of_Corruption.gif",
      level: 82,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 64,
      drop: "Draken Elite, Essence Of Malice, Paiz the Pauperizer.",
      sellPrice: "60000",
      type: "sword",
    },
    {
      name: "Blade of Destruction",
      image: "/images/tibia/items/swords/Blade_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "sword",
    },
    {
      name: "Bloody Edge",
      image: "/images/tibia/items/swords/Bloody_Edge.gif",
      level: 55,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "-3",
      bonus: "Nenhum",
      weight: 52,
      drop: "Betrayed Wraith, Essence Of Malice, Ferumbras Mortal Shell, Madareth, Shulgrax, The Old Widow.",
      sellPrice: "30000",
      type: "sword",
    },
    {
      name: "Bone Sword",
      image: "/images/tibia/items/swords/Bone_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 13,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19,
      drop: "Cobra Assassin, Crypt Shambler, Braindeath.",
      sellPrice: "20",
      type: "sword",
    },
    {
      name: "Bright Sword",
      image: "/images/tibia/items/swords/Bright_Sword.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 36,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 29,
      drop: "The Enraged Thorn Knight.",
      sellPrice: "6000",
      type: "sword",
    },
    {
      name: "Broadsword",
      image: "/images/tibia/items/swords/Broadsword.gif",
      level: 0,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 26,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 52.5,
      drop: "Dragon, Orc Leader, Renegade Orc.",
      sellPrice: "500",
      type: "sword",
    },
    {
      name: "Broken Macuahuitl",
      image: "/images/tibia/items/swords/Broken_Macuahuitl.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 1,
      elementalDamage: "Nenhum",
      defense: 1,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 24,
      drop: "Ahau.",
      sellPrice: "1000",
      type: "sword",
    },
    {
      name: "Carlin Sword",
      image: "/images/tibia/items/swords/Carlin_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 15,
      elementalDamage: "Nenhum",
      defense: 13,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 40,
      drop: "Cobra Assassin, Stone Golem.",
      sellPrice: "118",
      type: "sword",
    },
    {
      name: "Cobra Sword",
      image: "/images/tibia/items/swords/Cobra_Sword.gif",
      level: 220,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "+2",
      bonus: "Sword Fighting +3",
      weight: 63,
      drop: "Scarlett Etzel, Ugly Monster.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Combat Knife",
      image: "/images/tibia/items/swords/Combat_Knife.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 8,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 8.7,
      drop: "Ancient Lion Knight, Assassin, Enraged Soul, Foam Stalker, Ghost, Smuggler, Tarnished Spirit, Werehyaena.",
      sellPrice: "1",
      type: "sword",
    },
    {
      name: "Cowtana",
      image: "/images/tibia/items/swords/Cowtana.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 34,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 28,
      drop: "Mooh'Tah Warrior.",
      sellPrice: "2500",
      type: "sword",
    },
    {
      name: "Crimson Sword",
      image: "/images/tibia/items/swords/Crimson_Sword.gif",
      level: 20,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 28,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 36,
      drop: "Parder, Sopping Corpus, Ushuriel.",
      sellPrice: "610",
      type: "sword",
    },
    {
      name: "Crimson Sword (Rashid)",
      image: "/images/tibia/items/swords/Crimson_Sword_%28Rashid%29.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 18,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 36,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Crude Umbral Blade",
      image: "/images/tibia/items/swords/Crude_Umbral_Blade.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 26,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 63,
      drop: "Ninguém",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Crude Umbral Slayer",
      image: "/images/tibia/items/swords/Crude_Umbral_Slayer.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 100,
      drop: "The Source Of Corruption.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Crystal Sword",
      image: "/images/tibia/items/swords/Crystal_Sword.gif",
      level: 25,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 35,
      elementalDamage: "Nenhum",
      defense: 26,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 69,
      drop: "Barbarian Skullhunter, Boar Man, Crystal Spider, Elf Overseer, Energy Elemental, Enraged Crystal Golem, High Voltage Elemental, Ice Golem, Ice Witch, Knowledge Elemental, Madareth, Melting Frozen Horror, Menacing Carnivor, Nighthunter, Rotspit, Shardhead, Spitter, Werelion, Werelioness.",
      sellPrice: "600",
      type: "sword",
    },
    {
      name: "Crystalline Sword",
      image: "/images/tibia/items/swords/Crystalline_Sword.gif",
      level: 62,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 34,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 48,
      drop: "Abyssador, Anomaly, Cliff Strider, Deathstrike, Gorzindel, Gnomevil, Harpy, Melting Frozen Horror, Outburst.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Dagger",
      image: "/images/tibia/items/swords/Dagger.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 8,
      elementalDamage: "Nenhum",
      defense: 6,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 9.5,
      drop: "Amazon, Brutus Bloodbeard, Crazed Summer Vanguard, Deadeye Devious, Faceless Bane, Goblin, Goblin Assassin, Goblin Leader, Goblin Scavenger, Muglex Clan Assassin, Muglex Clan Footman, Muglex Clan Scavenger, Naga Warrior, Ron The Ripper.",
      sellPrice: "2",
      type: "sword",
    },
    {
      name: "Demonrage Sword",
      image: "/images/tibia/items/swords/Demonrage_Sword.gif",
      level: 60,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 150,
      drop: "Angry Demon, Ayana the Crimson Curse, Demon, Demon Outcast, Ferumbras Mortal Shell, Katex Blood Tongue, Mazoran, Rage Squid, Srezz Yellow Eyes, Vexclaw, Yirkas Blue Scales.",
      sellPrice: "36000",
      type: "sword",
    },
    {
      name: "Djinn Blade",
      image: "/images/tibia/items/swords/Djinn_Blade.gif",
      level: 35,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 38,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 24.5,
      drop: "Ancient Spawn of Morgathla, Thalas, The Sandking.",
      sellPrice: "15000",
      type: "sword",
    },
    {
      name: "Dragon Slayer",
      image: "/images/tibia/items/swords/Dragon_Slayer.gif",
      level: 45,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 28,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 82,
      drop: "Dragon Lord, Frost Dragon, Ushuriel, Undead Dragon.",
      sellPrice: "15000",
      type: "sword",
    },
    {
      name: "Eldritch Claymore",
      image: "/images/tibia/items/swords/Eldritch_Claymore.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 6,
      elementalDamage: "50 Fire",
      defense: 33,
      defenseModifier: "0",
      bonus: "Sword Fighting +3",
      weight: 85,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Emerald Sword",
      image: "/images/tibia/items/swords/Emerald_Sword.gif",
      level: 100,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 33,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 41,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Epee",
      image: "/images/tibia/items/swords/Epee.gif",
      level: 30,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 37,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 14.5,
      drop: "Esmeralda, Girtablilu Warrior, Hulking Carnisylvan, Insectoid Worker, Ironblight, Swarmer, Tarbaz, The Lord of the Lice, War Golem, Werewolf, Yielothax.",
      sellPrice: "8000",
      type: "sword",
    },
    {
      name: "Falcon Longsword",
      image: "/images/tibia/items/swords/Falcon_Longsword.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 56,
      elementalDamage: "Nenhum",
      defense: 34,
      defenseModifier: "0",
      bonus: "Sword Fighting +4",
      weight: 82,
      drop: "Grand Chaplain Gaunder, Grand Master Oberon.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Fire Sword",
      image: "/images/tibia/items/swords/Fire_Sword.gif",
      level: 30,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 24,
      elementalDamage: "11 Fire",
      defense: 20,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 23,
      drop: "Albino Dragon, Demodras, Dragon Lord, Guardian Of Tales, Hellfire Fighter, Hellhound, Hero, Kerberos, Lava Golem, Magma Crawler, Massive Fire Elemental, Pirat Mate, Renegade Knight, Retching Horror, The Count Of The Core, The Baron from Below, The Duke Of The Depths, Thornfire Wolf, Vile Grandmaster, Vulcongra, Ushuriel, Weeper.",
      sellPrice: "4000",
      type: "sword",
    },
    {
      name: "Giant Sword",
      image: "/images/tibia/items/swords/Giant_Sword.gif",
      level: 55,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 180,
      drop: "Angry Demon, Askarak Prince, Behemoth, Bibby Bloodbath, Bretzecutioner, Cliff Strider, Demon, Demon Outcast, Destroyer, Golgordan, Hellhound, Katex Blood Tongue, Latrivan, Rage Squid, Srezz Yellow Eyes, Stonecracker, Stone Devourer, Terrorsleep, The Count Of The Core, The False God, Ushuriel, Vexclaw, Yirkas Blue Scales, Rotten Golem.",
      sellPrice: "17000",
      type: "sword",
    },
    {
      name: "Gilded Eldritch Claymore",
      image: "/images/tibia/items/swords/Gilded_Eldritch_Claymore.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 6,
      elementalDamage: "50 Fire",
      defense: 33,
      defenseModifier: "0",
      bonus: "Sword Fighting +3",
      weight: 86,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Glooth Blade",
      image: "/images/tibia/items/swords/Glooth_Blade.gif",
      level: 75,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 39,
      elementalDamage: "26 Earth",
      defense: 1,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 19,
      drop: "Blood Beast, Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Blob, Glooth Brigand, Glooth Golem, Glooth Fairy, Lisa.",
      sellPrice: "1500",
      type: "sword",
    },
    {
      name: "Gnome Sword",
      image: "/images/tibia/items/swords/Gnome_Sword.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 10,
      elementalDamage: "42 Energy",
      defense: 29,
      defenseModifier: "+3",
      bonus: "Sword Fighting +2",
      weight: 85,
      drop: "The Baron from Below, The Count Of The Core, The Duke Of The Depths.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Golden Magic Longsword",
      image: "/images/tibia/items/swords/Golden_Magic_Longsword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 0,
      elementalDamage: "Nenhum",
      defense: 0,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 43,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Golden Warlord Sword",
      image: "/images/tibia/items/swords/Golden_Warlord_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 0,
      elementalDamage: "Nenhum",
      defense: 0,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 64,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Grand Sanguine Blade",
      image: "/images/tibia/items/swords/Grand_Sanguine_Blade.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Fire",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Sword Fighting +5",
      weight: 60,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Grand Sanguine Razor",
      image: "/images/tibia/items/swords/Grand_Sanguine_Razor.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Energy",
      defense: 35,
      defenseModifier: "0",
      bonus: "Sword Fighting +5",
      weight: 79,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Haunted Blade",
      image: "/images/tibia/items/swords/Haunted_Blade.gif",
      level: 30,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 40,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 31,
      drop: "Bashmu, Braindeath, Enfeebled Silencer, Frazzlemaw, Guzzlemaw, Lost Soul, Lokathmor, Madareth, Nightstalker, Silencer, The Souldespoiler, Unaz the Mean, Varnished Diremaw.",
      sellPrice: "8000",
      type: "sword",
    },
    {
      name: "Havoc Blade",
      image: "/images/tibia/items/swords/Havoc_Blade.gif",
      level: 70,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 34,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 72,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Heavy Machete",
      image: "/images/tibia/items/swords/Heavy_Machete.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 18.4,
      drop: "Cobra Assassin, Cyclops Smith, Efreet, Marid, Menacing Carnivor, Merikh the Slaughterer.",
      sellPrice: "90",
      type: "sword",
    },
    {
      name: "Ice Rapier",
      image: "/images/tibia/items/swords/Ice_Rapier.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 42,
      elementalDamage: "18 Ice",
      defense: 1,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 15,
      drop: "Angry Demon, Animated Snowman, Broken Shaper, Crazed Winter Rearguard, Crazed Winter Vanguard, Demon, Demon Outcast, Faceless Bane, Frost Dragon, Girtablilu Warrior, Golgordan, Icecold Book, Ice Golem, Latrivan, Lumbering Carnivor, Madareth, Melting Frozen Horror, Pirat Mate, Renegade Quara Mantassin, Tremendous Tyrant, Vampire, Vampire Viscount, Vexclaw.",
      sellPrice: "1000",
      type: "sword",
    },
    {
      name: "Impaler of the Igniter",
      image: "/images/tibia/items/swords/Impaler_of_the_Igniter.gif",
      level: 150,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 25,
      elementalDamage: "26 Fire",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 59,
      drop: "Mazoran.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Incredible Mumpiz Slayer",
      image: "/images/tibia/items/swords/Incredible_Mumpiz_Slayer.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 17,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 42,
      drop: "Monstor.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Jagged Sword",
      image: "/images/tibia/items/swords/Jagged_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 21,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 29,
      drop: "Ninguém.",
      sellPrice: "500",
      type: "sword",
    },
    {
      name: "Katana",
      image: "/images/tibia/items/swords/Katana.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 31,
      drop: "Naga Warrior, Stalker, Vampire.",
      sellPrice: "35",
      type: "sword",
    },
    {
      name: "Knife",
      image: "/images/tibia/items/swords/Knife.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 5,
      elementalDamage: "Nenhum",
      defense: 5,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 4.2,
      drop: "Ancient Lion Knight, Assassin, Barbarian Headsplitter, Barbarian Skullhunter, Cobra Assassin, Crazed Summer Vanguard, Headpecker, Undead Prospector, Usurper Archer, Smuggler, Werehyaena.",
      sellPrice: "1",
      type: "sword",
    },
    {
      name: "Lion Longsword",
      image: "/images/tibia/items/swords/Lion_Longsword.gif",
      level: 270,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Earth",
      defense: 31,
      defenseModifier: "+2",
      bonus: "Sword Fighting +3",
      weight: 75,
      drop: "Drume.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Longsword",
      image: "/images/tibia/items/swords/Longsword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 17,
      elementalDamage: "Nenhum",
      defense: 14,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 42,
      drop: "Bonelord, Dragon, Elf, Firestarter, Marsh Stalker, Meadow Strider, Orc Leader, Quara Constrictor, Quara Constrictor Scout, Renegade Orc, Renegade Quara Constrictor, Tomb Servant.",
      sellPrice: "51",
      type: "sword",
    },
    {
      name: "Machete",
      image: "/images/tibia/items/swords/Machete.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 12,
      elementalDamage: "Nenhum",
      defense: 9,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 16.5,
      drop: "Cobra Assassin, Menacing Carnivor, Orc Spearman.",
      sellPrice: "6",
      type: "sword",
    },
    {
      name: "Magic Longsword",
      image: "/images/tibia/items/swords/Magic_Longsword.gif",
      level: 140,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 55,
      elementalDamage: "Nenhum",
      defense: 40,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 43,
      drop: "Ninguém.",
      sellPrice: "460",
      type: "sword",
    },
    {
      name: "Magic Sword",
      image: "/images/tibia/items/swords/Magic_Sword.gif",
      level: 80,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 48,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 42,
      drop: "Ninguém.",
      sellPrice: "350",
      type: "sword",
    },
    {
      name: "Mean Knight Sword",
      image: "/images/tibia/items/swords/Mean_Knight_Sword.gif",
      level: 0,
      vocation: "players without vocation",
      slots: 0,
      hands: "Uma",
      attack: 14,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 20,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Mercenary Sword",
      image: "/images/tibia/items/swords/Mercenary_Sword.gif",
      level: 40,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 27,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "Amenef The Burning, Betrayed Wraith, Bruise Payne, Ghulosh, Glooth Brigand, Madareth, Mutated Bat, Plagirath, Retching Horror, Serpent Spawn, The Noxious Spawn, Utua Stone Sting, Rotten Golem.",
      sellPrice: "12000",
      type: "sword",
    },
    {
      name: "Mystic Blade",
      image: "/images/tibia/items/swords/Mystic_Blade.gif",
      level: 60,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 44,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "30000",
      type: "sword",
    },
    {
      name: "Naga Sword",
      image: "/images/tibia/items/swords/Naga_Sword.gif",
      level: 300,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "44 Ice",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Sword Fighting +3",
      weight: 52,
      drop: "Timira the Many-Headed",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Nightmare Blade",
      image: "/images/tibia/items/swords/Nightmare_Blade.gif",
      level: 70,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 46,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "-3",
      bonus: "Nenhum",
      weight: 46,
      drop: "Ferumbras Mortal Shell, Frazzlemaw, Grimeleech, Grim Reaper, Guzzlemaw, Kroazur, Madareth, Prince Drazzak, Tormentor, Weakened Frazzlemaw.",
      sellPrice: "35000",
      type: "sword",
    },
    {
      name: "Pharaoh Sword",
      image: "/images/tibia/items/swords/Pharaoh_Sword.gif",
      level: 45,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 41,
      elementalDamage: "Nenhum",
      defense: 23,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 52,
      drop: "Dipthrah, Horestis.",
      sellPrice: "23000",
      type: "sword",
    },
    {
      name: "Poet's Fencing Quill",
      image: "/images/tibia/items/swords/Poet's_Fencing_Quill.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 10,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 2,
      drop: "Mephiles.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Pointed Rabbitslayer",
      image: "/images/tibia/items/swords/Pointed_Rabbitslayer.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 1.6,
      drop: "Dirtbeard.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Poison Dagger",
      image: "/images/tibia/items/swords/Poison_Dagger.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 16,
      elementalDamage: "2 Earth",
      defense: 8,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 8.8,
      drop: "Banshee, Dworc Venomsniper, Dworc Fleshhunter, Dworc Voodoomaster, Elder Mummy, Mummy, Orc Warrior, Thalas, Warlock, Putrid Mummy.",
      sellPrice: "50",
      type: "sword",
    },
    {
      name: "Rapier",
      image: "/images/tibia/items/swords/Rapier.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 10,
      elementalDamage: "Nenhum",
      defense: 8,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 15,
      drop: "Frost Troll, Mountain Troll, Werelioness.",
      sellPrice: "5",
      type: "sword",
    },
    {
      name: "Ratana",
      image: "/images/tibia/items/swords/Ratana.gif",
      level: 15,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 27,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 33,
      drop: "Corym Charlatan,Corym Skirmisher, Corym Vanguard, Werehyaena.",
      sellPrice: "500",
      type: "sword",
    },
    {
      name: "Relic Sword",
      image: "/images/tibia/items/swords/Relic_Sword.gif",
      level: 50,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 48,
      drop: "Madareth, Naga Warrior, Priestess Of The Wild Sun, Quara Predator, Renegade Quara Predator, Shadowpelt, Sopping Corpus, Spectre, Thul, Undead Elite Gladiator, Undertaker, Werebear, Werewolf.",
      sellPrice: "25000",
      type: "sword",
    },
    {
      name: "Ron the Ripper's Sabre",
      image: "/images/tibia/items/swords/Ron_the_Ripper's_Sabre.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 12,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 25,
      drop: "Ron The Ripper.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Runed Sword",
      image: "/images/tibia/items/swords/Runed_Sword.gif",
      level: 65,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 32,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 46,
      drop: "Ferumbras Mortal Shell, Prince Drazzak, Ragiaz, The Sandking, The Time Guardian, Ushuriel.",
      sellPrice: "45000",
      type: "sword",
    },
    {
      name: "Sabre",
      image: "/images/tibia/items/swords/Sabre.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 12,
      elementalDamage: "Nenhum",
      defense: 10,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 25,
      drop: "Amazon, Crazed Summer Vanguard, Gozzler, Orc, Pirate Buccaneer, Pirate Corsair.",
      sellPrice: "12",
      type: "sword",
    },
    {
      name: "Sai",
      image: "/images/tibia/items/swords/Sai.gif",
      level: 50,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 27,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Black Vixen, Choking Fear, Darkfang, Deep Terror, Ethershreck, Frazzlemaw, Guzzlemaw, Hemming, Paiz the Pauperizer, Plagirath, The Bloodweb, Weakened Frazzlemaw.",
      sellPrice: "16500",
      type: "sword",
    },
    {
      name: "Sanguine Blade",
      image: "/images/tibia/items/swords/Sanguine_Blade.gif",
      level: 600,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 8,
      elementalDamage: "46 Fire",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Sword Fighting +5",
      weight: 61,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Sanguine Razor",
      image: "/images/tibia/items/swords/Sanguine_Razor.gif",
      level: 600,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 8,
      elementalDamage: "50 Energy",
      defense: 35,
      defenseModifier: "0",
      bonus: "Sword Fighting +5",
      weight: 81,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Scimitar",
      image: "/images/tibia/items/swords/Scimitar.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 19,
      elementalDamage: "Nenhum",
      defense: 13,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 29,
      drop: "Burning Book, Cobra Assassin, Crazed Summer Vanguard, Diabolic Imp, Fire Devil, Honour Guard, Naga Warrior, Orc Leader, Orc Warlord, Renegade Orc, Salamander, Undead Elite Gladiator, Undead Gladiator, Ushuriel.",
      sellPrice: "150",
      type: "sword",
    },
    {
      name: "Serpent Sword",
      image: "/images/tibia/items/swords/Serpent_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 18,
      elementalDamage: "8 Earth",
      defense: 15,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 41,
      drop: "Adult Goanna, Cobra Vizier, Dragon, Gozzler, Menacing Carnivor, Naga Warrior, Noxious Ripptor, Omnivora, Ripper Spectre, Sea Serpent, Thalas, Werelioness, Wiggler, Young Goanna.",
      sellPrice: "900",
      type: "sword",
    },
    {
      name: "Shimmer Sword",
      image: "/images/tibia/items/swords/Shimmer_Sword.gif",
      level: 40,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 42,
      elementalDamage: "Nenhum",
      defense: 20,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 52,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Shiny Blade",
      image: "/images/tibia/items/swords/Shiny_Blade.gif",
      level: 120,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 49,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "+2",
      bonus: "Sword Fighting +1",
      weight: 45,
      drop: "Abyssador, Deathstrike, Gnomevil, Melting Frozen Horror.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Short Sword",
      image: "/images/tibia/items/swords/Short_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 11,
      elementalDamage: "Nenhum",
      defense: 11,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 35,
      drop: "Animated Cyclops, Chakoya Tribewarden, Cyclops, Cyclops Drone, Frost Giant, Frost Giantess, Goblin, Goblin Assassin, Goblin Leader, Goblin Scavenger, Lizard Templar, Muglex Clan Assassin, Muglex Clan Footman, Muglex Clan Scavenger, Pirate Skeleton, Salamander Trainer, Smuggler, Troll-Trained Salamander.",
      sellPrice: "10",
      type: "sword",
    },
    {
      name: "Silver Dagger",
      image: "/images/tibia/items/swords/Silver_Dagger.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 9,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 10.2,
      drop: "Ekatrix, Golgordan, Latrivan, Witch.",
      sellPrice: "500",
      type: "sword",
    },
    {
      name: "Slayer of Destruction",
      image: "/images/tibia/items/swords/Slayer_of_Destruction.gif",
      level: 200,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "sword",
    },
    {
      name: "Soulcutter",
      image: "/images/tibia/items/swords/Soulcutter.gif",
      level: 400,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 7,
      elementalDamage: "45 Death",
      defense: 32,
      defenseModifier: "+3",
      bonus: "Sword Fighting +5",
      weight: 63,
      drop: "Ninguém",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Soulshredder",
      image: "/images/tibia/items/swords/Soulshredder.gif",
      level: 400,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 10,
      elementalDamage: "47 Ice",
      defense: 35,
      defenseModifier: "0",
      bonus: "Sword Fighting +5",
      weight: 81,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Spike Sword",
      image: "/images/tibia/items/swords/Spike_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 24,
      elementalDamage: "Nenhum",
      defense: 21,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 50,
      drop: "Foam Stalker, Headpecker, Pirate Ghost, Ripper Spectre, Ushuriel, Vampire.",
      sellPrice: "1000",
      type: "sword",
    },
    {
      name: "Summerblade",
      image: "/images/tibia/items/swords/Summerblade.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 10,
      elementalDamage: "41 Fire",
      defense: 20,
      defenseModifier: "+3",
      bonus: "Sword Fighting +1",
      weight: 43,
      drop: "Izcandar the Banished.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Sword",
      image: "/images/tibia/items/swords/Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 14,
      elementalDamage: "Nenhum",
      defense: 12,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 35,
      drop: "Brittle Skeleton, Crazed Summer Vanguard, Gladiator, Hellfire Fighter, Jailer, Juvenile Cyclops, Lizard Templar, Lumbering Carnivor, Minotaur, Minotaur Bruiser, Mutated Human, Pirate Skeleton, Rotworm, Skeleton, Skeleton Elite Warrior, Skeleton Warrior, Smuggler, Smuggler Baron Silvertoe, Undead Mine Worker.",
      sellPrice: "25",
      type: "sword",
    },
    {
      name: "Tagralt Blade",
      image: "/images/tibia/items/swords/Tagralt_Blade.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 7,
      elementalDamage: "49 Earth",
      defense: 32,
      defenseModifier: "0",
      bonus: "Sword Fighting +3",
      weight: 81,
      drop: "Urmahlullu the Weakened.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Templar Scytheblade",
      image: "/images/tibia/items/swords/Templar_Scytheblade.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 23,
      elementalDamage: "Nenhum",
      defense: 15,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 29,
      drop: "High Templar Cobrass, Lizard Templar.",
      sellPrice: "200",
      type: "sword",
    },
    {
      name: "Thaian Sword",
      image: "/images/tibia/items/swords/Thaian_Sword.gif",
      level: 50,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 45,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 61,
      drop: "Ushuriel.",
      sellPrice: "16000",
      type: "sword",
    },
    {
      name: "The Avenger",
      image: "/images/tibia/items/swords/The_Avenger.gif",
      level: 75,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 38,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 64,
      drop: "Ninguém.",
      sellPrice: "42000",
      type: "sword",
    },
    {
      name: "The Calamity",
      image: "/images/tibia/items/swords/The_Calamity.gif",
      level: 100,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 51,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 68,
      drop: "The Scourge of Oblivion.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "The Epiphany",
      image: "/images/tibia/items/swords/The_Epiphany.gif",
      level: 120,
      vocation: "Todas",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "The Justice Seeker",
      image: "/images/tibia/items/swords/The_Justice_Seeker.gif",
      level: 75,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 24,
      defenseModifier: "+3",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ninguém.",
      sellPrice: "40000",
      type: "sword",
    },
    {
      name: "Twiceslicer",
      image: "/images/tibia/items/swords/Twiceslicer.gif",
      level: 58,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 47,
      elementalDamage: "Nenhum",
      defense: 30,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 180,
      drop: "Draken Elite, Paiz the Pauperizer, The Count Of The Core.",
      sellPrice: "28000",
      type: "sword",
    },
    {
      name: "Twin Hooks",
      image: "/images/tibia/items/swords/Twin_Hooks.gif",
      level: 20,
      vocation: "Knights",
      slots: 3,
      hands: "Duas",
      attack: 32,
      elementalDamage: "Nenhum",
      defense: 22,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 50,
      drop: "Ghastly Dragon, Hatebreeder, Mindmasher, Ripper Spectre.",
      sellPrice: "500",
      type: "sword",
    },
    {
      name: "Two Handed Sword",
      image: "/images/tibia/items/swords/Two_Handed_Sword.gif",
      level: 20,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 30,
      elementalDamage: "Nenhum",
      defense: 25,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 70,
      drop: "Behemoth, Bibby Bloodbath, Black Knight, Bonelord, Crazed Summer Vanguard, Crypt Shambler, Elder Bonelord, Filth Toad, Frazzlemaw, Giant Spider, Guzzlemaw, Hero, Lumbering Carnivor, Madareth, Orc Warlord, Plaguesmith, Quara Mantassin, Quara Mantassin Scout, Quara Predator Scout, Renegade Knight, Renegade Quara Mantassin, Ripper Spectre, Stonecracker, Undead Elite Gladiator, Undead Gladiator, War Golem.",
      sellPrice: "450",
      type: "sword",
    },
    {
      name: "Umbral Blade",
      image: "/images/tibia/items/swords/Umbral_Blade.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 50,
      elementalDamage: "Nenhum",
      defense: 29,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 59,
      drop: "Ninguém",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Umbral Master Slayer",
      image: "/images/tibia/items/swords/Umbral_Master_Slayer.gif",
      level: 250,
      vocation: "Knights",
      slots: 2,
      hands: "Duas",
      attack: 54,
      elementalDamage: "Nenhum",
      defense: 35,
      defenseModifier: "0",
      bonus: "Sword Fighting +3",
      weight: 90,
      drop: "Ninguém",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Umbral Masterblade",
      image: "/images/tibia/items/swords/Umbral_Masterblade.gif",
      level: 250,
      vocation: "Knights",
      slots: 1,
      hands: "Uma",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "+3",
      bonus: "Sword Fighting +1",
      weight: 55,
      drop: "Ninguém",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Umbral Slayer",
      image: "/images/tibia/items/swords/Umbral_Slayer.gif",
      level: 120,
      vocation: "Knights",
      slots: 1,
      hands: "Duas",
      attack: 52,
      elementalDamage: "Nenhum",
      defense: 31,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 95,
      drop: "The Source Of Corruption.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Warlord Sword",
      image: "/images/tibia/items/swords/Warlord_Sword.gif",
      level: 120,
      vocation: "Knights",
      slots: 0,
      hands: "Duas",
      attack: 53,
      elementalDamage: "Nenhum",
      defense: 38,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 64,
      drop: "Ninguém.",
      sellPrice: "360",
      type: "sword",
    },
    {
      name: "Winterblade",
      image: "/images/tibia/items/swords/Winterblade.gif",
      level: 200,
      vocation: "Knights",
      slots: 2,
      hands: "Uma",
      attack: 10,
      elementalDamage: "40 Ice",
      defense: 22,
      defenseModifier: "+3",
      bonus: "Sword Fighting +1",
      weight: 43,
      drop: "Izcandar the Banished.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Wooden Sword",
      image: "/images/tibia/items/swords/Wooden_Sword.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      attack: 7,
      elementalDamage: "Nenhum",
      defense: 7,
      defenseModifier: "0",
      bonus: "Nenhum",
      weight: 20,
      drop: "Ninguém.",
      sellPrice: "",
      type: "sword",
    },
    {
      name: "Wyvern Fang",
      image: "/images/tibia/items/swords/Wyvern_Fang.gif",
      level: 25,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 32,
      elementalDamage: "Nenhum",
      defense: 19,
      defenseModifier: "+1",
      bonus: "Nenhum",
      weight: 20,
      drop: "Ripper Spectre, Wyvern.",
      sellPrice: "1500",
      type: "sword",
    },
    {
      name: "Zaoan Sword",
      image: "/images/tibia/items/swords/Zaoan_Sword.gif",
      level: 55,
      vocation: "Todas",
      slots: 2,
      hands: "Uma",
      attack: 43,
      elementalDamage: "Nenhum",
      defense: 18,
      defenseModifier: "+2",
      bonus: "Nenhum",
      weight: 49,
      drop: "Draken Elite, Eternal Guardian, Ethershreck, Ghastly Dragon, Hatebreeder, Lizard Gate Guardian, Paiz the Pauperizer.",
      sellPrice: "30000",
      type: "sword",
    },
    //rod
    {
      name: "Cobra Rod",
      image: "/images/tibia/items/rods/Cobra_Rod.gif",
      level: 220,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "90",
      manaPerAttack: "21",
      attributes: "Magic Level +2",
      weight: 25,
      drop: "Gaffir, Scarlett Etzel, Ugly Monster.",
      sellPrice: "",
    },
    {
      name: "Deepling Ceremonial Dagger",
      image: "/images/tibia/items/rods/Deepling_Ceremonial_Dagger.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "rod",
      averageDamage: "90",
      manaPerAttack: "23",
      attributes: "Magic Level +2",
      weight: 32,
      drop: "Brokul",
      sellPrice: "",
    },
    {
      name: "Deepling Fork",
      image: "/images/tibia/items/rods/Deepling_Fork.gif",
      level: 230,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "rod",
      averageDamage: "90",
      manaPerAttack: "23",
      attributes: "Magic Level +2",
      weight: 33,
      drop: "Brokul.",
      sellPrice: "",
    },
    {
      name: "Dream Blossom Staff",
      image: "/images/tibia/items/rods/Dream_Blossom_Staff.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "rod",
      averageDamage: "70",
      manaPerAttack: "18",
      attributes: "Magic Level +1",
      weight: 32,
      drop: "Boogy, Dark Faun, Mercurial Menace, Nymph, Pixie, Pooka.",
      sellPrice: "5000",
    },
    {
      name: "Eldritch Rod",
      image: "/images/tibia/items/rods/Eldritch_Rod.gif",
      level: 250,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "85-105",
      manaPerAttack: "22",
      attributes: "Magic Level +2, Healing Magic Level +2",
      weight: 35,
      drop: "The Brainstealer.",
      sellPrice: "",
    },
    {
      name: "Energized Limb",
      image: "/images/tibia/items/rods/Energized_Limb.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "rod",
      averageDamage: "98",
      manaPerAttack: "24",
      attributes: "Magic Level +2",
      weight: 27,
      drop: "Maxxenius.",
      sellPrice: "",
    },
    {
      name: "Falcon Rod",
      image: "/images/tibia/items/rods/Falcon_Rod.gif",
      level: 300,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "90",
      manaPerAttack: "20",
      attributes: "Magic Level +3",
      weight: 37,
      drop: "Grand Master Oberon, Preceptor Lazare.",
      sellPrice: "",
    },
    {
      name: "Gilded Eldritch Rod",
      image: "/images/tibia/items/rods/Gilded_Eldritch_Rod.gif",
      level: 250,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "85-105",
      manaPerAttack: "22",
      attributes: "Magic Level +2, Healing Magic Level +2",
      weight: 36,
      drop: "The Brainstealer.",
      sellPrice: "",
    },
    {
      name: "Glacial Rod",
      image: "/images/tibia/items/rods/Glacial_Rod.gif",
      level: 65,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "85",
      manaPerAttack: "17",
      attributes: "Magic Level +1",
      weight: 37,
      drop: "Burster Spectre, Cliff Strider, Flimsy Lost Soul, Icecold Book, Ironblight.",
      sellPrice: "6500",
    },
    {
      name: "Grand Sanguine Rod",
      image: "/images/tibia/items/rods/Grand_Sanguine_Rod.gif",
      level: 600,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "112",
      manaPerAttack: "20",
      attributes: "Magic Level +5, Ice Magic Level +1, Earth Magic Level +1",
      weight: 24,
      drop: "Desconhecido.",
      sellPrice: "",
    },
    {
      name: "Hailstorm Rod",
      image: "/images/tibia/items/rods/Hailstorm_Rod.gif",
      level: 33,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 27,
      drop: "Adept of the Cult, Blightwalker, Burster Spectre, Crazed Winter Rearguard, Crystal Wolf, Dark Carnisylvan, Faceless Bane, Flimsy Lost Soul, Ice Witch, Madareth, Marid, Melting Frozen Horror, Priestess, The Sandking, Tremendous Tyrant, Varnished Diremaw, Werehyaena Shaman.",
      sellPrice: "3000",
    },
    {
      name: "Jungle Rod",
      image: "/images/tibia/items/rods/Jungle_Rod.gif",
      level: 150,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "80-100",
      manaPerAttack: "19",
      attributes: "Magic Level +1",
      weight: 37,
      drop: "Ratmiral Blackwhiskers",
      sellPrice: "",
    },
    {
      name: "Lion Rod",
      image: "/images/tibia/items/rods/Lion_Rod.gif",
      level: 270,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "85-105",
      manaPerAttack: "20",
      attributes: "Magic Level +2",
      weight: 26,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
    },
    {
      name: "Moonlight Rod",
      image: "/images/tibia/items/rods/Moonlight_Rod.gif",
      level: 13,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "19",
      manaPerAttack: "3",
      attributes: "Nenhum",
      weight: 21,
      drop: "Animated Snowman, Black Vixen, Crazed Winter Rearguard, Golgordan, Latrivan, Minotaur Cult Prophet, Necropharus, The Blazing Rose, The Flaming Orchid, The Lily Of Night, Vampire Bride, Werefox.",
      sellPrice: "200",
    },
    {
      name: "Muck Rod",
      image: "/images/tibia/items/rods/Muck_Rod.gif",
      level: 65,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "85",
      manaPerAttack: "17",
      attributes: "Magic Level +1",
      weight: 37,
      drop: "Faceless Bane, Gorzindel, Hideous Fungus, Humongous Fungus, Kusuma, Noxious Ripptor, Plagirath, Ravenous Hunger, Stalking Stalk, The Duke Of The Depths.",
      sellPrice: "6000",
    },
    {
      name: "Naga Rod",
      image: "/images/tibia/items/rods/Naga_Rod.gif",
      level: 250,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "90-110",
      manaPerAttack: "21",
      attributes: "Magic Level +2, Ice Magic Level +2",
      weight: 29,
      drop: "Timira the Many-Headed",
      sellPrice: "",
    },
    {
      name: "Necrotic Rod",
      image: "/images/tibia/items/rods/Necrotic_Rod.gif",
      level: 19,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "30",
      manaPerAttack: "5",
      attributes: "Nenhum",
      weight: 23,
      drop: "Blood Hand, Burning Book, Dark Magician, Death Priest Shargon, Diabolic Imp, Faceless Bane, Fire Devil, Golgordan, Latrivan, Midnight Asura, Ogre Shaman, Omnivora, Shaper Matriarch, Souleater, The Flaming Orchid, True Midnight Asura, Undertaker, Venerable Girtablilu, Witch.",
      sellPrice: "1000",
    },
    {
      name: "Northwind Rod",
      image: "/images/tibia/items/rods/Northwind_Rod.gif",
      level: 22,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "30",
      manaPerAttack: "5",
      attributes: "Nenhum",
      weight: 29,
      drop: "Crazed Winter Rearguard, Crazed Winter Vanguard, Quara Predator Scout, Renegade Quara Constrictor, Roaring Water Elemental, Sea Serpent, Venerable Girtablilu.",
      sellPrice: "1500",
    },
    {
      name: "Ogre Scepta",
      image: "/images/tibia/items/rods/Ogre_Scepta.gif",
      level: 37,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 29,
      drop: "Ogre Sage, Ogre Shaman, Shaper Matriarch.",
      sellPrice: "3600",
    },
    {
      name: "Rod of Destruction",
      image: "/images/tibia/items/rods/Rod_of_Destruction.gif",
      level: 200,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "88",
      manaPerAttack: "18",
      attributes: "Magic Level +1",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
    },
    {
      name: "Sanguine Rod",
      image: "/images/tibia/items/rods/Sanguine_Rod.gif",
      level: 600,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "112",
      manaPerAttack: "20",
      attributes: "Magic Level +5, Ice Magic Level +1, Earth Magic Level +1",
      weight: 25,
      drop: "Desconhecido.",
      sellPrice: "",
    },
    {
      name: "Shimmer Rod",
      image: "/images/tibia/items/rods/Shimmer_Rod.gif",
      level: 40,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 31,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Snakebite Rod",
      image: "/images/tibia/items/rods/Snakebite_Rod.gif",
      level: 6,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "13",
      manaPerAttack: "2",
      attributes: "Nenhum",
      weight: 19,
      drop: "Baleful Bunny, Black Sphinx Acolyte, Cobra Vizier, Faceless Bane, Golgordan, Latrivan, Lizard Snakecharmer, Minotaur Cult Zealot, Serpent Spawn, Worm Priestess, Young Goanna.",
      sellPrice: "100",
    },
    {
      name: "Soulhexer",
      image: "/images/tibia/items/rods/Soulhexer.gif",
      level: 400,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "98-118",
      manaPerAttack: "21",
      attributes: "Magic Level +5",
      weight: 25,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Springsprout Rod",
      image: "/images/tibia/items/rods/Springsprout_Rod.gif",
      level: 37,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 27,
      drop: "Ancient Scarab, Ancient Spawn of Morgathla, Askarak Demon, Askarak Lord, Askarak Prince, Bog Raider, Choking Fear, Crawler, Cursed Book, Dark Carnisylvan, Deathbine, Deepworm, Devourer (Criatura), Fleshcrawler, Ink Blob, Ironblight, Liodile, Madareth, Ripper Spectre, Usurper Commander, Usurper Warlock, Varnished Diremaw, Venerable Girtablilu, Young Goanna.",
      sellPrice: "3600",
    },
    {
      name: "Terra Rod",
      image: "/images/tibia/items/rods/Terra_Rod.gif",
      level: 26,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "45",
      manaPerAttack: "8",
      attributes: "Nenhum",
      weight: 25,
      drop: "Acolyte of the Cult, Adult Goanna, Bonelord, Cobra Vizier, Devourer (Criatura), Drume, Elder Forest Fury, Faceless Bane, Flimsy Lost Soul, Fury, Lizard Dragon Priest, Lizard Snakecharmer, Menacing Carnivor, Mercurial Menace, Poisonous Carnisylvan, Razzagorn, Ripper Spectre, Scarlett Etzel, Varnished Diremaw, Wiggler, Young Goanna.",
      sellPrice: "2000",
    },
    {
      name: "The Chiller",
      image: "/images/tibia/items/rods/The_Chiller.gif",
      level: 0,
      vocation: "Druids",
      slots: 0,
      type: "rod",
      averageDamage: "6",
      manaPerAttack: "1",
      attributes: "Nenhum",
      weight: 15,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Underworld Rod",
      image: "/images/tibia/items/rods/Underworld_Rod.gif",
      level: 42,
      vocation: "Druids",
      slots: 2,
      type: "rod",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 29,
      drop: "Ancient Spawn of Morgathla, Arachnophobica, Black Sphinx Acolyte, Blood Priest, Carnivostrich, Choking Fear, Dark Carnisylvan, Drume, Faceless Bane, Grim Reaper, Grimeleech, Madareth, Metal Gargoyle, Midnight Asura, Poisonous Carnisylvan, Retching Horror, Sharpclaw, Sopping Corpus, Tarbaz, The False God, The Souldespoiler, Tremor Worm, True Midnight Asura, Usurper Warlock, Venerable Girtablilu, Werebadger, Worm Priestess, Rotten Golem.",
      sellPrice: "4400",
    },
    //wand
    {
      name: "Cobra Wand",
      image: "/images/tibia/items/wands/Cobra_Wand.gif",
      level: 270,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "95",
      manaPerAttack: "22",
      attributes: "Magic Level +2",
      weight: 19,
      drop: "Scarlett Etzel, Gaffir.",
      sellPrice: "",
    },
    {
      name: "Deepling Ceremonial Dagger",
      image: "/images/tibia/items/wands/Deepling_Ceremonial_Dagger.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "wand",
      averageDamage: "90",
      manaPerAttack: "23",
      attributes: "Magic Level +2",
      weight: 32,
      drop: "Brokul",
      sellPrice: "",
    },
    {
      name: "Deepling Fork",
      image: "/images/tibia/items/wands/Deepling_Fork.gif",
      level: 230,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "wand",
      averageDamage: "90",
      manaPerAttack: "23",
      attributes: "Magic Level +2",
      weight: 33,
      drop: "Brokul.",
      sellPrice: "",
    },
    /*
    {
      name: "Dream Blossom Staff",
      image: "/images/tibia/items/wands/Dream_Blossom_Staff.gif",
      level: 80,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "wand",
      averageDamage: "70",
      manaPerAttack: "18",
      attributes: "Magic Level +1",
      weight: 32,
      drop: "Boogy, Dark Faun, Mercurial Menace, Nymph, Pixie, Pooka.",
      sellPrice: "5000",
    },*/
    {
      name: "Eldritch Wand",
      image: "/images/tibia/items/wands/Eldritch_Wand.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "85-105",
      manaPerAttack: "22",
      attributes: "Magic Level +2, Fire Magic Level +1",
      weight: 36,
      drop: "The Brainstealer.",
      sellPrice: "",
    },
    {
      name: "Energized Limb",
      image: "/images/tibia/items/wands/Energized_Limb.gif",
      level: 180,
      vocation: "Sorcerers and Druids",
      slots: 2,
      type: "wand",
      averageDamage: "98",
      manaPerAttack: "24",
      attributes: "Magic Level +2",
      weight: 27,
      drop: "Maxxenius.",
      sellPrice: "",
    },
    {
      name: "Falcon Wand",
      image: "/images/tibia/items/wands/Falcon_Wand.gif",
      level: 300,
      vocation: "Sorcerer",
      slots: 2,
      type: "wand",
      averageDamage: "100",
      manaPerAttack: "21",
      attributes: "Magic Level +3",
      weight: 33,
      drop: "Grand Canon Dominus, Grand Master Oberon.",
      sellPrice: "",
    },
    {
      name: "Ferumbras' Staff (Wand)",
      image: "/images/tibia/items/wands/Ferumbras'_Staff_(Wand).gif",
      level: 100,
      vocation: "Sorcerers",
      slots: 0,
      type: "wand",
      averageDamage: "95",
      manaPerAttack: "19",
      attributes: "Magic Level +1",
      weight: 22.5,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Gilded Eldritch Wand",
      image: "/images/tibia/items/wands/Gilded_Eldritch_Wand.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "85-105",
      manaPerAttack: "22",
      attributes: "Magic Level +2, Fire Magic Level +1",
      weight: 37,
      drop: "The Brainstealer.",
      sellPrice: "",
    },
    {
      name: "Grand Sanguine Coil",
      image: "/images/tibia/items/wands/Grand_Sanguine_Coil.gif",
      level: 600,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "114",
      manaPerAttack: "21",
      attributes: "Magic Level +5, Fire Magic Level +1, Energy Magic Level +1",
      weight: 21,
      drop: "Desconhecido.",
      sellPrice: "",
    },
    {
      name: "Jungle Wand",
      image: "/images/tibia/items/wands/Jungle_Wand.gif",
      level: 150,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "80-100",
      manaPerAttack: "19",
      attributes: "Magic Level +1",
      weight: 32,
      drop: "Ratmiral Blackwhiskers",
      sellPrice: "",
    },
    {
      name: "Lion Wand",
      image: "/images/tibia/items/wands/Lion_Wand.gif",
      level: 220,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "89-109",
      manaPerAttack: "20",
      attributes: "Magic Level +2",
      weight: 21,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
    },
    {
      name: "Naga Wand",
      image: "/images/tibia/items/wands/Naga_Wand.gif",
      level: 250,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "90-120",
      manaPerAttack: "21",
      attributes: "Magic Level +2, Energy Magic Level +2",
      weight: 28,
      drop: "Timira the Many-Headed",
      sellPrice: "",
    },
    {
      name: "Sanguine Coil",
      image: "/images/tibia/items/wands/Sanguine_Coil.gif",
      level: 600,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "114",
      manaPerAttack: "21",
      attributes: "Magic Level +5, Fire Magic Level +1, Energy Magic Level +1",
      weight: 22,
      drop: "Desconhecido.",
      sellPrice: "",
    },
    {
      name: "Shimmer Wand",
      image: "/images/tibia/items/wands/Shimmer_Wand.gif",
      level: 40,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 31,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Sorcerer and Druid Staff",
      image: "/images/tibia/items/wands/Sorcerer_and_Druid_Staff.gif",
      level: 0,
      vocation: "",
      slots: 0,
      type: "wand",
      averageDamage: "25",
      manaPerAttack: "2",
      attributes: "Nenhum",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Soultainter",
      image: "/images/tibia/items/wands/Soultainter.gif",
      level: 400,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "100-120",
      manaPerAttack: "22",
      attributes: "Magic Level +5",
      weight: 19,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "The Scorcher",
      image: "/images/tibia/items/wands/The_Scorcher.gif",
      level: 0,
      vocation: "Sorcerers",
      slots: 0,
      type: "wand",
      averageDamage: "4",
      manaPerAttack: "1",
      attributes: "Nenhum",
      weight: 15,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Wand of Cosmic Energy",
      image: "/images/tibia/items/wands/Wand_of_Cosmic_Energy.gif",
      level: 26,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "45",
      manaPerAttack: "8",
      attributes: "Nenhum",
      weight: 25,
      drop: "Arachnophobica, Burster Spectre, Diamond Servant, Diamond Servant Replica, Elf Arcanist, Energy Elemental, Flimsy Lost Soul, Gorzindel, High Voltage Elemental, Knowledge Elemental, Mercurial Menace, Minotaur Mage, Quara Hydromancer, Quara Hydromancer Scout, Realityquake, Renegade Quara Hydromancer, Souleater, Sparkion, Spectre, Thanatursus, Tremendous Tyrant, Twisted Shaper, Usurper Warlock, Venerable Girtablilu, Yielothax.",
      sellPrice: "2000",
    },
    {
      name: "Wand of Darkness",
      image: "/images/tibia/items/wands/Wand_of_Darkness.gif",
      level: 41,
      vocation: "Sorcerers",
      slots: 0,
      type: "wand",
      averageDamage: "85",
      manaPerAttack: "15",
      attributes: "Magic Level +2",
      weight: 32,
      drop: "Ninguém.",
      sellPrice: "",
    },
    {
      name: "Wand of Decay",
      image: "/images/tibia/items/wands/Wand_of_Decay.gif",
      level: 19,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "30",
      manaPerAttack: "5",
      attributes: "Nenhum",
      weight: 23,
      drop: "Dark Apprentice, Knowledge Elemental, Menacing Carnivor, Merlkin, Ogre Shaman, Orc Cult Priest, Orc Shaman, Priestess Of The Wild Sun, Shaper Matriarch, Venerable Girtablilu.",
      sellPrice: "1000",
    },
    {
      name: "Wand of Defiance",
      image: "/images/tibia/items/wands/Wand_of_Defiance.gif",
      level: 65,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "85",
      manaPerAttack: "17",
      attributes: "Magic Level +1",
      weight: 37,
      drop: "Cliff Strider, Essence Of Malice, Harpy, Lady Tenebris, Mercurial Menace, Orewalker, Seacrest Serpent, Sphinx, Thanatursus, The Souldespoiler, Venerable Girtablilu.",
      sellPrice: "6500",
    },
    {
      name: "Wand of Destruction",
      image: "/images/tibia/items/wands/Wand_of_Destruction.gif",
      level: 200,
      vocation: "Sorcerers",
      slots: 2,
      type: "wand",
      averageDamage: "88",
      manaPerAttack: "18",
      attributes: "Magic Level +1",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
    },
    {
      name: "Wand of Dimensions",
      image: "/images/tibia/items/wands/Wand_of_Dimensions.gif",
      level: 37,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "55",
      manaPerAttack: "11",
      attributes: "Nenhum",
      weight: 28,
      drop: "Mazzinor.",
      sellPrice: "",
    },
    {
      name: "Wand of Draconia",
      image: "/images/tibia/items/wands/Wand_of_Draconia.gif",
      level: 22,
      vocation: "Sorcerer",
      slots: 2,
      type: "wand",
      averageDamage: "30",
      manaPerAttack: "5",
      attributes: "Nenhum",
      weight: 27,
      drop: "Blistering Fire Elemental, Crazed Summer Vanguard, Elder Wyrm, Feral Sphinx, Gazer Spectre, Insane Siren, Magma Crawler, Manticore, Ogre Rowdy, Seacrest Serpent, Wyrm.",
      sellPrice: "1500",
    },
    {
      name: "Wand of Dragonbreath",
      image: "/images/tibia/items/wands/Wand_of_Dragonbreath.gif",
      level: 13,
      vocation: "Sorcerer",
      slots: 2,
      type: "wand",
      averageDamage: "19",
      manaPerAttack: "3",
      attributes: "Nenhum",
      weight: 23,
      drop: "Crazed Summer Vanguard, Dark Apprentice, Fire Devil, Manticore, Menacing Carnivor.",
      sellPrice: "200",
    },
    {
      name: "Wand of Everblazing",
      image: "/images/tibia/items/wands/Wand_of_Everblazing.gif",
      level: 65,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "85",
      manaPerAttack: "17",
      attributes: "Magic Level +1",
      weight: 37,
      drop: "Essence Of Malice, Lava Golem, Magma Crawler, Manticore, Mazoran, Ogre Rowdy, Priestess Of The Wild Sun, Rage Squid, The Source Of Corruption, Weeper, Gaffir.",
      sellPrice: "6000",
    },
    {
      name: "Wand of Inferno",
      image: "/images/tibia/items/wands/Wand_of_Inferno.gif",
      level: 33,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 27,
      drop: "Dawnfire Asura, Dragon, Draken Spellweaver, Efreet, Enlightened of the Cult, Essence Of Malice, Feral Sphinx, Gazer Spectre, Gravedigger, Guardian Of Tales, Hand of Cursed Fate, Hellfire Fighter, Hellhound, Insane Siren, Lava Golem, Lizard Dragon Priest, Lokathmor, Madareth, Massive Fire Elemental, Ogre Rowdy, Shaburak Demon, Shaburak Lord, Shaburak Prince, The Baron from Below, The Count Of The Core, The Duke Of The Depths, Thornfire Wolf, Vulcongra, Wyvern.",
      sellPrice: "3000",
    },
    {
      name: "Wand of Starstorm",
      image: "/images/tibia/items/wands/Wand_of_Starstorm.gif",
      level: 37,
      vocation: "Sorcerer",
      slots: 2,
      type: "wand",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 25.5,
      drop: "Dark Carnisylvan, Elder Wyrm, Energuardian Of Tales, Flimsy Lost Soul, Headpecker, Lloyd, Madareth, Massive Energy Elemental, Mazzinor, Menacing Carnivor, Overcharged Energy Elemental, Poisonous Carnisylvan, Retching Horror, Shulgrax, Sphinx, Tremendous Tyrant, Usurper Warlock, Varnished Diremaw, Werehyaena Shaman, Wyrm.",
      sellPrice: "3600",
    },
    {
      name: "Wand of Voodoo",
      image: "/images/tibia/items/wands/Wand_of_Voodoo.gif",
      level: 42,
      vocation: "Sorcerer",
      slots: 2,
      type: "wand",
      averageDamage: "65",
      manaPerAttack: "13",
      attributes: "Nenhum",
      weight: 28.5,
      drop: "Black Sphinx Acolyte, Burster Spectre, Carnivostrich, Draken Abomination, Drume, Ghulosh, Grimeleech, Madareth, Menacing Carnivor, Shaper Matriarch, Sharpclaw, Usurper Commander, Undertaker, Venerable Girtablilu, Werebadger, Werehyaena Shaman.",
      sellPrice: "4400",
    },
    {
      name: "Wand of Vortex",
      image: "/images/tibia/items/wands/Wand_of_Vortex.gif",
      level: 6,
      vocation: "Sorcerer",
      slots: 0,
      type: "wand",
      averageDamage: "13",
      manaPerAttack: "2",
      attributes: "Nenhum",
      weight: 19,
      drop: "Novice of the Cult.",
      sellPrice: "100",
    },
    //distance
    {
      name: "Assassin Star",
      image: "/images/tibia/items/distance/Assassin_Star.gif",
      level: 80,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 65,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 2,
      drop: "Angry Demon, Askarak Demon, Askarak Lord, Behemoth, Betrayed Wraith, Black Vixen, Blightwalker, Bretzecutioner, Dark Torturer, Demon, Demon Outcast, Enfeebled Silencer, Falcon Knight, Falcon Paladin, Flameborn, Fury, Golgordan, Grand Canon Dominus, Hand of Cursed Fate, Hellhound, Hellspawn, Juggernaut, Kroazur, Latrivan, Mad Mage, Midnight Asura, Minotaur Amazon, Morguthis, Preceptor Lazare, Quara Predator, Raging Mage, Reflection Of A Mage, Renegade Quara Constrictor, Renegade Quara Predator, Silencer, Stonecracker, The Flaming Orchid, The Noxious Spawn, True Midnight Asura, Undead Dragon, Warlock, Werefox.",
      sellPrice: "100",
      type: "distance",
    },
    {
      name: "Broken Iks Spear",
      image: "/images/tibia/items/distance/Broken_Iks_Spear.gif",
      level: null,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      range: "?",
      attack: 1,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 13,
      drop: "Ahau, Iks Chuka.",
      sellPrice: "0",
      type: "distance",
    },
    {
      name: "Enchanted Spear",
      image: "/images/tibia/items/distance/Enchanted_Spear.gif",
      level: 42,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 38,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 20,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Glooth Spear",
      image: "/images/tibia/items/distance/Glooth_Spear.gif",
      level: 60,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "3",
      attack: 55,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 26,
      drop: "Blood Beast, Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Brigand, Glooth Fairy, Glooth Golem, Lisa, Rot Elemental.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Hunting Spear",
      image: "/images/tibia/items/distance/Hunting_Spear.gif",
      level: 20,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "3",
      attack: 32,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 22,
      drop: "Barbaria, Barbarian Brutetamer, Deepling Scout, Dworc Fleshhunter, Elder Forest Fury, Guard Captain Quaid, Lizard Sentinel, Minotaur Hunter, Naga Archer, Orc Berserker, Orc Warlord, Undead Elite Gladiator, Undead Gladiator, Valkyrie.",
      sellPrice: "25",
      type: "distance",
    },
    {
      name: "Leaf Star",
      image: "/images/tibia/items/distance/Leaf_Star.gif",
      level: 60,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "5",
      attack: 48,
      elementalDamage: "2 Earth",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 2,
      drop: "Arctic Faun, Baleful Bunny, Boogy, Crazed Summer Rearguard, Dark Faun, Faun, Guard Captain Quaid, Pixie, Young Goanna.",
      sellPrice: "50",
      type: "distance",
    },
    {
      name: "Mean Paladin Spear",
      image: "/images/tibia/items/distance/Mean_Paladin_Spear.gif",
      level: 0,
      vocation: "players without vocation",
      slots: 0,
      hands: "Uma",
      range: "3",
      attack: 15,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 10,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Royal Spear",
      image: "/images/tibia/items/distance/Royal_Spear.gif",
      level: 25,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "3",
      attack: 35,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 25,
      drop: "Albino Dragon, Blue Djinn, Crazed Summer Rearguard, Dragon Lord, Efreet, Fahim the Wise, Green Djinn, Iks Chuka, Marid, Merikh the Slaughterer, Minotaur Hunter, Orc Leader, Quara Predator, Renegade Quara Predator, Shaburak Demon.",
      sellPrice: "15",
      type: "distance",
    },
    {
      name: "Royal Star",
      image: "/images/tibia/items/distance/Royal_Star.gif",
      level: 120,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "5",
      attack: 64,
      elementalDamage: "2 Fire",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 2.2,
      drop: "Alptramun, Crazed Summer Rearguard, Drume, Izcandar the Banished, Malofur Mangrinder, Manticore, Maxxenius, Plagueroot, The Nightmare Beast, True Frost Flower Asura, True Midnight Asura, Scarlett Etzel, Urmahlullu the Weakened.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Small Stone",
      image: "/images/tibia/items/distance/Small_Stone.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 3.6,
      drop: "Arctic Faun, Clay Guardian, Crazed Summer Rearguard, Cursed Book, Dark Faun, Demon (Goblin), Earth Elemental, Eternal Guardian, Faun, Frost Giantess, Gargoyle, Goblin, Goblin Assassin, Jagged Earth Elemental, Goblin Scavenger, Massive Earth Elemental, Muglex Clan Assassin, Muglex Clan Footman, Muglex Clan Scavenger, Muddy Earth Elemental, Museum Stone Golem, Ogre Brute, Pooka, Raxias, Sibang, Stone Golem, Twisted Pooka.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Snowball",
      image: "/images/tibia/items/distance/Snowball.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "?",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 0.8,
      drop: "Yeti.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Spear",
      image: "/images/tibia/items/distance/Spear.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "3",
      attack: 25,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 20,
      drop: "Big Boss Trolliver, Black Knight, Crazed Summer Rearguard, Faceless Bane, Frost Troll, Island Troll, Lizard Sentinel, Mountain Troll, Orc Shaman, Orc Spearman, Pirate Marauder, Salamander Trainer, Orc Shaman, Scar Tribe Shaman, Swamp Troll, Troll, Troll Champion, Troll Marauder, Valkyrie, Young Troll.",
      sellPrice: "3",
      type: "distance",
    },
    {
      name: "Throwing Cake",
      image: "/images/tibia/items/distance/Throwing_Cake.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "?",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 0.8,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Throwing Knife",
      image: "/images/tibia/items/distance/Throwing_Knife.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 25,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 5,
      drop: "Crazed Summer Rearguard, Dworc Venomsniper, Orc Leader, Pirate Buccaneer, Pirate Corsair, Renegade Orc, Stalker.",
      sellPrice: "2",
      type: "distance",
    },
    {
      name: "Throwing Star",
      image: "/images/tibia/items/distance/Throwing_Star.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 30,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 2,
      drop: "Assassin, Bibby Bloodbath, Crypt Shambler, Demon Skeleton, Energy Elemental, Guard Captain Quaid, Knowledge Elemental, Orc Warlord, Pirate Corsair,  Troll Legionnaire, Undead Elite Gladiator, Undead Gladiator.",
      sellPrice: "2",
      type: "distance",
    },
    {
      name: "Throwing Star of Sula",
      image: "/images/tibia/items/distance/Throwing_Star_of_Sula.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "?",
      attack: 250,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: null,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Viper Star",
      image: "/images/tibia/items/distance/Viper_Star.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Uma",
      range: "4",
      attack: 28,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 2,
      drop: "Annihilon, Assassin, Blood Beast, Crazed Summer Rearguard.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Arbalest",
      image: "/images/tibia/items/distance/Arbalest.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "6",
      attack: 2,
      elementalDamage: "Nenhum",
      hitPercentage: 2,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 95,
      drop: "Ninguém.",
      sellPrice: "42000",
      type: "distance",
    },
    {
      name: "Chain Bolter",
      image: "/images/tibia/items/distance/Chain_Bolter.gif",
      level: 60,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "3",
      attack: 4,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 55,
      drop: "Ninguém",
      sellPrice: "40000",
      type: "distance",
    },
    {
      name: "Cobra Crossbow",
      image: "/images/tibia/items/distance/Cobra_Crossbow.gif",
      level: 220,
      vocation: "Paladins",
      slots: 2,
      hands: "Duas",
      range: "6",
      attack: 7,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +2",
      protection: "Nenhum",
      weight: 40,
      drop: "Ugly Monster, Scarlett Etzel",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Crossbow",
      image: "/images/tibia/items/distance/Crossbow.gif",
      level: 0,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 40,
      drop: "Crazed Summer Rearguard, Dragon, Dwarf Soldier, Elder Forest Fury, Elder Wyrm, Forest Fury, Guard Captain Quaid, Minotaur Archer, Minotaur Poacher, Naga Archer, Orc Marauder, Vicious Squire, Wyrm.",
      sellPrice: "160",
      type: "distance",
    },
    {
      name: "Crossbow of Destruction",
      image: "/images/tibia/items/distance/Crossbow_of_Destruction.gif",
      level: 200,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 65,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "distance",
    },
    {
      name: "Crude Umbral Crossbow",
      image: "/images/tibia/items/distance/Crude_Umbral_Crossbow.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "5",
      attack: 3,
      elementalDamage: "Nenhum",
      hitPercentage: 1,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 130,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Crystal Crossbow",
      image: "/images/tibia/items/distance/Crystal_Crossbow.gif",
      level: 90,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "6",
      attack: 4,
      elementalDamage: "Nenhum",
      hitPercentage: 3,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 84,
      drop: "Abyssador, Cave Chimera, Cliff Strider, Crazed Summer Rearguard, Deathstrike, Gnomevil, Naga Archer, Orewalker, Vok the Freakish.",
      sellPrice: "35000",
      type: "distance",
    },
    {
      name: "Grand Sanguine Crossbow",
      image: "/images/tibia/items/distance/Grand_Sanguine_Crossbow.gif",
      level: 600,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 10,
      elementalDamage: "Nenhum",
      hitPercentage: 7,
      bonus: "Distance Fighting +4",
      protection: "Fire +6%",
      weight: 47,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Modified Crossbow",
      image: "/images/tibia/items/distance/Modified_Crossbow.gif",
      level: 45,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 1,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 35,
      drop: "Soul of Dragonking Zyrtarch.",
      sellPrice: "10000",
      type: "distance",
    },
    {
      name: "Naga Crossbow",
      image: "/images/tibia/items/distance/Naga_Crossbow.gif",
      level: 300,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 8,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +2",
      protection: "earth +4%",
      weight: 102,
      drop: "Timira the Many-Headed",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Ornate Crossbow",
      image: "/images/tibia/items/distance/Ornate_Crossbow.gif",
      level: 50,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "6",
      attack: 1,
      elementalDamage: "Nenhum",
      hitPercentage: 2,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 91,
      drop: "Ayana the Crimson Curse, Cave Chimera, Deepling Guard, Deepling Master Librarian, Deepling Spellsinger, Deepling Tyrant, Freakish Lost Soul, Harpy, Katex Blood Tongue, Melting Frozen Horror, Naga Archer, Usurper Archer, Yirkas Blue Scales.",
      sellPrice: "12000",
      type: "distance",
    },
    {
      name: "Rift Crossbow",
      image: "/images/tibia/items/distance/Rift_Crossbow.gif",
      level: 120,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 4,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 130,
      drop: "Ferumbras Mortal Shell, Grimeleech, Hellflayer, Mazoran, Plagirath, Tarbaz, Shulgrax, The Souldespoiler, Vexclaw, Zamulosh.",
      sellPrice: "45000",
      type: "distance",
    },
    {
      name: "Royal Crossbow",
      image: "/images/tibia/items/distance/Royal_Crossbow.gif",
      level: 130,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 3,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 120,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Sanguine Crossbow",
      image: "/images/tibia/items/distance/Sanguine_Crossbow.gif",
      level: 600,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 10,
      elementalDamage: "Nenhum",
      hitPercentage: 7,
      bonus: "Distance Fighting +4",
      protection: "Fire +6%",
      weight: 47,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Soulpiercer",
      image: "/images/tibia/items/distance/Soulpiercer.gif",
      level: 400,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 9,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +4",
      protection: "Death +7%",
      weight: 40,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "The Devileye",
      image: "/images/tibia/items/distance/The_Devileye.gif",
      level: 100,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 20,
      elementalDamage: "Nenhum",
      hitPercentage: -20,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 55,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "The Ironworker",
      image: "/images/tibia/items/distance/The_Ironworker.gif",
      level: 80,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 4,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 150,
      drop: "Mazoran.",
      sellPrice: "50000",
      type: "distance",
    },
    {
      name: "Thorn Spitter",
      image: "/images/tibia/items/distance/Thorn_Spitter.gif",
      level: 150,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 9,
      elementalDamage: "Nenhum",
      hitPercentage: 1,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 126,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Triple Bolt Crossbow",
      image: "/images/tibia/items/distance/Triple_Bolt_Crossbow.gif",
      level: 70,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "5",
      attack: 3,
      elementalDamage: "Nenhum",
      hitPercentage: 2,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 62,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Umbral Crossbow",
      image: "/images/tibia/items/distance/Umbral_Crossbow.gif",
      level: 120,
      vocation: "Paladins",
      slots: 1,
      hands: "Duas",
      range: "5",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 2,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 125,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Umbral Master Crossbow",
      image: "/images/tibia/items/distance/Umbral_Master_Crossbow.gif",
      level: 250,
      vocation: "Paladins",
      slots: 2,
      hands: "Duas",
      range: "5",
      attack: 9,
      elementalDamage: "Nenhum",
      hitPercentage: 4,
      bonus: "Distance Fighting +3",
      protection: "Nenhum",
      weight: 120,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Bow",
      image: "/images/tibia/items/distance/Guardcatcher.gif",
      level: 0,
      vocation: "Todas",
      slots: 0,
      hands: "Duas",
      range: "6",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 31,
      drop: "Elf Scout, Firestarter, Hero, Hunter, Naga Archer, Orc Marauder, Poacher, Salamander, Stalking Stalk, Usurper Commander, Vok the Freakish.",
      sellPrice: "130",
      type: "distance",
    },
    {
      name: "Bow of Cataclysm",
      image: "/images/tibia/items/distance/Bow_of_Cataclysm.gif",
      level: 250,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 4,
      bonus: "Distance Fighting +2",
      protection: "Death +4%",
      weight: 47,
      drop: "King Zelos.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Bow of Destruction",
      image: "/images/tibia/items/distance/Bow_of_Destruction.gif",
      level: 200,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 55,
      drop: "Ninguém.",
      sellPrice: "50Gold Tokens",
      type: "distance",
    },
    {
      name: "Composite Hornbow",
      image: "/images/tibia/items/distance/Composite_Hornbow.gif",
      level: 50,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 3,
      elementalDamage: "Nenhum",
      hitPercentage: 2,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 52,
      drop: "Black Vixen, Cave Chimera, Elder Wyrm, Ironblight, Wyrm, Outburst.",
      sellPrice: "25000",
      type: "distance",
    },
    {
      name: "Crude Umbral Bow",
      image: "/images/tibia/items/distance/Crude_Umbral_Bow.gif",
      level: 75,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "7",
      attack: 2,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 50,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Eldritch Bow",
      image: "/images/tibia/items/distance/Eldritch_Bow.gif",
      level: 250,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +2, Holy Magic Level +1",
      protection: "Nenhum",
      weight: 58,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Elethriel's Elemental Bow",
      image: "/images/tibia/items/distance/Elethriel's_Elemental_Bow.gif",
      level: 70,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "4",
      attack: 7,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 44,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Elvish Bow",
      image: "/images/tibia/items/distance/Elvish_Bow.gif",
      level: 0,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 39,
      drop: "Cave Chimera, Elder Forest Fury, Elf Scout, Firestarter, Forest Fury, Naga Archer, Usurper Archer.",
      sellPrice: "2000",
      type: "distance",
    },
    {
      name: "Falcon Bow",
      image: "/images/tibia/items/distance/Falcon_Bow.gif",
      level: 300,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 7,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Distance Fighting +2",
      protection: "Fire +5%",
      weight: 35,
      drop: "Grand Commander Soeren, Grand Master Oberon.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Gilded Eldritch Bow",
      image: "/images/tibia/items/distance/Gilded_Eldritch_Bow.gif",
      level: 250,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +2, Holy Magic Level +1",
      protection: "Nenhum",
      weight: 59,
      drop: "The Brainstealer.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Grand Sanguine Bow",
      image: "/images/tibia/items/distance/Grand_Sanguine_Bow.gif",
      level: 600,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 9,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +4",
      protection: "Earth +6%",
      weight: 47,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Hive Bow",
      image: "/images/tibia/items/distance/Hive_Bow.gif",
      level: 85,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 2,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 46,
      drop: "Hive Overseer, Maw.",
      sellPrice: "28000",
      type: "distance",
    },
    {
      name: "Icicle Bow",
      image: "/images/tibia/items/distance/Icicle_Bow.gif",
      level: 0,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 1,
      elementalDamage: "Nenhum",
      hitPercentage: 4,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 42,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Jungle Bow",
      image: "/images/tibia/items/distance/Jungle_Bow.gif",
      level: 150,
      vocation: "Paladins",
      slots: 2,
      hands: "Duas",
      range: "6",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Distance Fighting +1",
      protection: "Physical +3%",
      weight: 47,
      drop: "Ratmiral Blackwhiskers",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Lion Longbow",
      image: "/images/tibia/items/distance/Lion_Longbow.gif",
      level: 270,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +2",
      protection: "Ice +5%",
      weight: 48,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Living Vine Bow",
      image: "/images/tibia/items/distance/Living_Vine_Bow.gif",
      level: 220,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Distance Fighting +1",
      protection: "Earth +4%",
      weight: 38,
      drop: "Plagueroot.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Musician's Bow",
      image: "/images/tibia/items/distance/Musician's_Bow.gif",
      level: 0,
      vocation: "Todas",
      slots: 3,
      hands: "Duas",
      range: "4",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 0,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 12.5,
      drop: "Boogey.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Mycological Bow",
      image: "/images/tibia/items/distance/Mycological_Bow.gif",
      level: 105,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 4,
      elementalDamage: "Nenhum",
      hitPercentage: 4,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 49,
      drop: "Abyssador, Deathstrike, Diremaw, Gnomevil, Hideous Fungus, Humongous Fungus.",
      sellPrice: "35000",
      type: "distance",
    },
    {
      name: "Rift Bow",
      image: "/images/tibia/items/distance/Rift_Bow.gif",
      level: 120,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "7",
      attack: 5,
      elementalDamage: "Nenhum",
      hitPercentage: 3,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 41,
      drop: "Ferumbras Mortal Shell, Grimeleech, Hellflayer, Mazoran, Plagirath, Ragiaz, Shulgrax, The Source Of Corruption, Vexclaw.",
      sellPrice: "45000",
      type: "distance",
    },
    {
      name: "Sanguine Bow",
      image: "/images/tibia/items/distance/Sanguine_Bow.gif",
      level: 600,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 9,
      elementalDamage: "Nenhum",
      hitPercentage: 6,
      bonus: "Distance Fighting +4",
      protection: "Earth +6%",
      weight: 47,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Shimmer Bow",
      image: "/images/tibia/items/distance/Shimmer_Bow.gif",
      level: 40,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "6",
      attack: 1,
      elementalDamage: "Nenhum",
      hitPercentage: 7,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 48,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Silkweaver Bow",
      image: "/images/tibia/items/distance/Silkweaver_Bow.gif",
      level: 40,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 3,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 25,
      drop: "Orc Marauder, The Last Lore Keeper, The Source Of Corruption.",
      sellPrice: "12000",
      type: "distance",
    },
    {
      name: "Soulbleeder",
      image: "/images/tibia/items/distance/Soulbleeder.gif",
      level: 400,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "6",
      attack: 8,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Distance Fighting +4",
      protection: "Holy +7%",
      weight: 47,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Umbral Bow",
      image: "/images/tibia/items/distance/Umbral_Bow.gif",
      level: 120,
      vocation: "Paladins",
      slots: 1,
      hands: "Duas",
      range: "7",
      attack: 4,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 45,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Umbral Master Bow",
      image: "/images/tibia/items/distance/Umbral_Master_Bow.gif",
      level: 250,
      vocation: "Paladins",
      slots: 2,
      hands: "Duas",
      range: "7",
      attack: 6,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Distance Fighting +3",
      protection: "Nenhum",
      weight: 40,
      drop: "Ninguém",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Warsinger Bow",
      image: "/images/tibia/items/distance/Warsinger_Bow.gif",
      level: 80,
      vocation: "Paladins",
      slots: 3,
      hands: "Duas",
      range: "7",
      attack: 3,
      elementalDamage: "Nenhum",
      hitPercentage: 5,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 45,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    {
      name: "Yol's Bow",
      image: "/images/tibia/items/distance/Yol's_Bow.gif",
      level: 60,
      vocation: "Paladins",
      slots: 0,
      hands: "Duas",
      range: "7",
      attack: 0,
      elementalDamage: "Nenhum",
      hitPercentage: 7,
      bonus: "Nenhum",
      protection: "Nenhum",
      weight: 35,
      drop: "Ninguém.",
      sellPrice: "",
      type: "distance",
    },
    //amulets
    {
      name: "Amethyst Necklace",
      image: "/images/tibia/items/amulets/Amethyst_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.3,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Amulet of Loss",
      image: "/images/tibia/items/amulets/Amulet_of_Loss.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "100% contra a perda de itens",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.2,
      drop: "Blightwalker, Sulphider, Zugurosh.",
      sellPrice: "45000",
      type: "amulet",
    },
    {
      name: "Amulet of Theurgy",
      image: "/images/tibia/items/amulets/Amulet_of_Theurgy.gif",
      level: 220,
      vocation: "Sorcerers and Druids",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Ancient Amulet",
      image: "/images/tibia/items/amulets/Ancient_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.4,
      drop: "Ancient Scarab, Fleshcrawler, The Ravager.",
      sellPrice: "200",
      type: "amulet",
    },
    {
      name: "Beetle Necklace",
      image: "/images/tibia/items/amulets/Beetle_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Speed +2",
      protection: "Nenhuma.",
      weight: 8.2,
      drop: "Lancer Beetle, Orclops Doomhauler.",
      sellPrice: "1500",
      type: "amulet",
    },
    {
      name: "Bonfire Amulet",
      image: "/images/tibia/items/amulets/Bonfire_Amulet.gif",
      level: 80,
      vocation: "Todas",
      arm: 0,
      charges: "5",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +60%, Fire +40%",
      weight: 7,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Broken Amulet",
      image: "/images/tibia/items/amulets/Broken_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.2,
      drop: "Ninguém.",
      sellPrice: "50000",
      type: "amulet",
    },
    {
      name: "Bronze Amulet",
      image: "/images/tibia/items/amulets/Bronze_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Mana Drain +20%",
      weight: 5,
      drop: "Dworc Venomsniper, Dworc Voodoomaster, Minotaur, Minotaur Bruiser, Minotaur Cult Follower, Vampire.",
      sellPrice: "50",
      type: "amulet",
    },
    {
      name: "Bronze Necklace",
      image: "/images/tibia/items/amulets/Bronze_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.1,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Collar of Blue Plasma",
      image: "/images/tibia/items/amulets/Collar_of_Blue_Plasma.gif",
      level: 150,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Distance Fighting +4, Magic Level +2",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Alptramun, Anomaly, Bashmu, Breach Brood, Crazed Summer Rearguard, Dread Intruder, Dreadful Disruptor, Eradicator, Guard Captain Quaid, Izcandar the Banished, Lloyd, Malofur Mangrinder, Maxxenius, Plagueroot, Poisonous Carnisylvan, Realityquake, Rupture, The Nightmare Beast, The Unarmored Voidborn, World Devourer.",
      sellPrice: "6000",
      type: "amulet",
    },
    {
      name: "Collar of Green Plasma",
      image: "/images/tibia/items/amulets/Collar_of_Green_Plasma.gif",
      level: 150,
      vocation: "Sorcerers and Druids",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Regeneração rápida.",
      bonus: "Magic Level +3",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Alptramun, Arachnophobica, Dread Intruder, Eradicator, Izcandar the Banished, King Zelos, Malofur Mangrinder, Maxxenius, Outburst, Plagueroot, Reality Reaver, Rhindeer, Sparkion, The Nightmare Beast.",
      sellPrice: "6000",
      type: "amulet",
    },
    {
      name: "Collar of Red Plasma",
      image: "/images/tibia/items/amulets/Collar_of_Red_Plasma.gif",
      level: 150,
      vocation: "Knights",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Sword Fighting +4, Club Fighting +4, Axe Fighting +4",
      protection: "Physical +5%",
      weight: 5,
      drop: "Alptramun, Arachnophobica, Breach Brood, Burster, Dread Intruder, Eradicator, Izcandar the Banished, Malofur Mangrinder, Maxxenius, Outburst, Plagueroot, Reality Reaver, Rupture, Sparkion, The Nightmare Beast, World Devourer.",
      sellPrice: "6000",
      type: "amulet",
    },
    {
      name: "Crystal Necklace",
      image: "/images/tibia/items/amulets/Crystal_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.9,
      drop: "Amazon, Behemoth, Bretzecutioner, Crystal Spider, Destroyer, Hellgorak, Priestess.",
      sellPrice: "400",
      type: "amulet",
    },
    {
      name: "Demonbone Amulet",
      image: "/images/tibia/items/amulets/Demonbone_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6.9,
      drop: "Falcon Knight, Hellfire Fighter, Hellflayer, Hellgorak, Juggernaut, Lokathmor, Morguthis, Preceptor Lazare, Prince Drazzak, Shulgrax, Spectre.",
      sellPrice: "32000",
      type: "amulet",
    },
    {
      name: "Diamond Necklace",
      image: "/images/tibia/items/amulets/Diamond_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Dragon Necklace",
      image: "/images/tibia/items/amulets/Dragon_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Fire +8%",
      weight: 6.3,
      drop: "Acolyte of the Cult, Burster Spectre, Crazed Summer Vanguard, Feral Sphinx, Hunter, Stalking Stalk, The Count Of The Core, The Duke Of The Depths.",
      sellPrice: "100",
      type: "amulet",
    },
    {
      name: "Elven Amulet",
      image: "/images/tibia/items/amulets/Elven_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "50",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection:
        "Physical +5%, Fire +5%, Earth +5%, Energy +5%, Ice +5%, Holy +5%, Death +5%",
      weight: 2.7,
      drop: "Arachnophobica, Burning Gladiator, Burster Spectre, Crazed Winter Rearguard, Crypt Warden, Elf Arcanist, Lamassu, Tremendous Tyrant.",
      sellPrice: "100",
      type: "amulet",
    },
    {
      name: "Emerald Necklace",
      image: "/images/tibia/items/amulets/Emerald_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.5,
      drop: "Ninguém.",
      sellPrice: "4000 Trust Points",
      type: "amulet",
    },
    {
      name: "Enchanted Pendulet",
      image: "/images/tibia/items/amulets/Enchanted_Pendulet.gif",
      level: 180,
      vocation: "Paladins",
      arm: 2,
      charges: "-",
      duration: "120",
      attributes: "Nenhum.",
      bonus: "Distance Fighting +3",
      protection: "Energy +18%, Physical +5%",
      weight: 6.5,
      drop: "Faceless Bane.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Enchanted Sleep Shawl",
      image: "/images/tibia/items/amulets/Enchanted_Sleep_Shawl.gif",
      level: 180,
      vocation: "Paladins",
      arm: 3,
      charges: "-",
      duration: "60",
      attributes: "Nenhum.",
      bonus: "Distance Fighting +3",
      protection: "Earth +24%, Physical +7%",
      weight: 4.3,
      drop: "The Nightmare Beast.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Enchanted Theurgic Amulet",
      image: "/images/tibia/items/amulets/Enchanted_Theurgic_Amulet.gif",
      level: 220,
      vocation: "Sorcerers and Druids",
      arm: 2,
      charges: "-",
      duration: "120",
      attributes: "Nenhum.",
      bonus: "Magic Level +3",
      protection: "Physical +3%, Earth +14%",
      weight: 5,
      drop: "Bragrumol, Mozradek, Urmahlullu the Weakened, Xogixath.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Enchanted Turtle Amulet",
      image: "/images/tibia/items/amulets/Enchanted_Turtle_Amulet.gif",
      level: 200,
      vocation: "Knights",
      arm: 3,
      charges: "-",
      duration: "120",
      attributes: "Nenhum.",
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +7%, Ice +15%",
      weight: 4.8,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Enchanted Werewolf Amulet",
      image: "/images/tibia/items/amulets/Enchanted_Werewolf_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 3,
      charges: "-",
      duration: "60",
      attributes: "Fornece uma pequena quantidade de luz.",
      bonus: "Nenhum.",
      protection: "Physical +6%",
      weight: 5.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Exotic Amulet",
      image: "/images/tibia/items/amulets/Exotic_Amulet.gif",
      level: 180,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +4%, Earth +5%",
      weight: 7.3,
      drop: "Ratmiral Blackwhiskers.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Ferumbras' Amulet",
      image: "/images/tibia/items/amulets/Ferumbras'_Amulet.gif",
      level: 100,
      vocation: "Todas",
      arm: 3,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ferumbras Mortal Shell.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Foxtail Amulet",
      image: "/images/tibia/items/amulets/Foxtail_Amulet.gif",
      level: 100,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +5%",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Garlic Necklace",
      image: "/images/tibia/items/amulets/Garlic_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "150",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Life Drain +20%",
      weight: 3.8,
      drop: "Arachnophobica, Blightwalker, Burster Spectre, Exotic Bat, Novice of the Cult, Witch.",
      sellPrice: "50",
      type: "amulet",
    },
    {
      name: "Garnet Necklace",
      image: "/images/tibia/items/amulets/Garnet_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "Ninguém.",
      sellPrice: "4000 Trust Points",
      type: "amulet",
    },
    {
      name: "Gearwheel Chain",
      image: "/images/tibia/items/amulets/Gearwheel_Chain.gif",
      level: 75,
      vocation: "Todas",
      arm: 3,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 10,
      drop: "Control Tower, Glooth Bomb, Glooth Golem, Rustheap Golem, Walker.",
      sellPrice: "5000",
      type: "amulet",
    },
    {
      name: "Gill Necklace",
      image: "/images/tibia/items/amulets/Gill_Necklace.gif",
      level: 150,
      vocation: "Todas",
      arm: 0,
      charges: "750",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +15%, Earth +10%",
      weight: 4.1,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Glacier Amulet",
      image: "/images/tibia/items/amulets/Glacier_Amulet.gif",
      level: 60,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Ice +20%, Energy -10%",
      weight: 5,
      drop: "Animated Feather, Bashmu, Burster Spectre, Cave Chimera, Crazed Winter Rearguard, Crazed Winter Vanguard, Sea Serpent, Seacrest Serpent, Spiky Carnivor, Tarbaz.",
      sellPrice: "1500",
      type: "amulet",
    },
    {
      name: "Glooth Amulet",
      image: "/images/tibia/items/amulets/Glooth_Amulet.gif",
      level: 75,
      vocation: "Sorcerers and Druids",
      arm: 0,
      charges: "20",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection:
        "Physical +10%,  Fire +10%, Earth +10%, Energy +10%, Ice +10%, Holy +10%, e Death +10%",
      weight: 2.2,
      drop: "Blood Beast, Devourer (Criatura), Glooth Anemone, Glooth Bandit, Glooth Blob, Glooth Brigand, Glooth Fairy, Glooth Golem, Lisa, Rot Elemental.",
      sellPrice: "2000",
      type: "amulet",
    },
    {
      name: "Golden Amulet",
      image: "/images/tibia/items/amulets/Golden_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.3,
      drop: "Hellgorak.",
      sellPrice: "2000",
      type: "amulet",
    },
    {
      name: "Golden Hyaena Pendant",
      image: "/images/tibia/items/amulets/Golden_Hyaena_Pendant.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.5,
      drop: "King Zelos.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Golden Scorpion Pendant",
      image: "/images/tibia/items/amulets/Golden_Scorpion_Pendant.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3,
      drop: "Desconhecido.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Jerom's Family Necklace",
      image: "/images/tibia/items/amulets/Jerom's_Family_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6.3,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Koshei's Ancient Amulet",
      image: "/images/tibia/items/amulets/Koshei's_Ancient_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Death +8%, Holy -50%",
      weight: 5.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Leviathan's Amulet",
      image: "/images/tibia/items/amulets/Leviathan's_Amulet.gif",
      level: 80,
      vocation: "Todas",
      arm: 0,
      charges: "5",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +60%, Ice +40%",
      weight: 7,
      drop: "Animated Feather, Icecold Book, Leviathan, Liodile, Sea Serpent, Squid Warden.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Lightning Pendant",
      image: "/images/tibia/items/amulets/Lightning_Pendant.gif",
      level: 60,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Energy +20%, Earth -10%",
      weight: 5,
      drop: "Baleful Bunny, Burning Gladiator, Diamond Servant, Diamond Servant Replica, Elder Wyrm, Faceless Bane, High Voltage Elemental, Juvenile Bashmu, Massive Energy Elemental, Shulgrax, Sphinx, Spiky Carnivor, Tamru the Black, Wyrm, Yielothax.",
      sellPrice: "1500",
      type: "amulet",
    },
    {
      name: "Lion Amulet",
      image: "/images/tibia/items/amulets/Lion_Amulet.gif",
      level: 230,
      vocation: "Todas",
      arm: 3,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +3%, Ice +7%",
      weight: 5.2,
      drop: "Ancient Lion Knight, Drume.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Magma Amulet",
      image: "/images/tibia/items/amulets/Magma_Amulet.gif",
      level: 60,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Fire +20%, Ice -10%",
      weight: 5,
      drop: "Arachnophobica, Ayana the Crimson Curse, Bashmu, Bibby Bloodbath, Burning Gladiator, Crazed Summer Vanguard, Feral Sphinx, Hellhound, Kerberos, Lava Golem, Magma Crawler, Massive Fire Elemental, Mazoran, Sphinx, Vulcongra.",
      sellPrice: "1500",
      type: "amulet",
    },
    {
      name: "Necklace of the Deep",
      image: "/images/tibia/items/amulets/Necklace_of_the_Deep.gif",
      level: 120,
      vocation: "Todas",
      arm: 0,
      charges: "50",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Life Drain +50%",
      weight: 5.09,
      drop: "Arachnophobica, Deathling Scout, Deathling Spellsinger, Deepling Master Librarian, Deepling Spellsinger, Flimsy Lost Soul.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Onyx Pendant",
      image: "/images/tibia/items/amulets/Onyx_Pendant.gif",
      level: 60,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Death +2%",
      weight: 8.5,
      drop: "Lady Tenebris.",
      sellPrice: "3500",
      type: "amulet",
    },
    {
      name: "Ornamented Brooch",
      image: "/images/tibia/items/amulets/Ornamented_Brooch.gif",
      level: 0,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 8.4,
      drop: "Fleshcrawler.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Paw Amulet",
      image: "/images/tibia/items/amulets/Paw_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.2,
      drop: "Ninguém",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Pendulet",
      image: "/images/tibia/items/amulets/Pendulet.gif",
      level: 180,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Platinum Amulet",
      image: "/images/tibia/items/amulets/Platinum_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Angry Demon, Arachnophobica, Brimstone Bug, Burster Spectre, Crystal Spider, Darkfang, Demon, Demon Outcast, Enlightened of the Cult, Giant Spider, Golgordan, Hand of Cursed Fate, Lich, Nightstalker, Rage Squid, Sharpclaw, Spitter, Sulphur Scuttler, The Old Widow, Twisted Shaper, Vexclaw, Vile Grandmaster, Werebadger, Werefox, Werewolf.",
      sellPrice: "2500",
      type: "amulet",
    },
    {
      name: "Prismatic Necklace",
      image: "/images/tibia/items/amulets/Prismatic_Necklace.gif",
      level: 150,
      vocation: "Todas",
      arm: 0,
      charges: "750",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +10%, Energy +15%",
      weight: 6.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Protection Amulet",
      image: "/images/tibia/items/amulets/Protection_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "250",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +6%",
      weight: 5.5,
      drop: "Burster Spectre, Cobra Assassin, Cursed Ape, Cursed Book, Enlightened of the Cult, Golgordan, Hand of Cursed Fate, Ink Blob, Kongra, Massive Earth Elemental, Nightstalker, Orc Warlord, Shrieking Cry-Stal, Undead Elite Gladiator, Undead Gladiator, Valkyrie, Werehyaena Shaman.",
      sellPrice: "100",
      type: "amulet",
    },
    {
      name: "Rainbow Necklace",
      image: "/images/tibia/items/amulets/Rainbow_Necklace.gif",
      level: 220,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +3%, Fire +6%, Ice -5%",
      weight: 4.9,
      drop: "Urmahlullu the Weakened.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Rhodolith Necklace",
      image: "/images/tibia/items/amulets/Rhodolith_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5.5,
      drop: "Ninguém.",
      sellPrice: "4000 Trust Points",
      type: "amulet",
    },
    {
      name: "Ruby Necklace",
      image: "/images/tibia/items/amulets/Ruby_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5.7,
      drop: "Biting Book, Black Knight, Dawnfire Asura, Lost Soul, Hellgorak.",
      sellPrice: "2000",
      type: "amulet",
    },
    {
      name: "Sacred Tree Amulet",
      image: "/images/tibia/items/amulets/Sacred_Tree_Amulet.gif",
      level: 80,
      vocation: "Todas",
      arm: 0,
      charges: "5",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +60%, Earth +40%",
      weight: 7,
      drop: "Adult Goanna, Arachnophobica, Baleful Bunny, Bashmu, Cobra Scout, Cursed Book, Deepworm, Gorgo, Ink Blob, Lamassu, Liodile, Medusa, Noxious Ripptor, Nymph, Ogre Sage, Poisonous Carnisylvan, Ravenous Hunger, The Enraged Thorn Knight, The Many, Young Goanna, Rotten Golem.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Sapphire Amulet",
      image: "/images/tibia/items/amulets/Sapphire_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6.8,
      drop: "Lady Tenebris.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Sapphire Necklace",
      image: "/images/tibia/items/amulets/Sapphire_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Ninguém.",
      sellPrice: "4000 Trust Points",
      type: "amulet",
    },
    {
      name: "Scarab Amulet",
      image: "/images/tibia/items/amulets/Scarab_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 7.7,
      drop: "Ancient Scarab, Ancient Spawn of Morgathla, Fleshcrawler.",
      sellPrice: "200",
      type: "amulet",
    },
    {
      name: "Scarf",
      image: "/images/tibia/items/amulets/Scarf.gif",
      level: 0,
      vocation: "Todas",
      arm: 1,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 2,
      drop: "Cult Believer, Cult Enforcer, Cult Scholar, Hero, Novice of the Cult, Vicious Squire.",
      sellPrice: "15",
      type: "amulet",
    },
    {
      name: "Shockwave Amulet",
      image: "/images/tibia/items/amulets/Shockwave_Amulet.gif",
      level: 80,
      vocation: "Todas",
      arm: 0,
      charges: "5",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +60%, Energy +40%",
      weight: 7,
      drop: "Burster Spectre, Diamond Servant, Elder Wyrm, Essence Of Malice, Harpy, High Voltage Elemental, Massive Energy Elemental, Spiky Carnivor, Wyrm, Yielothax.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Shrunken Head Necklace",
      image: "/images/tibia/items/amulets/Shrunken_Head_Necklace.gif",
      level: 150,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Speed +10",
      protection: "Nenhuma.",
      weight: 5.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Silver Amulet",
      image: "/images/tibia/items/amulets/Silver_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Earth +10%",
      weight: 5,
      drop: "Adept of the Cult, Arachnophobica, Banshee, Big Boss Trolliver, Energy Elemental, Exotic Cave Spider, Golgordan, Island Troll, Latrivan, Midnight Asura, Mummy, Mutated Human, Troll, Troll Champion, Troll Guard, True Midnight Asura, Young Goanna.",
      sellPrice: "50",
      type: "amulet",
    },
    {
      name: "Silver Necklace",
      image: "/images/tibia/items/amulets/Silver_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Sleep Shawl",
      image: "/images/tibia/items/amulets/Sleep_Shawl.gif",
      level: 180,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.3,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Star Amulet",
      image: "/images/tibia/items/amulets/Star_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6.1,
      drop: "Ninguém.",
      sellPrice: "500",
      type: "amulet",
    },
    {
      name: "Stone Skin Amulet",
      image: "/images/tibia/items/amulets/Stone_Skin_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "5",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +80%, Death +80%",
      weight: 7,
      drop: "Arachnophobica, Banshee, Bloodback, Burster Spectre, Cobra Scout, Cursed Book, Darkfang, Drume, Ghulosh, Golgordan, Gorzindel, Hellgorak, Hemming, Hydra, Ink Blob, Latrivan, Lokathmor, Lost Soul, Massive Earth Elemental, Mazzinor, Minotaur Amazon, Misguided Thief, Morguthis, Nighthunter, Ogre Ruffian, Raging Mage, Shaper Matriarch, Stone Devourer, The Baron from Below, The Count Of The Core, The Duke Of The Depths, The Many, The Time Guardian, Warlock, Werebear, Wereboar, Werewolf, Rotten Golem.",
      sellPrice: "500",
      type: "amulet",
    },
    {
      name: "Strange Talisman",
      image: "/images/tibia/items/amulets/Strange_Talisman.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Energy +10%",
      weight: 2.9,
      drop: "Arachnophobica, Baleful Bunny, Burning Gladiator, Elder Mummy, Mummy, Mutated Human.",
      sellPrice: "30",
      type: "amulet",
    },
    {
      name: "Terra Amulet",
      image: "/images/tibia/items/amulets/Terra_Amulet.gif",
      level: 60,
      vocation: "Todas",
      arm: 0,
      charges: "200",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Earth +20%, Fire -10%",
      weight: 5,
      drop: "Adult Goanna, Baleful Bunny, Bashmu, Boogy, Cursed Book, Deathbine, Deepworm, Drillworm, Exotic Bat, Glooth Brigand, Gorgo, Hideous Fungus, Humongous Fungus, Ink Blob, Lamassu, Massive Earth Elemental, Medusa, Megasylvan Yselda, Ogre Sage, Omnivora, Plagirath, Spiky Carnivor, Two-Headed Turtle, Young Goanna, Rotten Golem.",
      sellPrice: "1500",
      type: "amulet",
    },
    {
      name: "The Cobra Amulet",
      image: "/images/tibia/items/amulets/The_Cobra_Amulet.gif",
      level: 250,
      vocation: "Todas",
      arm: 4,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Death +9%",
      weight: 5.3,
      drop: "Scarlett Etzel.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "The Eye of Suon",
      image: "/images/tibia/items/amulets/The_Eye_of_Suon.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "The Lion's Heart",
      image: "/images/tibia/items/amulets/The_Lion's_Heart.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Doma uma criatura específica.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 2,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Traditional Neckerchief",
      image: "/images/tibia/items/amulets/Traditional_Neckerchief.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Turtle Amulet",
      image: "/images/tibia/items/amulets/Turtle_Amulet.gif",
      level: 200,
      vocation: "Knights",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.8,
      drop: "Timira the Many-Headed.",
      sellPrice: "",
      type: "amulet",
    },
    {
      name: "Wailing Widow's Necklace",
      image: "/images/tibia/items/amulets/Wailing_Widow's_Necklace.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Fornece uma pequena quantidade de luz verde.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5.4,
      drop: "Wailing Widow.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Werewolf Amulet",
      image: "/images/tibia/items/amulets/Werewolf_Amulet.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Fornece uma pequena quantidade de luz.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5.5,
      drop: "Black Vixen, Werebadger, Werebear, Wereboar, Werefox.",
      sellPrice: "3000",
      type: "amulet",
    },
    {
      name: "Wolf Tooth Chain",
      image: "/images/tibia/items/amulets/Wolf_Tooth_Chain.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.3,
      drop: "Cyclops, Ekatrix, Gargoyle, Gloom Wolf, Orc Rider, Rorc, Witch, Yeti.",
      sellPrice: "100",
      type: "amulet",
    },
    {
      name: "Wolf Tooth Chain (Quest)",
      image: "/images/tibia/items/amulets/Wolf_Tooth_Chain_%28Quest%29.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 3.3,
      drop: "Ninguém.",
      sellPrice: "10000",
      type: "amulet",
    },
    //rings
    {
      name: "Alicorn Ring",
      image: "/images/tibia/items/rings/Alicorn_Ring.gif",
      level: 400,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Holy Magic Level +1",
      protection: "Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.85,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Arboreal Ring",
      image: "/images/tibia/items/rings/Arboreal_Ring.gif",
      level: 400,
      vocation: "Druids",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Healing Magic Level +2",
      protection: "Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.7,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Arcanomancer Sigil",
      image: "/images/tibia/items/rings/Arcanomancer_Sigil.gif",
      level: 400,
      vocation: "Sorcerers",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Fire Magic Level +1, Energy Magic Level +1",
      protection: "Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.9,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Axe Ring",
      image: "/images/tibia/items/rings/Axe_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Axe Fighting +4",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Arachnophobica, Dwarf Guard, Dwarf Soldier, Enslaved Dwarf, Madareth, Plaguesmith.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Blister Ring",
      image: "/images/tibia/items/rings/Blister_Ring.gif",
      level: 220,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Bragrumol, Mozradek, Urmahlullu the Weakened, Xogixath.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Broken Ring of Ending",
      image: "/images/tibia/items/rings/Broken_Ring_of_Ending.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Pode ser combinado a outro item.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.6,
      drop: "Yielothax.",
      sellPrice: "4000",
      type: "ring",
    },
    {
      name: "Broken Wedding Ring",
      image: "/images/tibia/items/rings/Broken_Wedding_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.4,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Butterfly Ring",
      image: "/images/tibia/items/rings/Butterfly_Ring.gif",
      level: 50,
      vocation: "Todas",
      arm: 2,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Death +3%",
      weight: 1.2,
      drop: "Dark Carnisylvan, Nymph, Pixie, Swan Maiden, Undertaker.",
      sellPrice: "2000",
      type: "ring",
    },
    {
      name: "Charged Alicorn Ring",
      image: "/images/tibia/items/rings/Charged_Alicorn_Ring.gif",
      level: 400,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "180",
      attributes: "Nenhum.",
      bonus: "Distance Fighting +3, Magic Level +1, Holy Magic Level +1",
      protection:
        "Fire +4%, Earth +4%, Energy +4%, Ice +4%, Holy +4%, Death +4%",
      weight: 0.85,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Charged Arboreal Ring",
      image: "/images/tibia/items/rings/Charged_Arboreal_Ring.gif",
      level: 400,
      vocation: "Druids",
      arm: 0,
      charges: "-",
      duration: "180",
      attributes: "Nenhum.",
      bonus: "Magic Level +2, Healing Magic Level +2",
      protection: "Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.7,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Charged Arcanomancer Sigil",
      image: "/images/tibia/items/rings/Charged_Arcanomancer_Sigil.gif",
      level: 400,
      vocation: "Sorcerers",
      arm: 0,
      charges: "-",
      duration: "180",
      attributes: "Nenhum.",
      bonus: "Magic Level +2, Fire Magic Level +1, Energy Magic Level +1",
      protection: "Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Charged Spiritthorn Ring",
      image: "/images/tibia/items/rings/Charged_Spiritthorn_Ring.gif",
      level: 400,
      vocation: "Knights",
      arm: 2,
      charges: "-",
      duration: "180",
      attributes: "Nenhum.",
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +8%, Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.95,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Claw of 'The Noxious Spawn'",
      image: "/images/tibia/items/rings/Claw_of_'The_Noxious_Spawn'.gif",
      level: 100,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Tira veneno ao usá-lo.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 5,
      drop: "The Noxious Spawn.",
      sellPrice: "15000",
      type: "ring",
    },
    {
      name: "Club Ring",
      image: "/images/tibia/items/rings/Club_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Club Fighting +4",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Animated Ogre Brute, Cursed Ape, Cyclops, Cyclops Drone, Cyclops Smith, Frost Giant, Frost Giantess, Gargoyle, Gang Member, Hairman The Huge, Insectoid Scout, Kongra, Madareth, Ogre Brute, Plaguesmith, War Golem.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Crystal Ring",
      image: "/images/tibia/items/rings/Crystal_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Banshee, Boar Man, Crystal Spider, Elder Mummy, Energy Elemental, Fury, High Voltage Elemental, Iks Chuka, Kusuma, Mantosaurus, Midnight Asura, Mummy, Naga Archer, Nightstalker, Raging Mage, Stone Golem, The Diamond Blossom, True Midnight Asura, Vashresamun, Warlock.",
      sellPrice: "250",
      type: "ring",
    },
    {
      name: "Death Ring",
      image: "/images/tibia/items/rings/Death_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 1,
      charges: "-",
      duration: "7,5",
      attributes: "Nenhum.",
      bonus: "Shielding -10",
      protection: "Death +5%",
      weight: 0.8,
      drop: "Arachnophobica, Betrayed Wraith, Blightwalker, Bretzecutioner, Burning Book, Dark Torturer, Dawnfire Asura, Defiler, Destroyer, Diabolic Imp, Fury, Golgordan, Grave Guard, Gravedigger, Grim Reaper, Hand of Cursed Fate, Latrivan, Lost Soul, Madareth, Nightmare, Nightmare Scion, Phantasm, Shulgrax, Souleater, Spectre, Spidris, Spidris Elite, The Blazing Rose, Tormentor, Undead Dragon, Zanakeph.",
      sellPrice: "1000",
      type: "ring",
    },
    {
      name: "Dwarven Ring",
      image: "/images/tibia/items/rings/Dwarven_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "60",
      attributes: "Impede-o de ficar bêbado.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1.1,
      drop: "Crazed Beggar, Drillworm, Dwarf, Dwarf Geomancer, Dwarf Miner, Gore Horn, Gozzler, Haunted Treeling, Lost Basher, Lost Berserker, Lost Exile, Lost Husher, Massive Earth Elemental, Mindmasher, Novice of the Cult, Orewalker, Stone Devourer, War Golem.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Enchanted Blister Ring",
      image: "/images/tibia/items/rings/Enchanted_Blister_Ring.gif",
      level: 220,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "60",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Fire +6%",
      weight: 0.9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Enchanted Ring of Souls",
      image: "/images/tibia/items/rings/Enchanted_Ring_of_Souls.gif",
      level: 200,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "120",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +2%, Life Drain +10%",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Energy Ring",
      image: "/images/tibia/items/rings/Energy_Ring.gif",
      level: 0,
      vocation: "Knights and Paladins",
      arm: 0,
      charges: "-",
      duration: "10",
      attributes: "Provém Mana Shield.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Albino Dragon, Animated Feather, Arachnophobica, Askarak Demon, Askarak Lord, Bruise Payne, Choking Fear, Demodras, Dipthrah, Dragon Lord, Energy Elemental, Enlightened of the Cult, Frost Dragon, Golgordan, Hand of Cursed Fate, High Voltage Elemental, Infernalist, Knowledge Elemental, Latrivan, Magma Crawler, Massive Water Elemental, Mazoran, Metal Gargoyle, Mutated Bat, Serpent Spawn, Shaburak Demon, Shaburak Lord, Tarbaz, The Old Widow, Twisted Shaper, Warlock, Water Elemental, Vexclaw.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Engraved Wedding Ring",
      image: "/images/tibia/items/rings/Engraved_Wedding_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.4,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Family Signet Ring",
      image: "/images/tibia/items/rings/Family_Signet_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "15000",
      type: "ring",
    },
    {
      name: "Frozen Claw",
      image: "/images/tibia/items/rings/Frozen_Claw.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 4.55,
      drop: "",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Gold Ring",
      image: "/images/tibia/items/rings/Gold_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1,
      drop: "Angry Demon, Blightwalker, Cave Chimera, Dark Carnisylvan, Demon, Golden Servant, Golgordan, Harpy, Katex Blood Tongue, Latrivan, Mantosaurus, Misguided Thief, Shrieking Cry-Stal, Yirkas Blue Scales.",
      sellPrice: "8000",
      type: "ring",
    },
    {
      name: "Horn (Ring)",
      image: "/images/tibia/items/rings/Horn_%28Ring%29.gif",
      level: 0,
      vocation: "Todas",
      arm: 1,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1.2,
      drop: "",
      sellPrice: "300",
      type: "ring",
    },
    {
      name: "Life Ring",
      image: "/images/tibia/items/rings/Life_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "20",
      attributes: "Regeneração rápida.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Acolyte of the Cult, Arachnophobica, Barbarian Headsplitter, Barbarian Skullhunter, Choking Fear, Deathling Scout, Deathling Spellsinger, Deepling Elite, Deepling Master Librarian, Deepling Scout, Deepling Spellsinger, Deepling Warrior, Deepworm, Ghoul, Lizard Dragon Priest, Lizard Snakecharmer, Madareth, Mahrdis, Massive Water Elemental, Metal Gargoyle, Mutated Tiger, Omnivora, Renegade Quara Hydromancer, Rot Elemental, Serpent Spawn, The Lord of the Lice, The Noxious Spawn, The Snapper, Undead Prospector,  Zombie.",
      sellPrice: "50",
      type: "ring",
    },
    {
      name: "Lion Ring",
      image: "/images/tibia/items/rings/Lion_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.4,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Might Ring",
      image: "/images/tibia/items/rings/Might_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "20",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection:
        "Physical +20%, Fire +20%, Earth +20%, Energy +20%, Ice +20%, Holy +20%, Death +20%",
      weight: 1,
      drop: "Angry Demon, Ashmunrah, Cult Scholar, Demon, Demon Outcast, Diamond Servant, Diamond Servant Replica, Hero, Latrivan, Rage Squid, Roaring Lion, Vexclaw, Vicious Squire, Worker Golem, Yielothax.",
      sellPrice: "250",
      type: "ring",
    },
    {
      name: "Power Ring",
      image: "/images/tibia/items/rings/Power_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Fist Fighting +6",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Arachnophobica, Cursed Ape, Dark Monk, Monk, Kongra, Ogre Brute, Stone Golem.",
      sellPrice: "50",
      type: "ring",
    },
    {
      name: "Prismatic Ring",
      image: "/images/tibia/items/rings/Prismatic_Ring.gif",
      level: 120,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "60",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +10%, Energy +8%",
      weight: 1.05,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Rerun's Ring",
      image: "/images/tibia/items/rings/Rerun's_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Ring of Blue Plasma",
      image: "/images/tibia/items/rings/Ring_of_Blue_Plasma.gif",
      level: 100,
      vocation: "Paladins",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Distance Fighting +3, Magic Level +1",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Alptramun, Anomaly, Arachnophobica, Brain Squid, Breach Brood, Burster, Cave Chimera, Count Vlarkorth, Crazed Summer Rearguard, Dread Intruder, Dreadful Disruptor, Duke Krule, Eradicator, Essence Of Malice, Freakish Lost Soul, Guard Captain Quaid, Izcandar Champion of Winter, King Zelos, Lord Azaram, Malofur Mangrinder, Maxxenius, Outburst, Pirat Bombardier, Plagueroot, Reality Reaver, Realityquake\n, Rupture, Sir Baeloc, Soul-Broken Harbinger, Sparkion, The Nightmare Beast, The Unarmored Voidborn, Venerable Girtablilu,  World Devourer.",
      sellPrice: "8000",
      type: "ring",
    },
    {
      name: "Ring of Ending",
      image: "/images/tibia/items/rings/Ring_of_Ending.gif",
      level: 200,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Fornece uma pequena quantidade de luz avermelhada.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Ring of Green Plasma",
      image: "/images/tibia/items/rings/Ring_of_Green_Plasma.gif",
      level: 100,
      vocation: "Sorcerers and Druids",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Regeneração rápida de 1 de HP e 4 de MP a cada 6 segundos.",
      bonus: "Magic Level +2",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Alptramun, Anomaly, Burster, Dread Intruder, Eradicator, Essence Of Malice, Izcandar the Banished, Katex Blood Tongue, Malofur Mangrinder, Maxxenius, Outburst, Realityquake, Reality Reaver, Rupture, Sopping Corpus, Srezz Yellow Eyes, The Nightmare Beast, Utua Stone Sting, World Devourer, Yirkas Blue Scales.",
      sellPrice: "8000",
      type: "ring",
    },
    {
      name: "Ring of Healing",
      image: "/images/tibia/items/rings/Ring_of_Healing.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "7,5",
      attributes: "Provém rápida regeneração.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ancient Spawn of Morgathla, Angry Demon, Arachnophobica, Arthei, Banshee, Boreth, Broken Shaper, Choking Fear, Death Priest, Demon, Demon Outcast, Esmeralda, Hydra, Glooth Bomb, Golgordan, Kollos, Lady Tenebris, Latrivan, Lersatio, Lich, Lloyd, Madareth, Marziel, Minotaur Amazon, Minotaur Cult Follower, Minotaur Cult Prophet, Moohtant, Moohtant Wallbreaker, Nightfiend, Overcharged Energy Elemental, Plagirath, Prince Drazzak, Quara Hydromancer, Quara Hydromancer Scout, Ragiaz, Rahemos, Renegade Quara Constrictor, Sea Serpent, Shaper Matriarch, Sharpclaw, The Enraged Thorn Knight, The Sandking, The Time Guardian, Tremor Worm, Werebadger, Werefox, Zamulosh, Vexclaw.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Ring of Red Plasma",
      image: "/images/tibia/items/rings/Ring_of_Red_Plasma.gif",
      level: 100,
      vocation: "Knights",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Sword Fighting +3, Club Fighting +3, Axe Fighting +3",
      protection: "Physical +3%",
      weight: 0.9,
      drop: "Alptramun, Cobra Assassin, Cobra Scout, Dread Intruder, Eradicator, Izcandar the Banished, King Zelos, Maxxenius, Outburst, Realityquake, Rupture, The Last Lore Keeper, The Nightmare Beast, The Scourge of Oblivion, The Source Of Corruption, The Unarmored Voidborn, Usurper Commander, World Devourer.",
      sellPrice: "8000",
      type: "ring",
    },
    {
      name: "Ring of Secret Thoughts",
      image: "/images/tibia/items/rings/Ring_of_Secret_Thoughts.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Urmahlullu the Weakened.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Ring of Secret Thoughts (Charged)",
      image:
        "/images/tibia/items/rings/Ring_of_Secret_Thoughts_%28Charged%29.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Ring of Souls",
      image: "/images/tibia/items/rings/Ring_of_Souls.gif",
      level: 200,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Brain Head, The Pale Worm.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Ring of the Sky",
      image: "/images/tibia/items/rings/Ring_of_the_Sky.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.4,
      drop: "Crape Man, Draken Spellweaver, Draken Warmaster, Izcandar the Banished, Lady Tenebris, Mad Mage, Malofur Mangrinder, Maxxenius, Plagueroot, Raging Mage, The Nightmare Beast, Warlock.",
      sellPrice: "30000",
      type: "ring",
    },
    {
      name: "Ring of Wishes",
      image: "/images/tibia/items/rings/Ring_of_Wishes.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.5,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Shapeshifter Ring",
      image: "/images/tibia/items/rings/Shapeshifter_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 1,
      charges: "-",
      duration: "600",
      attributes: "Muda a sua aparência para forma de algumas criaturas.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Signet Ring",
      image: "/images/tibia/items/rings/Signet_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "15000",
      type: "ring",
    },
    {
      name: "Spiritthorn Ring",
      image: "/images/tibia/items/rings/Spiritthorn_Ring.gif",
      level: 400,
      vocation: "Knights",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Physical +2%, Fire +4%, Earth +4%, Energy +4%, Ice +4%",
      weight: 0.95,
      drop: "Magma Bubble.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Star Ring",
      image: "/images/tibia/items/rings/Star_Ring.gif",
      level: 0,
      vocation: "players without vocation",
      arm: 0,
      charges: "-",
      duration: "10",
      attributes: "Aumenta o seu hitpoints quando você não estiver com fome.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Stealth Ring",
      image: "/images/tibia/items/rings/Stealth_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "10",
      attributes: "Quando equipado, deixa o usuário invisível.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 1,
      drop: "Angry Demon, Askarak Prince, Bibby Bloodbath, Black Vixen, Brimstone Bug, Burning Book, Cave Devourer, Demon, Demon Outcast, Diabolic Imp, Ekatrix, Enfeebled Silencer, Ghost, Golden Servant, Golden Servant Replica, Golgordan, Latrivan, Mad Mage, Minotaur Hunter, Mutated Rat, Omruc, Orc Warlord, Phantasm, Pirate Ghost, Pooka, Quara Mantassin Scout, Quara Mantassin, Raging Mage, Renegade Quara Mantassin, Sea Serpent, Shaburak Prince, Silencer, Spectre, Sulphur Scuttler, Tarnished Spirit, Thalas, The Horned Fox, The Old Widow, Troll Legionnaire, Twisted Pooka, Undead Elite Gladiator, Undead Gladiator, Young Sea Serpent, Zamulosh.",
      sellPrice: "200",
      type: "ring",
    },
    {
      name: "Suspicious Signet Ring",
      image: "/images/tibia/items/rings/Suspicious_Signet_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.8,
      drop: "Ninguém.",
      sellPrice: "15000",
      type: "ring",
    },
    {
      name: "Sweetheart Ring",
      image: "/images/tibia/items/rings/Sweetheart_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes:
        "Quando equipado com ele, o personagem solta corações ao usá-lo.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Ninguém.",
      sellPrice: "500",
      type: "ring",
    },
    {
      name: "Sword Ring",
      image: "/images/tibia/items/rings/Sword_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "30",
      attributes: "Nenhum.",
      bonus: "Sword Fighting +4",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Arachnophobica, Damaged Worker Golem, Madareth, Mooh'Tah Warrior, Orc Cult Fanatic, Orc Leader, Renegade Orc, Vulcongra, Werehyaena Shaman.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Time Ring",
      image: "/images/tibia/items/rings/Time_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "10",
      attributes: "Nenhum.",
      bonus: "Speed +30",
      protection: "Nenhuma.",
      weight: 0.9,
      drop: "Adept of the Cult, Armadile, Crystal Spider, Darkfang, Giant Spider, Hemming, Hide, Madareth, Renegade Quara Pincher, Rhindeer, Spitter, Tarantula, Thalas, The Bloodweb, The Old Widow, Werebear, Werewolf, Zamulosh.",
      sellPrice: "100",
      type: "ring",
    },
    {
      name: "Unstable Ring of Ending",
      image: "/images/tibia/items/rings/Unstable_Ring_of_Ending.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.6,
      drop: "Ninguém.",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Vampire's Signet Ring",
      image: "/images/tibia/items/rings/Vampire's_Signet_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.1,
      drop: "",
      sellPrice: "",
      type: "ring",
    },
    {
      name: "Wedding Ring",
      image: "/images/tibia/items/rings/Wedding_Ring.gif",
      level: 0,
      vocation: "Todas",
      arm: 0,
      charges: "-",
      duration: "-",
      attributes: "Nenhum.",
      bonus: "Nenhum.",
      protection: "Nenhuma.",
      weight: 0.4,
      drop: "Banshee, Hero, Vile Grandmaster.",
      sellPrice: "100",
      type: "ring",
    },
  ],
};
