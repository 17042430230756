import SpeedView from "../../../components/views/calculators/speed";

const PageSpeed = () => {
  return (
    <>
      <SpeedView />
    </>
  );
};

export default PageSpeed;
