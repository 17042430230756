import { useState } from "react";
import { motion } from "framer-motion";
import useSWR from "swr";
import { fadeIn, slideIn } from "../../../utility/effects";
import { API_BASE_URL } from "../../../utility/constants";
import { ImageToBase64 } from "../../../libs/functions";
import Loader from "../../helpers/Loader";

const fetcher = async (url) => {
  const response = await fetch(url);
  const data = await response.json();

  // Certifique-se de que data.bosses é um array
  const bossesArray = Array.isArray(data.bosses) ? data.bosses : [];
  return bossesArray.filter((boss) => boss.name.trim() !== "");
};

const DreamScarComponent = () => {
  const [filterServer, setFilterServer] = useState("");

  const { data: bosses, error } = useSWR(
    `${API_BASE_URL}/library/dreamscarboss`,
    fetcher
  );

  const isLoading = !bosses && !error;

  // Verifique se bosses é um array antes de usar o método filter
  const filteredBosses = Array.isArray(bosses)
    ? bosses.filter(
        (boss) =>
          boss.server.toLowerCase().includes(filterServer.toLowerCase()) &&
          boss.name.trim() !== ""
      )
    : [];

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase font-teko text-center"
          variants={slideIn}
        >
          Dream <span className="text-yellow-400">Scar</span>
        </motion.h1>

        <div className="flex items-center justify-center mb-4">
          <label htmlFor="serverFilter" className="text-lg font-medium">
            Filtrar por Servidor:
          </label>
          <input
            type="text"
            placeholder="Digite o nome do boss"
            id="serverFilter"
            className="input input-bordered input-secondary w-full max-w-xs ml-4 my-10"
            value={filterServer}
            onChange={(e) => setFilterServer(e.target.value)}
          />
        </div>

        {isLoading && <Loader />}

        {!isLoading && filteredBosses.length > 0 && (
          <table className="table-auto w-full table-zebra">
            <thead>
              <tr>
                <th className="px-4 py-2">Servidor</th>
                <th className="px-4 py-2">Nome do Boss</th>
                <th className="px-4 py-2">Imagem</th>
              </tr>
            </thead>
            <tbody>
              {filteredBosses.map((boss, index) => (
                <tr key={index}>
                  <td className="border border-secondary px-4 py-2">
                    {boss.server}
                  </td>
                  <td className="border border-secondary px-4 py-2">
                    {boss.name}
                  </td>
                  <td className="border border-secondary px-4 py-2">
                    <div className="relative h-16 w-16">
                      <ImageToBase64
                        src={boss.image}
                        alt={boss.name}
                        layout="fill"
                        objectFit="cover"
                      />
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}

        {!isLoading && filteredBosses.length === 0 && !error && (
          <p className="w-full text-center">
            Nenhum boss encontrado. Tente novamente!
          </p>
        )}

        {!isLoading && error && (
          <p>Ocorreu um erro ao carregar os dados. Tente novamente.</p>
        )}
      </motion.div>
    </>
  );
};

export default DreamScarComponent;
