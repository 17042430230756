import ExerciseWeaponsView from "../../../components/views/calculators/exercise-weapons";

const PageExerciseWeapons = () => {
  return (
    <>
      <ExerciseWeaponsView />
    </>
  );
};

export default PageExerciseWeapons;
