import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";
import {
  analyzeHuntData,
  validateHuntData,
  extractNumbersFromString,
  getCreatureName,
  calculateExp,
  capitalizeFirstLetter,
  ImageToBase64,
} from "../../../../libs/functions";
import HuntAnalyzerResult from "./HuntAnalyzerResult";
import { experienceTable } from "../../../../data/exptable";

const HuntAnalyzerView = () => {
  const [huntSession, setHuntSession] = useState("");
  const [includeStamina, setIncludeStamina] = useState(true);
  const [includeBoost, setIncludeBoost] = useState(false);
  const [includeDoubleXP, setIncludeDoubleXP] = useState(false);
  const [huntAnalyzerData, setHuntAnalyzerData] = useState({
    creaturesData: [],
    gainedExperience: 0,
    lootedItems: [],
  });

  const handleChange = (analyserInput) => {
    if (!validateHuntData(analyserInput.currentTarget.value) && !huntSession) {
      return;
    }
    setHuntSession(analyserInput.currentTarget.value);
  };

  const handleCheckboxChange = (checkboxType) => {
    switch (checkboxType) {
      case "stamina":
        setIncludeStamina((prevState) => !prevState);
        break;
      case "boost":
        setIncludeBoost((prevState) => !prevState);
        break;
      case "doubleXP":
        setIncludeDoubleXP((prevState) => !prevState);
        break;
      default:
        break;
    }
  };

  const matchingCreatureNames = new Set(
    huntAnalyzerData.creaturesData.map((creature) =>
      getCreatureName(creature.name)
    )
  );

  const [preySelection, setPreySelection] = useState(
    Object.fromEntries(
      [...matchingCreatureNames].map((creatureName) => [creatureName, false])
    )
  );

  const handlePreyChange = (creatureName) => {
    setPreySelection((prevSelection) => ({
      ...prevSelection,
      [creatureName]: !prevSelection[creatureName],
    }));
  };

  const PreySelection = ({ preySelection, handlePreyChange }) => (
    <div className="flex flex-wrap gap-4 max-w-xs sm:max-w-md md:max-w-xl">
      {Array.from(matchingCreatureNames).map((creatureName) => (
        <label key={creatureName} className="flex items-center">
          <input
            type="checkbox"
            checked={preySelection[creatureName] || false}
            onChange={() => handlePreyChange(creatureName)}
            className="toggle toggle-success"
          />
          <span className="ml-2">{capitalizeFirstLetter(creatureName)}</span>
        </label>
      ))}
    </div>
  );

  useEffect(() => {
    const processedData = analyzeHuntData(huntSession, {
      includeStamina,
      includeBoost,
    });

    const dataWithExp = processedData.killedMonsters.map((killedMonster) => {
      const creatureName = getCreatureName(killedMonster.toLowerCase());
      const matchingCreature = experienceTable.creatures.find(
        (creature) => creature.name.toLowerCase() === creatureName
      );

      if (!matchingCreature) {
        return { name: killedMonster, exp: 0 };
      }

      const creatureExp = calculateExp(
        matchingCreature,
        includeStamina,
        includeBoost,
        includeDoubleXP,
        preySelection
      );
      const totalExp = creatureExp * extractNumbersFromString(killedMonster);

      return {
        name: killedMonster,
        exp: totalExp,
      };
    });

    const gainedExperience = dataWithExp.reduce(
      (acc, creature) => acc + creature.exp,
      0
    );

    setHuntAnalyzerData({
      creaturesData: dataWithExp,
      gainedExperience,
      lootedItems: processedData.lootedItems,
    });
  }, [
    huntSession,
    includeStamina,
    includeBoost,
    includeDoubleXP,
    preySelection,
  ]);

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Analizador de <span className="text-yellow-400">Hunt</span>
        </motion.h1>
        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <div>
            <h2 className="text-2xl mb-4">Como Utilizar?</h2>

            <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
              <ul className="mb-4 ml-4">
                <li className="list-decimal">Faça uma hunt no jogo.</li>
                <li className="list-decimal">
                  Copie seu Hunt Analyzer como mostra a imagem abaixo.
                </li>
                <li className="list-decimal">
                  Cole no campo abaixo seu Hunt Analyzer.
                </li>
                <li className="list-decimal">
                  Selecione as opções que você deseja (Stamina, XP Boost e
                  Preys).
                </li>
              </ul>
            </div>

            <div className="bg-base-300 border border-base-100 p-4 rounded-lg shadow-md">
              <div className="flex items-center justify-around flex-col">
                <div className="relative w-52 mb-4">
                  <ImageToBase64
                    alt="hunt analyzer"
                    src="/images/tibia/hunt-analyzer.png"
                  />
                </div>

                <div className="flex items-center space-x-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={includeStamina}
                      onChange={() => handleCheckboxChange("stamina")}
                      className="toggle toggle-success"
                    />
                    <span className="ml-2">Stamina</span>
                  </label>

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={includeBoost}
                      onChange={() => handleCheckboxChange("boost")}
                      className="toggle toggle-success"
                    />
                    <span className="ml-2">XP Boost</span>
                  </label>

                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      checked={includeDoubleXP}
                      onChange={() => handleCheckboxChange("doubleXP")}
                      className="toggle toggle-success"
                    />
                    <span className="ml-2">Double XP Evento</span>
                  </label>
                </div>

                {huntSession && huntSession.trim() !== "" && (
                  <div className="alert my-8 indicator">
                    <span className="indicator-item badge badge-primary mr-8">
                      Preys (40%)
                    </span>
                    <PreySelection
                      preySelection={preySelection}
                      handlePreyChange={handlePreyChange}
                    />
                  </div>
                )}
              </div>

              <textarea
                className="textarea textarea-bordered w-full textarea-lg border-neutral my-8"
                onChange={handleChange}
                placeholder="Insira os dados do seu Hunt Analyzer aqui"
              />
              <AnimatePresence>
                {(huntAnalyzerData.creaturesData.length > 0 ||
                  huntAnalyzerData.lootedItems.length > 0) && (
                  <HuntAnalyzerResult
                    key="result-content"
                    huntSessionData={huntSession}
                    creaturesData={huntAnalyzerData.creaturesData}
                    gainedExperience={huntAnalyzerData.gainedExperience}
                    lootedItems={huntAnalyzerData.lootedItems}
                    includeStamina={includeStamina}
                  />
                )}
              </AnimatePresence>
            </div>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default HuntAnalyzerView;
