import ExperienceView from "../../../components/views/calculators/experience";

const PageExperience = () => {
  return (
    <>
      <ExperienceView />
    </>
  );
};

export default PageExperience;
