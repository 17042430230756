import HuntAnalyzerView from "../../../components/views/tools/hunt-analyzer/HuntAnalyzer";

const PageHuntAnalyzer = () => {
  return (
    <>
      <HuntAnalyzerView />
    </>
  );
};

export default PageHuntAnalyzer;
