import React from "react";
import { motion } from "framer-motion";
import CopyToClipboard from "../../../helpers/CopyToClipboard";
import ResultItem from "./ResultItem";
import {
  ConvertGold,
  ImageToBase64,
  getCurrency,
} from "../../../../libs/functions";
import { slideDown } from "../../../../utility/effects";

const SplitResult = ({ payments, individualProfit, numberOfplayers }) => {
  function totalProfit() {
    const result = individualProfit * numberOfplayers;
    return ConvertGold(result);
  }

  return (
    <motion.div
      className="bg-base-300 p-4 rounded-lg shadow-md mb-8"
      variants={slideDown}
    >
      <h2 className="text-2xl font-bold mb-4">Resultado</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {payments.map((payment) => (
          <ResultItem
            key={`${payment.name}${payment.amount}`}
            name={payment.name}
            amount={payment.amount}
            payTo={payment.payTo}
          />
        ))}
      </div>

      <div className="mt-8">
        <div className="flex items-center mb-4">
          <ImageToBase64
            width={30}
            height={30}
            src="/images/tibia/items/Crystal_Coin.gif"
            alt="tibia crystal coin"
            className="w-10"
          />

          <strong className="ml-2">Totao Profit:</strong>
          <span className="ml-2">
            {totalProfit()}
            {getCurrency(individualProfit * numberOfplayers)}
          </span>

          <strong className="ml-2">Profit Individual</strong>
          <span className="ml-2">
            {ConvertGold(individualProfit)}
            {getCurrency(individualProfit)}
          </span>
        </div>

        <div className="flex justify-between">
          <div>
            <CopyToClipboard title="Copiar tudo  ">
              {`${payments
                .map(
                  ({ name, amount, payTo }) =>
                    `${name} deve pagar ${ConvertGold(
                      amount
                    )} para ${payTo} (transfer ${amount} to ${payTo}) \n`
                )
                .join("")}\nTotal Profit ${ConvertGold(
                individualProfit * numberOfplayers
              )}${getCurrency(
                individualProfit * numberOfplayers
              )}\nProfit Individual ${ConvertGold(
                individualProfit
              )}${getCurrency(individualProfit)}
              `}
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default SplitResult;
