import LootByWeightView from "../../../components/views/calculators/loot-by-weight";

const PageLootByWeight = () => {
  return (
    <>
      <LootByWeightView />
    </>
  );
};

export default PageLootByWeight;
