const speedSpells = [
  {
    name: "Charge",
    formula: (baseSpeed) => baseSpeed + Math.floor(baseSpeed * 0.9) - 36,
    description: "Charge (utani tempo hur)",
    image: "/images/tibia/spells/Charge.gif",
  },
  {
    name: "Haste",
    formula: (baseSpeed) => (baseSpeed += Math.floor(baseSpeed * 0.3) - 12),
    description: "Haste (utani hur)",
    image: "/images/tibia/spells/Haste.gif",
  },
  {
    name: "Strong Haste",
    formula: (baseSpeed) => baseSpeed + Math.floor(baseSpeed * 0.7) - 28,
    description: "Strong Haste (utani gran hur)",
    image: "/images/tibia/spells/Strong_Haste.gif",
  },
  {
    name: "Swift Foot",
    formula: (baseSpeed) => baseSpeed + Math.floor(baseSpeed * 0.8) - 32,
    description: "Swift Foot (utamo tempo san)",
    image: "/images/tibia/spells/Swift_Foot.gif",
  },
];

const speedItems = [
  {
    name: "Alloy Legs",
    speed: 10,
    image: "/images/tibia/items/legs/Alloy_Legs.gif",
  },
  {
    name: "Badger Boots",
    speed: 10,
    image: "/images/tibia/items/boots/Badger_Boots.gif",
  },
  {
    name: "Beetle Necklace",
    speed: 2,
    image: "/images/tibia/items/amulets/Beetle_Necklace.gif",
  },
  {
    name: "Boots of Haste",
    speed: 20,
    image: "/images/tibia/items/boots/Boots_of_Haste.gif",
  },
  {
    name: "Cobra Boots",
    speed: 10,
    image: "/images/tibia/items/boots/Cobra_Boots.gif",
  },
  {
    name: "Crest of the Deep Seas",
    speed: 5,
    image: "/images/tibia/items/helmets/Crest_of_the_Deep_Seas.gif",
  },
  {
    name: "Draken Boots",
    speed: 15,
    image: "/images/tibia/items/boots/Draken_Boots.gif",
  },
  {
    name: "Elite Draken Mail",
    speed: 10,
    image: "/images/tibia/items/armors/Elite_Draken_Mail.gif",
  },
  {
    name: "Feverbloom Boots",
    speed: 15,
    image: "/images/tibia/items/boots/Feverbloom_Boots.gif",
  },
  {
    name: "Fish Tail (Equippedo)",
    speed: 300,
    image: "/images/tibia/items/others/Fish_Tail_(Equipped).gif",
  },
  {
    name: "Grasshopper Legs",
    speed: 10,
    image: "/images/tibia/items/legs/Grasshopper_Legs.gif",
  },
  {
    name: "Magical Torch",
    speed: 25,
    image: "/images/tibia/items/others/Magical_Torch.gif",
  },
  {
    name: "Oriental Shoes",
    speed: 15,
    image: "/images/tibia/items/boots/Oriental_Shoes.gif",
  },
  {
    name: "Pair of Soulstalkers",
    speed: 20,
    image: "/images/tibia/items/boots/Pair_of_Soulstalkers.gif",
  },
  {
    name: "Pair of Soulwalkers",
    speed: 15,
    image: "/images/tibia/items/boots/Pair_of_Soulwalkers.gif",
  },
  {
    name: "Prismatic Armor",
    speed: 15,
    image: "/images/tibia/items/armors/Prismatic_Armor.gif",
  },
  {
    name: "Prismatic Boots",
    speed: 15,
    image: "/images/tibia/items/boots/Prismatic_Boots.gif",
  },
  {
    name: "Sanguine Boots",
    speed: 10,
    image: "/images/tibia/items/boots/Sanguine_Boots.gif",
  },
  {
    name: "Sanguine Galoshes",
    speed: 10,
    image: "/images/tibia/items/boots/Sanguine_Galoshes.gif",
  },
  {
    name: "Sparking Rainbow Shield",
    speed: 10,
    image: "/images/tibia/items/shields/Sparking_Rainbow_Shield.gif",
  },
  {
    name: "Stoic Iks Boots",
    speed: 15,
    image: "/images/tibia/items/boots/Stoic_Iks_Boots.gif",
  },
  {
    name: "Shrunken Head Necklace",
    speed: 10,
    image: "/images/tibia/items/amulets/Shrunken_Head_Necklace.gif",
  },
  {
    name: "Swan Feather Cloak",
    speed: 10,
    image: "/images/tibia/items/armors/Swan_Feather_Cloak.gif",
  },
  {
    name: "Tiara of Power",
    speed: 20,
    image: "/images/tibia/items/helmets/Tiara_of_Power.gif",
  },
  {
    name: "Time Ring",
    speed: 30,
    image: "/images/tibia/items/rings/Time_Ring.gif",
  },
  {
    name: "Void Boots",
    speed: 30,
    image: "/images/tibia/items/boots/Void_Boots.gif",
  },
  {
    name: "Werewolf Helmet",
    speed: 15,
    image: "/images/tibia/items/helmets/Werewolf_Helmet.gif",
  },
  {
    name: "Winged Boots",
    speed: 15,
    image: "/images/tibia/items/boots/Winged_Boots.gif",
  },
  {
    name: "Zaoan Armor",
    speed: 10,
    image: "/images/tibia/items/armors/Zaoan_Armor.gif",
  },
  {
    name: "Zaoan Shoes",
    speed: 5,
    image: "/images/tibia/items/boots/Zaoan_Shoes.gif",
  },
  // Adicione mais itens conforme necessário
];

export { speedItems, speedSpells };
