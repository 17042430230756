import HighscoreSearch from "../../../components/community/highscores";

const PageHighScores = () => {
  return (
    <>
      <HighscoreSearch />
    </>
  );
};

export default PageHighScores;
