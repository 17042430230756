import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { fadeIn, slideIn, slideUp } from "../../../../utility/effects";

import SplitResult from "./SplitResult";
import {
  ImageToBase64,
  getPayments,
  validatePartyData,
} from "../../../../libs/functions";

const LootSpliterView = () => {
  const [huntSession, setHuntSession] = useState("");
  const [paymentData, setPaymentData] = useState();
  const [numberOfPlayers, setNumberOfPlayers] = useState(0);
  const [individualProfit, setIndividualProfit] = useState(0);

  function handleChange(analyserInput) {
    if (!validatePartyData(analyserInput.currentTarget.value) && !huntSession) {
      return;
    }
    setHuntSession(analyserInput.currentTarget.value);
  }

  useEffect(() => {
    const payments = getPayments(huntSession);
    setPaymentData(payments.payments);
    setIndividualProfit(payments.individualProfit);
    setNumberOfPlayers(payments.numberOfPlayers);
  }, [huntSession]);

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Loot <span className="text-yellow-400">Spliter</span>
        </motion.h1>
        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <div>
            <h2 className="text-2xl mb-4">Como Utilizar?</h2>
          </div>
          <div className="bg-base-300 border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">Faça uma hunt com sua pt no jogo</li>
              <li className="list-decimal">
                Copie seu Party Hunt Analyzer como mostra a imagem abaixo
              </li>
              <li className="list-decimal">
                Cole no campo abaixo seu Party Hunt Analyzer
              </li>
              <li className="list-decimal">
                Nossa I.A se encarrega do trabalho duro pra você :)
              </li>
            </ul>
          </div>
          <div className="bg-base-300 border border-base-100 p-4 rounded-lg shadow-md">
            <div className="flex items-center justify-around">
              <div className="relative w-60">
                <ImageToBase64
                  alt="party hunt analyzer"
                  src="/images/tibia/party-hunt-analyser.png"
                />
              </div>
            </div>
            <textarea
              className="textarea textarea-bordered w-full textarea-lg mt-4"
              onChange={handleChange}
              placeholder="Insira os dados do seu Party Hunt aqui"
            />

            <AnimatePresence>
              {paymentData && paymentData.length > 0 && (
                <SplitResult
                  key="result-content"
                  payments={paymentData}
                  numberOfplayers={numberOfPlayers}
                  individualProfit={individualProfit}
                />
              )}
            </AnimatePresence>
          </div>
        </motion.div>
      </motion.div>
    </>
  );
};

export default LootSpliterView;
