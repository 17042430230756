import React from "react";
import { motion } from "framer-motion";
import { calculateSessionTime } from "../../../../libs/functions";
import HuntAnalyzerItemResult from "./HuntAnalyzerItemResult";
import HuntAnalyzerTotalExp from "./HuntAnalyzerTotalExp";
import { fadeIn } from "../../../../utility/effects";

const HuntAnalyzerResult = ({
  huntSessionData,
  creaturesData,
  gainedExperience,
  lootedItems,
  includeStamina,
}) => {
  return (
    <motion.div
      className="bg-base-300 p-4 rounded-lg shadow-md mb-8"
      initial="hidden"
      animate="visible"
      variants={fadeIn}
    >
      {/* Adicione a exibição do tempo de sessão aqui */}
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">Tempo de Sessão:</h3>
        <p>
          O dados abaixo equivalem para{" "}
          <span className="text-info">
            {calculateSessionTime(huntSessionData)}
          </span>{" "}
          de hunt de acordo com seu Hunt Analizer
        </p>
      </div>

      {/* Renderização dos dados de killedMonsters */}
      <div className="mb-4">
        <h3 className="text-xl font-bold mb-2">Criaturas Mortas:</h3>
        {creaturesData.map((creature, index) => (
          <HuntAnalyzerItemResult
            key={index}
            title={creature.name}
            exp={creature.exp}
            includeStamina={includeStamina}
          />
        ))}
      </div>

      {/* Exibir a exp total */}
      <HuntAnalyzerTotalExp
        gainedExperience={gainedExperience}
        includeStamina={includeStamina}
      />

      {/* Renderização dos dados de lootedItems **/}
      <div>
        <h3 className="text-xl font-bold mb-2">Itens Coletados:</h3>
        <div className="flex flex-wrap">
          {lootedItems.map((item, index, array) => (
            <span key={index} className="mr-2 mb-2">
              {item}
              {index < array.length - 1 && ", "}
            </span>
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default HuntAnalyzerResult;
