import LootSpliterView from "../../../components/views/tools/loot-spliter/LootSpliter";

const PageLootSpliter = () => {
  return (
    <>
      <LootSpliterView />
    </>
  );
};

export default PageLootSpliter;
