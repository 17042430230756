import { Routes, Route, useLocation } from "react-router-dom";

import PageHome from "./pages/home";
import PageAbout from "./pages/about";
import PageContact from "./pages/contact";
import { useEffect } from "react";
import PageDonate from "./pages/donate";
import PageSecurity from "./pages/security-hints";
import PageBlessings from "./pages/calculators/blessings";
import PageMagicShield from "./pages/calculators/magic-shield";
import PageSharedExp from "./pages/calculators/shared-exp";
import PageExperience from "./pages/calculators/experience";
import PageStamina from "./pages/calculators/stamina";
import PageSpeed from "./pages/calculators/speed";
import PageExerciseWeapons from "./pages/calculators/exercise-weapons";
import PageHuntAnalyzer from "./pages/tools/hunt-analyzer";
import PageLootSpliter from "./pages/tools/loot-spliter";
import PageLootByWeight from "./pages/calculators/loot-by-weight";
import PagePartyFinder from "./pages/tools/party-finder";
import PageNotFound from "./pages/404";
import PageCharacter from "./pages/community/characters/[name]";
import PageNpcItemPrice from "./pages/library/npc-item-price";
import PageDreamScar from "./pages/library/dream-scar";
import PageBossList from "./pages/library/boss-list";
import PageHighScores from "./pages/community/highscores";

function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Routes>
        <Route index element={<PageHome />} />
        <Route path="about" element={<PageAbout />} />
        <Route path="contact" element={<PageContact />} />
        <Route path="donate" element={<PageDonate />} />
        <Route path="security-hints" element={<PageSecurity />} />

        <Route path="calculators/blessings" element={<PageBlessings />} />
        <Route path="calculators/magic-shield" element={<PageMagicShield />} />
        <Route path="calculators/shared-exp" element={<PageSharedExp />} />
        <Route path="calculators/experience" element={<PageExperience />} />
        <Route path="calculators/stamina" element={<PageStamina />} />
        <Route path="calculators/speed" element={<PageSpeed />} />
        <Route
          path="calculators/exercise-weapons"
          element={<PageExerciseWeapons />}
        />
        <Route
          path="calculators/loot-by-weight"
          element={<PageLootByWeight />}
        />

        <Route path="tools/hunt-analyzer" element={<PageHuntAnalyzer />} />
        <Route path="tools/loot-spliter" element={<PageLootSpliter />} />
        <Route path="tools/party-finder" element={<PagePartyFinder />} />

        <Route path="community/character/:name" element={<PageCharacter />} />
        <Route path="community/highscores" element={<PageHighScores />} />

        <Route path="/library/dream-scar" element={<PageDreamScar />} />
        <Route path="/library/npc-item-price" element={<PageNpcItemPrice />} />
        <Route path="/library/boss-list" element={<PageBossList />} />

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
