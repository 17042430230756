import React from "react";
import { Link } from "react-router-dom";
import { links } from "../../data/menu";

const Footer = () => {
  // Separate links with and without sublinks
  const linksWithSublinks = links.filter((link) => link.subLinks);
  const linksWithoutSublinks = links.filter((link) => !link.subLinks);

  return (
    <>
      <footer className="bg-base-300 text-center">
        <div className="mx-6 pt-8 pb-4 text-center">
          <div className="mb-6">
            Tibia e as imagens relacionadas ao mesmo é uma marca registrada da{" "}
            <a
              href={"https://www.cipsoft.com/en/"}
              className="hover:underline text-accent"
            >
              CipSoft GmbH
            </a>
            .
          </div>

          {/* Render links with sublinks in separate columns */}
          <div className="grid grid-cols-1 md:grid-cols-4 mb-4">
            <ul className="mb-0 list-none">
              <h5 className="mb-2.5 font-bold uppercase">O Site</h5>
              {linksWithoutSublinks.map((link, index) => (
                <li key={index}>
                  <Link to={link.url} className="link-hover hover:text-accent">
                    {link.text}
                  </Link>
                </li>
              ))}
            </ul>

            {linksWithSublinks.map((link, index) => (
              <div key={index}>
                <h5 className="mb-2.5 font-bold uppercase mt-6 md:mt-0">
                  {link.text}
                </h5>
                <ul className="mb-0 list-none">
                  {link.subLinks.map((subLink, subIndex) => (
                    <li key={subIndex}>
                      <Link
                        to={subLink.url}
                        className="link-hover hover:text-accent"
                      >
                        {subLink.text}
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>

        <div className="p-6 text-center flex-col justify-center items-center">
          <p className="text-sm">
            &copy; {new Date().getFullYear()}{" "}
            <Link to="/" className="font-bold uppercase font-teko">
              Exori <span className="text-yellow-400">Gran</span>
            </Link>{" "}
            . Todos os direitos reservados.
          </p>

          <p className="mt-4 text-xs text-center flex justify-center items-center text-white gap-1">
            Desenvolvido com ❤️ por
            <Link to="https://jprzimba.com.br/" target="_blank">
              <span className="font-teko text-lg font-bold text-transparent bg-clip-text bg-gradient-to-r from-red-500 via-yellow-500 to-orange-500 shadow-fire">
                jprzimba
              </span>
            </Link>
          </p>
        </div>
      </footer>
    </>
  );
};

export default Footer;
