import React, { useState, useEffect, useRef, useCallback } from "react";
import { motion } from "framer-motion";
import {
  fadeIn,
  slideDown,
  slideIn,
  slideUp,
} from "../../../../utility/effects";
import {
  formatGold,
  formatNumberEx,
  formatTime,
} from "../../../../libs/functions";

const ExerciseWeaponsView = () => {
  const [vocation, setVocation] = useState("Knight");
  const [currentSkill, setCurrentSkill] = useState("");
  const [currentSkillPercentage, setCurrentSkillPercentage] = useState("0");
  const [targetSkill, setTargetSkill] = useState("");
  const [loyalty, setLoyalty] = useState("0");
  const [isEvent, setIsEvent] = useState(false);
  const [isDummy, setIsDummy] = useState(false);
  const [description, setDescription] = useState("");

  const exerciseFormResultsRef = useRef();

  const mainSkillCalculationPointsRequired = useCallback(
    (
      vocationConstant,
      currentSkill,
      currentSkillPercentage,
      targetSkill,
      isDummy,
      isEvent,
      skillOffset
    ) => {
      const currentSkillTotalPoints = totalSkillPointsAtGivenLevel(
        1600,
        vocationConstant,
        parseInt(currentSkill) + 1,
        skillOffset
      );
      const pointsToNextSkill =
        pointsToNextSkillLevel(
          1600,
          vocationConstant,
          parseInt(currentSkill),
          skillOffset
        ) *
        (currentSkillPercentage / 100);
      const targetSkillTotalPoints = totalSkillPointsAtGivenLevel(
        1600,
        vocationConstant,
        targetSkill,
        skillOffset
      );

      let totalPointsNeededForTarget =
        targetSkillTotalPoints - (currentSkillTotalPoints - pointsToNextSkill);

      if (isEvent) {
        totalPointsNeededForTarget /= 2;
      }

      if (isDummy) {
        totalPointsNeededForTarget /= 1.1;
      }

      return totalPointsNeededForTarget;
    },
    []
  );

  useEffect(() => {
    const calculateTrainingCost = () => {
      // Definir constantes
      const pointsMainSkillRegularWeapon = 300000;
      const pointsMainSkillDurableWeapon = pointsMainSkillRegularWeapon * 3.6;
      const pointsMainSkillLastingWeapon = pointsMainSkillRegularWeapon * 28.8;

      const costRegularK = 347222;
      const costDurableK = 1250000;
      const costLastingK = 10000000;

      const mainMagicConstant = 1.1;

      // Converter porcentagem para decimal
      const percentageDecimal = currentSkillPercentage.includes(",")
        ? currentSkillPercentage.replace(",", ".")
        : currentSkillPercentage;

      // Calcular pontos totais necessários para a habilidade alvo
      const totalPointsRequired = mainSkillCalculationPointsRequired(
        mainMagicConstant,
        currentSkill,
        percentageDecimal,
        targetSkill,
        isDummy,
        isEvent,
        0
      );

      // Calcular armas necessárias
      const regularWeaponsRequired = Math.ceil(
        totalPointsRequired /
          (pointsMainSkillRegularWeapon * (1 + loyalty / 100))
      );
      const durableWeaponsRequired = Math.ceil(
        totalPointsRequired /
          (pointsMainSkillDurableWeapon * (1 + loyalty / 100))
      );
      const lastingWeaponsRequired = Math.ceil(
        totalPointsRequired /
          (pointsMainSkillLastingWeapon * (1 + loyalty / 100))
      );

      // Calcular custos
      const calculateCosts = (weaponsRequired, costK) => {
        const costInK = weaponsRequired * costK;
        return {
          display: `${formatGold(costInK)}`,
        };
      };

      const regularCosts = calculateCosts(regularWeaponsRequired, costRegularK);
      const durableCosts = calculateCosts(durableWeaponsRequired, costDurableK);
      const lastingCosts = calculateCosts(lastingWeaponsRequired, costLastingK);

      // Atualizar resultados HTML
      exerciseFormResultsRef.current = (
        <div className="text-center">
          <p className="text-lg font-normal">
            Para upar da skill{" "}
            <span className="text-secondary">{currentSkill}</span> para a skill{" "}
            <span className="text-secondary">{targetSkill}</span>, você precisa
            usar um total de:
          </p>

          <div className="mt-4">
            <p className="mb-2">
              <span className="text-yellow-400">
                {formatNumberEx(regularWeaponsRequired)}
              </span>{" "}
              regular exercise weapons, a um custo de{" "}
              <span className="text-yellow-400">{regularCosts.display}</span>{" "}
              gold coins, tempo necessário:{" "}
              {formatTime(regularWeaponsRequired / 3.6)}
            </p>

            <p className="mb-2">
              <span className="text-yellow-400">
                {formatNumberEx(durableWeaponsRequired)}
              </span>{" "}
              durable exercise weapons, a um custo de{" "}
              <span className="text-yellow-400">{durableCosts.display}</span>{" "}
              gold coins, tempo necessário: {formatTime(durableWeaponsRequired)}
            </p>

            <p>
              <span className="text-yellow-400">
                {formatNumberEx(lastingWeaponsRequired)}
              </span>{" "}
              lasting exercise weapons, a um custo de{" "}
              <span className="text-yellow-400">{lastingCosts.display}</span>{" "}
              gold coins, tempo necessário:{" "}
              {formatTime(lastingWeaponsRequired * 8)}
            </p>
          </div>
        </div>
      );
      setDescription(exerciseFormResultsRef.current);
    };

    calculateTrainingCost();
  }, [
    vocation,
    currentSkill,
    currentSkillPercentage,
    targetSkill,
    loyalty,
    isDummy,
    isEvent,
    mainSkillCalculationPointsRequired,
  ]);

  const pointsToNextSkillLevel = (
    skillConstant,
    vocationConstant,
    skill,
    skillOffset
  ) => {
    const exponent = Math.pow(vocationConstant, skill - skillOffset);
    return skillConstant * exponent;
  };

  const totalSkillPointsAtGivenLevel = (
    skillConstant,
    vocationConstant,
    skill,
    skillOffset
  ) => {
    const exponent = Math.pow(vocationConstant, skill - skillOffset);
    return skillConstant * ((exponent - 1) / (vocationConstant - 1));
  };

  return (
    <>
      <motion.div
        className="max-w-screen-xl mx-auto p-4 pt-24"
        initial="hidden"
        animate="visible"
        variants={fadeIn}
      >
        <motion.h1
          className="text-4xl font-bold mb-4 uppercase text-center font-teko"
          variants={slideIn}
        >
          Calculadora de{" "}
          <span className="text-yellow-400">Exercise Weapons</span>
        </motion.h1>

        <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
          <h2 className="text-2xl mb-4">Como Utilizar?</h2>

          <div className="bg-base-300 first-letter:border border-base-100 p-4 rounded-lg shadow-md mb-8 relative">
            <ul className="mb-4 ml-4">
              <li className="list-decimal">
                Preencha os campos abaixo, escolhendo a vocação e informando a
                skill atual com Loyalty.
              </li>
              <li className="list-decimal">
                Insira a porcentagem do seu skill na barra.
              </li>
              <li className="list-decimal">
                Informe a skill desejada e escolha o bônus de Loyalty, se
                aplicável.
              </li>
              <li className="list-decimal">
                Marque as opções de evento de Double XP/Skill e Dummy Privado,
                se aplicáveis.
              </li>
              <li className="list-decimal">
                Nossa I.A irá calcular os resultados conforme você for alterando
                os campos.
              </li>
            </ul>
          </div>

          <div
            role="alert"
            className="alert alert-warning mb-4 max-w-4xl mx-auto"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="stroke-current shrink-0 h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
              />
            </svg>
            <span>
              Se Tibia Coin custar menos de <strong>10,5k.</strong> em seu
              servidor, compre <strong>Exercise Weapons</strong> na{" "}
              <strong>loja</strong>, caso contrário compre na{" "}
              <strong>Store</strong> do jogo.
            </span>
          </div>
        </motion.div>

        <motion.form
          className="w-4xl mx-auto justify-center flex flex-wrap gap-4"
          variants={slideUp}
        >
          <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
            <label htmlFor="vocation">Vocação & Skill:</label>
            <select
              name="vocation"
              id="vocation"
              value={vocation}
              onChange={(e) => setVocation(e.target.value)}
              className="select select-bordered select-secondary w-full"
            >
              <option value="Mage">Druid/Sorcerer - Magic Level</option>
              <option value="Knight">Knight - Melee</option>
              <option value="Paladin Magic">Paladin - Magic</option>
              <option value="Paladin Distance">Paladin - Distance</option>
            </select>
          </div>

          <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
            <label id="currentskilllabel" htmlFor="currentskill">
              Skill Atual com Loyalty:
            </label>
            <input
              type="number"
              id="currentskill"
              name="currentskill"
              autoComplete="off"
              value={currentSkill}
              onChange={(e) => setCurrentSkill(e.target.value)}
              className="input input-bordered input-secondary w-full"
            />
          </div>

          <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
            <label
              id="currentskillpercentagelabel"
              htmlFor="currentskillpercentage"
            >
              % para o próximo skill:
            </label>
            <input
              type="text"
              id="currentskillpercentage"
              name="currentskillpercentage"
              value={currentSkillPercentage}
              onChange={(e) => setCurrentSkillPercentage(e.target.value)}
              className="input input-bordered input-secondary w-full"
            />
          </div>

          <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
            <label id="targetskilllabel" htmlFor="targetskill">
              Skill Desejado:
            </label>
            <input
              type="number"
              id="targetskill"
              name="targetskill"
              autoComplete="off"
              value={targetSkill}
              onChange={(e) => setTargetSkill(e.target.value)}
              className="input input-bordered input-secondary w-full"
            />
          </div>

          <div className="mb-2 w-full md:w-1/2 lg:w-1/3">
            <label id="loyaltylabel" htmlFor="loyalty">
              Bônus de Loyalty:
            </label>
            <select
              name="loyalty"
              id="loyalty"
              value={loyalty}
              onChange={(e) => setLoyalty(e.target.value)}
              className="select select-bordered select-secondary w-full"
            >
              <option value="0">0%</option>
              <option value="5">5%</option>
              <option value="10">10%</option>
              <option value="15">15%</option>
              <option value="20">20%</option>
              <option value="25">25%</option>
              <option value="30">30%</option>
              <option value="35">35%</option>
              <option value="40">40%</option>
              <option value="45">45%</option>
              <option value="50">50%</option>
            </select>
          </div>

          <div className="mb-4 w-full flex flex-col md:flex-row gap-2 lg:items-center lg:justify-center">
            <div className="flex">
              <label id="eventlabel" htmlFor="event">
                Evento de Double XP/Skill?
              </label>
              <input
                type="checkbox"
                id="event"
                name="event"
                checked={isEvent}
                onChange={() => setIsEvent(!isEvent)}
                className="toggle toggle-success ml-2"
              />
            </div>

            <div className="flex">
              <label id="dummylabel" htmlFor="dummy" className="md:ml-4">
                Dummy Privado?
              </label>
              <input
                type="checkbox"
                id="dummy"
                name="dummy"
                checked={isDummy}
                onChange={() => setIsDummy(!isDummy)}
                className="toggle toggle-success ml-2"
              />
            </div>
          </div>
        </motion.form>

        {currentSkill > 0 && targetSkill > 0 && (
          <motion.div className="max-w-4xl mx-auto" variants={slideUp}>
            <motion.div
              className="bg-base-300 p-4 rounded-md shadow-md text-center max-w-4xl mx-auto"
              variants={slideDown}
            >
              <span className="font-normal">{description}</span>
            </motion.div>
          </motion.div>
        )}
      </motion.div>
    </>
  );
};

export default ExerciseWeaponsView;
