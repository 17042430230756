import DreamScarComponent from "../../../components/library/dream-scar";

const PageDreamScar = () => {
  return (
    <>
      <DreamScarComponent />
    </>
  );
};

export default PageDreamScar;
