const CopyToClipboard = ({ children, title }) => {
  function copyText(entryText) {
    navigator.clipboard.writeText(entryText);
  }

  function handleClick() {
    try {
      copyText(children);
    } catch (error) {
      console.error(error);
    }
  }

  return (
    <button
      className="border p-2 rounded-md flex items-center space-x-2 cursor-pointer hover:scale-102 transition-transform duration-300 active:text-primary  hover:bg-base-300 bg-base-100"
      title="Copiar para Área de Transferência"
      onClick={handleClick}
    >
      {title || title === "" ? title : children}
      <i className="bi bi-files" />
    </button>
  );
};

export default CopyToClipboard;
